// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as utils from './textFragment.utils';

export const applyTextFragmentHighlights = (textFragmentHash: string, document?: Document) => {
  if (!textFragmentHash) return;

  const fragmentDirectives = utils.getFragmentDirectives(textFragmentHash);

  const parsedFragmentDirectives = utils.parseFragmentDirectives(fragmentDirectives, document);

  const processedFragmentDirectives = utils.processFragmentDirectives(parsedFragmentDirectives, document);

  const createdMarks = processedFragmentDirectives['text'];

  const firstFoundMatch: HTMLSpanElement | undefined = createdMarks?.find(
    (marks: HTMLSpanElement[]) => marks.length,
  )?.[0];

  if (firstFoundMatch) {
    window.setTimeout(() =>
      firstFoundMatch.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      }),
    );
  }

  return !!firstFoundMatch;
};
