import { memo } from 'react';
import { ActionIcon, Flex, List, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

import { MedicalSubConditionsTooltipProps } from './MedicalSubConditionsTooltip.types';

const MedicalSubConditionsTooltipBase = ({ medicalCondition }: MedicalSubConditionsTooltipProps) => {
  const [opened, { toggle, close }] = useDisclosure();

  if (!medicalCondition.subConditions.length) return null;

  return (
    <Popover opened={opened} withArrow withinPortal shadow="md">
      <Popover.Target>
        <ActionIcon
          size="xs"
          color="dark.3"
          onClickCapture={(event) => {
            event.stopPropagation();
            toggle();
          }}
        >
          <IconInfoCircle size={14} />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown sx={{ overflow: 'auto' }}>
        <Flex direction="column" gap={4}>
          <Flex align="center" gap="xs" justify="space-between">
            <Text fz="0.75rem" fw={600} color="dark.3">
              Related conditions
            </Text>

            <ActionIcon
              size="xs"
              onClickCapture={(event) => {
                event.stopPropagation();
                close();
              }}
            >
              <IconX size={12} />
            </ActionIcon>
          </Flex>

          <List type="ordered" fz="0.75rem">
            {medicalCondition.subConditions.map((subCondition) => (
              <List.Item key={subCondition}>
                <Text fz="0.75rem" sx={{ whiteSpace: 'nowrap' }}>
                  {subCondition}
                </Text>
              </List.Item>
            ))}
          </List>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const OldMedicalSubConditionsTooltip = memo(MedicalSubConditionsTooltipBase);
