import { create } from 'zustand';

import { LayoutState, LeftSidebarType, RightSidebarType } from './layout.states.types';

export const useLayoutStore = create<LayoutState>(() => ({
  isLeftSidebarOpened: false,
  isRightSidebarOpened: false,
  isPrinting: false,
  leftSidebarType: LeftSidebarType.CONVERSATIONS,
  rightSidebarType: RightSidebarType.ASSET,
}));

export const toggleRightSidebar = (rightSidebarType = RightSidebarType.ASSET) =>
  useLayoutStore.setState((state) => ({
    isRightSidebarOpened: !state.isRightSidebarOpened,
    rightSidebarType,
  }));

export const openRightSidebar = (rightSidebarType = RightSidebarType.ASSET) =>
  useLayoutStore.setState({ isRightSidebarOpened: true, rightSidebarType });

export const closeRightSidebar = () => useLayoutStore.setState({ isRightSidebarOpened: false });

export const toggleLeftSidebar = (leftSidebarType = LeftSidebarType.CONVERSATIONS) =>
  useLayoutStore.setState((state) => ({ isLeftSidebarOpened: !state.isLeftSidebarOpened, leftSidebarType }));

export const openLeftSidebar = (leftSidebarType = LeftSidebarType.CONVERSATIONS) =>
  useLayoutStore.setState({ isLeftSidebarOpened: true, leftSidebarType });

export const closeLeftSidebar = () => useLayoutStore.setState({ isLeftSidebarOpened: false });

export const togglePrinting = (isPrinting?: boolean) =>
  useLayoutStore.setState((state) => ({
    isPrinting: typeof isPrinting !== 'undefined' ? isPrinting : !state.isPrinting,
  }));

export const setHeaderRightSection = (headerRightSection?: React.ReactNode) =>
  useLayoutStore.setState({ headerRightSection });

useLayoutStore.subscribe((state, prevState) => {
  if (state.isPrinting === prevState.isPrinting) return;

  if (state.isPrinting) {
    document.body.classList.add('printing');

    return;
  }

  document.body.classList.remove('printing');
});
