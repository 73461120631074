import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ResponseError } from '@/shared/@types';
import { DeleteFilesMutation, DeleteFilesMutationVariables } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { deleteFilesMutation } from '@/pageAI/api';

export const useDeleteFiles = (collectionId?: string) => {
  const queryClient = useQueryClient();
  const { request } = useGraphQLRequest();

  const { mutateAsync: deleteFiles } = useMutation<DeleteFilesMutation, ResponseError, DeleteFilesMutationVariables>({
    mutationFn: async (variables) => {
      return request(deleteFilesMutation, variables);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['files']);

      if (collectionId) {
        queryClient.refetchQueries(['file-collections']);
      }
    },
  });

  return { deleteFiles };
};
