import { memo } from 'react';
import { Flex } from '@mantine/core';

import { DeleteAssetButton } from '../DeleteAssetButton';
import { AssetItemActionsProps } from './AssetItemActions.types';
import { ShareFileButton } from '../ShareFileButton';
import { AssetType } from '@/shared/@types';

const AssetItemActionsBase: React.FC<AssetItemActionsProps> = ({
  asset,
  collectionId,
  hideDeleteButton = false,
  canShare = false,
  onDeleted,
}) => {
  return (
    <Flex gap={4} justify="flex-end">
      {canShare && asset.type === AssetType.FILE && <ShareFileButton fileAsset={asset} />}

      {!hideDeleteButton && <DeleteAssetButton assets={[asset]} collectionId={collectionId} onDeleted={onDeleted} />}
    </Flex>
  );
};

export const AssetItemActions = memo(AssetItemActionsBase);
