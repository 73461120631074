import { createStyles, Container, Title, Text, Button, Group, Box } from '@mantine/core';
import { Link } from 'react-router-dom';

import { NotFoundIllustration } from '@/shared/images/NotFoundIllustration';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inner: {
    position: 'relative',
    flexGrow: 1,
  },

  image: {
    ...theme.fn.cover(),
    opacity: 0.75,
  },

  content: {
    paddingTop: 220,
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '2.375rem',

    [theme.fn.smallerThan('sm')]: {
      fontSize: '2rem',
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.lg,
  },
}));

interface NotFoundPlaceholderProps {
  title?: string;
  description?: string;
  action?: React.ReactNode;
}

export const NotFoundPlaceholder: React.FC<NotFoundPlaceholderProps> = ({
  title = 'Nothing to see here',
  description = 'The page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error, please contact us.',
  action = 'Take me back to home page',
}) => {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <Box className={classes.inner}>
        <NotFoundIllustration className={classes.image} />

        <Box className={classes.content}>
          <Title className={classes.title}>{title}</Title>

          <Text color="dimmed" size="lg" align="center" className={classes.description}>
            {description}
          </Text>

          <Group position="center">
            {typeof action === 'string' ? (
              <Link to="/">
                <Button size="md">{action}</Button>
              </Link>
            ) : (
              action
            )}
          </Group>
        </Box>
      </Box>
    </Container>
  );
};
