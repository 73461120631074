import { memo } from 'react';
import { Flex, Text } from '@mantine/core';

import { Panel } from '@/pageAI/designSystem/Panel';
import AddUserIcon from '@/pageAI/icons/add-user.svg?react';
import { CreateClientButton } from '../CreateClientButton';

const CreateClientPanelBase = () => {
  return (
    <Panel sx={{ padding: 96, alignItems: 'center' }}>
      <AddUserIcon width={80} height={80} />

      <Flex direction="column" align="center" gap={4}>
        <Text fz="1.25rem" fw={600} color="dark.5" align="center">
          Create your first client
        </Text>

        <Text color="dark.2" align="center" mb="xs">
          Get started with Page by creating your first client record.
          <br />
          You can upload files and get summaries of the case timeline, medical records, service and claims history
          within minutes.
        </Text>

        <CreateClientButton />
      </Flex>
    </Panel>
  );
};

export const CreateClientPanel = memo(CreateClientPanelBase);
