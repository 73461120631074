import { useMutation } from '@tanstack/react-query';

import {
  ApproveRequestToJoinOrganizationInput,
  ApproveRequestToJoinOrganizationMutation,
  DeclineRequestToJoinOrganizationInput,
  DeclineRequestToJoinOrganizationMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { approveRequestToJoinOrganizationMutation, declineRequestToJoinOrganizationMutation } from '@/pageAI/api';
import { useUpdateOrganization } from '../useUpdateOrganization';

export const useManageRequestToJoinOrganization = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateOrganizationLocally } = useUpdateOrganization();

  const { mutateAsync: approveRequest, isLoading: isApproving } = useMutation<
    ApproveRequestToJoinOrganizationMutation,
    Error,
    ApproveRequestToJoinOrganizationInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(approveRequestToJoinOrganizationMutation, { input });

      return responseBody;
    },
    onSuccess: (responseBody, input) => {
      if (!responseBody.approveRequestToJoinOrganization?.joinRequest) return;

      const joinRequest = responseBody.approveRequestToJoinOrganization.joinRequest;
      const organizationId = joinRequest.organization.id;

      notify('Success', 'Request to join the organization has been approved', 'brand');

      updateOrganizationLocally(organizationId)((currentOrganization) => ({
        joinRequests: currentOrganization.joinRequests.filter((request) => request.id !== input.joinRequestId),
      }));
    },
    onError: () => {
      notify('Error', 'Could not approve request to join the organization');
    },
  });

  const { mutateAsync: declineRequest, isLoading: isDeclining } = useMutation<
    DeclineRequestToJoinOrganizationMutation,
    Error,
    DeclineRequestToJoinOrganizationInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(declineRequestToJoinOrganizationMutation, { input });

      return responseBody;
    },
    onSuccess: (responseBody, input) => {
      if (!responseBody.declineRequestToJoinOrganization?.joinRequest) return;

      const joinRequest = responseBody.declineRequestToJoinOrganization.joinRequest;
      const organizationId = joinRequest.organization.id;

      notify('Success', 'Request to join the organization has been declined');

      updateOrganizationLocally(organizationId)((currentOrganization) => ({
        joinRequests: currentOrganization.joinRequests.filter((request) => request.id !== input.joinRequestId),
      }));
    },
    onError: () => {
      notify('Error', 'Could not decline request to join the organization');
    },
  });

  return { approveRequest, declineRequest, isApproving, isDeclining };
};
