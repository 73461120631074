import { memo } from 'react';
import { Flex, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import { ActionPanelProps } from './ActionPanel.types';

const ActionPanelBase = ({ label, subLabel, variant = 'dark', icon, sx, url, onClick }: ActionPanelProps) => {
  const button =
    variant === 'dark' ? (
      <Flex
        direction="column"
        gap="xs"
        py="lg"
        px="xl"
        sx={(theme) => ({
          width: '100%',
          borderRadius: 4,
          cursor: 'pointer',
          transition: 'all 0.1s',
          backgroundColor: theme.colors.brand[4],
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.brand[6] },
          ...sx,
        })}
        onClick={onClick}
      >
        {icon}

        <Text fz="1.25rem" fw={600}>
          {label}
        </Text>
      </Flex>
    ) : (
      <Flex
        direction="column"
        gap="xs"
        py="lg"
        px="xl"
        sx={(theme) => ({
          width: '100%',
          border: `1px solid ${theme.colors.gray[2]}`,
          borderRadius: 4,
          cursor: 'pointer',
          transition: 'all 0.1s',
          backgroundColor: theme.white,
          '&:hover': { backgroundColor: theme.colors.gray[0] },
          ...sx,
        })}
        onClick={onClick}
      >
        {icon}

        <Text fz="1rem" fw={600} color="dark.5">
          {label}
        </Text>

        <Text fz="0.875rem" color="dark.2">
          {subLabel}
        </Text>
      </Flex>
    );

  if (typeof url === 'string') {
    return (
      <Link to={url} style={{ textDecoration: 'none', flexGrow: 1 }}>
        {button}
      </Link>
    );
  }

  return <>{button}</>;
};

export const ActionPanel = memo(ActionPanelBase);
