import { memo, useCallback } from 'react';
import { Box, Flex, ScrollArea } from '@mantine/core';

import { UnifiedFileIndexProps } from './UnifiedFileIndex.types';
import { ClientFilePanel } from '../../clients/ClientFilePanel';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { AssetItem, AssetType } from '@/shared/@types';
import { AddOrRemoveFileFromConditionButton } from '../../medicalConditions/errorCorrection/AddOrRemoveFileFromConditionButton';
import { useUnifiedScrollPosition } from '@/pageAI/hooks/unified/useUnifiedScrollPosition/useUnifiedScrollPosition';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { truthy } from '@/shared/utils/boolean';

const UnifiedFileIndexBase = (props: UnifiedFileIndexProps) => {
  const client = useSelectedClient(true);
  const { isLoading, isError } = useFileGroups();

  const scrollAreaRef = useUnifiedScrollPosition(UnifiedTab.FILE_INDEX);

  const renderActionCell = useCallback((assetItem: AssetItem) => {
    if (assetItem.type === AssetType.COLLECTION) return null;

    return (
      <Flex gap={4} justify="flex-end">
        <AddOrRemoveFileFromConditionButton fileAsset={assetItem} />
      </Flex>
    );
  }, []);

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading file index..."
          description={[
            'Please wait while Page retrieves the file index',
            client ? ` of ${getClientDisplayName(client)}` : '',
            '.',
          ]
            .filter(truthy)
            .join('')}
          fullScreen
        />
      </Flex>
    );

  if (isError) throw new Error('Could not load file index');

  return (
    <ScrollArea h="calc(100vh - 56px)" ref={scrollAreaRef}>
      <Box px={32}>
        <ClientFilePanel
          client={client}
          hiddenColumns={['Classification', 'Status']}
          colgroup={
            <colgroup>
              <col style={{ width: '3%', minWidth: 100 }} />
              <col style={{ width: '60%' }} />
              <col style={{ width: '17%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
          }
          hideUploadButton
          renderActionCell={renderActionCell}
        />
      </Box>
    </ScrollArea>
  );
};

export const UnifiedFileIndex = memo(UnifiedFileIndexBase);
