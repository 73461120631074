import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ClientPermissionRole,
  ClientPermissionType,
  ClientQuery,
  CreateClientPermissionInput,
  CreateClientPermissionMutation,
  DeleteClientPermissionMutation,
  DeleteClientPermissionMutationVariables,
  UpdateClientPermissionInput,
  UpdateClientPermissionMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import {
  createClientPermissionMutation,
  deleteClientPermissionMutation,
  updateClientPermissionMutation,
} from '@/pageAI/api';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateClient } from '../useUpdateClient';

export const useShareClient = () => {
  const { notify } = useNotifications();
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { updateClientLocally } = useUpdateClient();

  const { mutateAsync: createClientPermission, isLoading: isSharing } = useMutation<
    CreateClientPermissionMutation,
    Error,
    CreateClientPermissionInput
  >({
    mutationFn: async (input) => {
      return request(createClientPermissionMutation, { input });
    },
  });

  const { mutateAsync: updateClientPermission, isLoading: isUpdating } = useMutation<
    UpdateClientPermissionMutation,
    Error,
    UpdateClientPermissionInput
  >({
    mutationFn: async (input) => {
      return request(updateClientPermissionMutation, { input });
    },
  });

  const shareClient = useCallback(
    async (clientId: string, email: string, role = ClientPermissionRole.Viewer) => {
      try {
        const responseBody = await createClientPermission({
          clientId,
          email,
          role,
          type: ClientPermissionType.User,
        });

        const clientQuery = queryClient.getQueryData<ClientQuery>(['client', clientId]);

        if (!clientQuery) throw new Error('Client not found');

        const permissions = {
          ...clientQuery.client.permissions,
          nodes: [...clientQuery.client.permissions.nodes, responseBody.createClientPermission],
          totalCount: clientQuery.client.permissions.totalCount + 1,
        };

        updateClientLocally({ id: clientId, permissions });

        notify('Success', 'Access updated', 'brand');
      } catch (error) {
        notify('Error', 'Failed to share client');

        throw error;
      }
    },
    [createClientPermission, updateClientLocally, notify, queryClient],
  );

  const updateClientPermissionRole = useCallback(
    async (clientId: string, permissionId: string, email: string, role: ClientPermissionRole) => {
      try {
        await updateClientPermission({ id: permissionId, email, role });

        const clientQuery = queryClient.getQueryData<ClientQuery>(['client', clientId]);

        if (!clientQuery) throw new Error('Client not found');

        const permissions = {
          ...clientQuery.client.permissions,
          nodes: clientQuery.client.permissions.nodes.map((permission) => {
            if (permission.id === permissionId) {
              return { ...permission, role };
            }

            return permission;
          }),
        };

        updateClientLocally({ id: clientId, permissions });

        notify('Success', 'Access updated', 'brand');
      } catch (error) {
        notify('Error', 'Failed to update permission role');

        throw error;
      }
    },
    [updateClientPermission, notify, updateClientLocally, queryClient],
  );

  const { mutateAsync: deleteClientPermission, isLoading: isUnsharing } = useMutation<
    DeleteClientPermissionMutation,
    Error,
    DeleteClientPermissionMutationVariables
  >({
    mutationFn: async ({ id }) => {
      return request(deleteClientPermissionMutation, { id });
    },
  });

  const unshareClient = useCallback(
    async (clientId: string, permissionId: string) => {
      try {
        await deleteClientPermission({
          id: permissionId,
        });

        const clientQuery = queryClient.getQueryData<ClientQuery>(['client', clientId]);

        if (!clientQuery) throw new Error('Client not found');

        const permissions = {
          ...clientQuery.client.permissions,
          nodes: clientQuery.client.permissions.nodes.filter((permission) => permission.id !== permissionId),
          totalCount: clientQuery.client.permissions.totalCount - 1,
        };

        updateClientLocally({ id: clientId, permissions });

        notify('Success', 'Access updated', 'brand');
      } catch (error) {
        notify('Error', 'Failed to revoke access');

        throw error;
      }
    },
    [deleteClientPermission, updateClientLocally, queryClient, notify],
  );

  return { shareClient, updateClientPermissionRole, unshareClient, isSharing, isUpdating, isUnsharing };
};
