import { memo } from 'react';
import { ActionIcon, Loader, Tooltip } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { ClientItemAlertProps } from './ClientItemAlert.types';
import { useFileUploadStatusForClient } from '@/pageAI/hooks/files/useUploadFileStatusForClient';
import { useEnsureTimelineGeneration } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';
import { FileUploadStatus } from '@/shared/@types';

const ClientItemAlertBase = ({ client }: ClientItemAlertProps) => {
  const { status: uploadStatus } = useFileUploadStatusForClient(client.id);
  const { hasScheduled } = useEnsureTimelineGeneration(client);

  if (!client.viewerCanUploadFiles) return null;

  if (uploadStatus === FileUploadStatus.UPLOADING) {
    return (
      <Tooltip
        label={
          <>
            The client&apos;s files are being uploaded. Please do not close or refresh Page.
            {hasScheduled && (
              <>
                <br />
                The timeline will be generated once your upload is complete.
              </>
            )}
          </>
        }
        withinPortal
        withArrow
      >
        <ActionIcon>
          <Loader size={14} />
        </ActionIcon>
      </Tooltip>
    );
  }

  if (hasScheduled) {
    return (
      <Tooltip
        label={
          <>
            It seems like some of the client&apos;s files are missing. Please ensure that you have uploaded all the
            relevant files.
            <br />
            The timeline generation process will not start until then.
          </>
        }
        withinPortal
        withArrow
      >
        <ActionIcon color="red">
          <IconAlertTriangle size={16} />
        </ActionIcon>
      </Tooltip>
    );
  }

  return null;
};

export const ClientItemAlert = memo(ClientItemAlertBase);
