import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ActionIcon, Flex, LoadingOverlay, Switch, Text, Tooltip } from '@mantine/core';
import { IconChevronsRight, IconInfoCircle } from '@tabler/icons-react';

import { AssetSidebarProps } from './AssetSidebar.types';
import { TextPreviewer, TextPreviewerRef } from '@/shared/components/preview/TextPreviewer';
import {
  FilePreviewTab,
  setFilePreviewTextContent,
  setIsLoadingTextContent,
  useFilePreviewStore,
} from '@/shared/states/filePreview';
import { closeRightSidebar } from '@/shared/states/layout';
import { useGetOcrText } from '@/shared/hooks/files/useGetOcrText';
import { AssetType } from '@/shared/@types';
import { NotFoundPlaceholder } from '@/shared/components/common/placeholders/NotFoundPlaceholder';
import { PDF_HIGHLIGHTING_FLAG_STORAGE_KEY } from '@/shared/services/files';
import { isFeatureFlagEnabled, toggleFeatureFlag } from '@/shared/services/featureFlags';
import { PDFPreviewer, PDFPreviewerInstance } from '@/shared/components/pdf/PDFPreviewer';
import { formatDate } from '@/shared/utils/date';
import { AssetSidebarActionMenu } from '../AssetSidebarActionMenu';

const AssetSidebarBase: React.FC<AssetSidebarProps> = () => {
  const {
    asset,
    displayName,
    textContent,
    isLoadingTextContent,
    defaultTab,
    initialPageIndex,
    highlightedTexts,
    pagesToHighlight,
    alwaysHighlight,
  } = useFilePreviewStore();
  const { getOcrText } = useGetOcrText();

  const pdfPreviewerInstanceRef = useRef<PDFPreviewerInstance | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const textPreviewerRef = useRef<TextPreviewerRef | null>(null);
  const [activeTab, setActiveTab] = useState<FilePreviewTab>(defaultTab);
  const [containerY, setContainerY] = useState(0);
  const currentPDFPageIndex = useRef(initialPageIndex);
  const [isPDFHighlightingEnabled, setIsPDFHighlightingEnabled] = useState(
    isFeatureFlagEnabled(PDF_HIGHLIGHTING_FLAG_STORAGE_KEY),
  );

  useEffect(() => {
    if (!containerRef.current) return;

    const { y } = containerRef.current.getBoundingClientRect();

    setContainerY(y);
  }, [containerRef]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (asset?.type !== AssetType.FILE || textContent || isLoadingTextContent) return;

    (async () => {
      setIsLoadingTextContent(true);

      const ocrText = await getOcrText(asset.id);

      setFilePreviewTextContent(ocrText);
      setIsLoadingTextContent(false);
    })();
  }, [asset, textContent, getOcrText, isLoadingTextContent]);

  const handleTabChange = useCallback((newTabValue: FilePreviewTab) => {
    setActiveTab(newTabValue);
  }, []);

  const handlePDFHighlightingChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPDFHighlightingEnabled(event.currentTarget.checked);

    toggleFeatureFlag(PDF_HIGHLIGHTING_FLAG_STORAGE_KEY)(event.currentTarget.checked);
  }, []);

  const renderTextPreviewer = () => {
    if (isLoadingTextContent) return <LoadingOverlay visible />;

    if (!textContent)
      return (
        <NotFoundPlaceholder
          action={null}
          description="The text content of this file does not exist. Either the file does not exist or it has not gone through the OCR process yet. If you think this is an error, please contact us."
        />
      );

    return (
      <TextPreviewer
        ref={textPreviewerRef}
        content={textContent}
        initialPageIndex={currentPDFPageIndex.current}
        paginated={!!textContent && !!textContent.length}
      />
    );
  };

  const renderSecondaryRightSection = () => {
    if (alwaysHighlight) return null;

    return (
      <Flex
        align="center"
        sx={{
          position: 'relative',
          marginTop: 6,
          marginRight: 8,
          ...(activeTab === FilePreviewTab.PDF && highlightedTexts.length > 0
            ? {}
            : {
                opacity: 0,
                pointerEvents: 'none',
              }),
        }}
        gap={4}
      >
        <Switch
          size="sm"
          label="Highlight"
          sx={{ '.ghost-Switch-label': { paddingLeft: 4 } }}
          checked={isPDFHighlightingEnabled}
          onChange={handlePDFHighlightingChange}
        />

        <Tooltip label="The PDF highlighting feature is experimental." withinPortal withArrow>
          <Flex sx={(theme) => ({ color: theme.colors.gray[5] })}>
            <IconInfoCircle size={16} />
          </Flex>
        </Tooltip>
      </Flex>
    );
  };

  const handlePDFPageIndexChange = useCallback((pageIndex: number) => {
    currentPDFPageIndex.current = pageIndex;
  }, []);

  const assetDisplayName = displayName || `${asset?.name} [${asset?.index}]`;
  const fullTextToDisplay = (
    <>
      {assetDisplayName}
      {asset?.createdAt && <>&nbsp;-&nbsp;Received {formatDate(asset.createdAt)}</>}
    </>
  );

  return (
    <Flex
      direction="column"
      sx={(theme) => ({
        height: '100%',
        background: theme.fn.lighten(theme.colors.gray[0], 0.5),
        position: 'relative',
        zIndex: 2,
      })}
      ref={containerRef}
    >
      <Flex align="center" justify="center" sx={{ width: '100%', position: 'relative' }} px="xs" mt="sm" gap="xs">
        <Flex
          align="center"
          justify="center"
          direction="column"
          sx={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          <Flex align="center" gap={4} sx={{ width: '100%' }}>
            <Tooltip label={fullTextToDisplay} withinPortal withArrow>
              <Text
                fz="0.875rem"
                fw={600}
                color="dark.4"
                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '80%' }}
              >
                {assetDisplayName}
              </Text>
            </Tooltip>

            {asset?.createdAt && (
              <Text
                fz="0.75rem"
                color="dark.4"
                mt={-4}
                mb={-4}
                sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                – Received {formatDate(asset.createdAt)}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex align="center" justify="flex-end" gap={4}>
          {createPortal(renderSecondaryRightSection(), document.querySelector('#toolbarViewerRight') || document.body)}

          <AssetSidebarActionMenu tab={activeTab} onTabChange={handleTabChange} />

          <Tooltip label="Collapse" withArrow withinPortal>
            <ActionIcon onClick={closeRightSidebar} color="gray.6" size="xs">
              <IconChevronsRight size={16} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex align="center" justify="center" mt={4}></Flex>

      <Flex sx={{ padding: '8px 0 0', height: `calc(100vh - 44px - ${containerY}px)` }}>
        {activeTab === FilePreviewTab.PDF && (
          <PDFPreviewer
            ref={pdfPreviewerInstanceRef}
            fileUrl={asset?.url}
            initialPageIndex={initialPageIndex}
            highlightedTexts={isPDFHighlightingEnabled || alwaysHighlight ? highlightedTexts : []}
            highlightWithinPages={pagesToHighlight}
            onPageChange={handlePDFPageIndexChange}
          />
        )}

        {activeTab === FilePreviewTab.Text && (
          <Flex direction="column" sx={{ width: '100%' }}>
            <Flex
              sx={{
                width: '100%',
                flexGrow: 1,
                minHeight: 0,
                minWidth: 0,
                position: 'relative',
              }}
            >
              {renderTextPreviewer()}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const AssetSidebar = memo(AssetSidebarBase);
