import { memo, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Button, Flex, Loader, Popover, ScrollArea, Text, TextInput } from '@mantine/core';
import { useDisclosure, usePrevious } from '@mantine/hooks';
import { IconCircleCheckFilled, IconFile, IconSelector } from '@tabler/icons-react';

import { TimelineFileFilterProps } from './TimelineFileFilter.types';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { singularOrPlural } from '@/shared/utils/string';
import { getFileNamesFromFileGroups } from '@/pageAI/services/files';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { truthy } from '@/shared/utils/boolean';
import { resetFileGroupFiltersEventBus } from '../TimelineFileGroupFilter';

const TimelineFileFilterBase = ({
  initialValue = [],
  fileGroupValues,
  selectableFileTypes,
  maxHeight = 400,
  onChange,
}: TimelineFileFilterProps) => {
  const { fileGroupsMapping } = useFileGroups();
  const previousFileGroupValues = usePrevious(fileGroupValues);

  const [isPending, startTransition] = useTransition();
  const [opened, { open, close }] = useDisclosure();

  const [searchValue, setSearchValue] = useState('');
  const [value, setValue] = useState<string[] | null>(initialValue);
  const skipFileGroupValuesEffect = useRef(false);

  const filteredSelectableFileTypes = useMemo(
    () => selectableFileTypes.filter((fileType) => fileType.toLowerCase().includes(searchValue.toLowerCase())),
    [selectableFileTypes, searchValue],
  );

  const changeValue = useCallback(
    (newValue: string[] | null) => {
      setValue(newValue);

      startTransition(() => onChange?.(newValue));
    },
    [onChange],
  );

  useEffect(() => {
    if (previousFileGroupValues === fileGroupValues || (!previousFileGroupValues && !fileGroupValues?.length)) return;

    if (skipFileGroupValuesEffect.current) {
      skipFileGroupValuesEffect.current = false;

      return;
    }

    if (!fileGroupValues || fileGroupValues.length === 0) return changeValue([]);

    const fileNames = getFileNamesFromFileGroups(
      (fileGroupValues || []).map((value) => fileGroupsMapping[value]).filter(truthy),
    );

    const newlySelectedFileTypes = fileNames.filter((fileName) => selectableFileTypes.includes(fileName));

    if (!newlySelectedFileTypes.length) return changeValue(null);

    changeValue(newlySelectedFileTypes);
  }, [previousFileGroupValues, fileGroupsMapping, fileGroupValues, selectableFileTypes, changeValue]);

  const handleValueChange = (rawSelectedValue: string[] | null) => {
    resetFileGroupFiltersEventBus.publish({});
    skipFileGroupValuesEffect.current = true;

    if (rawSelectedValue === null) return changeValue(null);

    if (rawSelectedValue?.length === 0) return changeValue([]);

    const currentlySelectedFileTypes = value && value.length === 0 ? selectableFileTypes : value || [];

    let actualNewValue: string[] | null =
      value?.length === selectableFileTypes.length ? [] : currentlySelectedFileTypes;

    const selectedValue = rawSelectedValue[0];

    if (currentlySelectedFileTypes.includes(selectedValue)) {
      actualNewValue = currentlySelectedFileTypes.filter((fileType) => fileType !== selectedValue);

      if (actualNewValue.length === 0) actualNewValue = null;
    } else {
      actualNewValue = [...currentlySelectedFileTypes, selectedValue];
    }

    if (actualNewValue?.length === selectableFileTypes.length) actualNewValue = [];

    changeValue(actualNewValue);
  };

  const handleClearAll = () => {
    handleValueChange(null);
  };

  const handleSelectAll = () => {
    if (value && (value.length === 0 || value.length === selectableFileTypes.length)) return;

    handleValueChange([]);
  };

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    setSearchValue(newSearchValue);
  };

  const renderActionButtons = () => {
    return (
      <Flex
        align="center"
        justify="space-between"
        sx={(theme) => ({
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 8,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          '.ghost-Button-root': {
            padding: 0,
            fontWeight: 500,
            height: 'fit-content',
            '.ghost-Button-leftIcon': {
              marginRight: 4,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              borderBottom: `1px solid ${theme.colors.dark[3]}`,
            },
          },
        })}
      >
        <Flex ml={16}>
          <Text fz="0.75rem" color="dark.4">
            {getPlaceholder()}
          </Text>
        </Flex>

        <Flex align="center">
          <Button size="xs" variant="subtle" onClick={handleClearAll}>
            Unselect all
          </Button>

          <Text fz="0.875rem" color="dark.4" mx={2}>
            -
          </Text>

          <Button size="xs" variant="subtle" onClick={handleSelectAll}>
            Select all
          </Button>
        </Flex>
      </Flex>
    );
  };

  const getPlaceholder = () => {
    if (value?.length === 0)
      return `${selectableFileTypes.length} ${singularOrPlural(
        'file type',
        'file types',
      )(selectableFileTypes.length)} selected`;

    if (!value) return '0 file types selected';

    return `${value.length} ${singularOrPlural('file type', 'file types')(value.length)} selected`;
  };

  return (
    <Popover opened={opened} position="bottom" shadow="sm" width="target" closeOnClickOutside onClose={close}>
      <Popover.Target>
        <TextInput
          value={searchValue}
          onChange={handleSearchValueChange}
          placeholder={getPlaceholder()}
          onFocusCapture={open}
          size="xs"
          icon={<IconFile size={16} />}
          rightSection={isPending ? <Loader size={12} /> : <IconSelector size={12} onClick={open} />}
          w={360}
        />
      </Popover.Target>

      <Popover.Dropdown p={0}>
        {renderActionButtons()}

        <ScrollArea
          sx={{
            height: Math.max(Math.min(maxHeight, filteredSelectableFileTypes.length * 32 + 8), 32),
            padding: '0 4px',
            position: 'relative',
          }}
        >
          {filteredSelectableFileTypes.length > 0 ? (
            <>
              {filteredSelectableFileTypes.map((fileType, index) => {
                const isSelected = value?.length === 0 || !!value?.includes(fileType);

                return (
                  <MenuItem
                    key={fileType}
                    title={fileType}
                    sx={{
                      height: 32,
                      fontSize: '0.75rem',
                      '.ghost-Text-root': {
                        maxWidth: 280,
                      },
                      ...(index === 0 && {
                        marginTop: 4,
                      }),
                      ...(index === filteredSelectableFileTypes.length - 1 && {
                        marginBottom: 4,
                      }),
                    }}
                    onClick={() => handleValueChange([fileType])}
                    rightSection={
                      isSelected ? (
                        <Flex align="center" justify="center" sx={{ minWidth: 16, minHeight: 16 }}>
                          <IconCircleCheckFilled size={16} />
                        </Flex>
                      ) : null
                    }
                  />
                );
              })}
            </>
          ) : (
            <Flex align="center" justify="center" sx={{ height: 32, paddingLeft: 12 }}>
              <Text fz="0.75rem" color="gray.7">
                Nothing found
              </Text>
            </Flex>
          )}
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  );
};

export const TimelineFileFilter = memo(TimelineFileFilterBase);
