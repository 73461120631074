import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';

export const useNotifications = () => {
  const notify = useCallback(
    (title = 'Error', message: React.ReactNode = 'Error', color = 'red', autoClose: number | boolean = 5000) => {
      const id = uuidv4();

      notifications.show({
        id,
        ...(typeof autoClose === 'number' ? { autoClose } : { autoClose: false }),
        title,
        message,
        color,
        sx: { backgroundColor: 'white' },
        loading: false,
        withBorder: true,
      });

      return () => notifications.hide(id);
    },
    [],
  );

  return { notify };
};
