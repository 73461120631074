import { useQuery } from '@tanstack/react-query';

import { organizationInvitationQuery } from '@/pageAI/api/organization';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';

export const fetchOrganizationInvitation = async (request: GraphQLRequestFunction, id: string | undefined | null) => {
  if (!id) return Promise.resolve(null);

  const responseBody = await request(organizationInvitationQuery, { id });

  if (!responseBody.organizationInvitation) return null;

  return responseBody.organizationInvitation;
};

export const queryMap = {
  organizationInvitation: {
    queryKey: (id?: string | null) => ['organization-invitation', id],
    queryFn: fetchOrganizationInvitation,
  },
};

export type OrganizationInvitationQuery = Awaited<ReturnType<typeof queryMap.organizationInvitation.queryFn>>;

export const useOrganizationInvitation = (id?: string | null) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(queryMap.organizationInvitation.queryKey(id), () =>
    queryMap.organizationInvitation.queryFn(request, id),
  );

  return { invitation: data, isLoading, isError };
};
