type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const waitUntilTruthy = (checkFunction: () => boolean, numOfRetries = 5) => {
  return new Promise((resolve, reject) => {
    let retries = 0;

    const loop = () => {
      const isTruthy = checkFunction();

      if (isTruthy) return resolve(isTruthy);

      retries++;

      if (retries >= numOfRetries) {
        reject(new Error('waitUntilTruthy: Exceeded number of retries'));
        return;
      }

      requestIdleCallback(loop);
    };

    requestIdleCallback(loop);
  });
};
