import { Organization, OrganizationMember, OrganizationMemberRole } from '@/pageAI/gql/graphql';

export const ORGANIZATION_MEMBER_ROLE_DISPLAY_TEXT_MAPPING = {
  ADMIN: 'Admin',
  OWNER: 'Owner',
  EDITOR: 'Member',
  MEMBER: 'Member',
};

export const ORGANIZATION_MEMBER_ROLE_COLOR_MAPPING = {
  ADMIN: 'orange',
  OWNER: 'red',
  EDITOR: 'gray',
  MEMBER: 'gray',
};

export const canUpdateMemberRole = (currentUser: OrganizationMember) =>
  currentUser.role === OrganizationMemberRole.Owner;

export const getOrganizationDisplayName = (organization: Pick<Organization, 'name' | 'website'>) => {
  return organization.name || organization.website;
};

export const canInviteMembers = (currentUser: OrganizationMember) => {
  return [OrganizationMemberRole.Admin, OrganizationMemberRole.Owner].includes(currentUser.role);
};

export const canManageRequests = (currentUser: OrganizationMember) => {
  return [OrganizationMemberRole.Admin, OrganizationMemberRole.Owner].includes(currentUser.role);
};

export const canRemoveMembers = (currentUser: OrganizationMember) => {
  return currentUser.role === OrganizationMemberRole.Owner;
};
