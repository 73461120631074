import { Auth0Error } from './auth.services.types';

export const isWorkEmailError = (error: Auth0Error) => {
  return error.error_description?.includes('You must use work email');
};

export const isEmailUnverifiedError = (error: Auth0Error) => {
  return (
    error.error_description === 'Email is unverified' || error.error_description?.includes('Please verify your email')
  );
};

export const parseErrorFromSearchParams = (searchParams: URLSearchParams) => {
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  if (!error || !errorDescription) return;

  return {
    error,
    error_description: errorDescription,
  } as Auth0Error;
};
