import { useCallback, useLayoutEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { clientMedicalConditionsQuery } from '@/pageAI/api';
import { UseClientMedicalConditionsProps } from './useClientMedicalConditions.types';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';
import {
  getMedicalConditionParamsFromSearchParams,
  normalizeMedicalCondition,
} from '@/pageAI/services/medicalConditions';
import { selectCondition, setConditions, toggleConditionSidebar, useUnifiedStore } from '@/pageAI/states/unified';
import { ConditionStatus } from '@/pageAI/gql/graphql';
import { truthy } from '@/shared/utils/boolean';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';

const fetchMedicalConditions = async (request: GraphQLRequestFunction, clientId: string | undefined) => {
  if (typeof clientId !== 'string') return Promise.resolve(null);

  const responseBody = await request(clientMedicalConditionsQuery, { id: clientId });

  const medicalConditions = responseBody.client.medicalConditions.nodes
    ?.filter(truthy)
    .filter((condition) => condition.conditionStatus !== ConditionStatus.UpdateFailed)
    .map((condition) => normalizeMedicalCondition(condition))
    .toSorted((conditionA, conditionB) => conditionA.conditionOrder - conditionB.conditionOrder);

  return medicalConditions || [];
};

export const queryMap = {
  client: {
    medicalConditions: {
      queryKey: (clientId?: string) => ['client', clientId, 'medicalConditions'],
      queryFn: fetchMedicalConditions,
    },
  },
};

export type ClientConditionsQuery = Awaited<ReturnType<typeof queryMap.client.medicalConditions.queryFn>>;

export const useClientMedicalConditions = ({ clientId, autoSelect = true }: UseClientMedicalConditionsProps) => {
  const { request } = useGraphQLRequest();

  const initConditionsState = useCallback(
    (conditions?: MedicalConditionItem[]) => {
      if (!conditions) return;

      const { medicalConditionId } = getMedicalConditionParamsFromSearchParams(
        new URLSearchParams(window.location.search),
      );

      toggleConditionSidebar(true);
      setConditions(conditions);

      if (autoSelect) {
        selectCondition(medicalConditionId || conditions[0]?.id);
      }
    },
    [autoSelect],
  );

  const { data, isLoading, isError } = useQuery(
    queryMap.client.medicalConditions.queryKey(clientId),
    () => queryMap.client.medicalConditions.queryFn(request, clientId),
    {
      onSuccess: initConditionsState,
    },
  );

  useLayoutEffect(() => {
    if (!data?.length || useUnifiedStore.getState().conditions.length) return;

    initConditionsState(data);
  }, [initConditionsState, data]);

  return {
    medicalConditions: data || [],
    isLoading,
    isError,
  };
};
