import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ResearchType } from '@/pageAI/@types';
import { setMessageRetrieverType } from '@/shared/states/chat';
import { MessageRetrieverType } from '@/pageAI/gql/graphql';

export const useResearchType = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const rawResearchType = searchParams.get('type') as ResearchType | 'undefined';

  const researchType = rawResearchType || ResearchType.RESEARCH;

  const setResearchType = useCallback(
    (type: ResearchType) => {
      searchParams.set('type', type);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (!rawResearchType) return;

    if (rawResearchType === ResearchType.INVESTIGATE) setMessageRetrieverType(MessageRetrieverType.Docs);
    else if (rawResearchType === ResearchType.RESEARCH) setMessageRetrieverType(MessageRetrieverType.Web);
  }, [rawResearchType]);

  return [researchType, setResearchType] as const;
};
