import { memo, useCallback, useState, useTransition } from 'react';
import { Flex, LoadingOverlay, SegmentedControl } from '@mantine/core';

import { CaseTimelineTabsProps } from './CaseTimelineTabs.types';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';

const CaseTimelineTabsBase = ({ initialValue = 'All', sx, onChange, disabled }: CaseTimelineTabsProps) => {
  const [value, setValue] = useState<CaseTimelineTab>(initialValue);
  const [isPending, startTransition] = useTransition();

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue as CaseTimelineTab);

      startTransition(() => onChange?.(newValue as CaseTimelineTab));
    },
    [onChange],
  );

  return (
    <SegmentedControl
      size="xs"
      sx={{
        '.ghost-SegmentedControl-label': {
          width: 96,
        },
        ...sx,
      }}
      data={[
        {
          value: 'All',
          label:
            isPending && value === 'All' ? (
              <Flex align="center" justify="center" gap={4}>
                All
                <LoadingOverlay visible sx={{ svg: { width: '16px !important', height: '16px !important' } }} />
              </Flex>
            ) : (
              'All'
            ),
        },
        {
          value: 'Bookmarked',
          label:
            isPending && value === 'Bookmarked' ? (
              <Flex align="center" justify="center" gap={4}>
                Bookmarked
                <LoadingOverlay visible sx={{ svg: { width: '16px !important', height: '16px !important' } }} />
              </Flex>
            ) : (
              'Bookmarked'
            ),
        },
      ]}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export const CaseTimelineTabs = memo(CaseTimelineTabsBase);
