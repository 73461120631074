import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Box } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';

import { CopyMedicalConditionLinkButtonProps } from './CopyMedicalConditionLinkButton.types';
import { CopyButton } from '@/shared/components/common/CopyButton';
import { appendSearchParams } from '@/shared/utils/url';

const CopyMedicalConditionLinkButtonBase = ({ selectedMedicalConditionId }: CopyMedicalConditionLinkButtonProps) => {
  const link = useMemo(() => {
    if (!selectedMedicalConditionId) return window.location.href;

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('medicalConditionId', selectedMedicalConditionId);

    return appendSearchParams(window.location.href, searchParams);
  }, [selectedMedicalConditionId]);

  return (
    <>
      {createPortal(
        <Box ml={4}>
          <CopyButton
            value={link}
            tooltip="Copy link"
            copiedTooltip="Link copied"
            color="gray.6"
            icon={<IconLink size={16} />}
          />
        </Box>,
        document.getElementById('page-ai-header-right-section') || document.body,
      )}
    </>
  );
};

export const CopyMedicalConditionLinkButton = CopyMedicalConditionLinkButtonBase;
