import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { addFilesToConditionsMutation, removeFilesFromConditionMutation } from '@/pageAI/api/conditions';
import {
  AddFilesToConditionsInput,
  AddFilesToConditionsMutation,
  RemoveFilesFromConditionInput,
  RemoveFilesFromConditionMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { singularOrPlural } from '@/shared/utils/string';
import { useUpdateCondition } from '../useUpdateCondition';
import { showAsyncNotification } from '@/shared/services/notifications';

export const useManageConditionFiles = (clientId: string) => {
  const { request } = useGraphQLRequest();
  const { updateConditionLocally } = useUpdateCondition(clientId);

  const { mutateAsync: addFilesToConditionBase, isLoading: isAdding } = useMutation<
    AddFilesToConditionsMutation,
    Error,
    AddFilesToConditionsInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(addFilesToConditionsMutation, { input });

      return responseBody;
    },
  });

  const addFilesToConditions = useCallback(
    async (conditionIds: string[], fileIds: string[]) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        `Adding ${fileIds.length} ${singularOrPlural('file', 'files')(fileIds.length)} to the condition(s)`,
      );

      try {
        const responseBody = await addFilesToConditionBase({
          conditionIds,
          files: fileIds.map((fileId) => ({ id: fileId })),
          clientId,
        });

        responseBody.addFilesToConditions.forEach((condition) => {
          updateConditionLocally(condition.id, condition);
        });

        onSuccess(
          'Success',
          `${fileIds.length} ${singularOrPlural('file', 'files')(fileIds.length)} ${singularOrPlural(
            'was',
            'were',
          )(fileIds.length)} added to the condition`,
        );
      } catch (error) {
        console.error(error);

        onError('Error', 'Failed to add the selected file(s) to the condition(s).');
      }
    },
    [addFilesToConditionBase, clientId, updateConditionLocally],
  );

  const { mutateAsync: removeFilesFromConditionBase, isLoading: isRemoving } = useMutation<
    RemoveFilesFromConditionMutation,
    Error,
    RemoveFilesFromConditionInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(removeFilesFromConditionMutation, { input });

      return responseBody;
    },
  });

  const removeFilesFromCondition = useCallback(
    async (conditionId: string, fileIds: string[], isConditionEntry = false) => {
      const { onSuccess, onError } = showAsyncNotification(
        'Executing...',
        isConditionEntry
          ? 'The entry is being removed from the condition.'
          : `Removing ${fileIds.length} ${singularOrPlural('file', 'files')(fileIds.length)} from the condition`,
      );

      try {
        const responseBody = await removeFilesFromConditionBase({
          conditionId,
          files: fileIds.map((fileId) => ({ id: fileId })),
          clientId,
        });

        updateConditionLocally(conditionId, responseBody.removeFilesFromCondition);

        onSuccess(
          'Success',
          isConditionEntry
            ? 'The entry has been removed.'
            : `${fileIds.length} ${singularOrPlural('file', 'files')(fileIds.length)} ${singularOrPlural(
                'was',
                'were',
              )(fileIds.length)} removed from the condition.`,
        );
      } catch (error) {
        console.error(error);

        onError(
          'Error',
          isConditionEntry
            ? 'Failed to remove the entry from the condition.'
            : 'Failed to remove the selected file(s) from the condition.',
        );
      }
    },
    [clientId, removeFilesFromConditionBase, updateConditionLocally],
  );

  return {
    addFilesToConditions,
    removeFilesFromCondition,
    isAdding,
    isRemoving,
  };
};
