import { graphql } from '@/pageAI/gql';

export const createInvitationMutation = graphql(/* GraphQL */ `
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        code
      }
    }
  }
`);

export const resendVerificationEmailMutation = graphql(/* GraphQL */ `
  mutation ResendVerificationEmail($input: ResendVerificationEmailInput!) {
    resendVerificationEmail(input: $input) {
      clientMutationId
    }
  }
`);
