import { memo } from 'react';
import { Badge, Box, Flex } from '@mantine/core';
import { IconAlertTriangle, IconChevronDown } from '@tabler/icons-react';

import { FileTypeBadgesProps } from './FileTypeBadges.types';
import {
  TIMELINE_EVENT_TYPE_COLOR_MAPPING,
  TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING,
} from '@/pageAI/services/caseTimeline';

const FileTypeBadgesBase = ({ fileAsset, editable = true, onClick }: FileTypeBadgesProps) => {
  let fileTypes = (fileAsset.metadata?.pageAIMetadata?.eventTypeClassifications ||
    []) as (keyof typeof TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING)[];

  if (!fileTypes || !fileTypes.length) fileTypes = ['Unclassified'];

  const fileType = fileTypes[0];

  return (
    <Flex align="center" gap={4}>
      <Badge
        key={fileType}
        onClick={fileAsset.viewerCanUpdate ? onClick : undefined}
        variant="light"
        sx={(theme) => ({
          width: 172,
          paddingRight: 6,
          backgroundColor:
            fileType === 'Unclassified'
              ? theme.colors.orange[0]
              : theme.colors[TIMELINE_EVENT_TYPE_COLOR_MAPPING[fileType]][1],
          transition: 'all 0.2s',
          ...(fileAsset.viewerCanUpdate && {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor:
                fileType === 'Unclassified'
                  ? theme.colors.orange[1]
                  : theme.colors[TIMELINE_EVENT_TYPE_COLOR_MAPPING[fileType]][2],
            },
          }),
          '.ghost-Badge-inner': {
            color: fileType === 'Unclassified' ? theme.colors.orange[7] : theme.colors.dark[4],
            fontWeight: 500,
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            gap: 4,
          },
        })}
      >
        {fileType === 'Unclassified' && <IconAlertTriangle size={12} />}

        {TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING[fileType]}

        <Box sx={{ flexGrow: 1 }} />

        {editable && fileAsset.viewerCanUpdate && <IconChevronDown size={12} />}
      </Badge>
    </Flex>
  );
};

export const FileTypeBadges = memo(FileTypeBadgesBase);
