import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, Loader, SegmentedControl, Text, Tooltip } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { IconInfoCircle } from '@tabler/icons-react';

import { ClientFilePanelProps } from './ClientFilePanel.types';
import { ClientFileCtaPlaceholder } from '../ClientFileCtaPlaceholder';
import { UploadClientFileButton } from '../UploadClientFileButton';
import { Panel } from '@/pageAI/designSystem/Panel';
import { useUploadMultipleFiles } from '@/pageAI/hooks/files/useUploadMultipleFiles';
import { OcrMethod } from '@/pageAI/gql/graphql';
import { useFileStatusPolling } from '@/shared/hooks/files/useFileStatusPolling';
import { AssetItem, AssetType, FileAsset, FileUploadStatus } from '@/shared/@types';
import { AssetItemActions } from '@/shared/components/files/actions/AssetItemActions';
import { RetryOcrAllButton } from '@/shared/components/files/RetryOcrAllButton';
import { extractClientFileInfo, getClientDisplayName } from '@/pageAI/services/clients';
import { FileProgressBadge } from '@/shared/components/files/FileProgressBadge';
import { FILE_INDEXING_FLAG_STORAGE_KEY, getTotalPagesFromFiles } from '@/shared/services/files';
import { useEnsureClientFilesUploaded } from '@/pageAI/hooks/files/useEnsureClientFilesUploaded';
import { isFeatureFlagEnabled } from '@/shared/services/featureFlags';
import { useClientPersistentPDFScale } from '@/pageAI/hooks/files/useClientPersistentPDFScale';
import { AssetTable } from '@/pageAI/components/files/AssetTable';
import {
  filterImportantFileAssets,
  filterCombinedCFiles,
  filterUnclassifiedFileAssets,
  reviewUnclassifiedFileAssetsPubsub,
  isThereSplittingFile,
  filterFileByFileTypes,
  filterFileAssetsByKeywords,
} from '@/pageAI/services/files';
import { useClientFileTab } from './ClientFilePanel.utils';
import { singularOrPlural } from '@/shared/utils/string';
import { TableNavigationHint } from '../../files/TableNavigationHint';
import { truthy } from '@/shared/utils/boolean';
import { useFileFilterSearchParams, useFileSearchFilters } from '@/pageAI/hooks/files/useFileFilterSearchParams';
import { FileSearchContextProvider } from '@/pageAI/contexts/fileSearchContext';
import { useClientFiles } from '@/pageAI/hooks/clients/useClientFiles';
import { FileIndexRightHeader } from '../FileIndexRightHeader';
import { useUnifiedStore } from '@/pageAI/states/unified';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';

const getOCROptions = () => {
  return {
    shouldIndex: isFeatureFlagEnabled(FILE_INDEXING_FLAG_STORAGE_KEY),
    shouldOcr: false,
    ocrMethod: OcrMethod.HandwritingOcr,
    chunkCharSize: 1000,
    chunkCharOverlap: 1000,
  };
};

const ClientFilePanelBase = ({
  client,
  hiddenColumns,
  colgroup,
  hideUploadButton,
  renderActionCell,
}: ClientFilePanelProps) => {
  const queryClient = useQueryClient();

  const { fileAssets: unfilteredFileAssets, fileCollection } = useClientFiles(client);

  useClientPersistentPDFScale(client.id);
  const { saveNumberOfFiles } = useEnsureClientFilesUploaded(client.id);

  const [shouldPollFailedFiles, setShouldPollFailedFiles] = useState(false);
  const { fileGroups, setFileGroups, fileTypes, setFileTypes, keywords, setKeywords } = useFileSearchFilters();
  useFileFilterSearchParams({ fileGroups, fileTypes, keywords });
  const [initialLoadCount] = useState(
    () => useUnifiedStore.getState().scrollPosition[UnifiedTab.FILE_INDEX]?.loaded || 20,
  );

  const { tab, setTab } = useClientFileTab();
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const refetchClient = useCallback(async () => {
    await queryClient.refetchQueries(['client', client.id]);
  }, [client.id, queryClient]);

  const clientDisplayName = useMemo(() => getClientDisplayName(client), [client]);

  const fileAssets = useMemo(
    () => (unfilteredFileAssets ? filterCombinedCFiles(unfilteredFileAssets) : []),
    [unfilteredFileAssets],
  );

  useEffect(() => {
    const unsubscribe = reviewUnclassifiedFileAssetsPubsub.subscribe(() => {
      tableContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTab('Unclassified');
    });

    return unsubscribe;
  }, [setTab]);

  const { uploadMultipleFiles, isUploading } = useUploadMultipleFiles({
    id: client.id,
    postUploadAction: async () => {
      await refetchClient();
    },
    uploadingTitle: `Uploading files for ${clientDisplayName}...`,
    successTitle: `Files uploaded successfully for ${clientDisplayName}!`,
    errorTitle: `Failed to upload files for ${clientDisplayName}`,
  });

  useFileStatusPolling(fileCollection, refetchClient, shouldPollFailedFiles);

  const fileAssetsWithExtractedInfo = useMemo(
    () =>
      filterCombinedCFiles(
        [...(fileAssets || [])].map((fileAsset, fileIndex) => {
          const clientFileInfo = extractClientFileInfo(fileAsset, fileIndex);

          return {
            ...fileAsset,
            index:
              fileAsset.status.uploadStatus &&
              [FileUploadStatus.UPLOADING, FileUploadStatus.FAILED].includes(fileAsset.status.uploadStatus)
                ? '--------'
                : clientFileInfo.indexNumber,
            name: clientFileInfo.cleanedFileName,
          };
        }),
      ),
    [fileAssets],
  );

  const filteredFileAssets = useMemo(() => {
    let results: FileAsset[] = filterFileByFileTypes(fileAssetsWithExtractedInfo, fileTypes);

    switch (tab) {
      case 'Unclassified':
        results = filterUnclassifiedFileAssets(results);
        break;
      case 'Important':
        results = filterImportantFileAssets(results);
        break;
      default:
    }

    if (keywords) {
      results = filterFileAssetsByKeywords(results, keywords);
    }

    return results;
  }, [fileAssetsWithExtractedInfo, fileTypes, tab, keywords]);

  const previousFilteredFileAssets = usePrevious(filteredFileAssets);

  useEffect(() => {
    if (tab === 'All') return;

    if (previousFilteredFileAssets?.length === 1 && filteredFileAssets.length === 0) setTab('All');
  }, [fileTypes, previousFilteredFileAssets, filteredFileAssets, tab, setTab]);

  const handleUpload = (files: File[]) => {
    if (!fileCollection) return;

    saveNumberOfFiles(fileAssetsWithExtractedInfo.length + files.length);

    uploadMultipleFiles({
      files,
      collectionId: fileCollection.id,
      options: getOCROptions(),
    });
  };

  const headerOverrides = useMemo(() => {
    const headers = [];
    const ocrOptions = getOCROptions();

    headers[3] = (
      <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', gap: 4 }}>
        Received Date
        <Tooltip
          label="This is the date the file(s) were received by the VA if part of a CFILE, or the upload date to Page for non-CFILE documents."
          withArrow
          withinPortal
        >
          <Flex align="center" justify="center" sx={(theme) => ({ color: theme.colors.gray[5] })}>
            <IconInfoCircle size={14} />
          </Flex>
        </Tooltip>
      </Box>
    );

    headers[6] = (
      <RetryOcrAllButton
        files={fileAssetsWithExtractedInfo}
        options={{
          method: ocrOptions.ocrMethod,
          indexingOptions: {
            doIndexing: ocrOptions.shouldIndex,
            chunkCharOverlap: ocrOptions.chunkCharOverlap,
            chunkCharSize: ocrOptions.chunkCharSize,
          },
        }}
        onRetry={() => setShouldPollFailedFiles(true)}
      />
    );

    return headers;
  }, [fileAssetsWithExtractedInfo]);

  const renderFileActions = useCallback(
    (assetItem: AssetItem, rowIndex: number) => {
      if (!fileCollection || assetItem.type !== AssetType.FILE) return null;

      return (
        <AssetItemActions
          asset={assetItem}
          collectionId={fileCollection.id}
          hideDeleteButton={!assetItem.viewerCanDelete}
          canShare
          onDeleted={refetchClient}
        />
      );
    },
    [fileCollection, refetchClient],
  );

  const totalPagesFromFiles = useMemo(() => getTotalPagesFromFiles(filteredFileAssets), [filteredFileAssets]);

  const areThereUnclassifiedFiles = useMemo(
    () => filterUnclassifiedFileAssets(fileAssetsWithExtractedInfo).length > 0,
    [fileAssetsWithExtractedInfo],
  );

  const areThereImportantFiles = useMemo(
    () => filterImportantFileAssets(fileAssetsWithExtractedInfo).length > 0,
    [fileAssetsWithExtractedInfo],
  );

  if (!fileCollection) return <ClientFileCtaPlaceholder />;

  if (!fileAssetsWithExtractedInfo?.length && !isUploading) {
    if (client.viewerCanUploadFiles && !unfilteredFileAssets?.length) {
      return (
        <ClientFileCtaPlaceholder
          uploadFileButton={
            client.viewerCanUploadFiles ? (
              <UploadClientFileButton client={client} onUpload={handleUpload} disabled={isUploading} />
            ) : null
          }
        />
      );
    }

    // TODO: Take care of the case where the client has no files and the user cannot upload files
  }

  const getPlaceholderText = () => {
    if (isUploading) return 'Files are being uploaded...';

    if (tab === 'All' && !!unfilteredFileAssets?.length && isThereSplittingFile(unfilteredFileAssets))
      return (
        <Flex align="center" gap={4}>
          <Loader size={14} />

          <Text color="gray.7" fz="0.875rem">
            Files are being processed...
          </Text>
        </Flex>
      );

    if (fileTypes?.length !== 0) return 'No files found. Please try with a different input.';

    if (tab === 'Unclassified') return 'You have no unclassified files.';

    if (client.viewerCanUploadFiles) return 'No files found. Upload some files to get started.';

    return 'This client has no files.';
  };

  const availableTabs = [
    'All',
    areThereUnclassifiedFiles ? 'Unclassified' : undefined,
    areThereImportantFiles ? 'Important' : undefined,
  ].filter(truthy);

  return (
    <FileSearchContextProvider
      fileGroups={fileGroups}
      setFileGroups={setFileGroups}
      fileTypes={fileTypes}
      setFileTypes={setFileTypes}
    >
      <Panel ref={tableContainerRef} sx={{ gap: 4, paddingLeft: 0, paddingRight: 0, paddingTop: 4 }}>
        <Flex
          align="center"
          gap="xs"
          justify="space-between"
          pt="xs"
          pb="xs"
          sx={(theme) => ({ position: 'sticky', top: 0, zIndex: 2, background: theme.white })}
        >
          <Flex align="center" gap="xs">
            <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
              File Index
            </Text>

            <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

            <Text fw={500} fz="0.875rem" color="dark.3" sx={{ whiteSpace: 'nowrap' }}>
              <>
                {filteredFileAssets.length < fileAssetsWithExtractedInfo.length && <>{filteredFileAssets.length}/</>}
                {fileAssetsWithExtractedInfo.length}&nbsp;
                {singularOrPlural('file', 'files')(fileAssetsWithExtractedInfo.length)}
              </>
              &nbsp;
              <>
                ({totalPagesFromFiles} {singularOrPlural('page', 'pages')(totalPagesFromFiles)})
              </>
            </Text>

            {availableTabs.length > 1 && (
              <SegmentedControl
                value={tab}
                onChange={(value) => setTab(value as 'All' | 'Unclassified' | 'Important')}
                data={availableTabs}
                size="xs"
                sx={{
                  '.ghost-SegmentedControl-label': {
                    width: 96,
                  },
                }}
              />
            )}

            <TableNavigationHint />
          </Flex>

          <Flex align="center" gap="xs">
            <FileIndexRightHeader client={client} keywords={keywords} onKeywordsChange={setKeywords} />

            <FileProgressBadge files={fileAssets} />

            {client.viewerCanUploadFiles && !hideUploadButton && (
              <UploadClientFileButton client={client} onUpload={handleUpload} disabled={isUploading} compact />
            )}
          </Flex>
        </Flex>

        <Box>
          <AssetTable
            fileAssets={filteredFileAssets}
            renderActionCell={renderActionCell || renderFileActions}
            headerOverrides={headerOverrides}
            placeholderText={getPlaceholderText()}
            hiddenColumns={hiddenColumns}
            colgroup={colgroup}
            trStyle={{
              cursor: 'pointer',
            }}
            initialLoadCount={initialLoadCount}
          />
        </Box>
      </Panel>
    </FileSearchContextProvider>
  );
};

export const ClientFilePanel = memo(ClientFilePanelBase);
