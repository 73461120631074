import { Avatar, Menu, Text, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogout, IconUser } from '@tabler/icons-react';

import { useLogout } from '@/shared/hooks/auth/useLogout';
import { ProfileMenuProps } from './ProfileMenu.types';
import { UserProfileModal } from '@/pageAI/components/users/UserProfileModal';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';

export const ProfileMenu = ({ avatarUrl }: ProfileMenuProps) => {
  const { currentUser: user } = useCurrentUser();
  const { logout } = useLogout();

  const [isProfileModalOpened, { open: openProfileModal, close: closeProfileModal }] = useDisclosure();

  if (!user) return null;

  return (
    <Menu shadow="md" position="top-start" withinPortal zIndex={300}>
      <Menu.Target>
        <UnstyledButton
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.white,
            opacity: 0.85,

            '&:hover': {
              opacity: 1,
            },
          })}
        >
          <Avatar
            sx={{ minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, borderRadius: '50%' }}
            alt={user.firstName}
            src={avatarUrl || user.avatarUrl}
          />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown sx={{ width: '100%', zIndex: 300 }}>
        <Menu.Label>
          <Text color="brand" span fw={500}>
            {getUserDisplayName(user)}
          </Text>
        </Menu.Label>

        <Menu.Item onClick={openProfileModal} icon={<IconUser size={16} />}>
          Profile
        </Menu.Item>

        <Menu.Item color="red" onClick={logout} icon={<IconLogout size={16} />}>
          Log out
        </Menu.Item>
      </Menu.Dropdown>

      <UserProfileModal opened={isProfileModalOpened} onClose={closeProfileModal} />
    </Menu>
  );
};
