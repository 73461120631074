import { useMutation } from '@tanstack/react-query';

import { RemoveOrganizationInvitationInput, RemoveOrganizationInvitationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { removeOrganizationInvitationMutation } from '@/pageAI/api';
import { useUpdateOrganization } from '../useUpdateOrganization';

export const useRemoveOrganizationInvitation = (organizationId: string) => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateOrganizationLocally } = useUpdateOrganization();

  const { mutateAsync: removeInvitation, isLoading } = useMutation<
    RemoveOrganizationInvitationMutation,
    Error,
    RemoveOrganizationInvitationInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(removeOrganizationInvitationMutation, { input });

      return responseBody;
    },
    onSuccess: (responseBody, input) => {
      notify('Success', 'Invitation removed', 'brand');

      updateOrganizationLocally(organizationId)((currentOrganization) => ({
        invitations: currentOrganization.invitations.filter((invitation) => invitation.id !== input.invitationId),
      }));
    },
    onError: () => {
      notify('Error', 'Failed to remove the invitation');
    },
  });

  return { removeInvitation, isLoading };
};
