import { Maybe, Organization, User } from '@/pageAI/gql/graphql';
import { getFullName } from '@/shared/utils/name';

export const getUserDisplayName = (user: Omit<User, 'organizations'>) => {
  return (user.firstName ? getFullName(user.firstName, user.lastName).trim() : user.email) || '';
};

export const doesUserHaveOrganization = (
  user: Omit<User, 'organizations'> & {
    organizations?: Maybe<{ nodes?: Maybe<Maybe<Partial<Omit<Organization, 'joinRequests'>>>[]> }>;
  },
) => {
  return !!user.organizations?.nodes?.length;
};

export const doesUserNeedToUpdateProfile = (user: Omit<User, 'organizations'>) => {
  return !user.role;
};

export const canUserGenerateInvitationLink = (user: Omit<User, 'organizations'>) => {
  return user.email?.endsWith('@pageai.co');
};
