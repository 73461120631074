import { memo, useMemo } from 'react';
import { Box } from '@mantine/core';

import {
  getKeywordsFromMedicalCondition,
  normalizeMedicalConditionTimelineEvents,
  removeMedicalConditionDebugWords,
} from '@/pageAI/services/medicalConditions';
import { CaseTimeline } from '@/pageAI/components/timeline/CaseTimeline';
import { CaseTimelineContextProvider } from '@/pageAI/contexts/caseTimelineContext';
import { HighlightType } from '@/shared/utils/markdown';
import { MedicalConditionItemAsTimelineProps } from './MedicalConditionItemAsTimeline.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

const MedicalConditionItemAsTimelineBase = ({
  medicalCondition,
  sortOrder = 'desc',
}: MedicalConditionItemAsTimelineProps) => {
  const client = useSelectedClient(true);

  const timelineEvents = useMemo(() => normalizeMedicalConditionTimelineEvents(medicalCondition), [medicalCondition]);

  const keywords = useMemo(
    () => getKeywordsFromMedicalCondition(medicalCondition).map(removeMedicalConditionDebugWords),
    [medicalCondition],
  );

  if (!client) return null;

  return (
    <CaseTimelineContextProvider
      tab="All"
      prefix={medicalCondition.headerCondition}
      matchWholeWords
      keywords={keywords}
      setSearchKeywords={() => {}}
      eventTypes={[]}
      setEventTypes={() => {}}
      fileGroups={[]}
      setFileGroups={() => {}}
      fileTypes={[]}
      setFileTypes={() => {}}
      confidenceLevels={[]}
      setConfidenceLevels={() => {}}
      displayOnlyAdditionalEvents={false}
      setDisplayOnlyAdditionalEvents={() => {}}
      displayOnlyImportantFiles={false}
      setDisplayOnlyImportantFiles={() => {}}
      highlightType={HighlightType.LIGHTER}
    >
      <Box
        sx={(theme) => ({
          paddingLeft: 4,
          '.ghost-Timeline-item': {
            '&:not(:last-of-type)::before': { left: -4 },
            paddingLeft: 14,
            '.ghost-Timeline-itemBullet': {
              width: 20,
              height: 20,
            },
            p: { margin: 0 },
            ul: { fontSize: '0.875rem', paddingLeft: 24, margin: 0 },
            '.ghost-Timeline-itemTitle': {
              fontSize: '0.875rem',
              paddingTop: 3,
            },
          },
        })}
      >
        <CaseTimeline timeline={timelineEvents} virtualized={false} sortOrder={sortOrder} searchParamsDisabled />
      </Box>
    </CaseTimelineContextProvider>
  );
};

export const MedicalConditionItemAsTimeline = memo(MedicalConditionItemAsTimelineBase);
