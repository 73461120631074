import { memo, useMemo, useState } from 'react';
import { Accordion, Alert, Button, Flex, Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';

import { FileDropzone } from '@/shared/components/files/FileDropzone';
import { UploadFileOptions } from '@/shared/@types';
import { UploadFileModalProps } from './UploadFileModal.types';
import { getDefaultUploadOptions, getNewFileAssets } from '@/shared/services/files';
import { AssetTable } from '../AssetTable';

export const ALLOWED_FILE_TYPES = ['application/pdf'];

const UploadFileModalBase: React.FC<UploadFileModalProps> = ({
  onClose,
  onUpload,
  existingFileAssets,
  initialFiles = [],
  defaultDescription = 'Drop folder(s) or file(s) here or click to upload',
  opened = false,
  rejectable = true,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>(initialFiles);

  const { newFileAssets, newFiles } = useMemo(
    () =>
      existingFileAssets
        ? getNewFileAssets(existingFileAssets, selectedFiles)
        : { newFileAssets: [], newFiles: selectedFiles },
    [existingFileAssets, selectedFiles],
  );

  const form = useForm({
    initialValues: getDefaultUploadOptions(),
  });

  const handleFileUpload = async (files: File[]) => {
    setSelectedFiles(files);
  };

  const handleSubmit = (values: UploadFileOptions) => {
    if (newFiles.length > 0) onUpload?.(newFiles, values);

    setSelectedFiles([]);
    onClose();
  };

  const areThereDuplicatedFiles = newFiles.length !== selectedFiles.length;

  return (
    <Modal size={1000} opened={opened} onClose={onClose} title="Upload Files" centered>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="sm" sx={{ position: 'relative' }}>
          <Flex sx={{ height: 160, position: 'relative' }}>
            <FileDropzone
              visible
              accept={ALLOWED_FILE_TYPES}
              description={
                selectedFiles.length > 0 ? `You have selected ${selectedFiles.length} file(s)` : defaultDescription
              }
              subDescription="Supported format: *.pdf. The size should not exceed 1GB."
              onUpload={handleFileUpload}
              multiple
              rejectable={rejectable}
            />
          </Flex>

          {areThereDuplicatedFiles &&
            (newFileAssets.length > 0 || (newFiles.length === 0 && selectedFiles.length > 0)) && (
              <Alert
                title={
                  <Flex align="center" gap={4}>
                    <IconAlertCircle size="1rem" />
                    Duplicated files detected!
                  </Flex>
                }
                color="red"
                variant="outline"
              >
                <Flex direction="column" gap="xs">
                  <Text fz="0.875rem">
                    {newFiles.length > 0 ? (
                      <>
                        Within the files that you have selected, there are {selectedFiles.length - newFileAssets.length}
                        &nbsp;files that have already been uploaded. Therefore, those files will not be uploaded again,
                        and only the following list of new files will be uploaded:
                      </>
                    ) : (
                      <>All the files you have selected have already been uploaded.</>
                    )}
                  </Text>

                  {newFileAssets.length > 0 && (
                    <Accordion
                      defaultValue=""
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[3]}`,
                        borderRadius: 4,
                        '.ghost-Accordion-content': {
                          padding: 0,
                        },
                      })}
                    >
                      <Accordion.Item value="listOfNewFiles" sx={{ border: 'none' }}>
                        <Accordion.Control
                          fz="0.875rem"
                          sx={{
                            paddingLeft: 10,
                            paddingRight: 4,
                            '.ghost-Accordion-label': {
                              fontWeight: 500,
                            },
                          }}
                        >
                          List of new files ({newFileAssets.length})
                        </Accordion.Control>

                        <Accordion.Panel>
                          <AssetTable
                            assetItems={newFileAssets}
                            hiddenColumns={['Received Date', 'Status', '']}
                            colgroup={
                              <colgroup>
                                <col style={{ width: '3%' }} />
                                <col style={{ width: '70%' }} />
                                <col style={{ width: '17%' }} />
                                <col style={{ width: '10%' }} />
                              </colgroup>
                            }
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </Flex>
              </Alert>
            )}

          <Flex justify="flex-end" gap="xs">
            {areThereDuplicatedFiles && !newFiles.length ? (
              <>
                <Button variant="subtle" color="gray.7" onClick={onClose}>
                  OK
                </Button>
              </>
            ) : (
              <>
                <Button variant="subtle" color="gray.7" onClick={onClose}>
                  Cancel
                </Button>

                <Button type="submit" disabled={!selectedFiles.length}>
                  Upload
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const UploadFileModal = memo(UploadFileModalBase);
