import { memo, useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Box, Flex, ScrollArea } from '@mantine/core';
import { IconShare } from '@tabler/icons-react';

import { PAGE_AI_HEADER_HEIGHT, PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { ClientFileSearchLayout } from '@/pageAI/components/clients/ClientFileSearchLayout';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { setSelectedClient } from '@/pageAI/states/client';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { CopyButton } from '@/shared/components/common/CopyButton';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { ClientSubPageMenu } from '@/pageAI/components/clients/ClientSubPageMenu';

const ClientFileSearchPageBase = () => {
  const { clientId } = useParams();

  const { isLoading: isLoadingFileGroups, isError: isFileGroupsError } = useFileGroups();
  const { client, isLoading: isLoadingClient, isError: isClientError } = useClient({ clientId });

  const isLoading = isLoadingFileGroups || isLoadingClient;
  const isError = isFileGroupsError || isClientError;

  useLayoutEffect(() => {
    if (!isLoading && client) {
      setSelectedClient(client);
    }
  }, [client, isLoading, clientId]);

  const items = useMemo(() => {
    if (!client) return [];

    return [
      { title: 'Clients', href: '/clients' },
      { title: getClientDisplayName(client), href: `/clients/${client.id}` },
    ]
      .map((item, index) => (
        <Anchor
          key={item.href}
          to={item.href}
          sx={{ ...(index === 2 ? { fontWeight: 600 } : { fontWeight: 500 }) }}
          component={Link}
        >
          {item.title}
        </Anchor>
      ))
      .concat(<ClientSubPageMenu client={client} title="File Search" />);
  }, [client]);

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading client..."
        description="Please wait while Page retrieves the client."
        fullScreen
      />
    );

  if (isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  return (
    <Flex direction="column">
      <PageAIHeader headerItems={items} />

      <ScrollArea
        sx={{ height: `calc(100vh - ${PAGE_AI_HEADER_HEIGHT}px)` }}
        viewportProps={{ id: [clientId, 'scroll-area-viewport'].join('-'), className: 'scroll-area-viewport' }}
      >
        <Box p="md" pt={0}>
          <ClientFileSearchLayout client={client} />
        </Box>

        {createPortal(
          <Box ml={4}>
            <CopyButton
              value={window.location.href}
              tooltip="Share search results"
              copiedTooltip="Link copied"
              color="gray.6"
              icon={<IconShare size={16} />}
            />
          </Box>,
          document.getElementById('page-ai-header-right-section') || document.body,
        )}
      </ScrollArea>
    </Flex>
  );
};

export const ClientFileSearchPage = memo(ClientFileSearchPageBase);
