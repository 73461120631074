import { Box, Text, UnstyledButton, createStyles, px, type MantineColor, Flex } from '@mantine/core';
import { forwardRef, memo } from 'react';

import { MenuItemProps } from './MenuItem.types';

const useStyles = createStyles((theme, { color }: { color?: MantineColor }) => {
  const verticalPadding = px(theme.spacing.sm) / 2;

  return {
    button: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: verticalPadding,
      paddingBottom: verticalPadding,
      paddingLeft: theme.spacing.sm,
      paddingRight: theme.spacing.sm,
      borderRadius: 4,
      color: color && theme.colors[color][6],
      transition: 'background .15s ease',
      '&[disabled]': {
        cursor: 'not-allowed',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
      },
      '&:hover:not([disabled])': {
        background: theme.fn.rgba(
          color ? theme.colors[color][6] : theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
          color ? (theme.colorScheme === 'dark' ? 0.15 : 0.08) : 0.25,
        ),
      },
      '&:active:not([disabled])': {
        background: theme.fn.rgba(
          color ? theme.colors[color][6] : theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
          color ? (theme.colorScheme === 'dark' ? 0.3 : 0.2) : 0.5,
        ),
      },
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexGrow: 1,
    },
  };
});

const MenuItemBase = (
  { className, icon, title, children, color, disabled, sx, rightSection, onClick, onMouseDown }: MenuItemProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const { cx, classes } = useStyles({ color });

  const content = children || title;

  return (
    <UnstyledButton
      ref={ref}
      className={cx(classes.button, className)}
      disabled={disabled}
      onClickCapture={onClick}
      onMouseDownCapture={onMouseDown}
      sx={sx}
    >
      <Flex align="center" justify="space-between" w="100%" gap="xs">
        <Flex align="center" fz="0.875rem">
          {icon && (
            <Box fz={0} mr="xs">
              {icon}
            </Box>
          )}

          {typeof content === 'string' ? (
            <Text className={classes.title} fz="0.875rem" color="dark">
              {content}
            </Text>
          ) : (
            content
          )}
        </Flex>

        {rightSection}
      </Flex>
    </UnstyledButton>
  );
};

export const MenuItem = memo(forwardRef(MenuItemBase));
