import { memo } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { OrganizationInvitationRowProps } from './OrganizationInvitationRow.types';
import { formatDate, formatDateTime } from '@/shared/utils/date';
import { MemberRoleBadge } from '../MemberRoleBadge';
import { OrganizationMemberRole } from '@/pageAI/gql/graphql';
import { useRemoveOrganizationInvitation } from '@/pageAI/hooks/organizations/useRemoveOrganizationInvitation';

const OrganizationInvitationRowBase = ({ invitation, organizationId }: OrganizationInvitationRowProps) => {
  const { removeInvitation, isLoading } = useRemoveOrganizationInvitation(organizationId);

  const handleRemoveInvitation = () => {
    removeInvitation({ invitationId: invitation.id });
  };

  return (
    <tr key={invitation.id} style={{ height: 50 }}>
      <td>
        <Flex align="center" gap={6}>
          <Text fw={600} color="dark.4">
            {invitation.email}
          </Text>
        </Flex>
      </td>

      <td>
        <Tooltip label={formatDateTime(invitation.createdAt)} withinPortal withArrow>
          <Text color="dark.5" sx={{ width: 'fit-content' }}>
            {formatDate(invitation.createdAt)}
          </Text>
        </Tooltip>
      </td>

      <td>
        <MemberRoleBadge role={invitation.role as unknown as OrganizationMemberRole} />
      </td>

      <td align="right">
        <Tooltip label="Remove invitation" withinPortal withArrow>
          <ActionIcon variant="subtle" onClick={handleRemoveInvitation} loading={isLoading}>
            <IconX size={14} />
          </ActionIcon>
        </Tooltip>
      </td>
    </tr>
  );
};

export const OrganizationInvitationRow = memo(OrganizationInvitationRowBase);
