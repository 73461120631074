import { memo, useMemo } from 'react';
import { Flex, LoadingOverlay } from '@mantine/core';

import { ClientMainLayoutProps } from './ClientMainLayout.types';
import { ClientFilePanel } from '../ClientFilePanel';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { getClientFiles } from '@/pageAI/services/clients';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { useFileUploadStatusForClient } from '@/pageAI/hooks/files/useUploadFileStatusForClient';
import { FileUploadStatus } from '@/shared/@types';
import { ClientAlert } from '../ClientAlert';
import { SummariesPanel } from '@/pageAI/components/summaries/SummariesPanel';
import { ClientNotFoundPlaceholder } from '../ClientNotFoundPlaceholder';
import { FileClassificationAlert } from '@/pageAI/components/files/FileClassificationAlert';

const ClientMainLayoutBase = ({ clientId }: ClientMainLayoutProps) => {
  const { client, isLoading, isError } = useClient({ clientId });
  const { status: uploadStatus } = useFileUploadStatusForClient(clientId);
  const clientFileObject = useMemo(() => (client ? getClientFiles(client) : undefined), [client]);

  if (isLoading) return <LoadingOverlay visible />;

  if (isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  const isFileEmpty = !clientFileObject?.fileAssets || !clientFileObject.fileAssets.length;
  const canShowSummaryPanel = !isFileEmpty || uploadStatus === FileUploadStatus.UPLOADING;

  return (
    <Flex direction="column" gap="md" sx={{ width: '100%' }} px="md">
      <Flex direction="column" gap="md">
        <ClientAlert client={client} />

        <FileClassificationAlert client={client} />

        {canShowSummaryPanel && <SummariesPanel client={client} />}

        <ClientFilePanel client={client} />
      </Flex>
    </Flex>
  );
};

export const ClientMainLayout = memo(ClientMainLayoutBase);
