import { memo } from 'react';
import { Badge, Flex, List, Text, Tooltip } from '@mantine/core';

import { MergedConditionsBadgeProps } from './MergedConditionsBadge.types';
import {
  CONDITION_STATUS_COLOR_MAPPING,
  CONDITION_STATUS_TEXT_MAPPING,
  removeMedicalConditionDebugWords,
} from '@/pageAI/services/medicalConditions';
import { ConditionStatus } from '@/pageAI/gql/graphql';

const MergedConditionsBadgeBase = ({ condition }: MergedConditionsBadgeProps) => {
  if (!condition.mergedConditions?.length || condition.conditionStatus !== ConditionStatus.Updating) return null;

  return (
    <Tooltip
      label={
        <Flex direction="column" py={4}>
          <Text fz="0.875rem" color="white">
            Merged conditions:
          </Text>

          <List type="ordered" fz="0.875rem" pl="xs">
            {condition.mergedConditions.map((mergedCondition) => (
              <List.Item key={mergedCondition} sx={{ color: 'white' }}>
                <Text fz="0.875rem" color="white">
                  {removeMedicalConditionDebugWords(mergedCondition)}
                </Text>
              </List.Item>
            ))}
          </List>
        </Flex>
      }
      position="top-start"
      withinPortal
    >
      <Badge color={CONDITION_STATUS_COLOR_MAPPING[condition.conditionStatus]} variant="outline" ml={4} size="xs">
        {CONDITION_STATUS_TEXT_MAPPING[condition.conditionStatus]}
      </Badge>
    </Tooltip>
  );
};

export const MergedConditionsBadge = memo(MergedConditionsBadgeBase);
