import { memo } from 'react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { IconArrowsJoin } from '@tabler/icons-react';

import { MergeConditionsButtonProps } from './MergeConditionsButton.types';

const MergeConditionsButtonBase = ({ isMergeMode = false, onClick }: MergeConditionsButtonProps) => {
  return (
    <Tooltip label={isMergeMode ? 'Cancel merging conditions' : 'Merge conditions'} withArrow withinPortal>
      <Flex align="center" justify="center">
        <ActionIcon
          onClick={onClick}
          color={isMergeMode ? 'dark' : 'gray.7'}
          variant={isMergeMode ? 'light' : 'subtle'}
        >
          <IconArrowsJoin size={16} />
        </ActionIcon>
      </Flex>
    </Tooltip>
  );
};

export const MergeConditionsButton = memo(MergeConditionsButtonBase);
