import { memo, useRef, useState } from 'react';
import { ActionIcon, Button, Flex, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconX } from '@tabler/icons-react';

import { CaseTimelineEditorProps } from './CaseTimelineEditor.types';
import { ConfidenceSelect } from '../ConfidenceSelect';
import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { EventConfidenceLevel } from '@/pageAI/@types/summaries';
import { formatShortDate, parseDateOnly } from '@/shared/utils/date';
import { canEditEventSummaries } from '@/pageAI/services/caseTimeline';
import { truthy } from '@/shared/utils/boolean';
import { SummariesType } from '@/pageAI/gql/graphql';

const CaseTimelineEditorBase = ({ item, onSubmit, onClose }: CaseTimelineEditorProps) => {
  const [date, setDate] = useState<Date | null>(parseDateOnly(item.date));
  const [confidenceLevel, setConfidenceLevel] = useState((item.confidenceLevel || 'low') as EventConfidenceLevel);
  const [eventSummary, setEventSummary] = useState(item.summaries?.[0] || '');
  const generationMatch = useRef(item.generation);

  const canEditSummaries = canEditEventSummaries(item);

  const handleConfirm = () => {
    if (!date) return;

    onSubmit({
      date: formatShortDate(date),
      confidenceLevel,
      ...(canEditSummaries
        ? {
            summaries: [eventSummary],
            summariesTypes:
              !item.summariesTypes || !item.summariesTypes.length
                ? [SummariesType.EventSummary]
                : item.summariesTypes?.filter(truthy),
          }
        : {}),
      generationMatch: generationMatch.current || 0,
    });

    onClose();
  };

  return (
    <Flex direction="column" gap="md">
      <Flex direction="column" gap="xs">
        <Flex align="center" justify="space-between">
          <Text fw={600} color="dark.4" mb={-4}>
            Edit event
          </Text>

          <ActionIcon size="xs" onClick={onClose}>
            <IconX size={14} />
          </ActionIcon>
        </Flex>

        <Flex align="center" gap="xs" sx={{ '> *': { flex: '1 1' } }}>
          <DateInput label="Date" value={date} valueFormat="YYYY-MM-DD" onChange={setDate} />

          <ConfidenceSelect value={confidenceLevel} onChange={setConfidenceLevel} />
        </Flex>

        {canEditSummaries && (
          <Flex direction="column">
            <Text fz="0.875rem" fw={500} color="dark.5">
              Event summary
            </Text>

            <Flex
              sx={(theme) => ({
                padding: '6px 12px',
                border: `1px solid ${theme.colors.gray[3]}`,
                borderRadius: 4,
                transition: 'all 0.1s',
                '&:hover': {
                  borderColor: theme.colors.dark[4],
                },
                maxWidth: 640,
              })}
            >
              <InlineEditor data={eventSummary} onDataChange={setEventSummary} />
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex justify="flex-end" gap="xs">
        <Button variant="subtle" color="gray.7" onClick={onClose}>
          Cancel
        </Button>

        <Button onClick={handleConfirm}>Confirm</Button>
      </Flex>
    </Flex>
  );
};

export const CaseTimelineEditor = memo(CaseTimelineEditorBase);
