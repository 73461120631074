import { create } from 'zustand';
import { EventBus } from 'native-pubsub';

import {
  FileUploadBatch,
  StartUploadingFilesProps,
  UploadMultipleFileResult,
  UploadMultipleFileState,
} from './useUploadMultipleFiles.types';
import {
  convertBrowserFileToAsset,
  convertToBrowserFile,
  getUploadFailedFiles,
  getUploadedFiles,
  isThereUploadingFile,
  normalizeFile,
} from '@/shared/services/files';
import { BrowserFile, FileAsset, FileUploadStatus } from '@/shared/@types';
import { wait } from '@/shared/utils/time';
import { MB_TO_BYTES } from '@/shared/constants';

export const LARGE_FILE_THRESHOLD_IN_MB = 1;

export const useUploadMultipleFileStore = create<UploadMultipleFileState>(() => ({
  uploadBatchMapping: {},
}));

export const uploadBatchCompletePubsub = new EventBus<{ uploadBatch: FileUploadBatch }>();

export const useUploadBatch = (id: string) => {
  const fileAssets = useUploadMultipleFileStore((state) => state.uploadBatchMapping[id]?.fileAssets);
  const status = useUploadMultipleFileStore((state) => state.uploadBatchMapping[id]?.status);

  return { id, fileAssets: fileAssets || [], status: status || FileUploadStatus.UPLOADED };
};

export const startUploadingFiles = ({
  id,
  files,
  collectionId,
  uploadFunction,
  onSuccess,
  onError,
  postUploadAction,
}: StartUploadingFilesProps) => {
  const initialFiles: BrowserFile[] = files.map(convertToBrowserFile);

  const fileAssets = initialFiles.map((browserFile) =>
    convertBrowserFileToAsset(browserFile.originalFile, FileUploadStatus.UPLOADING, browserFile.id),
  );

  const filesSmallerThan100KB = initialFiles.filter((browserFile) => browserFile.originalFile.size < 0.1 * MB_TO_BYTES);

  const filesFrom100KBTo200KB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 0.1 * MB_TO_BYTES && browserFile.originalFile.size < 0.2 * MB_TO_BYTES,
  );

  const filesFrom200KBTo500KB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 0.2 * MB_TO_BYTES && browserFile.originalFile.size < 0.5 * MB_TO_BYTES,
  );

  const filesFrom500KBTo1MB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 0.5 * MB_TO_BYTES && browserFile.originalFile.size < 1 * MB_TO_BYTES,
  );

  const filesFrom1MBTo10MB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 1 * MB_TO_BYTES && browserFile.originalFile.size < 10 * MB_TO_BYTES,
  );

  const filesFrom10MBTo50MB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 10 * MB_TO_BYTES && browserFile.originalFile.size < 50 * MB_TO_BYTES,
  );

  const filesFrom50MBto100MB = initialFiles.filter(
    (browserFile) =>
      browserFile.originalFile.size >= 50 * MB_TO_BYTES && browserFile.originalFile.size < 100 * MB_TO_BYTES,
  );

  const filesFrom100MB = initialFiles.filter((browserFile) => browserFile.originalFile.size >= 100 * MB_TO_BYTES);

  useUploadMultipleFileStore.setState((state) => ({
    ...state,
    uploadBatchMapping: {
      ...state.uploadBatchMapping,
      [id]: {
        ...state.uploadBatchMapping[id],
        id,
        fileAssets,
        collectionId,
        status: FileUploadStatus.UPLOADING,
      },
    },
  }));

  let promiseResolve: ((result: UploadMultipleFileResult) => void) | undefined = undefined;

  const uploadPromise = new Promise<UploadMultipleFileResult>((resolve) => {
    promiseResolve = resolve;
  });

  const handleFinalUpload = (
    browserFile: BrowserFile,
    uploadedFileAsset: FileAsset | undefined,
    status = FileUploadStatus.UPLOADED,
  ) => {
    const uploadBatch = useUploadMultipleFileStore.getState().uploadBatchMapping[id];

    const finalFileAsset =
      uploadedFileAsset || convertBrowserFileToAsset(browserFile.originalFile, status, browserFile.id);

    const updatedFileAssets = uploadBatch.fileAssets.map((fileAsset) => {
      if (fileAsset.id === browserFile.id) return finalFileAsset;

      return fileAsset;
    });

    useUploadMultipleFileStore.setState((state) => ({
      ...state,
      uploadBatchMapping: {
        ...state.uploadBatchMapping,
        [id]: {
          ...state.uploadBatchMapping[id],
          fileAssets: updatedFileAssets,
        },
      },
    }));

    const successCount = updatedFileAssets.filter(
      (fileAsset) => fileAsset.status.uploadStatus === FileUploadStatus.UPLOADED,
    ).length;

    const isFinishing = !isThereUploadingFile(updatedFileAssets);

    if (isFinishing) handleFinish(updatedFileAssets);

    return { finalFileAsset, updatedFileAssets, successCount, isFinishing };
  };

  const handleSuccessUpload = (browserFile: BrowserFile, uploadedFileAsset: FileAsset) => {
    const { finalFileAsset, updatedFileAssets, isFinishing, successCount } = handleFinalUpload(
      browserFile,
      uploadedFileAsset,
      FileUploadStatus.UPLOADED,
    );

    if (!isFinishing) onSuccess?.(finalFileAsset, updatedFileAssets, successCount);

    return { finalFileAsset, updatedFileAssets, successCount };
  };

  const handleFailedUpload = (browserFile: BrowserFile) => {
    const { finalFileAsset, updatedFileAssets, successCount, isFinishing } = handleFinalUpload(
      browserFile,
      undefined,
      FileUploadStatus.FAILED,
    );

    if (!isFinishing) onError?.(finalFileAsset, updatedFileAssets, successCount);

    return { finalFileAsset, updatedFileAssets, successCount };
  };

  const handleFinish = async (finalFileAssets: FileAsset[]) => {
    const uploadedFiles = getUploadedFiles(finalFileAssets);

    const failedFiles = getUploadFailedFiles(finalFileAssets);

    const originalFailedFiles = initialFiles
      .filter((initialFile) => failedFiles.some((failedFile) => failedFile.id === initialFile.id))
      .map((failedBrowserFile) => failedBrowserFile.originalFile);

    const newStatus = failedFiles.length > 0 ? FileUploadStatus.FAILED : FileUploadStatus.UPLOADED;

    if (typeof postUploadAction === 'function') await postUploadAction();

    useUploadMultipleFileStore.setState((state) => {
      uploadBatchCompletePubsub.publish({
        uploadBatch: {
          ...state.uploadBatchMapping[id],
          fileAssets: finalFileAssets,
          status: newStatus,
        },
      });

      return {
        ...state,
        uploadBatchMapping: {
          ...state.uploadBatchMapping,
          [id]: {
            ...state.uploadBatchMapping[id],
            fileAssets: failedFiles,
            status: newStatus,
          },
        },
      };
    });

    promiseResolve?.({ uploadedFiles, failedFiles, fileAssets: finalFileAssets, originalFailedFiles });
  };

  const uploadFile = async (browserFile: BrowserFile, currentFailedCount = 0) => {
    try {
      const uploadedFile = await uploadFunction(browserFile.originalFile, collectionId, true);

      if (!uploadedFile) throw new Error('Failed to upload file');

      handleSuccessUpload(browserFile, normalizeFile(uploadedFile));
    } catch (error) {
      if (currentFailedCount >= 3) {
        handleFailedUpload(browserFile);

        return;
      }

      await wait(30000);

      uploadFile(browserFile, currentFailedCount + 1);
    }
  };

  let batchIndex = 0;

  const uploadBatches = [
    filesSmallerThan100KB,
    filesFrom100KBTo200KB,
    filesFrom200KBTo500KB,
    filesFrom500KBTo1MB,
    filesFrom1MBTo10MB,
    filesFrom10MBTo50MB,
    filesFrom50MBto100MB,
    filesFrom100MB,
  ];

  const uploadBatch = async (batch: BrowserFile[]) => {
    await Promise.allSettled(batch.map((browserFile) => uploadFile(browserFile)));

    batchIndex++;

    if (batchIndex < uploadBatches.length) {
      await uploadBatch(uploadBatches[batchIndex]);
    }
  };

  uploadBatch(uploadBatches[batchIndex]);

  return {
    id,
    initialFileAssets: fileAssets,
    uploadPromise,
  };
};
