import { memo } from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { DeleteClientModalProps } from './DeleteClientModal.types';
import { useDeleteClient } from '@/pageAI/hooks/clients/useDeleteClient';
import { getClientDisplayName } from '@/pageAI/services/clients';

const DeleteClientModalBase = ({ opened = false, client, onClose }: DeleteClientModalProps) => {
  const { deleteClient, isLoading } = useDeleteClient();
  const navigate = useNavigate();
  const location = useLocation();

  const handleDeleteClient = () => {
    if (location.pathname !== '/clients') navigate('/clients', { replace: true });

    Promise.resolve().then(async () => {
      await deleteClient(client.id);

      onClose();
    });
  };

  return (
    <Modal
      opened={opened}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
      centered
      title="Archive client"
      sx={{
        '.ghost-Modal-title': {
          fontWeight: 500,
        },
      }}
    >
      <Flex direction="column" gap="md">
        <Text fz="0.875rem">
          Are you sure you want to archive <strong>{getClientDisplayName(client)}</strong>?
        </Text>

        <Flex justify="flex-end" gap="xs">
          <Button variant="subtle" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>

          <Button color="red" onClick={handleDeleteClient} loading={isLoading}>
            Archive
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export const DeleteClientModal = memo(DeleteClientModalBase);
