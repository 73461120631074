import { memo } from 'react';
import { Anchor } from '@mantine/core';

import { openWebPreviewer } from '@/shared/states/webPreview';
import { MarkdownAnchorRendererOverrides, MarkdownAnchorRendererProps } from './MarkdownAnchorRenderer.types';

export const markdownAnchorRendererOverrides: MarkdownAnchorRendererOverrides = {
  action: undefined,
};

const MarkdownAnchorRendererBase: React.FC<MarkdownAnchorRendererProps> = ({ href, sx, children }) => {
  const handleClick = (event: React.MouseEvent) => {
    if (!href) return;

    event.preventDefault();

    const shouldSkip = markdownAnchorRendererOverrides.action?.(href);

    if (shouldSkip) return;

    openWebPreviewer(href);
  };

  return (
    <Anchor href={href} target="_blank" rel="noopener noreferrer" onClick={handleClick} sx={sx} color="blue.8">
      {children}
    </Anchor>
  );
};

export const MarkdownAnchorRenderer = memo(MarkdownAnchorRendererBase);
