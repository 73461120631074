import { memo, useState } from 'react';
import { Box, Flex, Popover, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCaretDownFilled } from '@tabler/icons-react';

import { ClientAssigneeProps } from './ClientAssignee.types';
import { UserAvatar } from '@/pageAI/components/users/UserAvatar';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';
import { OrganizationMemberMenu } from '@/pageAI/components/organizations/OrganizationMemberMenu';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { useAssignClient } from '@/pageAI/hooks/clients/useAssignClient';
import { User } from '@/pageAI/gql/graphql';

const ClientAssigneeBase = ({ client, compact }: ClientAssigneeProps) => {
  const [opened, { toggle, close }] = useDisclosure();

  const { currentUser } = useCurrentUser();
  const { assignClient } = useAssignClient();
  const organization = currentUser?.organizations?.nodes?.[0];

  const [assignee, setAssignee] = useState(client.assignee || client.creator);

  const handleClick = (event: React.MouseEvent) => {
    if (!client.viewerCanUpdate) return;

    event.stopPropagation();
    toggle();
  };

  const handleMemberSelect = async (newAssignee: User) => {
    close();

    const previousAssignee = assignee;

    setAssignee(newAssignee);

    try {
      await assignClient({ clientId: client.id, assigneeId: newAssignee.id });
    } catch (error) {
      setAssignee(previousAssignee);
    }
  };

  return (
    <Popover opened={opened} onClose={close} closeOnClickOutside closeOnEscape shadow="sm">
      <Popover.Target>
        <Tooltip label={assignee.email} withArrow withinPortal>
          <Box
            component="span"
            onClick={handleClick}
            sx={(theme) => ({
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: compact ? 4 : 6,
              marginLeft: compact ? 0 : -8,
              padding: compact ? '2px 4px' : '4px 8px',
              borderRadius: 4,
              transition: 'all 0.2s ease-in-out',
              ...(client.viewerCanUpdate && {
                '&:hover': {
                  background: theme.colors.gray[3],
                },
              }),
            })}
          >
            <UserAvatar user={assignee} size={compact ? 18 : 24} />

            <Text color="dark.5" fz={compact ? '0.875rem' : '1rem'}>
              {getUserDisplayName(assignee)}
            </Text>

            {client.viewerCanUpdate && !compact && (
              <Flex align="center" sx={(theme) => ({ color: theme.colors.dark[3] })}>
                <IconCaretDownFilled size={12} />
              </Flex>
            )}
          </Box>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <OrganizationMemberMenu organizationId={organization?.id} onMemberSelect={handleMemberSelect} />
      </Popover.Dropdown>
    </Popover>
  );
};

export const ClientAssignee = memo(ClientAssigneeBase);
