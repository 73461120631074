import { create } from 'zustand';

import { RightSidebarType, closeRightSidebar, openRightSidebar } from '../layout';
import { WebPreviewState } from './webPreview.states.types';

export const useWebPreviewStore = create<WebPreviewState>(() => ({
  url: '',
}));

export const setFilePreviewUrl = (url: string) => useWebPreviewStore.setState({ url });

export const openWebPreviewer = (url: string) => {
  setFilePreviewUrl(url);
  openRightSidebar(RightSidebarType.WEB_PREVIEW);
};

export const closeWebPreviewer = () => {
  setFilePreviewUrl('');
  closeRightSidebar();
};
