import { memo } from 'react';
import { Flex } from '@mantine/core';

import { OrganizationRequestsTabProps } from './OrganizationRequestsTab.types';
import { OrganizationJoinRequestsPanel } from '../OrganizationJoinRequestsPanel';
import { OrganizationInvitationsPanel } from '../OrganizationInvitationsPanel';

const OrganizationRequestsTabBase = ({ organization }: OrganizationRequestsTabProps) => {
  return (
    <Flex direction="column" gap="xl" sx={{ width: '100%' }}>
      <OrganizationJoinRequestsPanel organization={organization} />

      <OrganizationInvitationsPanel organization={organization} />
    </Flex>
  );
};

export const OrganizationRequestsTab = memo(OrganizationRequestsTabBase);
