import { memo } from 'react';
import { Button, Flex, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import { JoinRequestRowProps } from './JoinRequestRow.types';
import { UserAvatar } from '../../users/UserAvatar';
import { getUserDisplayName } from '@/pageAI/services/users';
import { formatDate, formatDateTime } from '@/shared/utils/date';
import { useManageRequestToJoinOrganization } from '@/pageAI/hooks/organizations/useManageRequestToJoinOrganization';

const JoinRequestRowBase = ({ joinRequest, organizationId }: JoinRequestRowProps) => {
  const { approveRequest, declineRequest, isApproving, isDeclining } = useManageRequestToJoinOrganization();

  const user = joinRequest.user;

  const handleDecline = () => {
    declineRequest({ joinRequestId: joinRequest.id });
  };

  const handleApprove = () => {
    approveRequest({ joinRequestId: joinRequest.id });
  };

  return (
    <tr key={user.id} style={{ height: 50 }}>
      <td>
        <Flex align="center" gap={6}>
          <UserAvatar user={user} size={24} />

          <Text fw={600} color="dark.4">
            {getUserDisplayName(user)}
          </Text>
        </Flex>
      </td>

      <td>
        <Tooltip label={formatDateTime(joinRequest.createdAt)} withinPortal withArrow>
          <Text color="dark.5" sx={{ width: 'fit-content' }}>
            {formatDate(joinRequest.createdAt)}
          </Text>
        </Tooltip>
      </td>

      <td>
        <Text color="dark.5" sx={{ width: 'fit-content' }}>
          {user.email}
        </Text>
      </td>

      <td style={{ textAlign: 'right' }}>
        <Flex align="center" justify="end" gap="xs">
          <Button
            size="xs"
            color="red"
            variant="subtle"
            leftIcon={<IconX size={14} />}
            onClick={handleDecline}
            loading={isDeclining}
            disabled={isApproving}
          >
            Decline
          </Button>

          <Button
            size="xs"
            variant="outline"
            leftIcon={<IconCheck size={14} />}
            onClick={handleApprove}
            loading={isApproving}
            disabled={isDeclining}
          >
            Accept
          </Button>
        </Flex>
      </td>
    </tr>
  );
};

export const JoinRequestRow = memo(JoinRequestRowBase);
