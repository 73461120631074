import { memo, useMemo } from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IgnoreClientAlertButtonProps } from './IgnoreClientAlertButton.types';
import { getClientDisplayName, getClientFiles } from '@/pageAI/services/clients';
import { useTriggerSummariesGeneration } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';

const IgnoreClientAlertButtonBase = ({ client }: IgnoreClientAlertButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  const { unschedule, triggerSummariesGeneration, hasScheduled, saveNumberOfFiles } =
    useTriggerSummariesGeneration(client);

  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  const shouldTriggerSummariesGeneration = hasScheduled && fileAssets && fileAssets.length > 0;

  const handleConfirm = () => {
    unschedule();
    saveNumberOfFiles(0);

    if (shouldTriggerSummariesGeneration) {
      triggerSummariesGeneration(client, true);
    }
  };

  return (
    <>
      <Button
        variant="light"
        color="red"
        size="xs"
        sx={{
          height: 20,
          padding: '0 4px',
        }}
        onClick={open}
      >
        Ignore
      </Button>

      <Modal opened={opened} size={640} title="Ignore missing files alert" onClose={close} centered>
        <Flex direction="column" gap="md">
          <Flex direction="column" gap={4}>
            <Text fz="0.875rem" color="dark">
              Are you sure you want to ignore the alert about missing files? Please confirm that all the relevant files
              have been properly uploaded for <strong>{getClientDisplayName(client)}</strong>.
            </Text>

            {shouldTriggerSummariesGeneration && (
              <Text fz="0.875rem" color="dark">
                If you are sure there are no missing files, you can also choose to generate the timeline now.
              </Text>
            )}
          </Flex>

          <Flex justify="flex-end" gap="xs">
            <Button color="gray.8" variant="subtle" onClick={close}>
              Cancel
            </Button>

            <Button color="red" onClick={handleConfirm}>
              {shouldTriggerSummariesGeneration ? 'Confirm & Generate summaries' : 'Confirm'}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const IgnoreClientAlertButton = memo(IgnoreClientAlertButtonBase);
