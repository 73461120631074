export const wait = (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), milliseconds);
  });
};

const convertMinutesToHour = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
};

export const formatTime = (seconds: number) => {
  const minutes = Math.ceil(seconds / 60);

  if (minutes <= 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return convertMinutesToHour(minutes);
  }
};
