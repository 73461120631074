import { useMemo } from 'react';
import { UseSelectableFileTypesProps } from './useSelectableFileTypes.types';
import { getClientFiles } from '@/pageAI/services/clients';
import {
  filterCaseTimelineEvents,
  getFileAssetsFromTimelineEvents,
  getUniqueFileTypesFromFileAssets,
} from '@/pageAI/services/caseTimeline';
import { sortAlphabetically } from '@/shared/utils/string';

export const useSelectableFileTypes = ({ client, events }: UseSelectableFileTypesProps) => {
  const { fileAssets } = useMemo(() => (client ? getClientFiles(client) : { fileAssets: [] }), [client]);

  const filteredEventsByEventTypes = useMemo(() => {
    if (!events) return;

    return client ? filterCaseTimelineEvents({ client, events }) : events;
  }, [client, events]);

  const selectableFileAssets = useMemo(() => {
    if (filteredEventsByEventTypes)
      return fileAssets ? getFileAssetsFromTimelineEvents(fileAssets, filteredEventsByEventTypes) : [];

    return fileAssets;
  }, [filteredEventsByEventTypes, fileAssets]);

  const selectableFileTypes = useMemo(
    () => getUniqueFileTypesFromFileAssets(selectableFileAssets).toSorted(sortAlphabetically),
    [selectableFileAssets],
  );

  return { selectableFileTypes };
};
