import { escapeRegExp } from 'lodash-es';
import { HighlightType } from './markdown.types';

const MARKDOWN_REPLACEMENT_BY_HIGHLIGHT_TYPE = {
  [HighlightType.BOLDER]: '~~~',
  [HighlightType.LIGHTER]: '`',
  [HighlightType.DEFAULT]: '~~',
};

export const highlightKeywordsInMarkdown = (
  markdown: string,
  keywords: string[],
  highlightType = HighlightType.DEFAULT,
  matchCallback?: (keyword: string, match: string, startIndex: number, endIndex: number) => void,
  shouldMatchWholeWords = false,
  shouldMatchWholePhrase = false,
) => {
  let replacedContent = markdown;

  if (shouldMatchWholePhrase) {
    keywords = [keywords.join(' ')];
  }

  keywords
    .sort((keywordA, keywordB) => keywordA.length - keywordB.length)
    .forEach((keyword) => {
      const sanitizedKeyword = keyword.replace(/[.,:;]/gi, '').toLowerCase();
      const regex = shouldMatchWholeWords
        ? new RegExp(`\\b${escapeRegExp(sanitizedKeyword)}\\b`, 'gi')
        : new RegExp(escapeRegExp(sanitizedKeyword), 'gi');

      replacedContent = replacedContent.replace(regex, (match, startIndex) => {
        const endIndex = startIndex + match.length - 1;

        matchCallback?.(keyword, match, startIndex, endIndex);

        const markdownReplacement = MARKDOWN_REPLACEMENT_BY_HIGHLIGHT_TYPE[highlightType];

        return [markdownReplacement, match, markdownReplacement].join('');
      });
    });

  return replacedContent;
};

const MARKDOWN_HREF_REGEX = /\[(.*?)\]\((.*?)\)/;

export const extractHrefPartsFromMarkdown = (markdown: string) => {
  const match = markdown.match(MARKDOWN_HREF_REGEX);

  if (!match) return { displayText: '', href: '' };

  return { displayText: match[1] || '', href: match[2] || '' };
};

export const extractHrefFromMarkdown = (markdown: string) => {
  return extractHrefPartsFromMarkdown(markdown).href;
};

export const highlightKeywordsInMarkdownHrefDisplayText = (
  markdown: string,
  keywords: string[],
  highlightType = HighlightType.DEFAULT,
  matchCallback?: (keyword: string, match: string, startIndex: number, endIndex: number) => void,
  shouldMatchWholeWords = false,
  shouldMatchWholePhrase = false,
) => {
  const { displayText, href } = extractHrefPartsFromMarkdown(markdown);
  const highlightedDisplayText = highlightKeywordsInMarkdown(
    displayText,
    keywords,
    highlightType,
    matchCallback,
    shouldMatchWholeWords,
    shouldMatchWholePhrase,
  );

  return `[${highlightedDisplayText}](${href})`;
};
