import { memo } from 'react';
import { Badge, Flex, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { CombinedRatingBadgeProps } from './CombinedRatingBadge.types';
import { isCombinedRatingEmpty } from '@/pageAI/services/medicalConditions';

const CombinedRatingBadgeBase = ({ client }: CombinedRatingBadgeProps) => {
  const [opened, { toggle, close }] = useDisclosure();

  const isEmpty = isCombinedRatingEmpty(client.latestCombinedRating);

  return (
    <Popover shadow="sm" onClose={close} opened={opened} withArrow offset={4}>
      <Popover.Target>
        <Badge
          size="lg"
          variant="outline"
          color={isEmpty ? 'red.7' : 'dark.4'}
          sx={(theme) => ({
            textTransform: 'none',
            ...(!isEmpty && {
              cursor: 'pointer',
              transition: 'all 0.1s ease-in-out',
              '&:hover': {
                backgroundColor: theme.colors.gray[1],
              },
            }),
          })}
          onClick={toggle}
        >
          {isEmpty ? (
            'No combined rating'
          ) : (
            <Flex align="center" gap={2}>
              Current combined rating
              {opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            </Flex>
          )}
        </Badge>
      </Popover.Target>

      <Popover.Dropdown>
        <Text fz="0.875rem" color="dark" sx={{ maxWidth: 'calc(100vw - 120px)' }}>
          {client.latestCombinedRating}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export const CombinedRatingBadge = memo(CombinedRatingBadgeBase);
