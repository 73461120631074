import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { fileGroupsQuery } from '@/pageAI/api';
import { truthy } from '@/shared/utils/boolean';
import { FileGroup } from '@/pageAI/gql/graphql';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';

export const useFileGroups = () => {
  const { currentUser } = useCurrentUser();
  const organizationId = currentUser?.organizations?.nodes?.[0]?.id as string | undefined;

  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(['file-groups', organizationId], () => {
    return organizationId ? request(fileGroupsQuery, { id: organizationId }) : Promise.resolve(null);
  });

  const fileGroups = useMemo(
    () =>
      data?.organization?.fileGroups.nodes
        .filter(truthy)
        .sort((fileGroupA, fileGroupB) => fileGroupA.displayName.localeCompare(fileGroupB.displayName)),
    [data],
  );

  const fileGroupsMapping = useMemo(
    () =>
      (fileGroups || []).reduce((acc, fileGroup) => {
        acc[fileGroup.displayName] = fileGroup;
        return acc;
      }, {} as Record<string, FileGroup>),
    [fileGroups],
  );

  return { fileGroups, fileGroupsMapping, isLoading, isError };
};
