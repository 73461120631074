import { memo } from 'react';
import { Flex } from '@mantine/core';

import { ClientFileSearchLayoutProps } from './ClientFileSearchLayout.types';
import { ClientFileSearchPanel } from '../ClientFileSearchPanel';
import { FileSearchContextProvider } from '@/pageAI/contexts/fileSearchContext';
import { useFileSearchFilters } from '@/pageAI/hooks/files/useFileFilterSearchParams';

const ClientFileSearchLayoutBase = ({ client }: ClientFileSearchLayoutProps) => {
  const { fileGroups, setFileGroups, fileTypes, setFileTypes } = useFileSearchFilters();

  return (
    <FileSearchContextProvider
      fileGroups={fileGroups}
      setFileGroups={setFileGroups}
      fileTypes={fileTypes}
      setFileTypes={setFileTypes}
    >
      <Flex direction="column" gap="md" sx={{ width: '100%' }}>
        <Flex direction="column" gap="md">
          <ClientFileSearchPanel client={client} />
        </Flex>
      </Flex>
    </FileSearchContextProvider>
  );
};

export const ClientFileSearchLayout = memo(ClientFileSearchLayoutBase);
