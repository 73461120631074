import { useSearchParams } from 'react-router-dom';

import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useCallback } from 'react';

export const useUnifiedTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let tab = searchParams.get('tab') as UnifiedTab | undefined;

  if (!tab) tab = UnifiedTab.SUMMARY;

  const setTab = useCallback(
    (tab: UnifiedTab) => {
      searchParams.set('tab', tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return [tab, setTab] as const;
};
