import { memo, useRef, useState, useTransition } from 'react';
import { ActionIcon, Button, Loader, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import { DebouncedSearchInputProps } from './DebouncedSearchInput.types';

const DebouncedSearchInputBase = ({
  initialValue,
  placeholder = 'Search for keywords...',
  forceRefocusRef,
  w,
  onChange,
  renderInputWrapper,
}: DebouncedSearchInputProps) => {
  const [value, setValue] = useState(initialValue);
  const [showInput, setShowInput] = useState(!!value);
  const [isPending, startTransition] = useTransition();
  const debounceTimeoutRef = useRef<number | null>(null);

  const handleChange = (event: { target: { value: string } }) => {
    const newValue = event.target.value;

    setValue(newValue);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = window.setTimeout(() => {
      startTransition(() => onChange?.(newValue));
    }, 500);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) return;

    const eventTarget = event.target;

    setTimeout(() => {
      if (forceRefocusRef?.current) {
        forceRefocusRef.current = false;

        eventTarget.focus();

        return;
      }

      setShowInput(false);
    });
  };

  if (showInput) {
    const inputNode = (
      <TextInput
        size="xs"
        placeholder={placeholder}
        w={w}
        icon={<IconSearch size={16} />}
        rightSection={
          isPending ? (
            <Loader size={12} />
          ) : (
            <ActionIcon size="xs" onClick={() => handleChange({ target: { value: '' } })}>
              <IconX size={14} />
            </ActionIcon>
          )
        }
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus
      />
    );

    if (typeof renderInputWrapper === 'function') {
      return renderInputWrapper(inputNode);
    }

    return inputNode;
  }

  return (
    <Button
      leftIcon={<IconSearch size={14} />}
      onClick={() => setShowInput(true)}
      variant="subtle"
      color="gray.7"
      size="xs"
    >
      Search
    </Button>
  );
};

export const DebouncedSearchInput = memo(DebouncedSearchInputBase);
