import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';

import { NotFoundPlaceholder } from '@/shared/components/common/placeholders/NotFoundPlaceholder';

const ClientNotFoundPlaceholderBase = () => {
  return (
    <NotFoundPlaceholder
      title="Client not found"
      description="Unfortunately, the client you are trying to view does not exist. You may have entered a wrong client ID, or the client has already been deleted."
      action={
        <Link to="/clients">
          <Button size="md" sx={{ marginTop: -4 }}>
            Back to Clients page
          </Button>
        </Link>
      }
    />
  );
};

export const ClientNotFoundPlaceholder = memo(ClientNotFoundPlaceholderBase);
