export const CONFIDENCE_LEVEL_UI_MAPPING = {
  high: {
    backgroundColor: 'teal',
    text: '✓',
    tooltip: 'High confidence',
    fontSize: '0.625rem',
  },
  medium: {
    backgroundColor: 'orange',
    text: '?',
    tooltip: 'Medium confidence – please verify',
    fontSize: '0.75rem',
  },
  low: {
    backgroundColor: 'red',
    text: '–',
    tooltip: 'Low confidence – please manually review',
    fontSize: '0.75rem',
  },
};
