import { memo } from 'react';
import { Tabs } from '@mantine/core';

import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { SummaryByConditionStatus } from '@/pageAI/gql/graphql';

const UnifiedTabsBase = () => {
  const client = useSelectedClient(true);
  const [tab, setTab] = useUnifiedTab();

  return (
    <Tabs
      value={tab}
      onTabChange={setTab}
      sx={{
        whiteSpace: 'nowrap',
        '.ghost-Tabs-tab': {
          padding: '6px 8px',
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      }}
    >
      <Tabs.List sx={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
        {client.summaryByConditionStatus === SummaryByConditionStatus.Generated && (
          <Tabs.Tab value={UnifiedTab.SUMMARY}>Summary</Tabs.Tab>
        )}
        <Tabs.Tab value={UnifiedTab.TIMELINE}>Timeline</Tabs.Tab>
        <Tabs.Tab value={UnifiedTab.CONTENT_SEARCH}>Content Search</Tabs.Tab>
        <Tabs.Tab value={UnifiedTab.FILE_INDEX}>File Index</Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export const UnifiedTabs = memo(UnifiedTabsBase);
