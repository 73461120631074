import { parseTextFragmentToParts } from '@/shared/lib/browser/textFragment';
import urlJoin from 'url-join';

export const joinUrls = (...parts: string[]) => {
  return urlJoin(...parts);
};

export const extractUrlOrigin = (url: string) => {
  return new URL(url).origin;
};

export const containsUrls = (text: string) => {
  const urlPattern = /https?:\/\/\S+/i; // Regular expression to match http or https URLs

  return urlPattern.test(text);
};

export const isImageDataURL = (url: string | null) => {
  if (typeof url !== 'string') return false;

  return url?.startsWith('data:image/') || url.startsWith('data:svg+xml');
};

export const extractUrlHostname = (url: string) => {
  const urlObject = new URL(url);

  return urlObject.hostname.replaceAll('www.', '');
};

export const extractUrlOriginAndPathname = (url: string) => {
  const urlObject = new URL(url);

  return joinUrls(urlObject.origin, urlObject.pathname);
};

export const extractTextFragmentFromUrl = (url: string) => {
  const urlObject = new URL(url);

  return parseTextFragmentToParts(urlObject.hash);
};

export const decodeURIText = (text: string) => {
  return decodeURIComponent(text.replace(/\+/g, '%20'));
};

export const isAbsoluteUrl = (url: string) => {
  try {
    new URL(url);

    return true;
  } catch {
    return false;
  }
};

export const isSameHostname = (url: string, anotherUrl: string) => {
  return new URL(url).hostname === new URL(anotherUrl).hostname;
};

export const replaceUrl = (url: string, baseUrl: string, currentUrl: string) => {
  if (!isAbsoluteUrl(url)) {
    const absoluteUrl = new URL(url, baseUrl).href;

    return new URL(absoluteUrl).href;
  }

  if (isSameHostname(url, currentUrl)) {
    const absoluteUrl = new URL(url);
    absoluteUrl.hostname = new URL(baseUrl).hostname;
    absoluteUrl.port = '';

    return absoluteUrl.href;
  }

  return null;
};

export const appendSearchParams = (url: string, searchParams: URLSearchParams) => {
  const urlObject = new URL(url);

  return [urlObject.href.split('?')[0], searchParams.toString()].join('?');
};
