import { memo, useCallback, useState } from 'react';
import { useClickOutside } from '@mantine/hooks';

import { CaseTimelineItemVerificationLevelProps } from './CaseTimelineItemVerificationLevel.types';
import { ConfidenceSelect } from '../ConfidenceSelect';
import { ConfidenceLevelBadge } from '../ConfidenceLevelBadge';
import { useUpdateTimelineEvent } from '@/pageAI/hooks/caseTimeline/useUpdateTimelineEvent';
import { EventConfidenceLevel } from '@/pageAI/@types/summaries';

const CaseTimelineItemVerificationLevelBase = ({ client, item }: CaseTimelineItemVerificationLevelProps) => {
  const actualConfidenceLevel = (item.confidenceLevel || 'low') as EventConfidenceLevel;
  const [showSelect, setShowSelect] = useState(false);
  const badgeRef = useClickOutside(() => setShowSelect(false));

  const { updateTimelineEventOptimistically } = useUpdateTimelineEvent();

  const handleContextMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setShowSelect(true);
  }, []);

  const handleConfidenceLevelChange = useCallback(
    (newConfidenceLevel: EventConfidenceLevel) => {
      setShowSelect(false);

      updateTimelineEventOptimistically(client, { ...item, confidenceLevel: newConfidenceLevel });
    },
    [updateTimelineEventOptimistically, client, item],
  );

  return (
    <ConfidenceLevelBadge confidenceLevel={actualConfidenceLevel} onContextMenu={handleContextMenu} ref={badgeRef}>
      {showSelect && (
        <>
          <ConfidenceSelect
            value={actualConfidenceLevel}
            onChange={handleConfidenceLevelChange}
            initiallyOpened
            size="xs"
            sx={{
              position: 'absolute',
              top: -16,
              minWidth: 180,
              '.ghost-InputWrapper-label': {
                display: 'none',
                "+ div[role='combobox']": {
                  opacity: 0,
                  pointerEvents: 'none',
                },
              },
            }}
          />
        </>
      )}
    </ConfidenceLevelBadge>
  );
};

export const CaseTimelineItemVerificationLevel = memo(CaseTimelineItemVerificationLevelBase);
