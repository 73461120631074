import { memo } from 'react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { IconInfoCircle, IconKeyboard } from '@tabler/icons-react';

const TimelineNavigationHintBase = () => {
  return (
    <Tooltip
      withArrow
      withinPortal
      label={
        <Flex align="center" gap={6}>
          <IconKeyboard size={14} /> You can use <strong>Shift + Arrow Up</strong> and&nbsp;
          <strong>Shift + Arrow Down</strong> to navigate the timeline.
        </Flex>
      }
    >
      <ActionIcon>
        <IconInfoCircle size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export const TimelineNavigationHint = memo(TimelineNavigationHintBase);
