import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createConversationMutation } from '@/pageAI/api';
import { CreateConversationInput, CreateConversationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useClientStore } from '@/pageAI/states/client';

export const useCreateConversation = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const selectedClient = useClientStore((state) => state.selectedClient);

  const { mutateAsync: createConversation, isLoading } = useMutation<
    CreateConversationMutation,
    Error,
    CreateConversationInput
  >({
    mutationFn: async (input) => {
      const response = await request(createConversationMutation, { input: { ...input, clientId: selectedClient?.id } });

      queryClient.refetchQueries(['conversations']);

      return response;
    },
  });

  return { createConversation, isLoading };
};
