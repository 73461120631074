import { memo } from 'react';
import { Box, Flex, ScrollArea } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

import { MedicalConditionItemAsSummary } from '../../medicalConditions/MedicalConditionItemAsSummary';
import { selectedConditionSelector, toggleConditionSidebar, useUnifiedStore } from '@/pageAI/states/unified';
import { isSidebarOpenedSelector } from '@/pageAI/states/unified';
import { Panel } from '@/pageAI/designSystem/Panel';
import { MedicalConditionTitle } from '../../medicalConditions/MedicalConditionTitle';
import { useConditionSharing } from './UnifiedConditionSummary.utils';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { truthy } from '@/shared/utils/boolean';

const UnifiedConditionSummaryBase = () => {
  const client = useSelectedClient(true);

  const { medicalConditions, isLoading, isError } = useClientMedicalConditions({ clientId: client.id });

  const isSidebarOpened = useUnifiedStore(isSidebarOpenedSelector);
  const condition = useUnifiedStore(selectedConditionSelector);
  const { ref: headerRef, height: headerHeight } = useElementSize();

  useConditionSharing({ selectedMedicalConditionId: condition?.id });

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading condition summary..."
          description={[
            'Please wait while Page retrieves the condition summary',
            client ? ` of ${getClientDisplayName(client)}` : '',
            '.',
          ]
            .filter(truthy)
            .join('')}
          fullScreen
        />
      </Flex>
    );

  if (isError || !medicalConditions) throw new Error('Could not load condition summary');

  if (!condition) return null;

  return (
    <Panel sx={{ padding: '16px 0 0 0', gap: 0 }}>
      <Flex
        direction="column"
        ref={headerRef}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          padding: '0 32px 12px',
        })}
      >
        <Flex align="center" justify="space-between" gap="xs">
          <Flex align="center" gap={6}>
            <Flex align="center" sx={{ flexWrap: 'wrap', width: '100%' }} gap={6}>
              <Flex align="center" sx={{ maxWidth: '100%' }} gap={4}>
                <MedicalConditionTitle isSidebarOpened={isSidebarOpened} onClick={toggleConditionSidebar} />
              </Flex>
            </Flex>
          </Flex>

          <Flex sx={{ justifyContent: 'end' }}></Flex>
        </Flex>
      </Flex>

      <ScrollArea
        sx={{
          height: `calc(100vh - ${headerHeight + 84}px)`,
          code: {
            fontFamily: 'unset',
            textDecoration: 'underline',
            fontWeight: 500,
          },
          '.ghost-ScrollArea-viewport': {
            '> div': {
              height: '100%',
            },
          },
        }}
      >
        <Box
          sx={{
            padding: '16px 32px',
          }}
        >
          <MedicalConditionItemAsSummary medicalCondition={condition} />
        </Box>
      </ScrollArea>
    </Panel>
  );
};

export const UnifiedConditionSummary = memo(UnifiedConditionSummaryBase);
