import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';

import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import {
  Auth0Error,
  isEmailUnverifiedError,
  isWorkEmailError,
  parseErrorFromSearchParams,
} from '@/shared/services/auth';
import { useLogout } from '@/shared/hooks/auth/useLogout';
import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';
import { VerifyEmailPage } from '@/pageAI/pages/VerifyEmailPage';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';

export const CallbackPage = () => {
  const { error: rawError } = useAuth0();
  const { logout } = useLogout();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const parsedError = parseErrorFromSearchParams(searchParams);
  const error = (rawError as unknown as Auth0Error | undefined) || parsedError;

  useEffect(() => {
    if (error) return;

    const navigationTimeout = setTimeout(() => {
      navigate('/', { replace: true });
    }, 2000);

    return () => clearTimeout(navigationTimeout);
  }, [error, navigate]);

  if (!error)
    return (
      <LoadingPlaceholder
        title="Authenticating..."
        description="Please wait while Page verifies your credentials."
        fullScreen
      />
    );

  if (isEmailUnverifiedError(error)) {
    return <VerifyEmailPage />;
  }

  return (
    <Flex align="center" justify="center" sx={{ width: '100%', height: '100%' }} pos="relative">
      <PageLogoCircle width={40} height={40} style={{ position: 'absolute', bottom: 24, right: 24 }} />

      {(() => {
        if (isWorkEmailError(error)) {
          return (
            <ErrorPlaceholder
              title="Access denied"
              description="You must use work email to access Page."
              action={
                <Button color="red" mt="xs" size="md" onClick={logout}>
                  Log out
                </Button>
              }
            />
          );
        }

        return (
          <ErrorPlaceholder
            description="An authentication error has occurred. Please try again later."
            action={
              <Button color="red" mt="xs" size="md" onClick={() => (window.location.href = window.location.origin)}>
                Retry
              </Button>
            }
          />
        );
      })()}
    </Flex>
  );
};
