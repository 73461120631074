import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';

import '@/shared/lib/sentry';

export const useSentry = () => {
  const { user } = useAuth0();

  const sentryAuth = useCallback(() => {
    Sentry.setUser({ email: user?.email });
  }, [user?.email]);

  useEffect(() => {
    sentryAuth();
  }, [sentryAuth]);

  return { sentryAuth };
};
