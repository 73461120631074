import { useEffect, useRef } from 'react';
import { Text } from '@mantine/core';

import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { CommentEditorProps } from './CommentEditor.types';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { moveCaretToEnd } from '@/shared/lib/dom';

const CommentEditor = ({ value, onChange, readonly = false, sx }: CommentEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (readonly) return;

    setTimeout(() => {
      const editableElement = containerRef.current?.querySelector('.ck.ck-content.ck-editor__editable');

      if (!editableElement) return;

      moveCaretToEnd(editableElement as HTMLElement);
    }, 100);
  }, [readonly]);

  if (readonly)
    return (
      <Text color="dark" fz="0.75rem" ref={containerRef}>
        <MarkdownRenderer content={value} />
      </Text>
    );

  return (
    <Text color="dark" fz="0.75rem" sx={sx} ref={containerRef}>
      <InlineEditor
        sx={{
          fontSize: '0.75rem',
          '.ck.ck-content.ck-editor__editable': {
            minHeight: 20,
            border: 'none',
          },
        }}
        data={value}
        onDataChange={onChange}
        toolbar={[]}
      />
    </Text>
  );
};

export default CommentEditor;
