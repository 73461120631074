import { memo } from 'react';
import { ActionIcon, Flex, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

import { ConversationHeaderProps } from './ConversationHeader.types';
import { useAppNavigate } from '@/shared/hooks/routes/useAppNavigate';

const ConversationHeaderBase: React.FC<ConversationHeaderProps> = ({ conversationName = 'Untitled conversation' }) => {
  const { navigateToChat } = useAppNavigate();

  return (
    <Flex align="center" gap={6} mb={32}>
      <ActionIcon color="dark" onClick={() => navigateToChat()}>
        <IconArrowLeft />
      </ActionIcon>

      <Text fz="1.125rem" fw={500}>
        {conversationName}
      </Text>
    </Flex>
  );
};

export const ConversationHeader = memo(ConversationHeaderBase);
