import { memo } from 'react';
import { ActionIcon, Menu } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconFileText, IconLetterCase, IconPrinter } from '@tabler/icons-react';

import { AssetSidebarActionMenuProps } from './AssetSidebarActionMenu.types';
import { FilePreviewTab, useFilePreviewStore } from '@/shared/states/filePreview';
import { downloadPdfEventBus } from './AssetSidebarActionMenu.utils';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { getPdfFilename, printPdf } from '@/shared/services/files';
import AddOrRemoveFileFromConditionMenuItem from '@/pageAI/components/medicalConditions/errorCorrection/AddOrRemoveFileFromConditionMenuItem';

const AssetSidebarActionMenuBase = ({ tab, onTabChange }: AssetSidebarActionMenuProps) => {
  const client = useSelectedClient();
  const { asset, additionalMenuItems } = useFilePreviewStore();

  const handleDownload = () => {
    if (!client || !asset) return null;

    const clientDisplayName = getClientDisplayName(client);

    const filename = getPdfFilename(asset, clientDisplayName);

    downloadPdfEventBus.publish({ asset, filename });
  };

  const handlePrint = async () => {
    if (!client || !asset) return null;

    const fileAssetBlob = await fetch(asset.url).then((r) => r.blob());

    printPdf(fileAssetBlob);
  };

  return (
    <Menu width={200} shadow="md" closeOnClickOutside={false}>
      <Menu.Target>
        <ActionIcon color="gray.6" size="xs">
          <IconDotsVertical size={14} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={tab === FilePreviewTab.PDF ? <IconLetterCase size={14} /> : <IconFileText size={14} />}
          onClick={() => onTabChange(tab === FilePreviewTab.PDF ? FilePreviewTab.Text : FilePreviewTab.PDF)}
        >
          {tab === FilePreviewTab.PDF ? 'View text' : 'View PDF'}
        </Menu.Item>

        {tab === FilePreviewTab.PDF && (
          <>
            <Menu.Item icon={<IconDownload size={14} />} onClick={handleDownload}>
              Download
            </Menu.Item>

            <Menu.Item icon={<IconPrinter size={14} />} onClick={handlePrint}>
              Print
            </Menu.Item>
          </>
        )}

        <AddOrRemoveFileFromConditionMenuItem />

        {additionalMenuItems}
      </Menu.Dropdown>
    </Menu>
  );
};

export const AssetSidebarActionMenu = memo(AssetSidebarActionMenuBase);
