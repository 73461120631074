import { useMutation } from '@tanstack/react-query';

import { regenerateAnswerMutation } from '@/pageAI/api';
import { RegenerateAnswerMutation, RegenerateAnswerMutationVariables } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useRegenerateAnswer = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync: regenerateAnswer, isLoading } = useMutation<
    RegenerateAnswerMutation,
    Error,
    RegenerateAnswerMutationVariables
  >({
    mutationFn: async (input) => {
      return request(regenerateAnswerMutation, input);
    },
  });

  return { regenerateAnswer, isLoading };
};
