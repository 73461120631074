import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateConversationMutation } from '@/pageAI/api';
import { ConversationQuery, UpdateConversationInput, UpdateConversationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useUpdateConversation = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutate: updateConversation, isLoading } = useMutation<
    UpdateConversationMutation,
    Error,
    UpdateConversationInput
  >({
    mutationFn: async (input) => {
      const response = await request(updateConversationMutation, { input });

      queryClient.refetchQueries(['conversations']);
      queryClient.setQueryData(['conversation', input.id], (prevData?: ConversationQuery) => {
        if (!prevData) return prevData;

        return {
          ...prevData,
          conversation: {
            ...prevData.conversation,
            name: input.name,
          },
        };
      });

      return response;
    },
  });

  return { updateConversation, isLoading };
};
