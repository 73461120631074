import { memo, useState } from 'react';
import { ActionIcon, Button, Flex, Popover, Text, TextInput } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconSearch } from '@tabler/icons-react';

import { SearchButtonProps } from './SearchButton.types';

const SearchButtonBase: React.FC<SearchButtonProps> = ({
  currentResultIndex = -1,
  totalOfResults = 0,
  onFind,
  onGoToNextResult,
  onGoToPreviousResult,
  onClose,
}) => {
  const [searchText, setSearchText] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFind = () => {
    onFind?.(searchText);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleFind();
    }
  };

  return (
    <Popover width={400} shadow="md" position="bottom-start" withinPortal onClose={onClose}>
      <Popover.Target>
        <ActionIcon variant="outline">
          <IconSearch size={14} />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex align="center" sx={{ width: '100%' }} gap="xs">
          <Flex align="center" sx={{ position: 'relative', flexGrow: 1 }}>
            <TextInput
              value={searchText}
              onChange={handleInputChange}
              size="xs"
              sx={{ flexGrow: 1 }}
              onKeyDown={handleKeyDown}
            />

            <Text color="gray.3" size="xs" sx={{ position: 'absolute', right: 8, top: 6 }}>
              {currentResultIndex + 1} of {totalOfResults}
            </Text>
          </Flex>

          <Flex align="center" gap={4}>
            <Button size="xs" leftIcon={<IconSearch size={14} />} onClick={handleFind}>
              Find
            </Button>

            <ActionIcon variant="outline" onClick={onGoToPreviousResult} disabled={currentResultIndex <= 0}>
              <IconArrowUp size={14} />
            </ActionIcon>

            <ActionIcon
              variant="outline"
              onClick={onGoToNextResult}
              disabled={currentResultIndex >= totalOfResults - 1}
            >
              <IconArrowDown size={14} />
            </ActionIcon>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const SearchButton = memo(SearchButtonBase);
