import { memo, useCallback, useState, useTransition } from 'react';
import { Flex, Loader, Switch } from '@mantine/core';

import { DisplayOnlyAdditionalEventsSwitchProps } from './DisplayOnlyAdditionalEventsSwitch.types';

const DisplayOnlyAdditionalEventsSwitchBase = ({ initialValue, onChange }: DisplayOnlyAdditionalEventsSwitchProps) => {
  const [isPending, startTransition] = useTransition();
  const [value, setValue] = useState(!!initialValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);

      if (typeof onChange !== 'function') return;

      startTransition(() => onChange(event.target.checked));
    },
    [onChange],
  );

  return (
    <Switch
      label={
        <Flex align="center" gap={6}>
          Show only additional events
          {isPending && <Loader size={12} />}
        </Flex>
      }
      checked={value}
      onChange={handleChange}
      size="xs"
    />
  );
};

export const DisplayOnlyAdditionalEventsSwitch = memo(DisplayOnlyAdditionalEventsSwitchBase);
