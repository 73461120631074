import { ContextMenuProvider } from 'mantine-contextmenu';

import { QueryClientProvider, queryClient } from '@/shared/lib/react-query';
import { useSentry } from '@/shared/hooks/useSentry';
import { PageAIApp } from '@/pageAI/PageAIApp';
import { Auth0ProviderWithConfig } from '@/shared/providers/Auth0ProviderWithConfig';

export const App = () => {
  useSentry();

  return (
    <Auth0ProviderWithConfig>
      <ContextMenuProvider>
        <QueryClientProvider client={queryClient}>
          <PageAIApp />
        </QueryClientProvider>
      </ContextMenuProvider>
    </Auth0ProviderWithConfig>
  );
};
