import { SubscriptionClient } from 'subscriptions-transport-ws';

import { WEB_SOCKET_ENDPOINT } from '@/shared/config';
import { CreateWebSocketSubscriptionParams } from './subscription.lib.types';

export const createWebSocketSubscription = <T>({
  query,
  accessToken,
  observer,
}: CreateWebSocketSubscriptionParams<T>) => {
  const subscriptionClient = new SubscriptionClient(WEB_SOCKET_ENDPOINT, {
    reconnect: true,
    connectionParams: {
      token: `${accessToken}`,
    },
  });

  // TODO: Fix this type issue
  const subscription = subscriptionClient.request({ query }).subscribe(observer);

  return {
    unsubscribe: () => {
      subscription.unsubscribe();
    },
    close: () => {
      subscriptionClient.close();
    },
  };
};
