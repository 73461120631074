import { memo } from 'react';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconUserPlus } from '@tabler/icons-react';

import { InviteMemberButtonProps } from './InviteMemberButton.types';
import { InviteMemberModal } from '../InviteMemberModal';

const InviteMemberButtonBase = ({ organization }: InviteMemberButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Button color="red" leftIcon={<IconUserPlus size={16} />} onClick={open}>
        Invite
      </Button>

      <InviteMemberModal opened={opened} onClose={close} organization={organization} />
    </>
  );
};

export const InviteMemberButton = memo(InviteMemberButtonBase);
