import { memo, useState } from 'react';
import { ActionIcon, Avatar, Flex, Text, Transition } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons-react';

import CommentEditor from '../CommentEditor';
import { AddCommentBoxProps } from './AddCommentBox.types';
import { useCreateComment } from '@/pageAI/hooks/caseTimeline/useCreateComment';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';

const AddCommentBoxBase = ({ client, event, opened = false, onSubmit }: AddCommentBoxProps) => {
  const { currentUser } = useCurrentUser();

  const { createComment } = useCreateComment();

  const [content, setContent] = useState('');

  const hasComments = event.comments.length > 0;

  const submitComment = () => {
    onSubmit?.();
    setContent('');

    createComment(client, event, { eventId: event.id, content });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter' || !event.metaKey) return;

    event.stopPropagation();

    submitComment();
  };

  if (!currentUser) return null;

  return (
    <Transition mounted={opened} transition="pop-bottom-left" duration={200} exitDuration={200}>
      {(styles) => (
        <Flex
          className="unprintable"
          style={styles}
          sx={(theme) => ({
            width: '100%',
            padding: '4px 8px',
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 4,
            background: theme.white,
          })}
          gap={6}
          onKeyDownCapture={handleKeyDown}
          {...(hasComments ? {} : { mt: 6, mb: 4 })}
        >
          <Flex gap={6} mt={2}>
            <Avatar
              sx={{ minWidth: 20, minHeight: 20, maxWidth: 20, maxHeight: 20 }}
              radius="50%"
              alt={currentUser.firstName}
              src={currentUser.avatarUrl}
            />
          </Flex>

          <Flex
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <CommentEditor value={content} onChange={setContent} readonly={false} sx={{ width: '100%' }} />

            {!content && (
              <Text sx={{ position: 'absolute', pointerEvents: 'none', left: 0, top: 2 }} fz="0.75rem" color="gray.6">
                Add a comment...
              </Text>
            )}
          </Flex>

          <Flex align="flex-end" mb={3}>
            <ActionIcon size="xs" variant="filled" color="brand.4" radius="50%" onClick={submitComment}>
              <IconArrowUp size={12} />
            </ActionIcon>
          </Flex>
        </Flex>
      )}
    </Transition>
  );
};

export const AddCommentBox = memo(AddCommentBoxBase);
