import { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';

interface ProtectedRouteProps {
  component: ComponentType;
  isSignUp?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, isSignUp = false }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <LoadingPlaceholder
        title="Redirecting..."
        description="Please wait while we redirect you to the right page."
        fullScreen
      />
    ),
    loginOptions: {
      screen_hint: isSignUp ? 'signup' : 'login',
    },
  });

  return <Component />;
};
