import { memo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex, Paper, Text } from '@mantine/core';

import { UserProfileForm, UserProfileFormValues } from '@/pageAI/components/users/UserProfileForm';
import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';
import { useUpdateUserProfile } from '@/pageAI/hooks/users/useUpdateUserProfile';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { redirectUrl } from '@/pageAI/routes/AllowedRoute';
import { truthy } from '@/shared/utils/boolean';
import { LogOutButton } from '@/pageAI/components/auth/LogOutButton';

const UserOnboardingPageBase = () => {
  const [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const { updateUserProfile, isLoading: isUpdating } = useUpdateUserProfile();
  const navigate = useNavigate();

  const isUpdate = searchParams.get('update') === 'true';
  const isOnboarding = searchParams.get('onboarding') === 'true';

  const handleSubmit = async (values: UserProfileFormValues) => {
    if (!currentUser) return;

    await updateUserProfile({
      clientMutationId: currentUser.id,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phone: values.phone?.trim(),
      role: values.role,
    });

    navigate(redirectUrl || ['/', isOnboarding ? '?showConfetti=true' : ''].filter(truthy).join(''), { replace: true });
  };

  return (
    <Flex align="center" justify="center" sx={(theme) => ({ background: theme.colors.dark[5], height: '100%' })}>
      <Paper p={64} shadow="sm" sx={{ width: 564 }}>
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" mb="md">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Update your profile
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              {isUpdate
                ? 'Kindly update your profile before continuing to use Page.'
                : 'Enter your basic information to get started.'}
            </Text>
          </Flex>

          {currentUser && (
            <UserProfileForm
              onSubmit={handleSubmit}
              bottomSection={
                <Flex mt="lg">
                  <Button type="submit" sx={{ width: '100%' }} color="red.6" size="md" loading={isUpdating}>
                    Continue
                  </Button>
                </Flex>
              }
            />
          )}
        </Flex>
      </Paper>

      <Flex align="center" justify="center" pos="fixed" right={12} bottom={12}>
        <LogOutButton />
      </Flex>
    </Flex>
  );
};

export const UserOnboardingPage = memo(UserOnboardingPageBase);
