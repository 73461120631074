import { memo, useRef } from 'react';
import { Box, Button, Text } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons-react';

import { HelpTabContentProps } from './HelpTabContent.types';
import { InlineEditor } from '@/shared/components/editor/InlineEditor';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

const HelpTabContentBase = ({ content, onChange, attachedFile, onAttachFile, placeholder }: HelpTabContentProps) => {
  const { notify } = useNotifications();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    if (file.size > 25 * 1024 * 1024) {
      return notify('Error', 'File size should not exceed 25MB');
    }

    onAttachFile?.(file);
  };

  return (
    <Box
      pos="relative"
      sx={(theme) => ({
        height: 240,
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 4,
        padding: '12px 8px',
      })}
    >
      {!content && placeholder && (
        <Text
          fz="0.875rem"
          color="dark.3"
          sx={{ position: 'absolute', top: 13.5, left: 8, pointerEvents: 'none', userSelect: 'none' }}
        >
          {placeholder}
        </Text>
      )}

      <Button
        sx={{
          position: 'absolute',
          left: 8,
          bottom: 12,
          '.ghost-Button-label': {
            maxWidth: 400,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'block',
            height: 'fit-content',
          },
        }}
        leftIcon={<IconPaperclip size={16} />}
        size="xs"
        variant="subtle"
        color="gray.7"
        onClick={handleAttach}
      >
        {attachedFile ? attachedFile.name : 'Attach'}
      </Button>

      <Box sx={{ display: 'none' }}>
        <input ref={fileInputRef} type="file" onChange={handleFileInputChange} />
      </Box>

      <InlineEditor data={content} onDataChange={onChange} />
    </Box>
  );
};

export const HelpTabContent = memo(HelpTabContentBase);
