import { Text, Flex, Button, useMantineTheme, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';

import { Panel } from '@/pageAI/designSystem/Panel';
import { FeatureFlagSwitch } from '@/pageAI/components/settings/FeatureFlagSwitch';
import {
  RETRY_OCR_FLAG_STORAGE_KEY,
  FILE_INDEXING_FLAG_STORAGE_KEY,
  PDF_HIGHLIGHTING_FLAG_STORAGE_KEY,
} from '@/shared/services/files';
import {
  DISPLAY_IRREGULAR_TIMELINE_EVENTS_FEATURE_FLAG,
  USE_LATEST_MODEL_FEATURE_FLAG,
} from '@/pageAI/services/caseTimeline';
import { toggleFileGroupManagementModalEventBus } from '@/pageAI/components/files/FileGroupManagementModal';
import { PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { InvitationLinkPanel } from '@/pageAI/components/onboarding/InvitationLinkPanel';

export const SettingsPage = () => {
  const theme = useMantineTheme();

  const featureSettingsSection = (
    <Panel sx={{ gap: 0, border: `1px solid ${theme.colors.gray[3]}` }}>
      <Text fw={600} color="dark">
        Feature Settings
      </Text>

      <Text fz="0.75rem" color="gray.7" mb="sm">
        These options below allow you to turn on/off certain features of the app.
      </Text>

      <Flex direction="column" gap={6}>
        <FeatureFlagSwitch
          flag={PDF_HIGHLIGHTING_FLAG_STORAGE_KEY}
          label="PDF highlighting"
          tooltip="The PDF highlighting feature is experimental."
        />

        <FeatureFlagSwitch
          flag={FILE_INDEXING_FLAG_STORAGE_KEY}
          label="File indexing"
          tooltip="Files need to be indexed after uploading before the Client Q&A functionality can be used."
        />

        <FeatureFlagSwitch
          flag={RETRY_OCR_FLAG_STORAGE_KEY}
          label="OCR retries"
          tooltip="Allows retrying OCR for files."
        />

        <FeatureFlagSwitch
          flag={DISPLAY_IRREGULAR_TIMELINE_EVENTS_FEATURE_FLAG}
          label="Irregular timeline events"
          tooltip="Shows out-of-range timeline events (before 1920 and in the future), duplicated events, and events with no dates."
        />

        <FeatureFlagSwitch
          flag={USE_LATEST_MODEL_FEATURE_FLAG}
          label="Use the latest model"
          tooltip="Use the latest GPT model when generating summaries."
        />
      </Flex>
    </Panel>
  );

  const fileGroupsSection = (
    <Panel sx={{ gap: 0, border: `1px solid ${theme.colors.gray[3]}` }}>
      <Text fw={600} color="dark">
        Custom Filters
      </Text>

      <Text fz="0.75rem" color="gray.7" mb="sm">
        Customize filters for your organization.
      </Text>

      <Flex direction="column" gap={6}>
        <Button
          variant="outline"
          sx={{ width: 'fit-content' }}
          size="xs"
          onClick={() => toggleFileGroupManagementModalEventBus.publish({})}
        >
          Customize Filters
        </Button>
      </Flex>
    </Panel>
  );

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={
          <Anchor to="/settings" component={Link} fw={600}>
            Settings
          </Anchor>
        }
      />

      <Flex p={32} py="xs" direction="column" gap="lg">
        {fileGroupsSection}

        <InvitationLinkPanel />

        {featureSettingsSection}
      </Flex>
    </Flex>
  );
};
