import { memo, useMemo } from 'react';
import { Box, Flex, Table, Text } from '@mantine/core';

import { Panel } from '@/pageAI/designSystem/Panel';
import { Organization } from '@/pageAI/@types';
import { singularOrPlural } from '@/shared/utils/string';
import { JoinRequestRow } from '../JoinRequestRow';

interface OrganizationJoinRequestsPanelProps {
  organization: Organization;
  query?: string;
}

// TODO: Refactor this into a re-usable table component
const OrganizationJoinRequestsPanelBase = ({ organization, query }: OrganizationJoinRequestsPanelProps) => {
  const rows = useMemo(
    () =>
      organization.joinRequests.map((joinRequest) => {
        return <JoinRequestRow key={joinRequest.id} joinRequest={joinRequest} organizationId={organization.id} />;
      }),
    [organization.joinRequests, organization.id],
  );

  const isEmpty = !organization.joinRequests.length;

  return (
    <Panel sx={{ padding: 0 }}>
      <Flex align="center" gap="xs">
        <Text fw={600} color="dark.4">
          Requests to Join
        </Text>

        <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

        <Text fw={500} fz="0.875rem" color="dark.3" sx={{ whiteSpace: 'nowrap' }}>
          {organization.joinRequests.length} {singularOrPlural('request', 'requests')(organization.joinRequests.length)}
        </Text>
      </Flex>

      <Table
        highlightOnHover
        className="join-requests-table"
        sx={{
          '&.ghost-Table-root.join-requests-table': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          ...(isEmpty && {
            thead: {
              tr: {
                th: {
                  borderBottom: 'none',
                },
              },
            },
          }),
        }}
      >
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '30%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>

        <thead>
          <tr>
            <th>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }} component="span">
                User
              </Box>
            </th>

            <th>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }} component="span">
                Request Date
              </Box>
            </th>

            <th style={{ cursor: 'initial', background: 'transparent' }}>Email</th>

            <th style={{ cursor: 'initial', background: 'transparent' }}></th>
          </tr>
        </thead>

        <tbody>{rows}</tbody>
      </Table>

      {isEmpty && (
        <Flex
          direction="column"
          justify="center"
          p="xs"
          mt={-11}
          sx={(theme) => ({
            outline: `1px solid ${theme.colors.gray[3]}`,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          })}
        >
          <Text fz="0.875rem" color="gray.7">
            There are no pending join requests.
          </Text>
        </Flex>
      )}
    </Panel>
  );
};

export const OrganizationJoinRequestsPanel = memo(OrganizationJoinRequestsPanelBase);
