import { useMutation } from '@tanstack/react-query';

import { GenerateCaseTimelineInput, GenerateSummariesMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { generateSummariesMutation } from '@/pageAI/api/summaries';

export const useGenerateSummaries = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync: generateSummaries, isLoading } = useMutation<
    GenerateSummariesMutation,
    Error,
    GenerateCaseTimelineInput
  >({
    mutationFn: async (input) => {
      return request(generateSummariesMutation, { input });
    },
  });

  return {
    generateSummaries,
    isLoading,
  };
};
