import { memo, useEffect } from 'react';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import Confetti from 'react-confetti';

const ONBOARDING_CONFETTI_LOCAL_STORAGE_KEY = 'showOnboardingConfetti';

const OnboardingConfettiModalBase = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const showOnboardingConfetti = searchParams.get('showConfetti') === 'true';
  const [value, setValue] = useLocalStorage({
    key: ONBOARDING_CONFETTI_LOCAL_STORAGE_KEY,
    defaultValue: showOnboardingConfetti,
  });

  useEffect(() => {
    if (!value) return;

    open();
    setValue(false);
    searchParams.delete('showConfetti');
    setSearchParams(searchParams, { replace: true });
  }, [value, setValue, open, searchParams, setSearchParams]);

  return (
    <Modal
      opened={opened}
      centered
      onClose={close}
      withCloseButton={false}
      withinPortal
      sx={{
        '.ghost-Paper-root.ghost-Modal-content': {
          minWidth: 564,
        },
      }}
    >
      <Flex direction="column" py={40} px={80} sx={{ width: '100%' }}>
        <Flex align="center" justify="center">
          <Text fz={80}>🎉</Text>
        </Flex>

        <Text align="center" fz="1.5rem" fw={600} mb="xs" color="dark">
          You are all set!
        </Text>

        <Text align="center" color="dark.3" fw={500} mb="xl">
          Welcome to Page! We&apos;re thrilled to have you here and can&apos;t wait to see the great things you&apos;ll
          be able to achieve with Page.
        </Text>

        <Button color="red" size="md" onClick={close}>
          Start using Page
        </Button>

        <Confetti width={540} height={420} recycle={false} />
      </Flex>
    </Modal>
  );
};

export const OnboardingConfettiModal = memo(OnboardingConfettiModalBase);
