import { memo } from 'react';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSparkles } from '@tabler/icons-react';

import { GenerateSummariesButtonProps } from './GenerateSummariesButton.types';
import { areGeneratingSummaries, canDisplaySummaries } from '@/pageAI/services/summaries';
import { GenerateSummariesModal } from '../GenerateSummariesModal';

const GenerateSummariesButtonBase = ({ client }: GenerateSummariesButtonProps) => {
  const [opened, { open, close }] = useDisclosure();

  const canRegenerateSummaries = canDisplaySummaries(client);

  if (!client.viewerCanGenerateTimeline) return null;

  const actionText = canRegenerateSummaries ? 'Regenerate summaries' : 'Generate summaries';
  const areGenerating = areGeneratingSummaries(client);

  return (
    <>
      <Button
        color="red"
        leftIcon={<IconSparkles size={16} />}
        onClick={open}
        variant={canRegenerateSummaries ? 'light' : 'filled'}
        disabled={areGenerating}
      >
        {actionText}
      </Button>

      <GenerateSummariesModal client={client} opened={opened} onClose={close} isRegenerate={canRegenerateSummaries} />
    </>
  );
};

export const GenerateSummariesButton = memo(GenerateSummariesButtonBase);
