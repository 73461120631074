import { memo, useCallback } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, clsx, Popover, Tooltip } from '@mantine/core';

import { useManageConditionEvents } from '@/pageAI/hooks/conditions/useManageConditionEvents';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { AddEventsToUnknownConditionButtonProps } from './AddEventToUnknownConditionButton.types';
import { AddEventToUnknownConditionDropdown } from '../AddEventToUnknownConditionDropdown';
import { IconPlus } from '@tabler/icons-react';

const AddEventToUnknownConditionButtonBase = ({ event, tab }: AddEventsToUnknownConditionButtonProps) => {
  const client = useSelectedClient(true);

  const { addEventsToConditions, isAdding } = useManageConditionEvents(client.id);

  const [isPopoverOpened, { toggle: togglePopover, close: closePopover }] = useDisclosure();
  const [isTooltipOpened, { open: openTooltip, close: closeTooltip }] = useDisclosure();

  const handleClickAdd = () => {
    togglePopover();
  };

  const handleConfirmAddToCondition = useCallback(
    async (conditions: MedicalConditionItem[]) => {
      addEventsToConditions(
        conditions.map((condition) => condition.id),
        [event.id],
      );

      closePopover();
    },
    [event.id, addEventsToConditions, closePopover],
  );

  return (
    <Popover opened={isPopoverOpened} onClose={closePopover} shadow="sm">
      <Popover.Target>
        <Tooltip opened={isTooltipOpened} withArrow withinPortal label="Add event to conditions">
          <ActionIcon
            loading={isAdding}
            onMouseEnter={openTooltip}
            onMouseLeave={closeTooltip}
            onClick={handleClickAdd}
            size="xs"
            color="gray.7"
            className={clsx(tab === 'All' ? 'extra-action' : '', 'unprintable')}
          >
            <IconPlus size={16} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <AddEventToUnknownConditionDropdown onClose={closePopover} onConfirm={handleConfirmAddToCondition} />
      </Popover.Dropdown>
    </Popover>
  );
};

export const AddEventToUnknownConditionButton = memo(AddEventToUnknownConditionButtonBase);
