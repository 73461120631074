import { memo, useState } from 'react';
import { Alert, Button, Flex, Text, TextInput, useMantineTheme } from '@mantine/core';

import { InvitationLinkPanelProps } from './InvitationLinkPanel.types';
import { Panel } from '@/pageAI/designSystem/Panel';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { canUserGenerateInvitationLink } from '@/pageAI/services/users';
import { useGenerateInvitaitonLink } from '@/pageAI/hooks/onboarding/useGenerateInvitationLink';
import { CopyButton } from '@/shared/components/common/CopyButton';
import { IS_STAGING } from '@/shared/config';

const InvitationLinkPanelBase = (props: InvitationLinkPanelProps) => {
  const theme = useMantineTheme();

  const { currentUser } = useCurrentUser();
  const { generateInvitationLink, isLoading } = useGenerateInvitaitonLink();
  const [invitationLink, setInvitationLink] = useState<string | null>(null);

  if (!currentUser || !canUserGenerateInvitationLink(currentUser)) return null;

  const handleGenerateLink = async () => {
    const responseBody = await generateInvitationLink({});

    const invitationCode = responseBody.createInvitation?.invitation?.code;

    if (!invitationCode) return;

    setInvitationLink([window.location.origin, '/sign-up?code=', invitationCode].join(''));
  };

  return (
    <Panel sx={{ gap: 0, border: `1px solid ${theme.colors.gray[3]}` }}>
      <Text fw={600} color="dark">
        Invitation Link
      </Text>

      <Text fz="0.75rem" color="gray.7" mb="sm">
        You can generate an invitation link that allows people to join Page.
      </Text>

      <Flex direction="column" gap="xs">
        {invitationLink && (
          <TextInput w={480} value={invitationLink} size="xs" rightSection={<CopyButton value={invitationLink} />} />
        )}

        <Button
          variant="outline"
          sx={{ width: 'fit-content' }}
          size="xs"
          onClick={handleGenerateLink}
          loading={isLoading}
        >
          Generate Invitation Link
        </Button>

        {IS_STAGING && (
          <Alert color="orange.6" title="Staging Environment">
            This is the staging environment. Invitation links generated here will only work in the staging environment
            and are only meant for internal testing.
          </Alert>
        )}
      </Flex>
    </Panel>
  );
};

export const InvitationLinkPanel = memo(InvitationLinkPanelBase);
