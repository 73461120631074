import { forwardRef, memo, useCallback, useState, useTransition } from 'react';
import { Flex, Loader, Select } from '@mantine/core';

import { ConfidenceSelectItemProps, ConfidenceSelectProps } from './ConfidenceSelect.types';
import { EventConfidenceLevel } from '@/pageAI/@types/summaries';
import { ConfidenceLevelBadge } from '../ConfidenceLevelBadge';

const confidenceLevelOptions: { value: EventConfidenceLevel; label: string }[] = [
  {
    value: 'high',
    label: 'High confidence',
  },
  {
    value: 'medium',
    label: 'Medium confidence',
  },
  {
    value: 'low',
    label: 'Low confidence',
  },
];

const ConfidenceSelectItem = forwardRef(
  ({ value, label, ...rest }: ConfidenceSelectItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <Flex ref={ref} {...rest} align="center" gap={6}>
        <ConfidenceLevelBadge confidenceLevel={value as EventConfidenceLevel} />

        {label}
      </Flex>
    );
  },
);

const ConfidenceSelectBase = ({
  value,
  initialValue,
  size,
  initiallyOpened = false,
  sx,
  icon,
  clearable = false,
  label = 'Confidence level',
  onChange,
}: ConfidenceSelectProps) => {
  const [isPending, startTransition] = useTransition();
  const [internalValue, setInternalValue] = useState<EventConfidenceLevel | undefined>(initialValue);

  const currentValue = typeof value === 'undefined' ? internalValue : value;

  const handleChange = useCallback(
    (newValue: EventConfidenceLevel) => {
      setInternalValue(newValue);

      if (typeof onChange === 'function') {
        startTransition(() => onChange(newValue));
      }
    },
    [onChange],
  );

  return (
    <Select
      icon={icon}
      rightSection={isPending ? <Loader size={12} /> : null}
      itemComponent={ConfidenceSelectItem}
      label={label}
      placeholder="Select a confidence level"
      data={confidenceLevelOptions}
      value={currentValue}
      onChange={handleChange}
      initiallyOpened={initiallyOpened}
      size={size}
      sx={sx}
      clearable={clearable}
      withinPortal
    />
  );
};

export const ConfidenceSelect = memo(ConfidenceSelectBase);
