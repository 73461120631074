import { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from '@mantine/core';

const UserAvatarBase = () => {
  const { user } = useAuth0();

  return (
    <Avatar
      sx={{ minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, borderRadius: '50%', alignSelf: 'flex-start' }}
      alt={user?.name}
      src={user?.picture}
    />
  );
};

export const UserAvatar = memo(UserAvatarBase);
