import { ConversationQuery } from '@/pageAI/gql/graphql';
import { UpdateChatMessageProps } from './chat.services.types';

export const updateChatMessage = ({ queryClient, conversationId, messageId, updateInfo }: UpdateChatMessageProps) => {
  queryClient.setQueryData(['conversation', conversationId], (prevData?: ConversationQuery) => {
    if (!prevData) return prevData;

    const newMessageNodes = prevData.conversation.messages.nodes.map((message) => {
      if (message.id === messageId)
        return {
          ...message,
          ...updateInfo,
        };

      return message;
    });

    return {
      ...prevData,
      conversation: {
        ...prevData.conversation,
        messages: {
          nodes: newMessageNodes,
        },
      },
    };
  });
};
