import { UnifiedState } from './unified.states';

export const conditionsSelector = (state: UnifiedState) => state.conditions;

export const selectedConditionSelector = (state: UnifiedState) => state.computed.selectedCondition;

export const isSidebarOpenedSelector = (state: UnifiedState) => state.isSidebarOpened;

// ============================== Summary Tab ==============================
export const summaryTabViewModeSelector = (state: UnifiedState) => state.summaryTabState.viewMode;

export const showNullFieldsSelector = (state: UnifiedState) => state.summaryTabState.showNullFields;

export const summaryTabSortOrderSelector = (state: UnifiedState) => state.summaryTabState.sortOrder;

export const summaryTabHighlightedSelector = (state: UnifiedState) => state.summaryTabState.highlighted;
