import { memo, useMemo } from 'react';
import { Anchor, Flex, Menu, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { ClientSubPageMenuProps } from './ClientSubPageMenu.types';
import { SummaryType } from '@/pageAI/services/summaries';

const ClientSubPageMenuBase = ({ client, title }: ClientSubPageMenuProps) => {
  const menuItems = useMemo(
    () =>
      [
        {
          key: 'case-timeline',
          title: 'Case Timeline',
          url: `/clients/${client.id}/summaries/${SummaryType.CaseTimeline}`,
        },
        {
          key: 'condition-summary',
          title: 'Condition Summary',
          url: `/clients/${client.id}/summaries/${SummaryType.ConditionSummary}`,
        },
        {
          key: 'file-search',
          title: 'Content Search',
          url: `/clients/${client.id}/file-search`,
        },
      ].filter((item) => item.title !== title),
    [client.id, title],
  );

  return (
    <Menu shadow="sm">
      <Menu.Target>
        <Flex
          align="center"
          gap={4}
          sx={(theme) => ({
            cursor: 'pointer',
            borderBottom: '1px solid transparent',
            '&:hover': {
              borderBottom: `1px solid ${theme.colors.dark[5]}`,
            },
            svg: {
              marginBottom: -2,
            },
          })}
        >
          <Text fw={600} color="dark" sx={{ lineHeight: 0, whiteSpace: 'nowrap' }}>
            {title}
          </Text>

          <IconChevronDown size={16} />
        </Flex>
      </Menu.Target>

      <Menu.Dropdown>
        {menuItems.map((menuItem) => {
          return (
            <Anchor
              key={menuItem.key}
              to={menuItem.url}
              component={Link}
              sx={{
                '&.ghost-Anchor-root': {
                  textDecoration: 'none',
                },
              }}
            >
              <Menu.Item>{menuItem.title}</Menu.Item>
            </Anchor>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};

export const ClientSubPageMenu = memo(ClientSubPageMenuBase);
