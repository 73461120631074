import { memo, useMemo } from 'react';
import { Text } from '@mantine/core';

import { TimelineEventSummaryProps } from './TimelineEventSummary.types';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import {
  doesEventHaveRatingDecisions,
  getEventDetailsTypeDisplayText,
  getEventNumberOfDecisionsWithFavorableFindings,
} from '@/pageAI/services/caseTimeline';

const TimelineEventSummaryBase = ({ event, sx, onClick }: TimelineEventSummaryProps) => {
  const hasRatingDecisions = useMemo(() => doesEventHaveRatingDecisions(event), [event]);
  const numberOfDecisionsWithFavorableFindings = useMemo(
    () => getEventNumberOfDecisionsWithFavorableFindings(event),
    [event],
  );

  const renderContent = () => {
    if (!event.summaries || !event.summaries.length) {
      if (event.detailsTypes) return <>{getEventDetailsTypeDisplayText(event.detailsTypes[0])}</>;

      return null;
    }

    if (hasRatingDecisions) {
      return (
        <>
          Rating Decision containing {event.summaries.length} decision(s), of which&nbsp;
          {numberOfDecisionsWithFavorableFindings} had favorable finding(s).
        </>
      );
    }

    return <MarkdownRenderer content={event.eventSummary} />;
  };

  return (
    <Text
      component="span"
      sx={{
        display: 'inline',
        '> p': { display: 'inline', margin: 0 },
        whiteSpace: 'pre-wrap',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      {renderContent()}
    </Text>
  );
};

export const TimelineEventSummary = memo(TimelineEventSummaryBase);
