import { memo, useMemo } from 'react';
import { ActionIcon, Box, Flex, SegmentedControl, Text, Tooltip } from '@mantine/core';
import { omit } from 'lodash-es';

import {
  canDisplayCodesheetSection,
  convertConditionToTimelineEvents,
  getKeywordsFromMedicalCondition,
  normalizeMedicalConditionFields,
  removeMedicalConditionDebugWords,
  shouldAlwaysHideNullFields,
} from '@/pageAI/services/medicalConditions';
import { MedicalConditionItemAsSummaryProps } from './MedicalConditionItemAsSummary.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import {
  SummaryViewMode,
  setSummaryTabSortOrder,
  setSummaryTabViewMode,
  showNullFieldsSelector,
  summaryTabSortOrderSelector,
  summaryTabViewModeSelector,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { CaseTimeline } from '../../timeline/CaseTimeline';
import { ShowNullFieldsFilter } from '../filters/ShowNullFieldsFilter';
import { IconSortAscending2, IconSortDescending2 } from '@tabler/icons-react';
import { ConditionSummarySection } from '../summary/ConditionSummarySection';
import { ConditionAllCodesheets } from '../summary/ConditionAllCodesheets';
import { RelatedConditionsTooltip } from '../RelatedConditionsTooltip';

const MedicalConditionItemAsSummaryBase = ({
  medicalCondition: rawMedicalCondition,
}: MedicalConditionItemAsSummaryProps) => {
  const client = useSelectedClient(true);
  const viewMode = useUnifiedStore(summaryTabViewModeSelector);
  const showNullFields = useUnifiedStore(showNullFieldsSelector);
  const sortOrder = useUnifiedStore(summaryTabSortOrderSelector);

  const forceHideNullFields = useMemo(() => shouldAlwaysHideNullFields(rawMedicalCondition), [rawMedicalCondition]);

  const medicalCondition = useMemo(
    () => normalizeMedicalConditionFields(rawMedicalCondition, !forceHideNullFields && showNullFields),
    [rawMedicalCondition, showNullFields, forceHideNullFields],
  );

  const convertedEvents = useMemo(
    () => (viewMode === 'Chronological' ? convertConditionToTimelineEvents(medicalCondition) : []),
    [medicalCondition, viewMode],
  );

  const keywords = useMemo(
    () => getKeywordsFromMedicalCondition(rawMedicalCondition).map(removeMedicalConditionDebugWords),
    [rawMedicalCondition],
  );

  const handleChangeViewMode = (viewMode: SummaryViewMode) => {
    setSummaryTabViewMode(viewMode);
  };

  const handleChangeSortOrder = () => {
    setSummaryTabSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  if (!client) return null;

  const renderBody = () => {
    if (viewMode === 'Chronological')
      return (
        <Box
          sx={{
            ul: {
              paddingLeft: 24,
              lineHeight: 0,
              li: {
                ul: {
                  li: {
                    ul: {
                      li: {
                        ul: {
                          marginBottom: -24,
                        },
                      },
                    },
                  },
                },
              },
            },
            li: {
              lineHeight: '24px',
            },
          }}
        >
          <CaseTimeline
            timeline={convertedEvents}
            virtualized={false}
            sortOrder={sortOrder}
            searchParamsDisabled
            readonly
          />
        </Box>
      );

    const displayCodesheetSection = canDisplayCodesheetSection(medicalCondition);

    return (
      <>
        {(medicalCondition.proceduralHistory || medicalCondition.filings || displayCodesheetSection) && (
          <Flex direction="column">
            <Flex align="center" justify="space-between" gap="xs">
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Procedural History
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={omit(medicalCondition.proceduralHistory, 'filings')}
              sectionKey="proceduralHistory"
              keywords={keywords}
              additionalSection={
                displayCodesheetSection ? (
                  <Flex
                    sx={(theme) => ({
                      borderBottom: `1px solid ${theme.colors.gray[3]}`,
                      padding: '8px 16px',
                    })}
                  >
                    <Flex>
                      <Text
                        fz="0.875rem"
                        color="dark"
                        sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
                      >
                        Latest Codesheet
                      </Text>
                    </Flex>

                    <ConditionAllCodesheets condition={medicalCondition} />
                  </Flex>
                ) : null
              }
            />

            {medicalCondition.filings && (
              <>
                <Flex
                  sx={(theme) => ({
                    padding: 8,
                    borderLeft: `1px solid ${theme.colors.gray[3]}`,
                    borderRight: `1px solid ${theme.colors.gray[3]}`,
                  })}
                >
                  <Text fz="0.875rem" fw={600} color="dark">
                    Filings
                  </Text>
                </Flex>

                <ConditionSummarySection
                  medicalCondition={medicalCondition}
                  section={medicalCondition.filings}
                  sectionKey="proceduralHistory.filings"
                  keywords={keywords}
                />
              </>
            )}
          </Flex>
        )}

        {medicalCondition.medicalEvidence && (
          <Flex direction="column" gap={4}>
            <Flex align="center" justify="space-between" gap="xs">
              <Text fz="0.875rem" fw={600} color="dark" mb={4}>
                Medical Evidence
              </Text>
            </Flex>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.medicalEvidence}
              sectionKey="medicalEvidence"
              keywords={keywords}
            />
          </Flex>
        )}

        {medicalCondition.otherEvidence && (
          <Flex direction="column" gap={4}>
            <Text fz="0.875rem" fw={600} color="dark" mb={4}>
              Other Evidence
            </Text>

            <ConditionSummarySection
              medicalCondition={medicalCondition}
              section={medicalCondition.otherEvidence}
              sectionKey="otherEvidence"
              keywords={keywords}
            />
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" gap="lg">
      <Flex
        justify="space-between"
        gap={8}
        sx={(theme) => ({
          flexWrap: 'wrap',
        })}
      >
        <Flex align="center" gap={4}>
          <Box>
            <Text fw={600} color="dark.4" mr={4} sx={{ display: 'inline' }}>
              {medicalCondition.headerCondition}
            </Text>

            <RelatedConditionsTooltip medicalCondition={medicalCondition} />
          </Box>

          {viewMode === 'Chronological' && (
            <Tooltip
              label={sortOrder === 'desc' ? 'Sorting latest to oldest' : 'Sorting oldest to latest'}
              withinPortal
              withArrow
            >
              <ActionIcon onClick={handleChangeSortOrder}>
                {sortOrder === 'desc' ? <IconSortDescending2 size={20} /> : <IconSortAscending2 size={20} />}
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>

        <Flex align="center" justify="flex-end" gap="xs" sx={{ flexGrow: 1 }}>
          {viewMode === 'Table View' && !forceHideNullFields && <ShowNullFieldsFilter />}

          <SegmentedControl
            size="xs"
            data={['Table View', 'Chronological']}
            value={viewMode}
            onChange={handleChangeViewMode}
            sx={{
              '.ghost-SegmentedControl-label': {
                width: 96,
              },
            }}
          />
        </Flex>
      </Flex>

      {renderBody()}
    </Flex>
  );
};

export const MedicalConditionItemAsSummary = memo(MedicalConditionItemAsSummaryBase);
