import { memo, useState } from 'react';
import { Anchor, Button, Flex, Modal, Tabs, Text, TextInput } from '@mantine/core';
import { IconBug, IconLink, IconMessage } from '@tabler/icons-react';

import { HelpModalProps } from './HelpModal.types';
import { useCreateFeedback } from '@/pageAI/hooks/help/useCreateFeedback';
import { FeedbackType } from '@/pageAI/gql/graphql';
import { HelpTabContent } from '../HelpTabContent';
import { appendDataToFeedback } from '@/pageAI/services/help';
import { useFilePreviewStore } from '@/shared/states/filePreview';

const HelpModalBase = ({ opened = false, onClose }: HelpModalProps) => {
  const [attachedLink, setAttachedLink] = useState(window.location.href);
  const [tab, setTab] = useState<'problem' | 'feedback'>('problem');
  const [problemContent, setProblemContent] = useState('');
  const [problemAttachedFile, setProblemAttachedFile] = useState<File | null>(null);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [feedbackAttachedFile, setFeedbackAttachedFile] = useState<File | null>(null);
  const previewingFileAsset = useFilePreviewStore((state) => state.asset);

  const { createFeedback, isLoading: isCreatingFeedback } = useCreateFeedback();

  const handleSend = async () => {
    if (tab === 'problem') {
      await createFeedback({
        type: FeedbackType.Problem,
        content: appendDataToFeedback({
          feedback: problemContent,
          attachedLink,
          attachedFileAsset: previewingFileAsset,
        }),
        ...(problemAttachedFile ? { attachment: problemAttachedFile } : {}),
      });
    } else {
      await createFeedback({
        type: FeedbackType.Feedback,
        content: appendDataToFeedback({
          feedback: feedbackContent,
          attachedLink,
          attachedFileAsset: previewingFileAsset,
        }),
        ...(feedbackAttachedFile ? { attachment: feedbackAttachedFile } : {}),
      });
    }

    onClose();
    setProblemContent('');
    setFeedbackContent('');
  };

  const isLoading = isCreatingFeedback;

  const title = tab === 'problem' ? 'Report a problem' : 'Send feedback';
  const description =
    tab === 'problem'
      ? "Please describe the problem that you're encountering. If this is related to specific timeline event, please share a link (by right-clicking on the event) so our team can investigate the issue."
      : 'Please provide feedback, suggestions, or any other comments you may have. We appreciate you taking the time to make Page better!';

  return (
    <Modal centered opened={opened} onClose={onClose} title={title} size="xl">
      <Flex direction="column" gap="md">
        <Flex direction="column" gap="xs">
          <Text fz="0.875rem" color="dark.3">
            {description}
          </Text>

          <Tabs value={tab} onTabChange={(newTab) => setTab(newTab as 'problem' | 'feedback')}>
            <Tabs.List>
              <Tabs.Tab value="problem" icon={<IconBug size={16} />}>
                Problem
              </Tabs.Tab>
              <Tabs.Tab value="feedback" icon={<IconMessage size={16} />}>
                Feedback
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="problem" pt="md">
              <HelpTabContent
                content={problemContent}
                onChange={setProblemContent}
                attachedFile={problemAttachedFile}
                onAttachFile={(file) => setProblemAttachedFile(file)}
                placeholder="Describe your problem here..."
              />
            </Tabs.Panel>

            <Tabs.Panel value="feedback" pt="md">
              <HelpTabContent
                content={feedbackContent}
                onChange={setFeedbackContent}
                attachedFile={feedbackAttachedFile}
                onAttachFile={(file) => setFeedbackAttachedFile(file)}
                placeholder="Enter your feedback here..."
              />
            </Tabs.Panel>
          </Tabs>

          <TextInput
            label="Attached link"
            value={attachedLink}
            onChange={(event) => setAttachedLink(event.target.value)}
            icon={<IconLink size={16} />}
          />
        </Flex>

        <Flex align="center" justify="space-between" gap="xs">
          <Text fz="0.875rem">
            You can also email us at&nbsp;
            <Anchor href="mailto:support@pageai.co" fw={500}>
              support@pageai.co
            </Anchor>
            .
          </Text>

          <Flex align="center" justify="flex-end" gap="xs">
            <Button variant="subtle" color="gray.8" onClick={onClose} disabled={isLoading}>
              Close
            </Button>

            <Button color="red" onClick={handleSend} loading={isLoading}>
              Send
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export const HelpModal = memo(HelpModalBase);
