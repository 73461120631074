import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { bookmarkClientTimelineEventMutation, unbookmarkClientTimelineEventMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import {
  BookmarkClientTimelineEventInput,
  BookmarkClientTimelineEventMutation,
  UnbookmarkClientTimelineEventInput,
  UnbookmarkClientTimelineEventMutation,
} from '@/pageAI/gql/graphql';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { useUpdateTimelineEvent } from '../useUpdateTimelineEvent';
import { Client } from '@/pageAI/@types';

export const useBookmarkTimelineEvent = () => {
  const { notify } = useNotifications();
  const { request } = useGraphQLRequest();
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();

  const bookmarkTimelineEventMutation = useMutation<
    BookmarkClientTimelineEventMutation,
    Error,
    BookmarkClientTimelineEventInput
  >({
    mutationFn: async (input) => {
      return request(bookmarkClientTimelineEventMutation, { input });
    },
  });

  const unbookmarkTimelineEventMutation = useMutation<
    UnbookmarkClientTimelineEventMutation,
    Error,
    UnbookmarkClientTimelineEventInput
  >({
    mutationFn: async (input) => {
      return request(unbookmarkClientTimelineEventMutation, { input });
    },
  });

  const bookmarkTimelineEvent = useCallback(
    async (client: Client, timelineEvent: CaseTimelineEvent) => {
      const reverseUpdate = updateTimelineEventLocally(client, { ...timelineEvent, bookmarked: true });

      try {
        await bookmarkTimelineEventMutation.mutateAsync({ id: timelineEvent.id });
      } catch (error) {
        notify('Error', 'Failed to bookmark timeline event');

        reverseUpdate();
      }
    },
    [updateTimelineEventLocally, bookmarkTimelineEventMutation, notify],
  );

  const unbookmarkTimelineEvent = useCallback(
    async (client: Client, timelineEvent: CaseTimelineEvent) => {
      const reverseUpdate = updateTimelineEventLocally(client, { ...timelineEvent, bookmarked: false });

      try {
        await unbookmarkTimelineEventMutation.mutateAsync({ id: timelineEvent.id });
      } catch (error) {
        notify('Error', 'Failed to unbookmark timeline event');

        reverseUpdate();
      }
    },
    [updateTimelineEventLocally, unbookmarkTimelineEventMutation, notify],
  );

  return { bookmarkTimelineEvent, unbookmarkTimelineEvent };
};
