import { useMutation } from '@tanstack/react-query';

import { GenerateSummaryByConditionInput, GenerateSummaryByConditionMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { generateSummaryByConditionMutation } from '@/pageAI/api/summaries';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useGenerateSummaryByConditions = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const { mutateAsync: generateSummaryByConditions, isLoading } = useMutation<
    GenerateSummaryByConditionMutation,
    Error,
    GenerateSummaryByConditionInput
  >({
    mutationFn: async (input) => {
      return request(generateSummaryByConditionMutation, { input });
    },
    onError: (error) => {
      if (error.message.includes('case timeline must be generated first'))
        return notify('Error', 'Case timeline must be generated first');

      notify('Error', 'Failed to generate summary by conditions');
    },
  });

  return {
    generateSummaryByConditions,
    isLoading,
  };
};
