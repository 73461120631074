export enum Weight {
  Gram = 'g',
  Kilogram = 'kg',
  Tonne = 't',
  Megatonne = 'Mt',
  Gigatonne = 'Gt',
}

export enum FileSize {
  Byte = 'B',
  Kilobyte = 'KB',
  Megabyte = 'MB',
  Gigabyte = 'GB',
  Terabyte = 'TB',
  Petabyte = 'PB',
}

export type GeneralUnit = Weight | FileSize;

export interface ConvertUnitResult {
  newValue: number;
  newUnit: GeneralUnit;
}

export interface UnitConversionStrategy {
  [key: string]: {
    unitOrder: GeneralUnit[];
    threshold: number;
  };
}
