import { memo } from 'react';
import { ConditionSummarySectionProps } from './ConditionSummarySection.types';
import { Box, Flex, Text } from '@mantine/core';
import {
  MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING,
  getSortedMedicalConditionFieldKeys,
} from '@/pageAI/services/medicalConditions';
import { MedicalConditionSummaryEntry } from '../../MedicalConditionSummaryEntry';
import { ConditionSummaryReferencedFileInfo } from '@/pageAI/gql/graphql';
import { setSummaryHighlighted, summaryTabHighlightedSelector, useUnifiedStore } from '@/pageAI/states/unified';

const ConditionSummarySectionBase = ({
  medicalCondition,
  section,
  sectionKey,
  keywords,
  additionalSection,
}: ConditionSummarySectionProps) => {
  const highlighted = useUnifiedStore(summaryTabHighlightedSelector);

  const isEntryHighlighted = (entryKey: string) => highlighted === entryKey;

  const handleEntryReferenceClick = (entry: ConditionSummaryReferencedFileInfo, entryKey: string) => {
    setSummaryHighlighted(entryKey);
  };

  return (
    <Box sx={(theme) => ({ border: `1px solid ${theme.colors.gray[3]}`, borderBottom: 'none', overflow: 'auto' })}>
      <Flex
        direction="column"
        sx={{
          ul: {
            marginTop: -4,
            marginBottom: 4,
            paddingLeft: 24,
            li: {
              marginTop: 4,
            },
          },
          ol: {
            marginTop: -4,
            fontSize: '0.875rem',
            paddingLeft: 12,
            li: {
              marginTop: 8,
              paddingLeft: 4,
            },
          },
        }}
      >
        {getSortedMedicalConditionFieldKeys(section).map((sectionField) => {
          const sectionItems = section[sectionField];

          return (
            <Flex
              key={sectionField}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.colors.gray[3]}`,
                padding: '8px 16px',
              })}
            >
              <Flex>
                <Text
                  fz="0.875rem"
                  color="dark"
                  sx={{ width: 220, whiteSpace: 'pre-line', wordBreak: 'break-word', paddingRight: 16 }}
                >
                  {MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING[
                    sectionField as keyof typeof MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING
                  ] || sectionField}
                </Text>
              </Flex>

              <Box>
                {(() => {
                  if (!sectionItems?.length) {
                    return (
                      <Text fz="0.875rem" color="dark">
                        N/A
                      </Text>
                    );
                  }

                  if (sectionItems.length === 1) {
                    const entry = sectionItems[0];
                    const entryKey = [sectionKey, sectionField, 0].join('.');

                    return (
                      <MedicalConditionSummaryEntry
                        medicalCondition={medicalCondition}
                        entryKey={entryKey}
                        entry={entry}
                        isHighlighted={isEntryHighlighted(entryKey)}
                        keywords={keywords}
                        onReferenceClick={handleEntryReferenceClick}
                      />
                    );
                  }

                  return (
                    <ol>
                      {sectionItems.map((entry: ConditionSummaryReferencedFileInfo, entryIndex: number) => {
                        const entryKey = [sectionKey, sectionField, entryIndex].join('.');

                        return (
                          <li key={[entry.summary, entry.reference].join('-')}>
                            <MedicalConditionSummaryEntry
                              medicalCondition={medicalCondition}
                              entryKey={entryKey}
                              entry={entry}
                              isHighlighted={isEntryHighlighted(entryKey)}
                              keywords={keywords}
                              onReferenceClick={handleEntryReferenceClick}
                            />
                          </li>
                        );
                      })}
                    </ol>
                  );
                })()}
              </Box>
            </Flex>
          );
        })}

        {additionalSection}
      </Flex>
    </Box>
  );
};

export const ConditionSummarySection = memo(ConditionSummarySectionBase);
