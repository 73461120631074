import { useMutation } from '@tanstack/react-query';

import { ResendVerificationEmailInput, ResendVerificationEmailMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { resendVerificationEmailMutation } from '@/pageAI/api';

export const useResendVerificationEmail = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const { mutateAsync: resendVerificationEmail, isLoading } = useMutation<
    ResendVerificationEmailMutation,
    Error,
    ResendVerificationEmailInput
  >({
    mutationFn: async (input) => request(resendVerificationEmailMutation, { input }),
    onSuccess: () => {
      notify('Success', 'Verification email has been resent', 'brand');
    },
    onError: () => {
      notify('Error', 'Failed to resend verification email');
    },
  });

  return { resendVerificationEmail, isLoading };
};
