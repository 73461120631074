import { memo } from 'react';
import { Flex, Input, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IMaskInput } from 'react-imask';

import { UserProfileFormValues, UserProfileFormProps } from './UserProfileForm.types';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';

const UserProfileFormBase = ({ bottomSection, onSubmit }: UserProfileFormProps) => {
  const { currentUser } = useCurrentUser();

  const form = useForm<UserProfileFormValues>({
    initialValues: {
      firstName: currentUser?.firstName.trim() || '',
      lastName: currentUser?.lastName.trim() || '',
      email: currentUser?.email || '',
      phone: currentUser?.phone?.trim() || '',
      role: currentUser?.role || '',
    },
    validate: {
      firstName: (value) => (value.trim().length === 0 ? 'First name is required' : null),
      lastName: (value) => (value.trim().length === 0 ? 'Last name is required' : null),
      role: (value) => (value.trim().length === 0 ? 'Role is required' : null),
    },
  });

  const handleSubmit = async (values: UserProfileFormValues) => {
    onSubmit?.(values);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Flex direction="column" gap="xs">
        <Flex direction="column" gap="xs">
          <Flex gap="xs">
            <TextInput
              label="First Name"
              placeholder="Enter your first name"
              required
              {...form.getInputProps('firstName')}
              sx={{ flexGrow: 1, width: '100%' }}
            />

            <TextInput
              label="Last Name"
              placeholder="Enter your last name"
              required
              {...form.getInputProps('lastName')}
              sx={{ flexGrow: 1, width: '100%' }}
            />
          </Flex>

          <TextInput label="Email" required {...form.getInputProps('email')} readOnly />

          <Input.Wrapper label="Phone number">
            <Input
              type="tel"
              component={IMaskInput}
              mask="+1 (000) 000-0000"
              placeholder="Enter your phone number"
              {...form.getInputProps('phone')}
            />
          </Input.Wrapper>

          <Select
            label="Role"
            required
            placeholder="Select your role"
            data={['Lawyer', 'Case Manager', 'Paralegal', 'Medical Expert', 'Accredited Agent', 'Veteran', 'Other']}
            {...form.getInputProps('role')}
          />
        </Flex>

        {bottomSection}
      </Flex>
    </form>
  );
};

export const UserProfileForm = memo(UserProfileFormBase);
