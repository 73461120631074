import { useCallback, useEffect, useRef } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { persistentPdfScalePubsub, usePersistentPDFScale } from '@/shared/hooks/files/usePersistentPDFScale';

export const CLIENT_PERSISTENT_PDF_SCALE_STORAGE_KEY = 'pdf_scale_by_client';

export const useClientPersistentPDFScale = (clientId: string) => {
  const [scaleByClient, setScaleByClient] = useLocalStorage<Record<string, number>>({
    key: CLIENT_PERSISTENT_PDF_SCALE_STORAGE_KEY,
    defaultValue: {},
  });
  const [scale, setScale] = usePersistentPDFScale();
  const renderCount = useRef(-1);

  const clientScale = scaleByClient[clientId] || 1;

  renderCount.current++;

  useEffect(() => {
    if (renderCount.current !== 1) return;

    setScale(clientScale, true);
  }, [clientScale, setScale, renderCount.current]);

  const setClientScale = useCallback(
    (newValue: number) => {
      if (clientScale === newValue) return;

      setScaleByClient((current) => ({
        ...current,
        [clientId]: newValue,
      }));

      setScale(newValue, true);
    },
    [clientScale, setScale, clientId, setScaleByClient],
  );

  useEffect(() => {
    const unsubscribe = persistentPdfScalePubsub.subscribe((newValue) => {
      setClientScale(newValue);
    });

    return unsubscribe;
  }, [setClientScale]);

  return [clientScale, setClientScale] as const;
};
