import { memo, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Divider, Flex, Popover, Text } from '@mantine/core';
import { IconFileSearch } from '@tabler/icons-react';

import { TimelineSearchInputProps } from './TimelineSearchInput.types';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { getClientFileSearchPath } from '@/pageAI/services/files';
import { DebouncedSearchInput } from '@/shared/components/common/forms/DebouncedSearchInput';

const TimelineSearchInputBase = ({
  placeholder = 'Search for keywords...',
  initialValue,
  onChange,
  matchWholeWords,
  onMatchWholeWordsChange,
  matchWholePhrase,
  onMatchWholePhraseChange,
}: TimelineSearchInputProps) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const forceRefocusRef = useRef(false);

  const renderInputWrapper = useCallback(
    (inputNode: React.ReactNode) => {
      return (
        <Popover width="target" shadow="sm" defaultOpened>
          <Popover.Target>{inputNode}</Popover.Target>

          <Popover.Dropdown p={4} onMouseDownCapture={() => (forceRefocusRef.current = true)}>
            <Flex direction="column" gap={4}>
              <Flex pl="sm" py={6} direction="column" gap={6}>
                <Checkbox
                  size="xs"
                  label="Match whole words"
                  checked={matchWholeWords}
                  onChange={(event) => {
                    onMatchWholeWordsChange?.(event.currentTarget.checked);
                  }}
                  onChangeCapture={() => {
                    forceRefocusRef.current = true;
                  }}
                  sx={{
                    '.ghost-Checkbox-body': {
                      alignItems: 'center',
                    },
                    '.ghost-Checkbox-inner': {
                      width: 14,
                      height: 14,
                      '.ghost-Checkbox-input': {
                        width: 14,
                        height: 14,
                      },
                    },
                    'label.ghost-Checkbox-label': {
                      paddingLeft: 10,
                    },
                  }}
                />

                <Checkbox
                  size="xs"
                  label="Match whole phrase"
                  checked={matchWholePhrase}
                  onChange={(event) => {
                    onMatchWholePhraseChange?.(event.currentTarget.checked);
                  }}
                  onChangeCapture={() => {
                    forceRefocusRef.current = true;
                  }}
                  sx={{
                    '.ghost-Checkbox-body': {
                      alignItems: 'center',
                    },
                    '.ghost-Checkbox-inner': {
                      width: 14,
                      height: 14,
                      '.ghost-Checkbox-input': {
                        width: 14,
                        height: 14,
                      },
                    },
                    'label.ghost-Checkbox-label': {
                      paddingLeft: 10,
                    },
                  }}
                />
              </Flex>

              <Divider />

              <MenuItem
                icon={<IconFileSearch size={14} />}
                title={<Text fz="0.75rem">Search file contents</Text>}
                onMouseDown={() => {
                  if (clientId) navigate(getClientFileSearchPath(clientId, initialValue));
                }}
              />
            </Flex>
          </Popover.Dropdown>
        </Popover>
      );
    },
    [
      clientId,
      navigate,
      initialValue,
      matchWholeWords,
      onMatchWholeWordsChange,
      matchWholePhrase,
      onMatchWholePhraseChange,
    ],
  );

  return (
    <DebouncedSearchInput
      initialValue={initialValue}
      placeholder={placeholder}
      onChange={onChange}
      renderInputWrapper={renderInputWrapper}
      forceRefocusRef={forceRefocusRef}
    />
  );
};

export const TimelineSearchInput = memo(TimelineSearchInputBase);
