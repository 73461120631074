import { forwardRef, useState } from 'react';
import { Button, Flex, Group, Overlay, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneProps, FileWithPath } from '@mantine/dropzone';
import { IconFileUpload, IconLink, IconUpload, IconX } from '@tabler/icons-react';

import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { FileDropzoneProps } from './FileDropzone.types';

const DEFAULT_ACCEPT = [
  'text/csv',
  'application/json',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const FileDropzoneBase = (
  {
    visible = false,
    loading = false,
    multiple = false,
    accept = DEFAULT_ACCEPT,
    maxSize = 1000 * 1048576, // 1MB = 1048576 bytes
    description = 'Drop a file here or click to upload.',
    subDescription = 'Supported format: *.csv, *.xls, *.xlsx, *.json. The size should not exceed 10MB.',
    canToggleMode = false,
    initialIsFileUpload = true,
    rejectable = true,
    onUpload,
    onToggleVisibility,
  }: FileDropzoneProps,
  ref: React.ForwardedRef<() => void>,
) => {
  const theme = useMantineTheme();
  const { notify } = useNotifications();

  const [isFileUpload, setIsFileUpload] = useState(initialIsFileUpload);

  const handleDrop = async (files: FileWithPath[]) => {
    onToggleVisibility?.(true);

    await onUpload?.(files);

    setTimeout(() => {
      onToggleVisibility?.(false);
    }, 1500);
  };

  const handleReject: DropzoneProps['onReject'] = (fileRejections) => {
    if (!rejectable) return;

    onToggleVisibility?.(false);

    if (
      fileRejections.some((fileRejection) => fileRejection.errors.some((error) => error.code === 'file-invalid-type'))
    )
      return notify('Error', 'Invalid file type!');

    notify('Error', 'File is too large!');
  };

  const renderToggleButton = () => {
    if (!canToggleMode) return null;

    return (
      <Button
        className="query-box-actions"
        variant="outline"
        onClick={(event) => {
          event.stopPropagation();
          setIsFileUpload(!isFileUpload);
        }}
        leftIcon={isFileUpload ? <IconLink size={16} /> : <IconUpload size={16} />}
        size="xs"
        sx={(theme) => ({
          visibility: 'hidden',
          zIndex: 10,
          background: 'white',
          '&:hover': {
            background: theme.fn.lighten(theme.colors.blue[0], 0.5),
          },
          position: 'absolute',
          top: 12,
          right: 12,
        })}
      >
        {isFileUpload ? 'Use links' : 'Use file upload'}
      </Button>
    );
  };

  if (!visible) return null;

  if (!isFileUpload && canToggleMode) return renderToggleButton();

  return (
    <Overlay zIndex={5} opacity={1} sx={{ borderRadius: 6 }}>
      <Dropzone
        openRef={ref}
        loading={loading}
        sx={(theme) => ({
          height: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!rejectable
            ? {
                '&.ghost-Dropzone-root[data-reject="true"]': {
                  background: theme.colors.gray[3],
                  borderColor: theme.colors.gray[4],
                },
              }
            : {}),
        })}
        onDrop={handleDrop}
        onReject={handleReject}
        multiple={multiple}
        maxSize={maxSize}
        accept={accept}
      >
        <Group
          position="center"
          spacing="xl"
          sx={{ pointerEvents: 'none', zIndex: 10, position: 'relative', backgroundColor: 'transparent' }}
        >
          <Flex gap="xs" sx={{ zIndex: 1 }}>
            <Dropzone.Accept>
              <IconUpload size={50} stroke={1.5} color={theme.colors.brand[6]} />
            </Dropzone.Accept>

            <Dropzone.Reject>
              {rejectable ? (
                <IconX size={50} stroke={1.5} color={theme.colors.red[6]} />
              ) : (
                <IconUpload size={50} stroke={1.5} color={theme.colors.brand[6]} />
              )}
            </Dropzone.Reject>

            <Dropzone.Idle>
              <IconFileUpload size={50} stroke={1.5} />
            </Dropzone.Idle>

            <Flex direction="column" gap="xs">
              <Text size="xl" inline>
                {description}
              </Text>

              {subDescription && (
                <Text size="sm" color="dimmed" inline>
                  {subDescription}
                </Text>
              )}
            </Flex>
          </Flex>
        </Group>
      </Dropzone>

      {renderToggleButton()}
    </Overlay>
  );
};

export const FileDropzone = forwardRef(FileDropzoneBase);
