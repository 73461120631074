import { memo, useEffect, useMemo, useRef } from 'react';
import { Alert, Box, Flex, ScrollArea, Sx, Text, Timeline } from '@mantine/core';
import { IconAlertCircle, IconCalendarEvent } from '@tabler/icons-react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { CaseTimelineProps } from './CaseTimeline.types';
import { getTimelineElementId, groupTimelineEventsByDate, sortCaseTimeline } from '@/pageAI/services/caseTimeline';
import { CaseTimelineItemViews } from '@/pageAI/components/summaries/CaseTimelineItemViews';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { useHighlightTimelineEvent, useTimelineKeyboardShortcuts } from './CaseTimeline.utils';
import { useTimelineFilterSearchParams } from '@/pageAI/hooks/caseTimeline/useTimelineFilterSearchParams/useTimelineFilterSearchParams';
import { truthy } from '@/shared/utils/boolean';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { saveTimelineScrollPosition } from '@/pageAI/states/unified';
import { createPortal } from 'react-dom';
import { TimelineTableOfContents } from '../TimelineTableOfContents';

const sharedStyle: Sx = {
  p: { margin: 0 },
  li: {
    '.case-timeline-event-badge:first-of-type': {
      marginLeft: 0,
    },
  },
};

const CaseTimelineBase = ({
  timeline,
  sortOrder = 'desc',
  isPrinting = false,
  readonly = false,
  virtualized = true,
  searchParamsDisabled = false,
}: CaseTimelineProps) => {
  const client = useSelectedClient(true);
  const { tab, prefix, bookmarkedTabSavedOffset, allTabSavedOffset } = useCaseTimelineContext();
  useTimelineFilterSearchParams(searchParamsDisabled);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const sortedCaseTimeline = useMemo(() => sortCaseTimeline(timeline, sortOrder), [timeline, sortOrder]);
  const groupedCaseTimelineItems = useMemo(() => groupTimelineEventsByDate(sortedCaseTimeline), [sortedCaseTimeline]);

  useTimelineKeyboardShortcuts(sortedCaseTimeline);

  const caseTimelineEventsByYear = useMemo(() => {
    let caseTimelineItemsByYear = Object.entries(groupedCaseTimelineItems);

    if (sortOrder === 'desc') caseTimelineItemsByYear = caseTimelineItemsByYear.toReversed();

    return caseTimelineItemsByYear;
  }, [groupedCaseTimelineItems, sortOrder]);

  const virtualizer = useVirtualizer({
    count: caseTimelineEventsByYear.length,
    initialOffset: () => (tab === 'All' ? allTabSavedOffset?.current || 0 : bookmarkedTabSavedOffset?.current || 0),
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: (index) => caseTimelineEventsByYear[index].length * 800,
    overscan: 2,
  });

  useEffect(() => {
    requestAnimationFrame(() => {
      virtualizer.scrollToOffset(
        tab === 'All' ? allTabSavedOffset?.current || 0 : bookmarkedTabSavedOffset?.current || 0,
      );
    });

    return () => {
      if (tab === 'All') {
        if (allTabSavedOffset) {
          allTabSavedOffset.current = virtualizer.scrollOffset;

          saveTimelineScrollPosition('All', allTabSavedOffset.current);
        }
      } else {
        if (bookmarkedTabSavedOffset) {
          bookmarkedTabSavedOffset.current = virtualizer.scrollOffset;

          saveTimelineScrollPosition('Bookmarked', bookmarkedTabSavedOffset.current);
        }
      }
    };
  }, [virtualizer]);

  useHighlightTimelineEvent(caseTimelineEventsByYear, virtualized ? virtualizer : undefined);

  if (!timeline.length) {
    return (
      <Box px="md">
        <Alert icon={<IconAlertCircle size="1rem" />} title="No results!" color="dark.3" variant="outline" m="md">
          No case timeline items were found for the selected filter(s). Try again with another set of filter.
        </Alert>
      </Box>
    );
  }

  if (!client) return null;

  if (isPrinting) {
    return (
      <Flex id={getTimelineElementId(client.id, prefix)} direction="column" gap="md" sx={sharedStyle}>
        {caseTimelineEventsByYear.map(([year, events]) => {
          return (
            <Flex direction="column" key={year}>
              <Flex align="center" justify="center" gap="sm">
                <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />

                <Text fz="0.875rem" fw={600} id={year}>
                  {year}
                </Text>

                <Box sx={(theme) => ({ flexGrow: 1, height: 1, border: `1px solid ${theme.colors.gray[5]}` })} />
              </Flex>

              <CaseTimelineItemViews caseTimelineItems={events} />
            </Flex>
          );
        })}
      </Flex>
    );
  }

  if (virtualized) {
    const items = virtualizer.getVirtualItems();

    return (
      <>
        {createPortal(
          <TimelineTableOfContents timelineByDate={groupedCaseTimelineItems} sortOrder={sortOrder} />,
          document.getElementById('case-timeline-header-prefix') || document.body,
        )}

        <ScrollArea
          id="case-timeline-scroll-area"
          viewportRef={scrollContainerRef}
          sx={{ overflow: 'auto', height: 'calc(100vh - 116px)', padding: '0px 32px 0 32px' }}
        >
          <Box sx={{ height: virtualizer.getTotalSize(), width: '100%', position: 'relative' }}>
            <Timeline
              id={getTimelineElementId(client.id, prefix)}
              active={99}
              bulletSize={24}
              lineWidth={2}
              sx={(theme) => ({
                paddingTop: 16,
                del: { background: theme.colors.yellow[3], textDecoration: 'none' },
                '.ghost-Timeline-item:last-of-type': {
                  paddingBottom: 24,
                },
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0]?.start ?? 0}px)`,
              })}
            >
              {items.map(({ index, key }) => {
                const mapping = caseTimelineEventsByYear[index];

                if (!mapping) return null;

                const [year, events] = mapping;

                return (
                  <Timeline.Item
                    key={key}
                    data-index={index}
                    id={[`${prefix}-timeline-${year}`].filter(truthy).join('-')}
                    bullet={<IconCalendarEvent size={12} />}
                    title={year}
                    ref={virtualizer.measureElement}
                    sx={{
                      ...sharedStyle,
                      '.extra-action': {
                        opacity: 0,
                        pointerEvents: 'none',
                        transition: 'opacity 0.1s ease-in-out',
                      },
                      ...(tab === 'All'
                        ? {
                            '.extra-action.extra-action--note': { display: 'none' },
                          }
                        : {
                            '.extra-action.extra-action--bookmark': { opacity: 1, pointerEvents: 'all' },
                          }),
                      ul: {
                        paddingLeft: 24,
                      },
                    }}
                  >
                    <CaseTimelineItemViews caseTimelineItems={events} readonly={readonly} />
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Box>
        </ScrollArea>
      </>
    );
  }

  return (
    <Timeline
      id={getTimelineElementId(client.id, prefix)}
      active={99}
      bulletSize={24}
      lineWidth={2}
      sx={(theme) => ({
        del: { background: theme.colors.yellow[3], textDecoration: 'none' },
      })}
    >
      {caseTimelineEventsByYear.map(([year, events]) => {
        return (
          <Timeline.Item
            key={year}
            id={`timeline-${year}`}
            bullet={<IconCalendarEvent size={12} />}
            title={year}
            sx={{
              ...sharedStyle,
              '.extra-action': {
                opacity: 0,
                pointerEvents: 'none',
                transition: 'opacity 0.1s ease-in-out',
              },
              ...(tab === 'All'
                ? {
                    '.extra-action.extra-action--note': { display: 'none' },
                  }
                : {
                    '.extra-action.extra-action--bookmark': { opacity: 1, pointerEvents: 'all' },
                  }),
            }}
          >
            <CaseTimelineItemViews caseTimelineItems={events} readonly={readonly} />
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export const CaseTimeline = memo(CaseTimelineBase);
