import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { deleteCommentMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { DeleteClientTimelineEventCommentInput, DeleteCommentMutation } from '@/pageAI/gql/graphql';
import { useUpdateTimelineEvent } from '../useUpdateTimelineEvent';
import { Client } from '@/pageAI/@types';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';

export const useDeleteComment = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();

  const { mutateAsync: deleteCommentBase, isLoading } = useMutation<
    DeleteCommentMutation,
    Error,
    DeleteClientTimelineEventCommentInput
  >({
    mutationFn: async (input) => {
      return request(deleteCommentMutation, { input });
    },
  });

  const deleteCommentLocally = useCallback(
    (client: Client, timelineEvent: CaseTimelineEvent, input: DeleteClientTimelineEventCommentInput) => {
      const updatedComments = timelineEvent.comments.filter((comment) => comment?.id !== input.id);

      const reverse = updateTimelineEventLocally(client, {
        ...timelineEvent,
        comments: updatedComments,
      });

      return reverse;
    },
    [updateTimelineEventLocally],
  );

  const deleteComment = useCallback(
    async (client: Client, timelineEvent: CaseTimelineEvent, input: DeleteClientTimelineEventCommentInput) => {
      const reverse = deleteCommentLocally(client, timelineEvent, input);

      try {
        await deleteCommentBase(input);
      } catch (error) {
        // TODO: This doesn't seem to work
        reverse();

        notify('Error', 'Failed to delete comment');
      }
    },
    [deleteCommentBase, notify, deleteCommentLocally],
  );

  return { deleteComment, deleteCommentLocally, isLoading };
};
