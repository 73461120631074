import { Flex, Text, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconExclamationMark } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

import { formatTime } from '@/shared/utils/time';
import { UseUploadMultipleFilesNotificationsProps } from './fileNotifications.services.types';

const getUploadingFileMessage = (numOfFiles: number, numOfUploadedFiles: number, estimatedUploadTime: number) => {
  const timeLeft = formatTime(estimatedUploadTime);

  return [`${numOfUploadedFiles}/${numOfFiles} have been uploaded`, `(about ${timeLeft} left...)`].join(' ');
};

export const initUploadMultipleFilesNotifications = ({
  notificationId = uuidv4(),
  uploadingTitle = 'Uploading...',
  successTitle = 'Successfully uploaded!',
  errorTitle = 'Failed to upload',
}: UseUploadMultipleFilesNotificationsProps) => {
  const showUploadingNotification = (totalNumOfFiles: number, estimatedUploadTime: number) => {
    notifications.show({
      id: notificationId,
      loading: true,
      title: uploadingTitle,
      message: getUploadingFileMessage(totalNumOfFiles, 0, estimatedUploadTime),
      autoClose: false,
      withCloseButton: false,
      withBorder: true,
    });
  };

  const updateUploadingNotification = (
    totalNumOfFiles: number,
    numOfUploadedFiles: number,
    estimatedUploadTime: number,
  ) => {
    notifications.update({
      id: notificationId,
      title: uploadingTitle,
      loading: true,
      message: getUploadingFileMessage(totalNumOfFiles, numOfUploadedFiles, estimatedUploadTime),
      autoClose: false,
      withCloseButton: false,
      withBorder: true,
    });
  };

  const finishUploadingNotification = (
    totalNumOfFiles: number,
    numOfUploadedFiles: number,
    numOfFailedFiles: number,
    onRetry: () => void,
  ) => {
    if (numOfUploadedFiles === totalNumOfFiles) {
      return notifications.update({
        id: notificationId,
        title: successTitle,
        message: `All ${totalNumOfFiles} files have been successfully uploaded!`,
        loading: false,
        icon: <IconCheck size="1rem" />,
        color: 'teal',
        autoClose: 5000,
        withCloseButton: true,
        withBorder: true,
      });
    }

    return notifications.update({
      id: notificationId,
      title: errorTitle,
      message: (
        <Flex align="center" gap={4}>
          <>
            {numOfFailedFiles}/{totalNumOfFiles} files have failed to be uploaded.
          </>

          <UnstyledButton
            sx={(theme) => ({
              color: theme.colors.red[5],
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' },
            })}
            onClick={() => {
              onRetry();

              notifications.hide(notificationId);
            }}
          >
            <Text fz="0.875rem" color="red">
              Retry
            </Text>
          </UnstyledButton>
        </Flex>
      ),
      loading: false,
      icon: <IconExclamationMark size="1rem" />,
      color: 'red',
      autoClose: false,
      withCloseButton: true,
      withBorder: true,
    });
  };

  return { showUploadingNotification, updateUploadingNotification, finishUploadingNotification };
};
