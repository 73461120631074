import { memo } from 'react';
import { ActionIcon, Flex, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { EventBus } from 'native-pubsub';

import { FileGroupRowProps } from './FileGroupRow.types';
import { useManageFileGroups } from '@/pageAI/hooks/files/useManageFileGroups';
import { FileGroup } from '@/pageAI/gql/graphql';
import { FileGroupBadge } from '../FileGroupBadge';

export const selectFileGroupEventBus = new EventBus<{ fileGroup: FileGroup }>();

const FileGroupRowBase = ({ fileGroup, organizationId }: FileGroupRowProps) => {
  const { deleteFileGroup } = useManageFileGroups(organizationId);

  const openConfirmDeleteModal = () =>
    modals.openConfirmModal({
      title: 'Delete custom filter',
      children: (
        <Text fz="0.875rem" color="dark.4">
          Are you sure you want to delete custom filter <strong>{fileGroup.displayName}</strong>?
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      cancelProps: {
        variant: 'subtle',
        color: 'gray.7',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () => deleteFileGroup({ id: fileGroup.id }),
      zIndex: 302,
      centered: true,
    });

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    openConfirmDeleteModal();
  };

  return (
    <Flex
      key={fileGroup.id}
      align="center"
      justify="space-between"
      sx={(theme) => ({
        padding: '4px 4px 4px 8px',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
          background: theme.colors.gray[1],
        },
      })}
      onClick={() => selectFileGroupEventBus.publish({ fileGroup })}
    >
      <Flex align="center" gap={6}>
        <FileGroupBadge fileGroup={fileGroup} />
      </Flex>

      <Flex align="center">
        <ActionIcon>
          <IconPencil size={14} />
        </ActionIcon>

        {!fileGroup.isDefault && (
          <ActionIcon onClick={handleDelete}>
            <IconTrash size={14} />
          </ActionIcon>
        )}
      </Flex>
    </Flex>
  );
};

export const FileGroupRow = memo(FileGroupRowBase);
