import React, { memo, useMemo } from 'react';
import { Alert, Anchor, Button, Flex, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { getClientFiles } from '@/pageAI/services/clients';
import { FileClassificationAlertProps } from './FileClassificationAlert.types';
import { filterUnclassifiedFileAssets, reviewUnclassifiedFileAssetsPubsub } from '@/pageAI/services/files';

const FileClassificationAlertBase = ({ client }: FileClassificationAlertProps) => {
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);
  const unclassifiedFiles = useMemo(() => filterUnclassifiedFileAssets(fileAssets), [fileAssets]);

  const handleReview = (event: React.MouseEvent) => {
    event.preventDefault();
    reviewUnclassifiedFileAssetsPubsub.publish({});
  };

  if (!unclassifiedFiles.length) return null;

  return (
    <Alert
      icon={<IconAlertTriangle />}
      variant="light"
      color="orange"
      sx={(theme) => ({ border: `1px solid ${theme.colors.orange[5]}` })}
    >
      <Flex justify="space-between">
        <Text fz="0.875rem" color="dark">
          You have unclassified files –&nbsp;
          <Anchor sx={{ textDecoration: 'underline' }} onClick={handleReview}>
            please review
          </Anchor>
          &nbsp;before generating your summaries.
        </Text>

        <Button
          variant="light"
          color="orange"
          size="xs"
          onClick={handleReview}
          sx={{
            height: 20,
            padding: '0 4px',
          }}
        >
          Review
        </Button>
      </Flex>
    </Alert>
  );
};

export const FileClassificationAlert = memo(FileClassificationAlertBase);
