import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Text, Flex, ActionIcon, Tooltip, Box } from '@mantine/core';
import { IconSortAscending2, IconSortDescending2 } from '@tabler/icons-react';

import { CaseTimelinePanelProps } from './CaseTimelinePanel.types';
import { useDelayedTimelineEvents, useScrollBorder, useTimelineFilters } from './CaseTimelinePanel.utils';
import { Panel } from '@/pageAI/designSystem/Panel';
import { DownloadTimelineButton } from '@/pageAI/components/timeline/DownloadTimelineButton';
import { CaseTimelineContextProvider } from '@/pageAI/contexts/caseTimelineContext';
import { CaseTimelineTabs } from '@/pageAI/components/timeline/CaseTimelineTabs';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';
import { CaseTimeline } from '@/pageAI/components/timeline/CaseTimeline';
import { filterCaseTimelineEvents } from '@/pageAI/services/caseTimeline';
import { TimelineFilters } from '@/pageAI/components/timeline/TimelineFilters';
import { TimelineCommentsSidebar } from '@/pageAI/components/comments/TimelineCommentsSidebar';
import { TimelineSearchInput } from '../TimelineSearchInput';
import { TimelineNavigationHint } from '../TimelineNavigationHint';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useUnifiedStore } from '@/pageAI/states/unified';

const CaseTimelinePanelBase = ({ client, caseTimeline }: CaseTimelinePanelProps) => {
  const [tab, setTab] = useState<CaseTimelineTab>('All');
  const [isFileTypesFilterPristine, setIsFileTypesFilterPristine] = useState(true);
  const allTabSavedOffset = useRef<number | undefined>(
    useUnifiedStore.getState().scrollPosition[UnifiedTab.TIMELINE]?.All,
  );
  const bookmarkedTabSavedOffset = useRef<number | undefined>(
    useUnifiedStore.getState().scrollPosition[UnifiedTab.TIMELINE]?.Bookmarked,
  );

  const {
    eventTypes,
    setEventTypes,
    fileGroups,
    setFileGroups,
    fileTypes,
    setFileTypes,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    confidenceLevels,
    setConfidenceLevels,
    searchKeywords,
    splitKeywords,
    setSearchKeywords,
    matchWholeWords,
    setMatchWholeWords,
    matchWholePhrase,
    setMatchWholePhrase,
    displayOnlyAdditionalEvents,
    setDisplayOnlyAdditionalEvents,
    displayOnlyImportantFiles,
    setDisplayOnlyImportantFiles,
    year,
    setYear,
    sortOrder,
    setSortOrder,
  } = useTimelineFilters(client, caseTimeline);

  const { delayedTimelineEvents: caseTimelineSource } = useDelayedTimelineEvents(caseTimeline, tab);

  const filteredCaseTimelineEvents = useMemo(
    () =>
      filterCaseTimelineEvents({
        client,
        events: caseTimelineSource,
        eventTypes,
        fileTypes,
        fromDate,
        toDate,
        confidenceLevels,
        displayOnlyAdditionalEvents,
        displayOnlyImportantFiles,
        keywords: splitKeywords,
        matchWholeWords,
        matchWholePhrase,
      }),
    [
      client,
      caseTimelineSource,
      eventTypes,
      fileTypes,
      fromDate,
      toDate,
      confidenceLevels,
      displayOnlyAdditionalEvents,
      displayOnlyImportantFiles,
      splitKeywords,
      matchWholeWords,
      matchWholePhrase,
    ],
  );

  const [showBorder] = useScrollBorder(filteredCaseTimelineEvents.length === 0);

  const handleChangeSelectedEventTypes = useCallback(
    (value?: string[]) => {
      setEventTypes(value || []);
    },
    [setEventTypes],
  );

  const handleChangeSelectedFileTypes = useCallback(
    (value: string[] | null) => {
      setFileTypes(value);
      setIsFileTypesFilterPristine(false);
    },
    [setFileTypes],
  );

  const handleChangeSearchKeywords = useCallback(
    (newValue: string) => {
      setSearchKeywords(newValue);
    },
    [setSearchKeywords],
  );

  const handleMatchWholeWordsChange = useCallback(
    (value: boolean) => {
      setMatchWholeWords(value);
    },
    [setMatchWholeWords],
  );

  const handleMatchWholePhraseChange = useCallback(
    (value: boolean) => {
      setMatchWholePhrase(value);
    },
    [setMatchWholePhrase],
  );

  const handleChangeSortOrder = useCallback(() => {
    setSortOrder((currentSortOrder) => (currentSortOrder === 'asc' ? 'desc' : 'asc'));
  }, [setSortOrder]);

  return (
    <>
      <CaseTimelineContextProvider
        tab={tab}
        keywords={splitKeywords}
        setSearchKeywords={handleChangeSearchKeywords}
        matchWholeWords={matchWholeWords}
        matchWholePhrase={matchWholePhrase}
        eventTypes={eventTypes}
        setEventTypes={handleChangeSelectedEventTypes}
        fileGroups={fileGroups}
        setFileGroups={setFileGroups}
        fileTypes={fileTypes}
        setFileTypes={handleChangeSelectedFileTypes}
        isFileTypesFilterPristine={isFileTypesFilterPristine}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        confidenceLevels={confidenceLevels}
        setConfidenceLevels={setConfidenceLevels}
        displayOnlyAdditionalEvents={displayOnlyAdditionalEvents}
        setDisplayOnlyAdditionalEvents={setDisplayOnlyAdditionalEvents}
        displayOnlyImportantFiles={displayOnlyImportantFiles}
        setDisplayOnlyImportantFiles={setDisplayOnlyImportantFiles}
        year={year}
        setYear={setYear}
        allTabSavedOffset={allTabSavedOffset}
        bookmarkedTabSavedOffset={bookmarkedTabSavedOffset}
      >
        <Flex>
          <Box pb="md" py={0} pos="relative" sx={{ flex: '1 1' }}>
            <Panel sx={{ padding: 0 }}>
              <Flex direction="column">
                <Flex
                  justify="space-between"
                  gap={8}
                  sx={(theme) => ({
                    flexWrap: 'wrap',
                    padding: filteredCaseTimelineEvents.length > 0 ? '16px 32px 12px 32px' : '16px 32px 0 32px',
                    borderBottom:
                      showBorder && filteredCaseTimelineEvents.length > 0
                        ? `1px solid ${theme.colors.gray[2]}`
                        : 'none',
                  })}
                >
                  <Flex align="center" gap={6}>
                    <Box id="case-timeline-header-prefix" mr={-4} ml={-4} />

                    <Text fw={600} color="dark.4">
                      Case Timeline
                    </Text>

                    <CaseTimelineTabs initialValue={tab} onChange={setTab} sx={{ marginLeft: 2, marginRight: 2 }} />

                    <Tooltip
                      label={sortOrder === 'desc' ? 'Sorting latest to oldest' : 'Sorting oldest to latest'}
                      withinPortal
                      withArrow
                    >
                      <ActionIcon onClick={handleChangeSortOrder}>
                        {sortOrder === 'desc' ? <IconSortDescending2 size={20} /> : <IconSortAscending2 size={20} />}
                      </ActionIcon>
                    </Tooltip>

                    <TimelineNavigationHint />
                  </Flex>

                  <Flex align="center" gap={4} justify="flex-end" sx={{ flexGrow: 1 }}>
                    <TimelineFilters caseTimeline={caseTimeline} />

                    <TimelineSearchInput
                      initialValue={searchKeywords}
                      onChange={handleChangeSearchKeywords}
                      matchWholeWords={matchWholeWords}
                      onMatchWholeWordsChange={handleMatchWholeWordsChange}
                      matchWholePhrase={matchWholePhrase}
                      onMatchWholePhraseChange={handleMatchWholePhraseChange}
                    />
                  </Flex>
                </Flex>

                <CaseTimeline key={tab} timeline={filteredCaseTimelineEvents} sortOrder={sortOrder} />
              </Flex>
            </Panel>
          </Box>

          <Box>
            <TimelineCommentsSidebar timeline={filteredCaseTimelineEvents} />
          </Box>
        </Flex>
      </CaseTimelineContextProvider>

      <DownloadTimelineButton
        client={client}
        originalTimeline={caseTimeline}
        filteredTimeline={filteredCaseTimelineEvents}
        sortOrder={sortOrder}
      />
    </>
  );
};

export const CaseTimelinePanel = memo(CaseTimelinePanelBase);
