import { forwardRef, memo, useCallback } from 'react';
import { Badge, Flex, Select } from '@mantine/core';

import {
  TIMELINE_EVENT_TYPE_COLOR_MAPPING,
  TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING,
} from '@/pageAI/services/caseTimeline';
import { TimelineEventTypesSelectItemProps, TimelineEventTypesSelectProps } from './TimelineEventTypesSelect.types';
import { TimelineEventType } from '@/pageAI/gql/graphql';

const TimelineEventTypesSelectItem = forwardRef(
  ({ label, selected, value, ...rest }: TimelineEventTypesSelectItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <Flex
        ref={ref}
        {...rest}
        align="center"
        justify="space-between"
        gap={6}
        sx={(theme) => ({
          '&.ghost-MultiSelect-item': {
            background: 'transparent',
            '&:hover': {
              background: theme.colors.gray[1],
            },
            '&[data-selected]': {
              color: theme.colors.dark[5],
              backgroundColor: theme.colors.gray[1],
              '&:hover': {
                backgroundColor: theme.colors.gray[2],
              },
            },
          },
        })}
      >
        <Badge
          variant="light"
          sx={(theme) => ({
            width: '100%',
            justifyContent: 'start',
            backgroundColor: theme.colors[TIMELINE_EVENT_TYPE_COLOR_MAPPING[value as TimelineEventType]][1],
            '.ghost-Badge-inner': {
              color: theme.colors.dark[4],
              fontWeight: 500,
              textTransform: 'none',
            },
          })}
        >
          {TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING[value as TimelineEventType]}
        </Badge>
      </Flex>
    );
  },
);

const eventTypeOptions = Object.values(TimelineEventType)
  .filter((eventType) => eventType != TimelineEventType.Other)
  .map((eventType) => ({
    value: eventType,
    label: TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING[eventType],
  }));

const TimelineEventTypesSelectBase = ({
  value,
  icon,
  label,
  size = 'xs',
  initiallyOpened,
  clearable,
  withinPortal = true,
  onChange,
}: TimelineEventTypesSelectProps) => {
  const handleChange = useCallback(
    (newValue: string) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  return (
    <Select
      size={size}
      placeholder="Event types"
      label={label}
      itemComponent={TimelineEventTypesSelectItem}
      icon={icon}
      initiallyOpened={initiallyOpened}
      data={eventTypeOptions}
      value={value}
      onChange={handleChange}
      clearable={clearable}
      withinPortal={withinPortal}
      sx={(theme) => ({
        '.ghost-Select-dropdown': {
          minWidth: '200px !important',
          '.ghost-Select-item': {
            '&[data-selected]': {
              color: theme.colors.dark[5],
              backgroundColor: theme.colors.gray[1],
              '&:hover': {
                backgroundColor: theme.colors.gray[2],
              },
            },
          },
        },
      })}
    />
  );
};

export const TimelineEventTypesSelect = memo(TimelineEventTypesSelectBase);
