import { memo, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Popover, Text } from '@mantine/core';
import { IconFileSearch } from '@tabler/icons-react';

import { FileIndexSearchInputProps } from './FileIndexSearchInput.types';
import { DebouncedSearchInput } from '@/shared/components/common/forms/DebouncedSearchInput';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { getClientFileSearchPath } from '@/pageAI/services/files';

const FileIndexSearchInputBase = ({ initialValue, onChange }: FileIndexSearchInputProps) => {
  const navigate = useNavigate();
  const { clientId } = useParams();

  const forceRefocusRef = useRef(false);

  const renderInputWrapper = useCallback(
    (inputNode: React.ReactNode) => {
      return (
        <Popover width="target" shadow="sm" defaultOpened>
          <Popover.Target>{inputNode}</Popover.Target>

          <Popover.Dropdown p={4} onMouseDownCapture={() => (forceRefocusRef.current = true)}>
            <Flex direction="column" gap={4}>
              <MenuItem
                icon={<IconFileSearch size={14} />}
                title={<Text fz="0.75rem">Search file contents</Text>}
                onMouseDown={() => {
                  if (clientId) navigate(getClientFileSearchPath(clientId, initialValue));
                }}
              />
            </Flex>
          </Popover.Dropdown>
        </Popover>
      );
    },
    [clientId, initialValue, navigate],
  );

  return (
    <DebouncedSearchInput
      initialValue={initialValue}
      w={200}
      placeholder="Enter file name or index..."
      onChange={onChange}
      renderInputWrapper={renderInputWrapper}
      forceRefocusRef={forceRefocusRef}
    />
  );
};

export const FileIndexSearchInput = memo(FileIndexSearchInputBase);
