import React from 'react';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconExclamationMark } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

export const showAsyncNotification = (title: React.ReactNode, message: React.ReactNode) => {
  const notificationId = uuidv4();

  notifications.show({
    id: notificationId,
    loading: true,
    title,
    message,
    autoClose: false,
    withCloseButton: false,
    withBorder: true,
  });

  const onSuccess = (title: React.ReactNode, message: React.ReactNode) => {
    notifications.update({
      id: notificationId,
      title,
      message,
      loading: false,
      icon: <IconCheck size="1rem" />,
      color: 'teal',
      autoClose: 5000,
      withCloseButton: true,
      withBorder: true,
    });
  };

  const onError = (title: React.ReactNode, message: React.ReactNode) => {
    notifications.update({
      id: notificationId,
      title,
      message,
      loading: false,
      icon: <IconExclamationMark size="1rem" />,
      color: 'red',
      autoClose: 5000,
      withCloseButton: true,
      withBorder: true,
    });
  };

  return { onSuccess, onError };
};
