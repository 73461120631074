import { memo } from 'react';
import { Avatar, Flex, Text } from '@mantine/core';

import { UserInfoRowProps } from './UserInfoRow.types';

const UserInfoRowBase = ({ name, email, picture, rightSection }: UserInfoRowProps) => {
  return (
    <Flex align="center" gap="xs">
      <Avatar src={picture || name} radius="50%" />

      <Flex direction="column">
        <Text fz="0.875rem" color="dark" fw={500} sx={{ lineHeight: '16px' }}>
          {name}
        </Text>

        {email && (
          <Text fz="0.75rem" color="gray.6" fw={500}>
            {email}
          </Text>
        )}
      </Flex>

      <Flex sx={{ flexGrow: 1 }} />

      {rightSection}
    </Flex>
  );
};

export const UserInfoRow = memo(UserInfoRowBase);
