import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { Anchor, Box, Flex, Loader, ScrollArea, SegmentedControl, Skeleton, Text, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { CreateClientPanel } from '@/pageAI/components/clients/CreateClientPanel';
import { DEFAULT_LIMIT, useClients } from '@/pageAI/hooks/clients/useClients';
import { ClientsTable } from '@/pageAI/components/clients/ClientsTable';
import { Panel } from '@/pageAI/designSystem/Panel';
import { CreateClientButton } from '@/pageAI/components/clients/CreateClientButton';
import { withPageWrapper } from '@/shared/hocs/withPageWrapper';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { PAGE_AI_HEADER_HEIGHT, PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { DataEmptyPlaceholder } from '@/pageAI/components/clients/DataEmptyPlaceholder';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { ClientTab } from '@/pageAI/services/clients';
import { singularOrPlural } from '@/shared/utils/string';
import { ClientOrderField, OrderDirection } from '@/pageAI/gql/graphql';
import { useClientSorting } from './ClientsPage.utils';

const ClientsPageBase = () => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 500);
  const [tab, setTab] = useState<ClientTab>('Organization');
  const { sortBy, setSortBy, sortOrder, setSortOrder } = useClientSorting();

  const { currentUser } = useCurrentUser();
  const { clients, isLoading, isError, totalCount, isFetching, fetchNextPage, hasNextPage } = useClients({
    first: DEFAULT_LIMIT,
    tab,
    query: debouncedQuery,
    sortBy,
    sortOrder,
    ...(currentUser && tab === 'Assigned to me' && { assigneedIds: [currentUser.id] }),
  });

  const [infiniteScrollRef, inView] = useInView();

  const organizationHasNoClients = tab === 'Organization' && !totalCount && !debouncedQuery && !isLoading;

  useEffect(() => {
    if (inView && !isLoading && !isFetching) fetchNextPage();
  }, [inView, isLoading, isFetching, fetchNextPage]);

  const handleSortChange = useCallback(
    (sortBy: ClientOrderField, sortOrder: OrderDirection) => {
      setSortBy(sortBy);
      setSortOrder(sortOrder);
    },
    [setSortBy, setSortOrder],
  );

  if (isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  const renderContent = () => {
    if (organizationHasNoClients)
      return (
        <Flex direction="column" justify="center" gap="md" sx={{ width: '100%', height: '100%' }}>
          <CreateClientPanel />
        </Flex>
      );

    if (!clients.length) {
      if (isFetching)
        return (
          <DataEmptyPlaceholder
            icon={<Loader size={40} />}
            title="Loading clients..."
            description="Please wait while your clients are being loaded."
          />
        );

      return (
        <DataEmptyPlaceholder
          title="No clients found"
          description={
            tab === 'Shared with me'
              ? 'There are no shared clients that match the current criteria.'
              : 'There are no clients in your organization that match the current criteria.'
          }
        />
      );
    }

    return (
      <Flex direction="column" sx={{ width: '100%' }}>
        <ClientsTable
          clients={clients}
          query={debouncedQuery}
          sortBy={sortBy}
          sortOrder={sortOrder}
          isLoadingMore={isFetching && hasNextPage}
          onSortChange={handleSortChange}
        />

        {hasNextPage && <Flex ref={infiniteScrollRef} />}
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={
          <Anchor to="/" component={Link} fw={600}>
            Clients
          </Anchor>
        }
        headerSuffix={
          <Flex align="center" gap="xs" ml="xs">
            <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

            {isLoading ? (
              <Skeleton width={64} height={24} />
            ) : (
              <Text fw={500} fz="0.875rem" color="dark.3" sx={{ whiteSpace: 'nowrap' }}>
                {totalCount} {singularOrPlural('client', 'clients')(totalCount)}
              </Text>
            )}

            <SegmentedControl
              value={tab}
              onChange={(value) => setTab(value as ClientTab)}
              data={['Organization', 'Assigned to me', 'Shared with me']}
              size="xs"
              sx={{ '.ghost-SegmentedControl-control': { width: 120 } }}
            />
          </Flex>
        }
        rightSection={
          <Flex align="center" gap="xs">
            <TextInput
              icon={<IconSearch size={16} />}
              placeholder="Search for clients..."
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              rightSection={(query && isLoading) || query !== debouncedQuery ? <Loader size={12} /> : null}
              w={240}
            />

            {tab === 'Organization' && !organizationHasNoClients && <CreateClientButton />}
          </Flex>
        }
      />

      <ScrollArea sx={{ height: `calc(100vh - ${PAGE_AI_HEADER_HEIGHT}px)`, overflow: 'auto' }}>
        <Box p="md" pt={4}>
          <Panel
            sx={{
              width: '100%',
              height: 'fit-content',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              padding: 16,
              gap: 4,
            }}
          >
            {renderContent()}
          </Panel>
        </Box>
      </ScrollArea>
    </Flex>
  );
};

export const ClientsPage = withPageWrapper(ClientsPageBase);
