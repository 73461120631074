import { create } from 'zustand';

import { TimelineReferenceHighlightState } from './timelineReferenceHighlights.states.types';

export const useTimelineReferenceHighlightStore = create<TimelineReferenceHighlightState>((set) => ({
  clientId: undefined,
  highlightedEventMapping: {},
}));

export const highlightEventReference = (clientId: string, eventId: string) => {
  useTimelineReferenceHighlightStore.setState({ clientId, highlightedEventMapping: { [eventId]: true } });
};

export const clearEventReferenceHighlight = () => {
  useTimelineReferenceHighlightStore.setState({ clientId: undefined, highlightedEventMapping: {} });
};
