import { useMutation } from '@tanstack/react-query';

import { generateConversationNameMutation } from '@/pageAI/api';
import { GenerateConversationNameInput, GenerateConversationNameMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useGenerateConversationName = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync: generateConversationName, isLoading } = useMutation<
    GenerateConversationNameMutation,
    Error,
    GenerateConversationNameInput
  >({
    mutationFn: async (input) => {
      return request(generateConversationNameMutation, { input });
    },
  });

  return { generateConversationName, isLoading };
};
