import { Fragment, memo, useMemo, useState } from 'react';
import { ActionIcon, Box, Divider, Flex, Input, Loader, ScrollArea, Text, Tooltip } from '@mantine/core';
import { IconSearch, IconSortAscendingLetters, IconSortDescendingNumbers } from '@tabler/icons-react';

import { categorizeConditions, sortConditionsByRating } from '@/pageAI/services/medicalConditions';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { MedicalConditionMenuProps } from './MedicalConditionMenu.types';
import { MedicalConditionMenuItem } from '../MedicalConditionMenuItem';

const MedicalConditionMenuBase = ({
  medicalConditions,
  selected,
  onSelect,
  maxHeight = 'calc(100vh - 154px)',
  checkboxMode = false,
  loading = false,
}: MedicalConditionMenuProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [sortState, setSortState] = useState('rating');

  const filteredMedicalConditions = useMemo(
    () =>
      medicalConditions.filter((medicalCondition) =>
        medicalCondition.headerCondition.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [medicalConditions, searchValue],
  );

  const { codesheetConditions, claimConditions, otherConditions } = useMemo(
    () => categorizeConditions(filteredMedicalConditions),
    [filteredMedicalConditions],
  );

  const sortedCodesheetConditions = useMemo(
    () => (sortState === 'asc' ? codesheetConditions : sortConditionsByRating(codesheetConditions)),
    [codesheetConditions, sortState],
  );

  const renderSortButton = () => {
    return (
      <Tooltip label={sortState === 'asc' ? 'Sorting alphebetically' : 'Sorting by rating'}>
        <ActionIcon
          size="xs"
          onClick={() => setSortState(sortState === 'asc' ? 'rating' : 'asc')}
          sx={(theme) => ({
            '&:hover': {
              background: theme.colors.gray[2],
            },
          })}
        >
          {sortState === 'asc' ? <IconSortAscendingLetters size={16} /> : <IconSortDescendingNumbers size={16} />}
        </ActionIcon>
      </Tooltip>
    );
  };

  const renderOptions = (medicalConditions: MedicalConditionItem[]) => {
    return (
      <>
        {medicalConditions.map((medicalCondition) => {
          return (
            <MedicalConditionMenuItem
              key={medicalCondition.id}
              medicalCondition={medicalCondition}
              selected={selected?.includes(medicalCondition.id)}
              highlightKeywords={[searchValue]}
              onSelect={onSelect}
              checkboxMode={checkboxMode}
            />
          );
        })}
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <Flex
        pl={4}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Input
          type="text"
          placeholder="Search conditions..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          icon={<IconSearch size={16} />}
          sx={(theme) => ({
            width: '100%',
            padding: 0,
            input: {
              fontSize: '0.875rem',
              border: 'none',
              background: theme.fn.lighten(theme.colors.gray[0], 0.5),
            },
          })}
        />
      </Flex>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }} gap={6}>
          <Loader size={14} />

          <Text fz="0.875rem" color="gray.7">
            Loading conditions...
          </Text>
        </Flex>
      );
    }

    if (filteredMedicalConditions.length === 0) {
      return (
        <Flex align="center" justify="center" sx={{ height: 40 }}>
          <Text fz="0.875rem" color="gray.7">
            Nothing found
          </Text>
        </Flex>
      );
    }

    return (
      <>
        <Flex align="center" px={12} gap={4}>
          <Text fz="0.75rem" color="gray.6" fw={500} py={6}>
            Conditions from Codesheet
          </Text>

          {renderSortButton()}
        </Flex>

        {renderOptions(sortedCodesheetConditions)}

        {claimConditions.length > 0 && (
          <>
            <Divider my={4} color="gray.3" />

            <Text fz="0.75rem" color="gray.6" fw={500} px={12} py={6}>
              Conditions from Claim Documents
            </Text>

            {renderOptions(claimConditions)}
          </>
        )}

        {otherConditions.length > 0 && (
          <>
            <Divider my={4} color="gray.3" />

            <Text fz="0.75rem" color="gray.6" fw={500} px={12} py={6}>
              Potential Conditions
            </Text>

            {renderOptions(otherConditions)}
          </>
        )}
      </>
    );
  };

  return (
    <Flex direction="column" onClick={(event) => event.stopPropagation()}>
      {renderSearchInput()}

      <ScrollArea.Autosize sx={{ maxHeight }}>
        <Box pl={4} py={4} pr="sm">
          {renderContent()}
        </Box>
      </ScrollArea.Autosize>
    </Flex>
  );
};

export const MedicalConditionMenu = memo(MedicalConditionMenuBase);
