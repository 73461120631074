import { memo } from 'react';
import { Flex, Text } from '@mantine/core';

import { GenerateSummariesButton } from '../GenerateSummariesButton';
import { SummariesPanelProps } from './SummariesPanel.types';
import { Panel } from '@/pageAI/designSystem/Panel';
import { canDisplaySummaries } from '@/pageAI/services/summaries';
import { useSummariesGenerationSchedule } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';
import { ClientSummaryCtaPlaceholder } from '@/pageAI/components/clients/ClientSummaryCtaPlaceholder';
import { SummariesTable } from '@/pageAI/components/summaries/SummariesTable';

const SummariesPanelBase = ({ client }: SummariesPanelProps) => {
  const { hasScheduled } = useSummariesGenerationSchedule(client);

  if (!canDisplaySummaries(client) && !hasScheduled) return <ClientSummaryCtaPlaceholder client={client} />;

  return (
    <Panel sx={{ padding: 0 }}>
      <Flex align="center" justify="space-between" gap="xs">
        <Text fw={600} color="dark.4" mb={-6}>
          Summaries
        </Text>

        <GenerateSummariesButton client={client} />
      </Flex>

      <SummariesTable client={client} />
    </Panel>
  );
};

export const SummariesPanel = memo(SummariesPanelBase);
