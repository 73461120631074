import { memo, useCallback } from 'react';

import { UpdateClientPermissionRoleSelectProps } from './UpdateClientPermissionRoleSelect.types';
import { PermissionRoleSelect } from '../PermissionRoleSelect';
import { useShareClient } from '@/pageAI/hooks/clients/useShareClient';
import { ClientPermissionRole } from '@/pageAI/gql/graphql';

const UpdateClientPermissionRoleSelectBase = ({ client, permission }: UpdateClientPermissionRoleSelectProps) => {
  const { updateClientPermissionRole, unshareClient, isUpdating, isUnsharing } = useShareClient();

  const handleChangePermissionRole = useCallback(
    (newRole: ClientPermissionRole | 'Revoke') => {
      if (!permission.email) return;

      if (newRole === 'Revoke') return unshareClient(client.id, permission.id);

      updateClientPermissionRole(client.id, permission.id, permission.email, newRole);
    },
    [updateClientPermissionRole, client, permission, unshareClient],
  );

  return (
    <PermissionRoleSelect
      value={permission.role}
      onChange={handleChangePermissionRole}
      sx={{ width: 100 }}
      loading={isUpdating || isUnsharing}
      removable
    />
  );
};

export const UpdateClientPermissionRoleSelect = memo(UpdateClientPermissionRoleSelectBase);
