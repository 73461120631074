import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateOrganizationInput, CreateOrganizationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { createOrganizationMutation } from '@/pageAI/api';

export const useCreateOrganization = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync: createOrganization, isLoading } = useMutation<
    CreateOrganizationMutation,
    Error,
    CreateOrganizationInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(createOrganizationMutation, { input });

      await queryClient.invalidateQueries(['currentUser']);

      return responseBody;
    },
    onError: (error) => {
      if (error.message.includes('invitation')) return notify('Error', 'Invitation code is invalid');

      notify('Error', 'Failed to create organization');
    },
  });

  return { createOrganization, isLoading };
};
