import { memo } from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';

import { UserProfileModalProps } from './UserProfileModal.types';
import { UserProfileForm, UserProfileFormValues } from '../UserProfileForm';
import { useUpdateUserProfile } from '@/pageAI/hooks/users/useUpdateUserProfile';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { UserAvatar } from '../UserAvatar';

const UserProfileModalBase = ({ opened = false, centered = false, onClose }: UserProfileModalProps) => {
  const { currentUser } = useCurrentUser();
  const { notify } = useNotifications();
  const { updateUserProfile, isLoading: isUpdating } = useUpdateUserProfile();

  const handleSubmit = async (values: UserProfileFormValues) => {
    if (!currentUser) return;

    await updateUserProfile({
      clientMutationId: currentUser.id,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phone: values.phone?.trim(),
      role: values.role,
    });

    notify('Success', 'Profile updated successfully', 'brand');

    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Update Profile"
      centered={centered}
      sx={{
        '.ghost-Modal-header': {
          background: 'transparent',
        },
      }}
    >
      <Flex direction="column" gap="xs">
        <Text fz="0.75rem" fw={500} color="gray.6" mt={-16} mb="xs">
          Manage your personal information.
        </Text>

        {currentUser && <UserAvatar user={currentUser} size={80} />}

        <UserProfileForm
          onSubmit={handleSubmit}
          bottomSection={
            <Flex align="center" justify="end" mt="xs" gap="xs">
              <Button variant="subtle" color="gray.7" onClick={onClose} disabled={isUpdating}>
                Close
              </Button>

              <Button type="submit" loading={isUpdating}>
                Save
              </Button>
            </Flex>
          }
        />
      </Flex>
    </Modal>
  );
};

export const UserProfileModal = memo(UserProfileModalBase);
