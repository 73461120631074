import { forwardRef, memo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { ActionIcon, Tooltip, clsx } from '@mantine/core';
import { IconMinus } from '@tabler/icons-react';

import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';
import { RemoveEventFromConditionButtonProps } from './RemoveEventToConditionButton.types';

const RemoveEventFromConditionButtonBase = (
  { tab, loading, onClick }: RemoveEventFromConditionButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  const [isTooltipOpened, { open: openTooltip, close: closeTooltip }] = useDisclosure();

  if (!selectedCondition) return null;

  return (
    <Tooltip opened={isTooltipOpened} withArrow withinPortal label="Remove event from current condition">
      <ActionIcon
        loading={loading}
        ref={ref}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        onClick={onClick}
        size="xs"
        color="gray.7"
        className={clsx(tab === 'All' ? 'extra-action' : '', 'unprintable')}
      >
        <IconMinus size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export const RemoveEventFromConditionButton = memo(forwardRef(RemoveEventFromConditionButtonBase));
