import { createContext, useContext } from 'react';

import { CaseTimelineContextValues } from './CaseTimelineContext.types';
import { HighlightType } from '@/shared/utils/markdown';

export const CaseTimelineContext = createContext<CaseTimelineContextValues>({
  tab: 'All',
  keywords: [],
  prefix: undefined,
  setSearchKeywords: () => {},
  eventTypes: [],
  setEventTypes: () => {},
  fileGroups: [],
  setFileGroups: () => {},
  fileTypes: [],
  setFileTypes: () => {},
  isFileTypesFilterPristine: true,
  confidenceLevels: [],
  setConfidenceLevels: () => {},
  displayOnlyAdditionalEvents: false,
  setDisplayOnlyAdditionalEvents: () => {},
  displayOnlyImportantFiles: false,
  setDisplayOnlyImportantFiles: () => {},
  highlightType: HighlightType.DEFAULT,
});

export const useCaseTimelineContext = () => {
  return useContext(CaseTimelineContext) as CaseTimelineContextValues;
};
