import { memo, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { useCurrentUser } from '../hooks/users/useCurrentUser';
import { doesUserHaveOrganization, doesUserNeedToUpdateProfile } from '../services/users';
import { getItemFromStorage } from '@/shared/lib/storage';
import { INVITATION_ID_STORAGE_KEY } from '../pages/AcceptInvitationPage';
import { useAuth0 } from '@auth0/auth0-react';
import { VerifyEmailPage } from '../pages/VerifyEmailPage';

export let redirectUrl = '';

const AllowedRouteBase = () => {
  const location = useLocation();
  const { user, isLoading: isAuthenticating } = useAuth0();
  const { currentUser, isLoading: isLoadingUser } = useCurrentUser();
  const existingInvitationId = useMemo(() => getItemFromStorage(INVITATION_ID_STORAGE_KEY), []);

  const isLoading = isAuthenticating || isLoadingUser;

  if (isLoading || !currentUser) {
    return (
      <LoadingPlaceholder
        title="Authenticating..."
        description="Please wait while Page retrieves your account information."
        fullScreen
      />
    );
  }

  if (!user?.email_verified) return <VerifyEmailPage />;

  const isOrganizationOnboarding = location.pathname.includes('/organization-onboarding');
  const isUserOnboarding = location.pathname.includes('/user-onboarding');

  if (existingInvitationId) return <Navigate to={`/accept-invitation?id=${existingInvitationId}`} />;

  if (!doesUserHaveOrganization(currentUser)) {
    if (!isOrganizationOnboarding) return <Navigate to="/organization-onboarding" />;

    return <Outlet />;
  }

  if (!isUserOnboarding && !isOrganizationOnboarding) {
    if (doesUserNeedToUpdateProfile(currentUser)) {
      redirectUrl = location.pathname.concat(location.search);

      return <Navigate to="/user-onboarding?update=true" replace />;
    }

    redirectUrl = '';
  }

  return <Outlet />;
};

export const AllowedRoute = memo(AllowedRouteBase);
