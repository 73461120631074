import { memo, useMemo } from 'react';
import { IconShare } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

import { ShareFileButtonProps } from './ShareFileButton.types';
import { CopyButton } from '@/shared/components/common/CopyButton';

const ShareFileButtonBase = ({ fileAsset }: ShareFileButtonProps) => {
  const [searchParams] = useSearchParams();

  const shareUrl = useMemo(() => {
    searchParams.set('fileId', fileAsset.id);

    return [window.location.href.split('?')[0], searchParams.toString()].join('?');
  }, [searchParams, fileAsset.id]);

  return (
    <CopyButton value={shareUrl} tooltip="Share" copiedTooltip="Link copied" size="xs" icon={<IconShare size={14} />} />
  );
};

export const ShareFileButton = memo(ShareFileButtonBase);
