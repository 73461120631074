import { memo } from 'react';

import { FileIndexRightHeaderProps } from './FileIndexRightHeader.types';
import { FileFilters } from '../../files/FileFilters';
import { FileIndexSearchInput } from '../../files/FileIndexSearchInput';

const FileIndexRightHeaderBase = ({ client, keywords, onKeywordsChange }: FileIndexRightHeaderProps) => {
  return (
    <>
      <FileFilters client={client} />

      <FileIndexSearchInput initialValue={keywords} onChange={onKeywordsChange} />
    </>
  );
};

export const FileIndexRightHeader = memo(FileIndexRightHeaderBase);
