import { useCallback, useEffect } from 'react';

import { CollectionAsset } from '@/shared/@types';
import { isThereFailedFile, isThereProcessingFile } from '@/shared/services/files';

export const useFileStatusPolling = (
  fileCollection: CollectionAsset | undefined,
  refetch: () => void,
  shouldPollFailedFiles = false,
) => {
  const startFileProcessStatusPolling = useCallback(
    (seconds = 10) => {
      const interval = setInterval(() => {
        if (!fileCollection?.files) return;

        if (
          isThereProcessingFile(fileCollection.files) ||
          (shouldPollFailedFiles && isThereFailedFile(fileCollection.files))
        )
          refetch();
      }, seconds * 1000);

      return () => clearInterval(interval);
    },
    [fileCollection?.files, shouldPollFailedFiles, refetch],
  );

  useEffect(() => {
    const stopPolling = startFileProcessStatusPolling();

    return () => stopPolling();
  }, [startFileProcessStatusPolling]);
};
