import { graphql } from '../gql';

export const mergeConditionsMutation = graphql(/* GraphQL */ `
  mutation MergeConditions($input: MergeConditionsInput!) {
    mergeConditionsErrorCorrection(input: $input) {
      destCondition {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
      sourceConditions {
        id
        isMiscellaneous
        headerCondition
        subConditions
        conditionStatus
        mergedConditions
      }
    }
  }
`);

export const deleteMedicalConditionMutation = graphql(/* GraphQL */ `
  mutation DeleteMedicalCondition($input: DeleteMedicalConditionInput!) {
    deleteMedicalCondition(input: $input) {
      deletedMedicalConditionId
    }
  }
`);

export const addEventsToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddEventsToConditions($input: AddEventsToConditionsInput!) {
    addEventsToConditions(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        date
        fileId
        reference
        summary
      }

      allCodesheets {
        date
        fileId
        reference
        summary
      }

      proceduralHistory {
        applicableItf {
          date
          fileId
          reference
          summary
        }
        filings {
          bvaAppeals {
            date
            fileId
            reference
            summary
          }
          disagreementNotices {
            date
            fileId
            reference
            summary
          }
          initialClaims {
            date
            fileId
            reference
            summary
          }
          requestsForHighLevelReview {
            date
            fileId
            reference
            summary
          }
          supplementalClaims {
            date
            fileId
            reference
            summary
          }
          bvaDisagreementNotices {
            date
            fileId
            reference
            summary
          }
        }
        firstDecision {
          date
          fileId
          reference
          summary
        }
        dateOfOriginalClaim {
          date
          fileId
          reference
          summary
        }
        otherDecisions {
          date
          fileId
          reference
          summary
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          date
          fileId
          reference
          summary
        }
        medicalOpinions {
          date
          fileId
          reference
          summary
        }
        addendumOpinions {
          date
          fileId
          reference
          summary
        }
      }

      otherEvidence {
        socStatements {
          date
          fileId
          reference
          summary
        }
        favorableFindings {
          date
          fileId
          reference
          summary
        }
        layStatements {
          date
          fileId
          reference
          summary
        }
        additionalEvidence {
          date
          fileId
          reference
          summary
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const removeEventsFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveEventsFromCondition($input: RemoveEventsFromConditionInput!) {
    removeEventsFromCondition(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        date
        fileId
        reference
        summary
      }

      allCodesheets {
        date
        fileId
        reference
        summary
      }

      proceduralHistory {
        applicableItf {
          date
          fileId
          reference
          summary
        }
        filings {
          bvaAppeals {
            date
            fileId
            reference
            summary
          }
          disagreementNotices {
            date
            fileId
            reference
            summary
          }
          initialClaims {
            date
            fileId
            reference
            summary
          }
          requestsForHighLevelReview {
            date
            fileId
            reference
            summary
          }
          supplementalClaims {
            date
            fileId
            reference
            summary
          }
          bvaDisagreementNotices {
            date
            fileId
            reference
            summary
          }
        }
        firstDecision {
          date
          fileId
          reference
          summary
        }
        dateOfOriginalClaim {
          date
          fileId
          reference
          summary
        }
        otherDecisions {
          date
          fileId
          reference
          summary
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          date
          fileId
          reference
          summary
        }
        medicalOpinions {
          date
          fileId
          reference
          summary
        }
        addendumOpinions {
          date
          fileId
          reference
          summary
        }
      }

      otherEvidence {
        socStatements {
          date
          fileId
          reference
          summary
        }
        favorableFindings {
          date
          fileId
          reference
          summary
        }
        layStatements {
          date
          fileId
          reference
          summary
        }
        additionalEvidence {
          date
          fileId
          reference
          summary
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const addFilesToConditionsMutation = graphql(/* GraphQL */ `
  mutation AddFilesToConditions($input: AddFilesToConditionsInput!) {
    addFilesToConditions(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        date
        fileId
        reference
        summary
      }

      allCodesheets {
        date
        fileId
        reference
        summary
      }

      proceduralHistory {
        applicableItf {
          date
          fileId
          reference
          summary
        }
        filings {
          bvaAppeals {
            date
            fileId
            reference
            summary
          }
          disagreementNotices {
            date
            fileId
            reference
            summary
          }
          initialClaims {
            date
            fileId
            reference
            summary
          }
          requestsForHighLevelReview {
            date
            fileId
            reference
            summary
          }
          supplementalClaims {
            date
            fileId
            reference
            summary
          }
          bvaDisagreementNotices {
            date
            fileId
            reference
            summary
          }
        }
        firstDecision {
          date
          fileId
          reference
          summary
        }
        dateOfOriginalClaim {
          date
          fileId
          reference
          summary
        }
        otherDecisions {
          date
          fileId
          reference
          summary
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          date
          fileId
          reference
          summary
        }
        medicalOpinions {
          date
          fileId
          reference
          summary
        }
        addendumOpinions {
          date
          fileId
          reference
          summary
        }
      }

      otherEvidence {
        socStatements {
          date
          fileId
          reference
          summary
        }
        favorableFindings {
          date
          fileId
          reference
          summary
        }
        layStatements {
          date
          fileId
          reference
          summary
        }
        additionalEvidence {
          date
          fileId
          reference
          summary
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);

export const removeFilesFromConditionMutation = graphql(/* GraphQL */ `
  mutation RemoveFilesFromCondition($input: RemoveFilesFromConditionInput!) {
    removeFilesFromCondition(input: $input) {
      id
      isMiscellaneous
      headerCondition
      subConditions
      conditionType
      conditionStatus
      conditionOrder
      isServiceConnected
      rating
      diagnosticCode

      mergedConditions

      latestCodesheet {
        date
        fileId
        reference
        summary
      }

      allCodesheets {
        date
        fileId
        reference
        summary
      }

      proceduralHistory {
        applicableItf {
          date
          fileId
          reference
          summary
        }
        filings {
          bvaAppeals {
            date
            fileId
            reference
            summary
          }
          disagreementNotices {
            date
            fileId
            reference
            summary
          }
          initialClaims {
            date
            fileId
            reference
            summary
          }
          requestsForHighLevelReview {
            date
            fileId
            reference
            summary
          }
          supplementalClaims {
            date
            fileId
            reference
            summary
          }
          bvaDisagreementNotices {
            date
            fileId
            reference
            summary
          }
        }
        firstDecision {
          date
          fileId
          reference
          summary
        }
        dateOfOriginalClaim {
          date
          fileId
          reference
          summary
        }
        otherDecisions {
          date
          fileId
          reference
          summary
        }
      }

      medicalEvidence {
        disabilityBenefitsQuestionnaires {
          date
          fileId
          reference
          summary
        }
        medicalOpinions {
          date
          fileId
          reference
          summary
        }
        addendumOpinions {
          date
          fileId
          reference
          summary
        }
      }

      otherEvidence {
        socStatements {
          date
          fileId
          reference
          summary
        }
        favorableFindings {
          date
          fileId
          reference
          summary
        }
        layStatements {
          date
          fileId
          reference
          summary
        }
        additionalEvidence {
          date
          fileId
          reference
          summary
        }
      }

      relatedTimelineEvents {
        id
        eventType
        summaries
        summariesTypes
        details
        detailsTypes
        date
        duplicated
        confidenceLevel
        reference
        referencedText
        verificationLevel
        bookmarked
        generation
        metadata {
          key
          value
        }
        viewerCanBookmark
        viewerCanComment
        viewerCanDelete
        viewerCanUnbookmark
        viewerCanUpdate
        viewerCanHide
        viewerCanUnhide
        createdAt
        updatedAt
        comments(first: 9999) {
          nodes {
            id
            content
            viewerCanDelete
            viewerCanUpdate
            viewerDidAuthor
            createdAt
            updatedAt
            author {
              id
              firstName
              lastName
              email
              avatarUrl
              phone
              role
              createdAt
              updatedAt
            }
          }
        }
      }

      generation
      createdAt
      updatedAt
    }
  }
`);
