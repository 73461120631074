import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateClientInput, UpdateClientMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { updateClientMutation } from '@/pageAI/api';
import { filterNullishKeys } from '@/shared/utils/object';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { Client } from '@/pageAI/@types';
import { ClientQuery, queryMap } from '../useClient';

export const useUpdateClient = (skipRefetch = false) => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const { mutateAsync: updateClient, isLoading } = useMutation<UpdateClientMutation, Error, UpdateClientInput>({
    mutationFn: async (input) => {
      return request(updateClientMutation, { input });
    },
    onSuccess: (responseBody) => {
      if (skipRefetch) return;

      queryClient.refetchQueries(queryMap.client.queryKey(responseBody.updateClient.id));
      queryClient.invalidateQueries(['clients']);
    },
  });

  const updateClientLocally = useCallback(
    (input: Partial<Client>) => {
      const previousClientQuery = queryClient.getQueryData<ClientQuery>(queryMap.client.queryKey(input.id));

      queryClient.setQueryData<ClientQuery>(queryMap.client.queryKey(input.id), (clientQuery) => {
        if (!clientQuery) return clientQuery;

        return {
          ...clientQuery,
          client: {
            ...clientQuery.client,
            ...filterNullishKeys(input),
          },
        };
      });

      return () => queryClient.setQueryData<ClientQuery>(queryMap.client.queryKey(input.id), previousClientQuery);
    },
    [queryClient],
  );

  const updateClientOptimistically = useCallback(
    async (input: UpdateClientInput) => {
      const revert = updateClientLocally(filterNullishKeys(input));

      try {
        await updateClient(input);
      } catch (error) {
        revert();

        notify('Error', 'Failed to update client');
      }
    },
    [updateClient, notify, updateClientLocally],
  );

  return { updateClient, updateClientLocally, updateClientOptimistically, isLoading };
};
