import { memo } from 'react';
import { Button, Flex, Modal, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { UpsertClientModalProps } from './UpsertClientModal.types';
import { useCreateClient } from '@/pageAI/hooks/clients/useCreateClient';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateClient } from '@/pageAI/hooks/clients/useUpdateClient';
import { getClientDetailsUrl } from '@/pageAI/services/clients';

const UpsertClientModalBase = ({ opened = false, isEdit = false, client, onClose }: UpsertClientModalProps) => {
  const { notify } = useNotifications();
  const navigate = useNavigate();

  const { createClient, isLoading: isCreating } = useCreateClient();
  const { updateClient, isLoading: isUpdating } = useUpdateClient();
  const form = useForm({
    initialValues: {
      firstName: client?.firstName || '',
      lastName: client?.lastName || '',
      bio: client?.bio || '',
    },
  });

  const isLoading = isCreating || isUpdating;

  const handleSubmit = async () => {
    const { firstName, lastName, bio } = form.values;

    try {
      if (isEdit) {
        if (!client) throw new Error('Client is not provided');

        await updateClient({ id: client.id, firstName, lastName, bio });
      } else {
        const responseBody = await createClient({ firstName, lastName, bio });

        Promise.resolve().then(() => navigate(getClientDetailsUrl(responseBody.createClient.id)));

        form.reset();
      }

      onClose();
    } catch (error) {
      notify('Error', isEdit ? "Failed to edit client's info" : 'Failed to create client');
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={isEdit ? 'Edit client' : 'Create client'}
      sx={{
        '.ghost-Modal-title': {
          fontWeight: 500,
        },
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="md">
          <Flex direction="column" gap="xs">
            <TextInput
              label="First name"
              placeholder="Enter first name..."
              required
              {...form.getInputProps('firstName')}
            />

            <TextInput
              label="Last name"
              placeholder="Enter last name..."
              required
              {...form.getInputProps('lastName')}
            />

            {isEdit && <Textarea label="Bio" placeholder="Enter bio..." {...form.getInputProps('bio')} />}
          </Flex>

          <Flex justify="flex-end" gap="xs">
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>

            <Button color="red" type="submit" loading={isLoading}>
              {isEdit ? 'Edit' : 'Create'}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const UpsertClientModal = memo(UpsertClientModalBase);
