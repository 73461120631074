import { memo } from 'react';
import { Anchor, Button, Checkbox, Flex, Modal, MultiSelect, ScrollArea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import { CreateOrganizationFormProps, CreateOrganizationFormValues } from './CreateOrganizationForm.types';

const practiceAreaOptions = [
  'VA Disability',
  'SSDI',
  'Long Term Disability',
  'Workers Comp',
  'Personal Injury',
  'Other',
];

const CreateOrganizationFormBase = ({ bottomSection, onSubmit }: CreateOrganizationFormProps) => {
  const [opened, { open, close }] = useDisclosure();

  const form = useForm<CreateOrganizationFormValues>({
    initialValues: {
      organizationName: '',
      website: '',
      practiceAreas: [],
      tosAccepted: false,
    },
    validate: {
      organizationName: (value) => (value.trim().length === 0 ? 'Name is required' : null),
      practiceAreas: (value) => (value.length === 0 ? 'Practice areas are required' : null),
      tosAccepted: (value) => (!value ? 'You must accept the Terms of Service to continue' : null),
    },
  });

  const handleSubmit = async (values: CreateOrganizationFormValues) => {
    onSubmit?.(values);
  };

  const handleClickTermsOfService = (event: React.MouseEvent) => {
    event.preventDefault();

    open();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Flex direction="column" gap="xs">
        <Flex direction="column" gap="xs">
          <TextInput
            label="Name"
            placeholder="Enter your organization's name"
            withAsterisk
            {...form.getInputProps('organizationName')}
            sx={{ flexGrow: 1, width: '100%' }}
          />

          <TextInput
            label="Website"
            placeholder="Enter your organization's website"
            {...form.getInputProps('website')}
            sx={{ flexGrow: 1, width: '100%' }}
          />

          <MultiSelect
            label="Practice areas"
            required
            placeholder="Select your practice areas"
            data={practiceAreaOptions}
            clearable
            {...form.getInputProps('practiceAreas')}
          />

          <Checkbox
            mt="sm"
            label={
              <>
                I have read and agree to the{' '}
                <Anchor sx={{ textDecoration: 'underline' }} onClick={handleClickTermsOfService}>
                  Terms of Service
                </Anchor>
                .
              </>
            }
            {...form.getInputProps('tosAccepted', { type: 'checkbox' })}
          />
        </Flex>

        {bottomSection}
      </Flex>

      <Modal
        opened={opened}
        withinPortal
        onClose={close}
        size={888}
        withCloseButton={false}
        sx={{
          '.ghost-Modal-body': {
            padding: 0,
            background: 'rgb(82, 86, 89)',
          },
        }}
      >
        <ScrollArea sx={{ height: '90vh' }}>
          <Flex direction="column">
            <object
              aria-label="Terms of Service"
              data="/files/Page - Master Services Agreement (MSA).pdf"
              type="application/pdf"
              width="100%"
              height="6480px"
            />

            <Flex align="center" justify="center" pl="xs" pt="xs" pb="lg" pr={36} gap="lg">
              <Button
                color="red.0"
                size="md"
                variant="outline"
                onClick={() => {
                  form.setFieldValue('tosAccepted', false);
                  close();
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Decline&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>

              <Button
                color="red.6"
                size="md"
                onClick={() => {
                  form.setFieldValue('tosAccepted', true);
                  close();
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accept&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </Flex>
          </Flex>
        </ScrollArea>
      </Modal>
    </form>
  );
};

export const CreateOrganizationForm = memo(CreateOrganizationFormBase);
