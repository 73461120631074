export const downloadTextAsFile = (text: string, fileName: string) => {
  // Create a Blob containing the text
  const blob = new Blob([text], { type: 'text/plain' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a download link
  const a = document.createElement('a');
  a.href = url;
  a.download = [fileName, '.txt'].join('');

  // Trigger the download
  a.click();

  // Clean up by revoking the temporary URL
  window.URL.revokeObjectURL(url);
};

export const openWindow = (url: string, target?: string) => {
  return window.open(url, target, 'noopener, noreferrer');
};

export const isIframeLoaded = async (iframe: HTMLIFrameElement) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

  return iframeDoc?.readyState == 'complete';
};

export const waitUntilIframeLoaded = async (iframe: HTMLIFrameElement, maxWaitTime = 10000) => {
  const interval = 50;

  return new Promise((resolve, reject) => {
    const intervalInstance = window.setInterval(() => {
      if (iframe.contentWindow?.document.readyState !== 'loading') {
        clearInterval(intervalInstance);

        return resolve(true);
      }

      maxWaitTime -= interval;

      if (maxWaitTime <= interval) {
        clearInterval(intervalInstance);

        reject();
      }
    }, interval);
  });
};

export const injectCssIntoIframe = (iframe: HTMLIFrameElement, css: string) => {
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

  if (iframeDoc) {
    const style = document.createElement('style');

    style.innerHTML = css;

    iframeDoc.head.appendChild(style);
  }
};

export const updateSearchParams =
  (searchParams: URLSearchParams) => (paramName: string, paramValue?: string | boolean | string[] | null) => {
    if (Array.isArray(paramValue)) {
      searchParams.delete(paramName);

      paramValue.forEach((value) => searchParams.append(paramName, value));

      return;
    }

    if (paramValue) return searchParams.set(paramName, paramValue.toString());

    searchParams.delete(paramName);
  };
