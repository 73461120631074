import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { conversationQuery, getMessageUpdatedSubscriptionQuery } from '@/pageAI/api';
import { createWebSocketSubscription } from '@/shared/lib/subscription';
import { useAccessToken } from '@/shared/hooks/auth/useAccessToken';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { ConversationQuery, MessageRole } from '@/pageAI/gql/graphql';
import { setIsWaitingForAnswer } from '@/shared/states/chat';
import { ConversationMessage } from '@/shared/@types';

export const useConversation = (conversationId?: string) => {
  const { request } = useGraphQLRequest();
  const { getAccessTokenSilently } = useAccessToken();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  // TODO: Fix this type issue
  const { data, isLoading, isError, refetch, isRefetching } = useQuery<any>(['conversation', conversationId], () =>
    conversationId ? request(conversationQuery as any, { id: conversationId }) : Promise.resolve(null),
  );

  const upsertConversationMessage = useCallback(
    (newMessage: ConversationMessage) => {
      queryClient.setQueryData(['conversation', conversationId], (prevData?: ConversationQuery) => {
        if (!prevData) return prevData;

        let shouldAppend = true;

        const newMessageNodes = prevData.conversation.messages.nodes.map((message) => {
          if (message.id === newMessage.id) {
            shouldAppend = false;

            return newMessage;
          }

          return message;
        });

        return {
          ...prevData,
          conversation: {
            ...prevData.conversation,
            messages: {
              nodes: shouldAppend ? [newMessage, ...prevData.conversation.messages.nodes] : newMessageNodes,
            },
          },
        };
      });
    },
    [queryClient, conversationId],
  );

  useEffect(() => {
    if (!data?.conversation.id) return;

    const cleanupFunctions: (() => void)[] = [];

    (async () => {
      const query = getMessageUpdatedSubscriptionQuery(data.conversation.id);

      const accessToken = await getAccessTokenSilently();

      const { close } = createWebSocketSubscription({
        query,
        accessToken,
        observer: {
          next: (message: { data: { messageUpdated: { message: ConversationMessage } } }) => {
            const newMessage = message.data.messageUpdated.message;

            upsertConversationMessage(newMessage);

            if (newMessage.content.length > 0 && newMessage.role === MessageRole.Ai) {
              setIsWaitingForAnswer(false);
            }
          },
          error: (error: Error) => {
            notify('Error', 'Something went wrong while waiting for an answer.');

            close();
          },
        },
      });

      cleanupFunctions.push(close);
    })();

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [data?.conversation.id, getAccessTokenSilently, notify, upsertConversationMessage]);

  return { conversation: data?.conversation, upsertConversationMessage, isLoading, refetch, isRefetching, isError };
};
