import { useMutation } from '@tanstack/react-query';

import { InviteOrganizationMemberInput, InviteOrganizationMemberMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { inviteOrganizationMemberMutation } from '@/pageAI/api';
import { useUpdateOrganization } from '../useUpdateOrganization';

export const useInviteOrganizationMember = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateOrganizationLocally } = useUpdateOrganization();

  const { mutateAsync: inviteMember, isLoading } = useMutation<
    InviteOrganizationMemberMutation,
    Error,
    InviteOrganizationMemberInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(inviteOrganizationMemberMutation, { input });

      return responseBody;
    },
    onSuccess: (responseBody) => {
      if (!responseBody.inviteOrganizationMember?.invitation) return;

      const invitation = responseBody.inviteOrganizationMember.invitation;
      const organizationId = invitation.organization.id;

      notify('Success', `An invitation has been sent to ${invitation.email}.`, 'brand');

      updateOrganizationLocally(organizationId)((currentOrganization) => ({
        invitations: [invitation, ...currentOrganization.invitations],
      }));
    },
    onError: (error) => {
      if (error.message.includes('user is already a member'))
        return notify('Error', 'User is already a member of the organization');

      notify('Error', 'Failed to send the invitation');
    },
  });

  return { inviteMember, isLoading };
};
