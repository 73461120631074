import { useCallback } from 'react';
import { useKeyPress } from 'ahooks';

import { isInViewport } from '@/shared/lib/dom';

export const useTableRowNavigation = (tableRef: React.RefObject<HTMLTableElement | null>, maxLength: number) => {
  const handleTableRowClick = useCallback(
    (newActiveRowIndex: number) => {
      if (tableRef.current) {
        const rowElement = tableRef.current.rows[newActiveRowIndex + 1];

        rowElement.click();

        if (!isInViewport(rowElement)) rowElement.scrollIntoView({ block: 'center' });
      }
    },
    [tableRef],
  );

  const findActiveRowIndex = useCallback(() => {
    if (!tableRef.current) return -1;

    const activeRow = tableRef.current.querySelector('tr[data-active=true]');

    if (!activeRow) return -1;

    return parseInt(activeRow.getAttribute('data-index') || '-1');
  }, [tableRef]);

  useKeyPress(['shift.uparrow'], (event) => {
    event.preventDefault();
    event.stopPropagation();

    const currentRowIndex = findActiveRowIndex();

    if (currentRowIndex === 0) return currentRowIndex;

    const newActiveRowIndex = currentRowIndex - 1;

    handleTableRowClick(newActiveRowIndex);
  });

  useKeyPress(['shift.downarrow'], (event) => {
    event.preventDefault();
    event.stopPropagation();

    const currentRowIndex = findActiveRowIndex();

    if (currentRowIndex === maxLength - 1) return currentRowIndex;

    const newActiveRowIndex = currentRowIndex + 1;

    handleTableRowClick(newActiveRowIndex);
  });
};
