import { useQuery } from '@tanstack/react-query';

import { UseClientProps } from './useClient.types';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { clientQuery } from '@/pageAI/api';
import { setSelectedClient } from '@/pageAI/states/client';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';

export const queryMap = {
  client: {
    queryKey: (clientId?: string) => ['client', clientId],
    queryFn: async (request: GraphQLRequestFunction, clientId?: string) => {
      if (typeof clientId !== 'string') return Promise.resolve(null);

      const responseBody = await request(clientQuery, { id: clientId });

      return responseBody;
    },
  },
};

export type ClientQuery = Awaited<ReturnType<typeof queryMap.client.queryFn>>;

export const useClient = ({ clientId }: UseClientProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery<ClientQuery>(
    queryMap.client.queryKey(clientId),
    () => queryMap.client.queryFn(request, clientId),
    {
      onSuccess: (responseBody) => {
        if (!responseBody?.client) return;

        setSelectedClient(responseBody.client);
      },
    },
  );

  return { client: data?.client, isLoading, isError };
};
