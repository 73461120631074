import { memo, useMemo, useState } from 'react';
import { ActionIcon, Flex, Highlight, Loader, ScrollArea, Text, TextInput, Tooltip } from '@mantine/core';
import { IconChevronRight, IconSearch, IconSortAscendingLetters, IconSortDescendingLetters } from '@tabler/icons-react';

import { FileNameSelectorProps } from './FileNameSelector.types';
import { singularOrPlural, sortAlphabetically } from '@/shared/utils/string';
import { SortOrder } from '@/shared/@types';

const FileNameSelectorBase = ({
  fileList,
  title = 'Selectable',
  loading = false,
  readonly = false,
  disabled = false,
  error,
  onSelect,
  renderItemActions,
}: FileNameSelectorProps) => {
  const [query, setQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const filteredFileList = useMemo(
    () => fileList.filter((file) => file.toLowerCase().includes(query.toLowerCase())),
    [fileList, query],
  );

  const sortedFileList = useMemo(
    () =>
      filteredFileList.sort((a, b) => {
        if (sortOrder === 'asc') {
          return sortAlphabetically(a, b);
        }

        return sortAlphabetically(b, a);
      }),
    [filteredFileList, sortOrder],
  );

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Flex direction="column" sx={{ width: '50%', flex: '1 1' }} gap="xs">
      <Flex align="center" justify="space-between" gap="xs">
        <Flex align="center" gap={6} mb={-12}>
          <Text fz="0.875rem" fw={500} color="dark">
            {title}
          </Text>

          <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

          <Text fz="0.75rem" color="dark.3">
            {sortedFileList.length} {singularOrPlural('file', 'files')(sortedFileList.length)}
          </Text>

          <Tooltip
            label={sortOrder === 'desc' ? 'Sorting alphabetically (Z → A)' : 'Sorting alphabetically (A → Z)'}
            withinPortal
            withArrow
            zIndex={500}
          >
            <ActionIcon onClick={handleSort} size="xs" color="gray.6">
              {sortOrder === 'asc' ? <IconSortAscendingLetters size={16} /> : <IconSortDescendingLetters size={16} />}
            </ActionIcon>
          </Tooltip>
        </Flex>

        <TextInput
          size="xs"
          placeholder="Search..."
          icon={<IconSearch size={14} />}
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          disabled={disabled}
        />
      </Flex>

      <ScrollArea
        sx={(theme) => ({
          padding: '0px 4px',
          border: `1px solid ${error ? theme.colors.red[5] : theme.colors.gray[3]}`,
          height: 400,
          borderRadius: 4,
        })}
      >
        <Flex direction="column">
          {loading || sortedFileList.length === 0 ? (
            <Flex align="center" justify="center" sx={{ height: 360 }}>
              {loading ? (
                <Loader size={24} />
              ) : (
                <Text fz="0.875rem" color={error ? 'red' : 'dark.3'} fw={500}>
                  {query ? 'No files found' : error || 'No files selected yet'}
                </Text>
              )}
            </Flex>
          ) : (
            <>
              {sortedFileList.map((file, index) => (
                <Flex
                  key={file}
                  align="center"
                  justify="space-between"
                  gap="xs"
                  onClick={() => {
                    if (readonly || disabled) return;

                    onSelect?.(file);
                  }}
                  sx={(theme) => ({
                    padding: '4px 8px',
                    borderRadius: 4,
                    cursor: readonly || disabled ? 'initial' : 'pointer',
                    transition: 'all 0.1s ease-in-out',
                    '&:hover': {
                      background: theme.colors.gray[1],
                    },
                    ...(index === 0 && { marginTop: 8 }),
                    ...(index === sortedFileList.length - 1 && { marginBottom: 8 }),
                  })}
                >
                  <Text fz="0.875rem" color="dark.4" sx={{ width: '100%' }}>
                    <Highlight
                      highlight={[query]}
                      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 360 }}
                    >
                      {file}
                    </Highlight>
                  </Text>

                  {!readonly && <>{renderItemActions?.(file) || <IconChevronRight size={12} />}</>}
                </Flex>
              ))}
            </>
          )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export const FileNameSelector = memo(FileNameSelectorBase);
