import { memo, useMemo, useState } from 'react';
import { ActionIcon, Checkbox, Flex, Popover, ScrollArea, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSelect } from '@tabler/icons-react';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { getMonthText } from '@/shared/utils/date';
import { FileSearchResult, SortOrder } from '@/shared/@types';
import { getFileSearchTableOfContents } from '@/pageAI/services/files';

import styles from './FileSearchResultQuickNavigation.module.scss';

export interface FileSearchResultQuickNavigationProps {
  results: FileSearchResult[];
  sortOrder?: SortOrder;
}

const FileSearchResultQuickNavigationBase = ({ results, sortOrder = 'desc' }: FileSearchResultQuickNavigationProps) => {
  const [showMonths, setShowMonths] = useState(true);
  const [opened, { close, toggle }] = useDisclosure();

  const tableOfContents = useMemo(() => getFileSearchTableOfContents(results), [results]);

  const sortedEntries = useMemo(() => {
    let entries = Object.entries(tableOfContents);

    if (sortOrder === 'desc') entries = entries.toReversed();

    return entries;
  }, [tableOfContents, sortOrder]);

  const navigateToResult = (result: FileSearchResult) => {
    const resultElement = document.querySelector(`[data-file-id="${result.file.id}"]`) as HTMLDivElement | null;

    if (!resultElement) return;

    resultElement.scrollIntoView();
    resultElement.classList.add(styles.flash);

    setTimeout(() => {
      resultElement.classList.remove(styles.flash);
    }, 1500);
  };

  const handleSelect = (year: string) => {
    close();

    const result = Object.values(tableOfContents[year]).at(sortOrder === 'desc' ? -1 : 0)?.[0];

    if (!result) return;

    navigateToResult(result);
  };

  const handleSelectMonth = (year: string, month: string) => {
    close();

    const result = tableOfContents[year][month][0];

    if (!result) return;

    navigateToResult(result);
  };

  return (
    <Popover shadow="sm" width={280} position="bottom-start" opened={opened} onClose={close}>
      <Popover.Target>
        <Tooltip label="Quick Navigation" withArrow withinPortal>
          <ActionIcon color="gray.7" onClick={toggle}>
            <IconSelect size={20} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Flex direction="column">
          <Flex align="center" justify="space-between" sx={{ padding: '12px 12px 8px 8px' }}>
            <Text size="sm" fw={500} color="dark">
              Quick Navigation
            </Text>

            <Checkbox
              label="Show months"
              size="xs"
              checked={showMonths}
              onChange={(event) => setShowMonths(event.target.checked)}
            />
          </Flex>

          {results.length ? (
            <ScrollArea h={440} sx={{ padding: '0 4px' }}>
              <Flex direction="column" pb={4}>
                {sortedEntries.map(([year, resultsByYear]) => {
                  let resultsByMonth = Object.entries(resultsByYear);

                  if (sortOrder === 'desc') resultsByMonth = resultsByMonth.toReversed();

                  return (
                    <Flex key={year} direction="column">
                      <MenuItem onClick={() => handleSelect(year)}>
                        <Text fw={500}>{year}</Text>
                      </MenuItem>

                      {showMonths
                        ? resultsByMonth.map(([month, results]) => {
                            return (
                              <Flex key={month} direction="column">
                                <MenuItem onClick={() => handleSelectMonth(year, month)}>
                                  <Flex align="center" gap={4} pl="xs">
                                    <Text>{getMonthText(month)}</Text>

                                    <Text color="dark.3" fz="0.75rem">
                                      ({results.length})
                                    </Text>
                                  </Flex>
                                </MenuItem>
                              </Flex>
                            );
                          })
                        : null}
                    </Flex>
                  );
                })}
              </Flex>
            </ScrollArea>
          ) : (
            <Flex px="xs" py="md" align="center" justify="center">
              <Text fz="0.875rem" fw={600} color="dark.2" align="center">
                No results
              </Text>
            </Flex>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const FileSearchResultQuickNavigation = memo(FileSearchResultQuickNavigationBase);
