import { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import { ActionIcon, Box, Button, Flex, Modal, Switch, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload, IconInfoCircle } from '@tabler/icons-react';

import { DownloadTimelineButtonProps } from './DownloadTimelineButton.types';
import { togglePrinting, useLayoutStore } from '@/shared/states/layout';
import { PrintableCaseTimeline } from '../PrintableCaseTimeline';
import { CaseTimelineTabs } from '../CaseTimelineTabs';
import { CaseTimelineTab } from '@/pageAI/@types/summaries';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { removeAllSpaces } from '@/shared/utils/string';
import { formatDate } from '@/shared/utils/date';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';

const DownloadTimelineButtonBase = ({
  client,
  originalTimeline,
  filteredTimeline,
  sortOrder,
}: DownloadTimelineButtonProps) => {
  const isPrinting = useLayoutStore((state) => state.isPrinting);

  const [progress, setProgress] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [opened, { open, close }] = useDisclosure();
  const [tab, setTab] = useState<CaseTimelineTab>('All');
  const [useCurrentFilters, setUseCurrentFilters] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const [showBookmarkSigns, setShowBookmarkSigns] = useState(true);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const timeline = useCurrentFilters ? filteredTimeline : originalTimeline;
  const clientDisplayName = getClientDisplayName(client);

  const handleExport = () => {
    setProgress(0);
    setIsExporting(true);
    togglePrinting(true);
    close();
  };

  const handleFinish = useCallback(() => {
    setIsExporting(false);

    setTimeout(() => {
      requestIdleCallback(() => {
        window.print();
        togglePrinting(false);
        document.body.classList.remove('printing');
      });
    });
  }, []);

  const handleProgress = useCallback((value: number) => {
    setProgress(value);
  }, []);

  const renderModalContent = () => {
    return (
      <Flex direction="column" gap="md">
        <Flex direction="column">
          <Text fz="0.875rem" fw={500} color="dark" mb={4}>
            Timeline filters
          </Text>

          <Flex direction="column" gap="xs">
            <Switch
              checked={useCurrentFilters}
              onChange={(event) => setUseCurrentFilters(event.currentTarget.checked)}
              label={
                <Flex align="center" gap={4}>
                  <Text>Use current filters</Text>

                  <Tooltip label="Use the filters that are currently applied to the timeline." withinPortal withArrow>
                    <ActionIcon size="xs">
                      <IconInfoCircle size={14} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              }
            />

            <Box>
              <CaseTimelineTabs initialValue={tab} onChange={setTab} disabled={useCurrentFilters} />
            </Box>
          </Flex>

          <Text fz="0.875rem" fw={500} color="dark" mt="xs" mb={4}>
            Export settings
          </Text>

          <Flex direction="column" gap="xs">
            <Switch
              checked={showComments}
              onChange={(event) => setShowComments(event.currentTarget.checked)}
              label="Show comments"
            />

            <Switch
              checked={showBookmarkSigns}
              onChange={(event) => setShowBookmarkSigns(event.currentTarget.checked)}
              label="Show bookmark signs"
            />

            <Switch
              checked={showEventDetails}
              onChange={(event) => setShowEventDetails(event.currentTarget.checked)}
              label="Show event details"
            />
          </Flex>
        </Flex>

        <Flex align="center" justify="end" gap="xs">
          <Button onClick={close} color="gray.8" variant="subtle">
            Close
          </Button>

          <Button color="red" onClick={handleExport} disabled={isExporting}>
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {createPortal(
        <Tooltip label="Export timeline" withinPortal withArrow>
          <ActionIcon onClick={open} color="gray.6">
            <IconDownload size={16} />
          </ActionIcon>
        </Tooltip>,
        document.querySelector('#page-ai-header-right-section') || document.body,
      )}

      {isPrinting
        ? createPortal(
            <Box
              className="printable"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 999999,
                background: 'white',
                overflow: 'visible',
                height: 'fit-content',
                ul: {
                  paddingLeft: 24,
                },
                ...(showComments
                  ? {}
                  : {
                      '.comments': {
                        display: 'none',
                      },
                    }),
                ...(showBookmarkSigns
                  ? {
                      '.extra-action.extra-action--bookmark': {
                        display: 'none',
                        '&.extra-action.extra-action--bookmarked': {
                          display: 'block',
                        },
                      },
                    }
                  : {
                      '.extra-action.extra-action--bookmark': {
                        display: 'none',
                      },
                    }),
              }}
            >
              <PrintableCaseTimeline
                client={client}
                caseTimeline={timeline}
                tab={tab}
                forceShowEventDetails={showEventDetails}
                sortOrder={sortOrder}
                onFinish={handleFinish}
                onProgress={handleProgress}
              />

              {isExporting && (
                <Flex
                  align="center"
                  justify="center"
                  sx={(theme) => ({ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, background: theme.white })}
                >
                  <LoadingPlaceholder
                    title="Exporting timeline..."
                    description={`Please wait while your timeline is being exported (${Math.floor(progress * 100)}%).`}
                  />
                </Flex>
              )}
            </Box>,
            document.body,
          )
        : null}

      {isPrinting && (
        <Helmet>
          <title>
            {removeAllSpaces(clientDisplayName)}_CaseTimeline_
            {formatDate(new Date(), { dateStyle: 'short', timeStyle: 'short', hour12: false }).replace(', ', '_')}
          </title>
        </Helmet>
      )}

      <Modal
        title="Export timeline to PDF"
        opened={opened}
        onClose={close}
        fullScreen={isPrinting}
        sx={{
          ...(isPrinting
            ? {
                display: 'none',
              }
            : {}),
        }}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

export const DownloadTimelineButton = memo(DownloadTimelineButtonBase);
