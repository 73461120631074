import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export type PageName = 'settings' | 'chat' | string;

export const usePageMatch = () => {
  const location = useLocation();

  const isInPage = useCallback(
    (pageName: PageName) => {
      return location.pathname.includes(pageName);
    },
    [location.pathname],
  );

  return { isInPage };
};
