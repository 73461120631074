import { useEffect } from 'react';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { highlightTimelineEventPubsub, scrollToTimelineEvent } from '@/pageAI/services/caseTimeline';
import {
  doesMedicalConditionContainTimelineEvent,
  getMedicalConditionElementId,
} from '@/pageAI/services/medicalConditions';
import { wait } from '@/shared/utils/time';

export const useHighlightTimelineEventInMedicalConditions = (medicalConditions: MedicalConditionItem[]) => {
  useEffect(() => {
    const unsubscribe = highlightTimelineEventPubsub.subscribe(({ event, shouldOpenReference, prefix }) => {
      if (!prefix) return;

      const containingMedicalCondition = medicalConditions.find(
        (medicalCondition) =>
          medicalCondition.headerCondition === prefix &&
          doesMedicalConditionContainTimelineEvent(medicalCondition, event.id),
      );

      if (!containingMedicalCondition) return;

      const medicalConditionElement = document.getElementById(
        getMedicalConditionElementId(containingMedicalCondition),
      ) as HTMLDivElement | undefined;

      if (!medicalConditionElement) return;

      const isMedicalConditionOpen = medicalConditionElement.getAttribute('data-active') === 'true';

      if (!isMedicalConditionOpen) {
        medicalConditionElement.querySelector('button')?.click();
      }

      requestIdleCallback(async () => {
        await wait(200);

        scrollToTimelineEvent(event, shouldOpenReference, true, prefix);
      });
    });

    return unsubscribe;
  }, [medicalConditions]);
};
