import { memo } from 'react';
import { Badge } from '@mantine/core';

import { FileGroupBadgeProps } from './FileGroupBadge.types';
import { TIMELINE_EVENT_TYPE_COLOR_MAPPING } from '@/pageAI/services/caseTimeline';

const FileGroupBadgeBase = ({ fileGroup }: FileGroupBadgeProps) => {
  return (
    <Badge
      sx={(theme) => ({
        backgroundColor:
          fileGroup.color ||
          theme.colors[
            TIMELINE_EVENT_TYPE_COLOR_MAPPING[fileGroup.displayName as keyof typeof TIMELINE_EVENT_TYPE_COLOR_MAPPING]
          ]?.[1] ||
          theme.colors.gray[5],
        color: theme.colors.dark[4],
        fontWeight: 500,
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
      })}
    >
      {fileGroup.displayName}
    </Badge>
  );
};

export const FileGroupBadge = memo(FileGroupBadgeBase);
