import { memo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { IconBug } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';

import { MainLink } from '@/pageAI/components/layout/MainLinks';
import { HelpModal } from '../HelpModal';

const HelpButtonBase = () => {
  const [opened, { open, close }] = useDisclosure();
  useLocation();

  return (
    <>
      <MainLink icon={<IconBug size={24} />} label="Report problem/feedback" onClick={open} />

      <HelpModal key={window.location.href} opened={opened} onClose={close} />
    </>
  );
};

export const HelpButton = memo(HelpButtonBase);
