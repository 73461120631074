import { createContext, useContext } from 'react';

import { FileSearchContextValues } from './FileSearchContext.types';

export const FileSearchContext = createContext<FileSearchContextValues>({
  fileGroups: [],
  setFileGroups: () => {},
  fileTypes: [],
  setFileTypes: () => {},
});

export const useFileSearchContext = () => {
  return useContext(FileSearchContext) as FileSearchContextValues;
};
