import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getFileFilterValuesFromSearchParams } from '@/pageAI/services/files';
import { FileTab } from '@/pageAI/services/files/files.services.types';

export const useClientFileTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialValues] = useState(() => getFileFilterValuesFromSearchParams(searchParams));

  const [tab, setTabBase] = useState(initialValues.tab || 'All');

  const setTab = useCallback(
    (tab: FileTab) => {
      setTabBase(tab);

      if (tab === 'All') {
        searchParams.delete('fileTab');
        setSearchParams(searchParams, { replace: true });
      } else {
        searchParams.set('fileTab', tab);
        setSearchParams(searchParams, { replace: true });
      }
    },
    [searchParams, setSearchParams],
  );

  return {
    tab,
    setTab,
  };
};
