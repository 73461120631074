import { useCallback, useMemo } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { EventBus } from 'native-pubsub';

import { getItemFromStorage } from '@/shared/lib/storage';

export const PERSISTENT_PDF_SCALE_STORAGE_KEY = 'pdf_scale';

export const persistentPdfScalePubsub = new EventBus<number>();

export const usePersistentPDFScale = () => {
  const defaultValue = useMemo(() => {
    const rawValue = getItemFromStorage(PERSISTENT_PDF_SCALE_STORAGE_KEY);

    let value = Number(rawValue);

    if (Number.isNaN(value)) value = 1;

    return value;
  }, []);

  const [scale, setScaleBase] = useLocalStorage<number>({
    key: PERSISTENT_PDF_SCALE_STORAGE_KEY,
    defaultValue,
  });

  const setScale = useCallback(
    (value: number, skipPubsub = false) => {
      setScaleBase(value);

      if (!skipPubsub) persistentPdfScalePubsub.publish(value);
    },
    [setScaleBase],
  );

  return [scale, setScale] as const;
};
