import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateClientInput, CreateClientMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { createClientMutation } from '@/pageAI/api';

export const useCreateClient = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const { mutateAsync: createClient, isLoading } = useMutation<CreateClientMutation, Error, CreateClientInput>({
    mutationFn: async (input) => {
      return request(createClientMutation, { input });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['clients']);
    },
  });

  return { createClient, isLoading };
};
