import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { searchFilesQuery } from '@/pageAI/api';
import { normalizeFile } from '@/shared/services/files';

export const useSearchClientFiles = (clientId: string, query = '') => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isFetching, isError } = useQuery(
    ['client-file-search', clientId, query],
    async () => {
      if (!query) return { nodes: [], totalCount: 0, pageInfo: {} };

      const response = await request(searchFilesQuery, { clientIds: [clientId], query });

      return response.searchFiles;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const normalizedResults = useMemo(
    () =>
      data
        ? data.nodes
            .map((node) => ({
              ...node,
              file: normalizeFile(node.file),
            }))
            .toSorted((resultA, resultB) => (resultA.score < resultB.score ? 1 : -1))
        : undefined,
    [data],
  );

  return {
    results: normalizedResults,
    totalCount: data?.totalCount,
    pageInfo: data?.pageInfo,
    isLoading: isLoading || isFetching,
    isError,
  };
};
