import { memo, useCallback, useMemo, useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { ActionIcon, Box, Flex, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconChevronsRight, IconMessage2, IconSortAscending2, IconSortDescending2 } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

import { TimelineCommentsSidebarProps } from './TimelineCommentsSidebar.types';
import { Panel } from '@/pageAI/designSystem/Panel';
import { TimelineEventCommentSection } from '@/pageAI/components/comments/TimelineEventCommentSection';
import { highlightTimelineEventPubsub, sortCaseTimeline } from '@/pageAI/services/caseTimeline';
import { SortOrder } from '@/shared/@types';
import { CommentSidebarEmptyPlaceholder } from '../CommentSidebarEmptyPlaceholder';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { TimelineEventSummary } from '../../timeline/TimelineEventSummary';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

const TimelineCommentsSidebarBase = ({ timeline }: TimelineCommentsSidebarProps) => {
  const theme = useMantineTheme();

  const client = useSelectedClient();
  const [opened, { toggle, close }] = useDisclosure();
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [activeEventId, setActiveEventId] = useState<string | null>(null);

  const eventsWithComments = useMemo(
    () =>
      sortCaseTimeline(
        timeline.filter((event) => event.comments && event.comments.length > 0),
        sortOrder,
      ),
    [timeline, sortOrder],
  );

  const handleChangeSortOrder = useCallback(() => {
    setSortOrder((currentSortOrder) => (currentSortOrder === 'asc' ? 'desc' : 'asc'));
  }, []);

  const handleHighlightEvent = useCallback((event: CaseTimelineEvent) => {
    highlightTimelineEventPubsub.publish({ event });
    setActiveEventId(event.id);
  }, []);

  return (
    <Panel
      sx={{
        padding: 0,
        marginTop: 16,
        height: '100%',
        width: 360,
        borderLeft: `1px solid ${theme.colors.gray[2]}`,
        borderTop: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: 0,
        borderTopLeftRadius: 4,
        gap: 0,
        background: theme.fn.lighten(theme.colors.gray[0], 0.5),
        transition: 'all 0.1s ease-in-out',
        ...(opened
          ? {
              marginRight: 0,
              opacity: 1,
            }
          : {
              marginRight: -360,
              opacity: 0,
              pointerEvents: 'none',
            }),
      }}
    >
      <Flex align="center" justify="space-between" p="sm" pb="xs" bg="gray.0" className="where-on-earth">
        <Flex align="center" gap={6}>
          <Text fw={600} color="dark.4">
            Comments
          </Text>

          <Tooltip
            label={sortOrder === 'desc' ? 'Sorting latest to oldest' : 'Sorting oldest to latest'}
            withinPortal
            withArrow
          >
            <ActionIcon onClick={handleChangeSortOrder} size="xs">
              {sortOrder === 'desc' ? <IconSortDescending2 size={16} /> : <IconSortAscending2 size={16} />}
            </ActionIcon>
          </Tooltip>
        </Flex>

        <Tooltip label="Collapse" withArrow withinPortal>
          <ActionIcon size="xs" color="gray" variant="subtle" onClick={close}>
            <IconChevronsRight size={16} />
          </ActionIcon>
        </Tooltip>
      </Flex>

      {eventsWithComments.length > 0 ? (
        <Flex direction="column">
          {eventsWithComments.map((event, eventIndex) => {
            const isActive = activeEventId === event.id;

            return (
              <Fragment key={event.id}>
                <Flex
                  direction="column"
                  onClick={() => handleHighlightEvent(event)}
                  p="sm"
                  pb="xs"
                  sx={(theme) => ({
                    position: 'relative',
                    p: { margin: 0 },
                    ...(isActive
                      ? {
                          '.overlay': {
                            background: theme.colors.gray[2],
                          },
                        }
                      : {}),
                    '&:hover': {
                      '.overlay': {
                        background: theme.colors.gray[2],
                      },
                    },
                  })}
                >
                  <Box
                    className="overlay"
                    sx={(theme) => ({
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                      cursor: 'pointer',
                      zIndex: 1,
                      background: theme.colors.gray[0],
                      transition: 'all 0.2s ease-in-out',
                    })}
                  />

                  <Flex
                    onClick={() => handleHighlightEvent(event)}
                    sx={(theme) => ({
                      borderLeft: `2px solid ${theme.colors.yellow[5]}`,
                      padding: '4px 6px',
                      background: theme.colors.gray[1],
                      borderRadius: 2,
                      zIndex: 2,
                      cursor: 'pointer',
                    })}
                  >
                    <Text
                      fz="0.75rem"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        'p:first-of-type': {
                          display: 'inline',
                        },
                      }}
                    >
                      <strong>{event.date}</strong>:{' '}
                      <TimelineEventSummary event={event} sx={{ whiteSpace: 'nowrap' }} />
                    </Text>
                  </Flex>

                  <Box
                    sx={{
                      zIndex: 2,
                      '.comment-container': {
                        background: theme.colors.gray[1],
                      },
                    }}
                  >
                    {client && (
                      <TimelineEventCommentSection opened={isActive} compact={false} client={client} event={event} />
                    )}
                  </Box>
                </Flex>

                {eventIndex < eventsWithComments.length - 1 && (
                  <Box sx={(theme) => ({ height: 1, background: theme.colors.gray[3] })} />
                )}
              </Fragment>
            );
          })}
        </Flex>
      ) : (
        <CommentSidebarEmptyPlaceholder />
      )}

      {createPortal(
        <Tooltip label="Show comments" withinPortal withArrow>
          <ActionIcon variant="subtle" ml={4} onClick={toggle} color="gray.6">
            <IconMessage2 size={16} />
          </ActionIcon>
        </Tooltip>,
        document.querySelector('#page-ai-header-right-section') || document.body,
      )}
    </Panel>
  );
};

export const TimelineCommentsSidebar = memo(TimelineCommentsSidebarBase);
