import { useMutation } from '@tanstack/react-query';

import { sendMessageMutation } from '@/pageAI/api';
import { SendMessageInput, SendMessageMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useSendMessage = () => {
  const { request } = useGraphQLRequest();

  const { mutate: sendMessage, isLoading } = useMutation<SendMessageMutation, Error, SendMessageInput>({
    mutationFn: async (input) => request(sendMessageMutation, { input }),
  });

  return { sendMessage, isLoading };
};
