import { graphql } from '@/pageAI/gql';

export const createCommentMutation = graphql(/* GraphQL */ `
  mutation CreateComment($input: CreateClientTimelineEventCommentInput!) {
    createClientTimelineEventComment(input: $input) {
      id
      content
      createdAt
      updatedAt
      viewerCanDelete
      viewerCanUpdate
      viewerDidAuthor
      author {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
    }
  }
`);

export const updateCommentMutation = graphql(/* GraphQL */ `
  mutation UpdateComment($input: UpdateClientTimelineEventCommentInput!) {
    updateClientTimelineEventComment(input: $input) {
      id
      content
      createdAt
      updatedAt
      viewerCanDelete
      viewerCanUpdate
      viewerDidAuthor
      author {
        id
        firstName
        lastName
        email
        avatarUrl
        phone
        role
        createdAt
        updatedAt
      }
    }
  }
`);

export const deleteCommentMutation = graphql(/* GraphQL */ `
  mutation DeleteComment($input: DeleteClientTimelineEventCommentInput!) {
    deleteClientTimelineEventComment(input: $input) {
      deletedCommentId
    }
  }
`);

export const getTimelineCommentOperationSubscriptionQuery = () => {
  return `subscription TimelineCommentOperationSubscription {
    event {
      ... on ClientTimelineEventCommentCreated {
        __typename
        comment {
          id
          content
          createdAt
          updatedAt
          event {
            id
            client {
              id
            }
          }
          author {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          viewerCanDelete
          viewerCanUpdate
          viewerDidAuthor
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
      ... on ClientTimelineEventCommentUpdated {
        __typename
        comment {
          id
          content
          createdAt
          updatedAt
          event {
            id
            client {
              id
            }
          }
          author {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
          viewerCanDelete
          viewerCanUpdate
          viewerDidAuthor
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
      ... on ClientTimelineEventCommentDeleted {
        __typename
        deletedCommentId
        event {
          id
          client {
            id
          }
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
    }
  }`;
};
