import { memo, useEffect, useState } from 'react';
import { ActionIcon, Button, Flex, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EventBus } from 'native-pubsub';

import { FileGroupManagement, FileGroupManagementMode } from '../FileGroupManagement';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { IconArrowLeft } from '@tabler/icons-react';

export const toggleFileGroupManagementModalEventBus = new EventBus<{ fileNames?: string[] }>();

const FileGroupManagementModalBase = () => {
  const [mode, setMode] = useState<FileGroupManagementMode>('View');
  const [fileNamesToSave, setFileNamesToSave] = useState<string[]>([]);
  const [opened, { open, close }] = useDisclosure();
  const { currentUser } = useCurrentUser();
  const organization = currentUser?.organizations?.nodes?.[0];

  useEffect(() => {
    const unsubscribe = toggleFileGroupManagementModalEventBus.subscribe(({ fileNames }) => {
      open();
      setMode('View');
      setFileNamesToSave([]);

      if (fileNames) {
        setFileNamesToSave(fileNames);
        setMode('Create');
      }
    });

    return unsubscribe;
  }, [open]);

  if (!organization) return null;

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        mode === 'View' ? (
          'Customize Filters'
        ) : (
          <Flex align="center" gap={4}>
            <ActionIcon
              color="gray.8"
              size="xs"
              onClick={() => {
                setMode('View');
              }}
            >
              <IconArrowLeft size={16} />
            </ActionIcon>

            {mode === 'Create' ? 'Create custom filter' : 'Edit custom filter'}
          </Flex>
        )
      }
      zIndex={301}
      withinPortal
      closeOnClickOutside
      size={mode === 'View' ? 480 : '100%'}
    >
      <Flex direction="column" gap="md">
        <FileGroupManagement
          organizationId={organization.id}
          fileNamesToSave={fileNamesToSave}
          mode={mode}
          onModeChange={setMode}
          onClose={close}
        />

        {mode === 'View' && (
          <Flex align="center" justify="end">
            <Button variant="subtle" color="gray.7" onClick={close}>
              Close
            </Button>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export const FileGroupManagementModal = memo(FileGroupManagementModalBase);
