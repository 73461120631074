import { useMutation } from '@tanstack/react-query';

import { CreateInvitationInput, CreateInvitationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { createInvitationMutation } from '@/pageAI/api';

export const useGenerateInvitaitonLink = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const { mutateAsync: generateInvitationLink, isLoading } = useMutation<
    CreateInvitationMutation,
    Error,
    CreateInvitationInput
  >({
    mutationFn: async (input) => request(createInvitationMutation, { input }),
    onError: () => {
      notify('Error', 'Failed to generate invitation link');
    },
  });

  return { generateInvitationLink, isLoading };
};
