import { memo } from 'react';
import { Button, Flex } from '@mantine/core';
import { IconReload } from '@tabler/icons-react';

import { ServiceUnavailablePlaceholderProps } from './ServiceUnavailablePlaceholder.types';
import { ErrorPlaceholder } from '../ErrorPlaceholder';

import SkyStarsIllustration from '@/shared/icons/sky-stars.svg?react';

const ServiceUnavailablePlaceholderBase = (props: ServiceUnavailablePlaceholderProps) => {
  return (
    <Flex
      align="center"
      justify="center"
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        'h1.ghost-Text-root': {
          marginTop: 16,
          fontSize: '2rem',
          color: theme.colors.orange[6],
        },
      })}
    >
      <ErrorPlaceholder
        illustration={<SkyStarsIllustration height={400} />}
        title="Service Unavailable"
        description="Page is under maintenance at the moment. Please try again later."
        action={
          <Button
            leftIcon={<IconReload size={20} />}
            mt="xs"
            color="orange.6"
            onClick={() => (window.location.href = window.location.origin)}
          >
            Refresh Page
          </Button>
        }
      />
    </Flex>
  );
};

export const ServiceUnavailablePlaceholder = memo(ServiceUnavailablePlaceholderBase);
