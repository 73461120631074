import { memo } from 'react';
import { Box } from '@mantine/core';

import { BlinkingCursorProps } from './BlinkingCursor.types';

import styles from './BlinkingCursor.module.scss';

const BlinkingCursorBase: React.FC<BlinkingCursorProps> = ({ sx }) => {
  return <Box className={styles.blinking} sx={{ display: 'inline-block', height: 16, width: 1.5, ...sx }} />;
};

export const BlinkingCursor = memo(BlinkingCursorBase);
