import { Highlight, HighlightedPart, PageInfo } from './TextPreviewer.types';
import { HighlightType, highlightKeywordsInMarkdown } from '@/shared/utils/markdown';

export function divideTextIntoPages(text: string): PageInfo[] {
  const pages: PageInfo[] = [];
  const pageRegex = /\[page (\d+)]([\s\S]*?)\[end page \1]/g;
  let match: RegExpExecArray | null;

  while ((match = pageRegex.exec(text)) !== null) {
    const page = parseInt(match[1], 10);
    const content = match[2].trim();
    const startIndex = match.index;
    const endIndex = pageRegex.lastIndex - 1; // Subtract 1 to not include the last index of "[end page x]"

    pages.push({
      page,
      startIndex,
      endIndex,
      content,
    });
  }

  return pages;
}

export const findPageIndexOfHighlightedPart = (pages: PageInfo[], highlightedPart: HighlightedPart) => {
  for (const pageInfo of pages) {
    // Check if the reference indices fall within the current page's range
    if (highlightedPart.startIndex >= pageInfo.startIndex) {
      if (highlightedPart.endIndex <= pageInfo.endIndex) return pageInfo.page - 1;

      if (!pages[pageInfo.page] || highlightedPart.endIndex <= pages[pageInfo.page].endIndex) return pageInfo.page;
    } else {
      return pageInfo.page - 1;
    }
  }

  return -1;
};

export const getHighlightsFromKeywords = (
  content: string,
  keywords: string[],
  highlightType = HighlightType.DEFAULT,
  matchWholeWords = false,
) => {
  const highlights: Highlight[] = [];

  const replacedContent = highlightKeywordsInMarkdown(
    content,
    keywords,
    highlightType,
    (keyword, match, startIndex, endIndex) => {
      highlights.push({
        keyword,
        startIndex,
        endIndex,
      });
    },
    matchWholeWords,
  );

  return { highlights, replacedContent };
};

export const addHighlightedPartMarkers = (
  pageInfo: PageInfo,
  highlightedPart: HighlightedPart,
  highlights?: Highlight[],
) => {
  const pageStartTextLength = `[page ${pageInfo.page}] `.length;
  const localStartIndex = highlightedPart.startIndex - pageInfo.startIndex - pageStartTextLength;
  const localEndIndex = highlightedPart.endIndex - pageInfo.startIndex - pageStartTextLength;

  if (
    localStartIndex === localEndIndex ||
    (localEndIndex > 0 && localStartIndex > 0 && localEndIndex - localStartIndex > pageInfo.content.length)
  )
    return pageInfo;

  let referenceContent = pageInfo.content.substring(localStartIndex, localEndIndex + 1);

  if (highlights) {
    const highlightedKeywords = highlights.map((highlight) => highlight.keyword);

    const { replacedContent } = getHighlightsFromKeywords(referenceContent, highlightedKeywords);

    referenceContent = replacedContent;
  }

  const newContent =
    pageInfo.content.substring(0, localStartIndex) +
    '\n\n > ***[Reference starts]*** \n\n ' +
    referenceContent +
    '\n\n > ***[Reference ends]*** \n\n' +
    pageInfo.content.substring(localEndIndex + 1);

  // Insert the reference markers
  return {
    ...pageInfo,
    content: newContent,
  };
};

export const scrollToHighlightedPart = (highlightedPart?: HighlightedPart) => {
  if (!highlightedPart) return;

  const markdownContainer = document.querySelector('.markdown-container');

  if (!markdownContainer) return;

  const element = markdownContainer.querySelector('blockquote p em strong');

  if (!element) return;

  setTimeout(() => element.scrollIntoView({ block: 'start' }));
};

// TODO: Refactor this
export const scrollToCode = () => {
  const markdownContainer = document.querySelector('.markdown-container');

  if (!markdownContainer) return;

  const element = markdownContainer.querySelector('code');

  if (!element) return;

  setTimeout(() => element.scrollIntoView({ block: 'end' }));
};
