import { memo } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { Text } from '@mantine/core';

import { CommentTimeProps } from './CommentTime.types';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { formatDateTime } from '@/shared/utils/date';

const CommentTimeBase = ({ comment, compact = true }: CommentTimeProps) => {
  const { isPrinting } = useCaseTimelineContext();

  const createdDate = new Date(comment.createdAt);
  const hasBeenEdited = comment.updatedAt !== comment.createdAt;
  const formattedDateTime = formatDateTime(createdDate);

  return (
    <Text
      fz="0.625rem"
      color="dark.3"
      sx={{
        display: 'inline-block',
        transition: 'all 0.1s',
        ...(compact
          ? {
              whiteSpace: 'nowrap',
              maxWidth: 36,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }
          : {}),
      }}
    >
      {isPrinting ? (
        <>{formattedDateTime}</>
      ) : (
        <>
          <ReactTimeAgo date={createdDate} />

          {hasBeenEdited && ' (edited)'}
        </>
      )}
    </Text>
  );
};

export const CommentTime = memo(CommentTimeBase);
