import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Flex, Paper, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';

import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { useOrganizationInvitation } from '@/pageAI/hooks/organizations/useOrganizationInvitation';
import { LogOutButton } from '@/pageAI/components/auth/LogOutButton';
import { useAcceptOrganizationInvitation } from '@/pageAI/hooks/organizations/useAcceptOrganizationInvitation';

export const INVITATION_ID_STORAGE_KEY = 'invitationId';

const AcceptInvitationPageBase = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [invitationId, setInvitationId, removeInvitationId] = useLocalStorage({
    key: INVITATION_ID_STORAGE_KEY,
    defaultValue: searchParams.get('id'),
  });

  const [isEmailMismatch, setIsEmailMismatch] = useState(false);

  const { user, isLoading: isAuthenticating, logout, loginWithRedirect } = useAuth0();
  const { invitation, isLoading: isLoadingInvitation, isError } = useOrganizationInvitation(invitationId);
  const { acceptInvitation, isLoading: isAccepting } = useAcceptOrganizationInvitation();

  const handleAcceptInvitation = useCallback(async () => {
    if (!invitation || !invitationId) return;

    setInvitationId(invitationId);

    if (user) {
      if (user.email !== invitation.email) {
        setIsEmailMismatch(true);

        return removeInvitationId();
      }

      await acceptInvitation({ invitationId });

      removeInvitationId();

      navigate('/user-onboarding?onboarding=true');

      return;
    }

    logout();
    loginWithRedirect({
      screen_hint: 'signup',
      login_hint: invitation.email,
    });
  }, [
    invitationId,
    invitation,
    logout,
    loginWithRedirect,
    user,
    acceptInvitation,
    removeInvitationId,
    setInvitationId,
    navigate,
  ]);

  useEffect(() => {
    if (!invitationId || !invitation || isAuthenticating || !user) return;

    if (user.email !== invitation.email) return;

    handleAcceptInvitation();
  }, [invitationId, invitation, isAuthenticating, user, handleAcceptInvitation]);

  if (isLoadingInvitation || isAuthenticating)
    return (
      <LoadingPlaceholder
        title="Welcome to Page"
        description="Please wait while we redirect you to the right page."
        fullScreen
      />
    );

  if (!invitationId || isError || !invitation)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: '100%' }}>
        <ErrorPlaceholder title="Invalid invitation" description="The invitation has already expired or is invalid." />
      </Flex>
    );

  const organizationName = invitation.organization.name || invitation.organization.website;

  return (
    <Flex align="center" justify="center" sx={(theme) => ({ background: theme.colors.dark[5], height: '100%' })}>
      <Paper p={64} shadow="sm" sx={{ width: 564 }}>
        <Flex direction="column" gap="md">
          <Flex direction="column" align="center">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Join {organizationName} on Page
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              You have been invited to join this organization on Page:
            </Text>
          </Flex>

          <Flex direction="column">
            <Flex align="center" gap={6} justify="center" mb="md">
              <Avatar src={invitation.organization.avatarUrl} radius="50%" />

              <Text color="dark" fw={500}>
                {invitation.organization.name || invitation.organization.website}
              </Text>
            </Flex>

            <Text fz="1rem" color="dark.2" align="center" fw={500} mb="xs">
              If you do not currently have a Page account, you will be prompted to create one with your work email
              before joining your organization.
            </Text>

            <Button
              sx={{ width: '100%' }}
              color="red.6"
              size="md"
              onClick={handleAcceptInvitation}
              loading={isAccepting}
            >
              Accept invitation
            </Button>

            {isEmailMismatch && (
              <Text fz="0.875rem" align="center" color="red" fw={600} mt={4}>
                The invitation is not meant for your email.
              </Text>
            )}
          </Flex>
        </Flex>
      </Paper>

      <Flex align="center" justify="center" pos="fixed" right={12} bottom={12}>
        <LogOutButton />
      </Flex>
    </Flex>
  );
};

export const AcceptInvitationPage = AcceptInvitationPageBase;
