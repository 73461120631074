import { memo } from 'react';
import { Flex, Text } from '@mantine/core';

import { Panel } from '@/pageAI/designSystem/Panel';
import FolderIcon from '@/pageAI/icons/folder.svg?react';
import { ClientFileCtaPlaceholderProps } from './ClientFileCtaPlaceholder.types';

const ClientFileCtaPlaceholderBase = ({ uploadFileButton }: ClientFileCtaPlaceholderProps) => {
  return (
    <Panel sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Flex
        direction="column"
        align="center"
        p="xl"
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: 4,
        })}
      >
        <FolderIcon width={80} height={80} />

        <Flex direction="column" align="center" gap={4}>
          <Text fz="1.25rem" fw={600} color="dark.5" align="center">
            Upload Case Files
          </Text>

          <Text color="dark.2" align="center" mb="xs">
            Start by uploading your client&apos;s case files.
            <br />
            Page will quickly review the documents and generate in-depth summaries and a detailed timeline of events,
            <br />
            which is inclusive of medical records, service history highlights, and previous claims.
          </Text>
        </Flex>

        {uploadFileButton}
      </Flex>
    </Panel>
  );
};

export const ClientFileCtaPlaceholder = memo(ClientFileCtaPlaceholderBase);
