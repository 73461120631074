import { memo, useEffect, useRef } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { TypedSubscriptionClient } from '@/shared/@types';
import { useAccessTokenOnce } from '@/shared/hooks/auth/useAccessTokenOnce';
import { getTimelineOperationSubscriptionQuery } from '@/pageAI/api';
import { WEB_SOCKET_ENDPOINT } from '@/shared/config';
import { useHandleTimelineOperationEvent } from './TimelineOperationWebSocket.utils';
import { TimelineOperationEventPayload } from '@/pageAI/@types/summaries';

const TimelineOperationWebSocketBase = () => {
  const { accessToken } = useAccessTokenOnce();
  const { handleTimelineUpdatedEvent } = useHandleTimelineOperationEvent();

  const handleTimelineUpdatedEventRef = useRef(handleTimelineUpdatedEvent);

  handleTimelineUpdatedEventRef.current = handleTimelineUpdatedEvent;

  const subscriptionClient = useRef<TypedSubscriptionClient | null>(null);

  useEffect(() => {
    if (!accessToken) return;

    const query = getTimelineOperationSubscriptionQuery();

    const observer = {
      next: (payload: TimelineOperationEventPayload) => {
        switch (payload.data.event.__typename) {
          case 'ClientTimelineEventUpdated':
            handleTimelineUpdatedEventRef.current(payload.data.event);
            break;
          default:
        }
      },
      error: (error: Error) => {
        console.error(error);
      },
    };

    if (!subscriptionClient.current) {
      subscriptionClient.current = new SubscriptionClient(WEB_SOCKET_ENDPOINT, {
        reconnect: true,
        connectionParams: {
          token: `${accessToken}`,
        },
      });
    }

    const subscription = subscriptionClient.current.request({ query }).subscribe(observer);

    return () => {
      subscription.unsubscribe();
    };
  }, [accessToken]);

  useEffect(() => {
    return () => {
      subscriptionClient.current?.close();
    };
  }, []);

  return null;
};

export const TimelineOperationWebSocket = memo(TimelineOperationWebSocketBase);
