import { useMutation } from '@tanstack/react-query';

import { ResponseError } from '@/shared/@types';
import { ocrFilesMutation } from '@/pageAI/api';
import { OcrFilesInput, OcrFilesMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useOcrFiles = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync: ocrFiles, isLoading } = useMutation<OcrFilesMutation, ResponseError, OcrFilesInput>({
    mutationFn: async (variables) => {
      return request(ocrFilesMutation, { input: variables });
    },
  });

  return { ocrFiles, isLoading };
};
