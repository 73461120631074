import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DeleteClientMutation, DeleteClientMutationVariables } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { deleteClientMutation } from '@/pageAI/api';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useDeleteClient = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteClientBase, isLoading } = useMutation<
    DeleteClientMutation,
    Error,
    DeleteClientMutationVariables
  >({
    mutationFn: async ({ id }) => {
      const response = await request(deleteClientMutation, { id });

      await queryClient.invalidateQueries(['clients']);

      return response;
    },
  });

  const deleteClient = useCallback(
    async (id: string) => {
      try {
        await deleteClientBase({ id });

        notify('Success', 'Client archived', 'brand');
      } catch (error) {
        notify('Error', 'Failed to archive client');
      }
    },
    [deleteClientBase, notify],
  );

  return { deleteClient, isLoading };
};
