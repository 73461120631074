import { memo, useCallback } from 'react';
import { Flex, Box } from '@mantine/core';

import { MedicalConditionSidebar } from '../../medicalConditions/MedicalConditionSidebar';
import { CopyMedicalConditionLinkButton } from '../../medicalConditions/CopyMedicalConditionLinkButton';
import { toggleConditionSidebar, useUnifiedStore } from '@/pageAI/states/unified';
import { isSidebarOpenedSelector, selectedConditionSelector } from '@/pageAI/states/unified';
import { UnifiedTimeline } from '../UnifiedTimeline';
import { UnifiedContentSearch } from '../UnifiedContentSearch';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { UnifiedFileIndex } from '../UnifiedFileIndex';
import { UnifiedConditionSummary } from '../UnifiedConditionSummary';
import { useUnifiedTab } from '@/pageAI/hooks/unified/useUnifiedTab';

const UnifiedLayoutBase = () => {
  const [tab] = useUnifiedTab();

  const isSidebarOpened = useUnifiedStore(isSidebarOpenedSelector);
  const selectedCondition = useUnifiedStore(selectedConditionSelector);
  const selectedConditionId = selectedCondition?.id;

  const handleCloseSidebar = useCallback(() => {
    toggleConditionSidebar(false);
  }, []);

  const renderContent = () => {
    if (tab === UnifiedTab.CONTENT_SEARCH) {
      return <UnifiedContentSearch />;
    }

    if (tab === UnifiedTab.TIMELINE) {
      return <UnifiedTimeline />;
    }

    if (tab === UnifiedTab.FILE_INDEX) {
      return <UnifiedFileIndex />;
    }

    return <UnifiedConditionSummary />;
  };

  return (
    <Flex>
      <MedicalConditionSidebar opened={isSidebarOpened} onClose={handleCloseSidebar} />

      <CopyMedicalConditionLinkButton selectedMedicalConditionId={selectedConditionId} />

      <Box pb="md" pos="relative" sx={{ flex: '1 1', width: isSidebarOpened ? 'calc(100% - 320px)' : '100%' }}>
        {renderContent()}
      </Box>
    </Flex>
  );
};

export const UnifiedLayout = memo(UnifiedLayoutBase);
