import { memo } from 'react';
import { Flex } from '@mantine/core';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';

const BotMessageAvatarBase = () => {
  return (
    <Flex sx={{ minWidth: 32, minHeight: 32, alignSelf: 'start' }}>
      <PageLogoCircle width={32} height={32} />
    </Flex>
  );
};

export const BotMessageAvatar = memo(BotMessageAvatarBase);
