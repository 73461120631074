import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';

import { updateCommentMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateTimelineEvent } from '../useUpdateTimelineEvent';
import { Client } from '@/pageAI/@types';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { UpdateClientTimelineEventCommentInput, UpdateCommentMutation } from '@/pageAI/gql/graphql';

export const useUpdateComment = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();
  const { currentUser } = useCurrentUser();

  const { mutateAsync: updateCommentBase, isLoading } = useMutation<
    UpdateCommentMutation,
    Error,
    UpdateClientTimelineEventCommentInput
  >({
    mutationFn: async (input) => {
      return request(updateCommentMutation, { input });
    },
  });

  const updateCommentLocally = useCallback(
    (
      client: Client,
      timelineEvent: CaseTimelineEvent,
      input: Partial<UpdateClientTimelineEventCommentInput> & { updatedAt?: Date; shouldFlash?: boolean },
    ) => {
      const updatedComments = timelineEvent.comments.map((comment) => {
        if (comment && comment.id === input.id) {
          return {
            ...comment,
            ...(input.content ? { content: input.content } : {}),
            updatedAt: input.updatedAt || new Date(),
            shouldFlash: input.shouldFlash,
          };
        }

        return comment;
      });

      const reverse = updateTimelineEventLocally(client, {
        ...timelineEvent,
        comments: updatedComments,
      });

      return reverse;
    },
    [updateTimelineEventLocally],
  );

  const updateComment = useCallback(
    async (client: Client, timelineEvent: CaseTimelineEvent, input: UpdateClientTimelineEventCommentInput) => {
      if (!currentUser) return;

      const reverse = updateCommentLocally(client, timelineEvent, input);

      try {
        await updateCommentBase(input);
      } catch (error) {
        // TODO: This doesn't seem to work (?)
        reverse();

        notify('Error', 'Failed to update comment');
      }
    },
    [updateCommentBase, notify, updateCommentLocally, currentUser],
  );

  return { updateComment, updateCommentLocally, isLoading };
};
