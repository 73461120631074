import { memo, useEffect, useRef } from 'react';
import { Text } from '@mantine/core';

import { RichTextInputProps } from './RichTextInput.types';

const RichTextInputBase: React.FC<RichTextInputProps> = ({
  textValue,
  sx,
  disabled,
  placeholder = 'Enter some text...',
  onChange,
  onEnter,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleRichTextInputChange: React.FormEventHandler<HTMLDivElement> = (event) => {
    const element = event.currentTarget;

    onChange?.(element.innerText, element.innerHTML, element);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      onEnter?.();
    }
  };

  useEffect(() => {
    if (ref.current && typeof textValue !== 'undefined' && textValue !== ref.current.innerText) {
      ref.current.innerText = textValue || '';
    }
  }, [textValue]);

  return (
    <Text
      ref={ref}
      color="dark"
      contentEditable={!disabled}
      display="block"
      fz="0.875rem"
      placeholder={placeholder}
      sx={{
        background: 'white',
        width: '100%',
        outline: 'none !important',
        minHeight: 48,
        ...sx,
      }}
      onInput={handleRichTextInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export const RichTextInput = memo(RichTextInputBase);
