import { memo, useMemo } from 'react';
import { Alert, Flex, Text } from '@mantine/core';
import { IconAlertCircle, IconAlertTriangle } from '@tabler/icons-react';

import { ClientAlertProps } from './ClientAlert.types';
import { useFileUploadStatusForClient } from '@/pageAI/hooks/files/useUploadFileStatusForClient';
import { useEnsureTimelineGeneration } from '@/pageAI/hooks/caseTimeline/useEnsureTimelineGeneration';
import { FileUploadStatus } from '@/shared/@types';
import { getClientFiles } from '@/pageAI/services/clients';
import { IgnoreClientAlertButton } from '../IgnoreClientAlertButton';

const ClientAlertBase = ({ client }: ClientAlertProps) => {
  const { status: uploadStatus } = useFileUploadStatusForClient(client.id);
  const { hasScheduled, expectedNumberOfFiles } = useEnsureTimelineGeneration(client);
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);

  if (uploadStatus === FileUploadStatus.UPLOADING) {
    return (
      <Alert
        icon={<IconAlertTriangle />}
        variant="light"
        color="orange"
        sx={(theme) => ({ border: `1px solid ${theme.colors.orange[5]}` })}
      >
        <Text fz="0.875rem" color="dark">
          {hasScheduled ? (
            <>
              Please <strong>do not close or refresh Page</strong> while the client&apos;s files are being uploaded. The
              timeline will be generated once your upload is complete.
            </>
          ) : (
            <>
              Please <strong>do not close or refresh Page</strong> until your upload is complete.
            </>
          )}
        </Text>
      </Alert>
    );
  }

  if (fileAssets && fileAssets.length < expectedNumberOfFiles) {
    return (
      <Alert
        icon={<IconAlertCircle />}
        variant="light"
        color="red"
        sx={(theme) => ({ border: `1px solid ${theme.colors.red[5]}` })}
      >
        <Flex justify="space-between">
          <Text fz="0.875rem" color="dark">
            It seems like some of the client&apos;s files are missing. Please ensure that you have uploaded all the
            relevant files.{hasScheduled ? ' The timeline generation process will not start until then.' : ''}
          </Text>

          <IgnoreClientAlertButton client={client} />
        </Flex>
      </Alert>
    );
  }

  return null;
};

export const ClientAlert = memo(ClientAlertBase);
