import { memo, useEffect } from 'react';
import { SegmentedControl } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { ResearchConversationSidebarProps } from './ResearchConversationSidebar.types';
import { ConversationSidebar } from '@/shared/components/chat/ConversationSidebar';
import { closeLeftSidebar } from '@/shared/states/layout';
import { useResearchType } from '@/pageAI/hooks/research/useResearchType';
import { ResearchType } from '@/pageAI/@types';
import { MessageRetrieverType } from '@/pageAI/gql/graphql';

const ResearchConversationSidebarBase = (props: ResearchConversationSidebarProps) => {
  const location = useLocation();
  const [researchType, setResearchType] = useResearchType();

  useEffect(() => {
    if (!location.pathname.includes('/chat') && !location.pathname.includes('/research')) closeLeftSidebar();
  }, [location.pathname]);

  const topAction = (
    <SegmentedControl
      fullWidth
      sx={(theme) => ({ background: theme.colors.gray[3] })}
      value={researchType}
      data={[
        { label: 'Research', value: ResearchType.RESEARCH },
        { label: 'Client Q&A', value: ResearchType.INVESTIGATE },
      ]}
      onChange={(value) => setResearchType(value as ResearchType)}
    />
  );

  return (
    <ConversationSidebar
      topAction={topAction}
      width={320}
      conversationType={
        researchType === ResearchType.INVESTIGATE ? MessageRetrieverType.Docs : MessageRetrieverType.Web
      }
    />
  );
};

export const ResearchConversationSidebar = memo(ResearchConversationSidebarBase);
