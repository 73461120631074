import { useEffect } from 'react';
import { Virtualizer } from '@tanstack/react-virtual';

import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import {
  getTimelineEventElementId,
  highlightTimelineEventPubsub,
  scrollToTimelineEvent,
} from '@/pageAI/services/caseTimeline';
import { isInViewport } from '@/shared/lib/dom';
import { useKeyPress } from 'ahooks';
import { useTimelineReferenceHighlightStore } from '@/pageAI/states/timelineReferenceHighlights';
import { keys } from '@/shared/utils/object';

export const useHighlightTimelineEvent = (
  caseTimelineEventsByYear: [string, Record<string, CaseTimelineEvent[]>][],
  virtualizer?: Virtualizer<HTMLDivElement, Element>,
) => {
  useEffect(() => {
    if (!virtualizer) return;

    const unsubscribe = highlightTimelineEventPubsub.subscribe(({ event, shouldOpenReference, prefix }) => {
      if (prefix) return;

      const timelineEventElement = document.getElementById(getTimelineEventElementId(event.id, prefix));

      const isVisible = timelineEventElement && isInViewport(timelineEventElement);

      if (!isVisible && virtualizer) {
        const virtualizerIndex = caseTimelineEventsByYear.findIndex(([_, eventsByDate]) => {
          return Object.values(eventsByDate).find((events) => events.find((e) => e.id === event.id));
        });

        if (virtualizerIndex > -1) {
          virtualizer.scrollToIndex(virtualizerIndex, { align: 'center' });
        }
      }

      scrollToTimelineEvent(event, shouldOpenReference, true, prefix);
    });

    return unsubscribe;
  }, [caseTimelineEventsByYear, virtualizer]);
};

export const useTimelineKeyboardShortcuts = (sortedCaseTimeline: CaseTimelineEvent[]) => {
  const highlightedEventMapping = useTimelineReferenceHighlightStore((state) => state.highlightedEventMapping);
  const firstHighlightedEventId = keys(highlightedEventMapping)[0];

  const navigateToNext = (isNext: boolean) => {
    const highlightedEventIndex = sortedCaseTimeline.findIndex((event) => event.id === firstHighlightedEventId);

    if (highlightedEventIndex === -1) return;

    const nextEvent = sortedCaseTimeline[highlightedEventIndex + (isNext ? 1 : -1)];

    if (!nextEvent) return;

    highlightTimelineEventPubsub.publish({ event: nextEvent, shouldOpenReference: true, hideHighlightEffect: true });
  };

  useKeyPress(['shift.uparrow'], () => navigateToNext(false));
  useKeyPress(['shift.downarrow'], () => navigateToNext(true));
};
