import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text, UnstyledButton } from '@mantine/core';

import { ConversationGroupProps } from './ConversationGroup.types';
import { useAppNavigate } from '@/shared/hooks/routes/useAppNavigate';

const ConversationGroupBase: React.FC<ConversationGroupProps> = ({ conversations, title = 'Conversations' }) => {
  const { conversationId } = useParams();
  const { navigateToChat } = useAppNavigate();

  return (
    <Flex direction="column" gap={4}>
      <Text color="dark.2" fz="0.75rem" fw={700}>
        {title}
      </Text>

      {conversations.length > 0 ? (
        <Flex direction="column">
          {conversations.map((conversation) => {
            const isActive = conversation.id === conversationId;

            return (
              <UnstyledButton
                key={conversation.id}
                sx={(theme) => ({
                  marginLeft: -4,
                  padding: '8px 4px',
                  fontSize: '0.875rem',
                  color: theme.colors.dark[3],
                  borderRadius: 4,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  ...(isActive
                    ? {
                        backgroundColor: theme.colors.gray[3],
                        fontWeight: 600,
                      }
                    : {
                        fontWeight: 500,
                        '&:hover': { backgroundColor: theme.colors.gray[2] },
                      }),
                })}
                onClick={() => navigateToChat(conversation.id)}
              >
                {conversation.name}
              </UnstyledButton>
            );
          })}
        </Flex>
      ) : (
        <Text color="dark.3" fz="0.875rem" fw={500}>
          No conversations
        </Text>
      )}
    </Flex>
  );
};

export const ConversationGroup = memo(ConversationGroupBase);
