import { useEffect, useRef } from 'react';

export const useResizeObserver = (
  callback: (contentRect: DOMRectReadOnly) => void,
  customRef?: React.RefObject<HTMLDivElement>,
) => {
  const internalRef = useRef<HTMLDivElement | null>(null);
  const ref = customRef || internalRef;

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserverCallback: ResizeObserverCallback = (entries) => {
      const element = entries[0].target;

      callback(element.getBoundingClientRect());
    };

    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current, callback]);

  return [ref] as const;
};
