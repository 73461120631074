import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionIcon, Button, Flex, Modal, Popover, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';

import { InteractableMemberRoleBadgeProps } from './InteractableMemberRoleBadge.types';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { useUpdateOrganizationMemberRole } from '@/pageAI/hooks/organizations/useUpdateOrganizationMemberRole';
import { OrganizationMemberRole } from '@/pageAI/gql/graphql';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { MemberRoleBadge } from '../MemberRoleBadge';

const ROLE_OPTIONS = [
  {
    role: OrganizationMemberRole.Owner,
    title: 'Owner',
    tooltip: 'Full management of your organization',
  },
  {
    role: OrganizationMemberRole.Admin,
    title: 'Admin',
    tooltip: 'Full management of your organization, except billing',
  },
  {
    role: OrganizationMemberRole.Member,
    title: 'Member',
    tooltip: 'Use all features, cannot modify membership or manage billing',
  },
];

const InteractableMemberRoleBadgeBase = ({ member, canUpdate }: InteractableMemberRoleBadgeProps) => {
  const { organizationId } = useParams();
  const { notify } = useNotifications();
  const { updateMemberRole, isLoading } = useUpdateOrganizationMemberRole();

  const [opened, { open, close }] = useDisclosure();
  const [selectedRole, setSelectedRole] = useState<OrganizationMemberRole>();

  const handleSelectMemberRole = (newRole: OrganizationMemberRole) => () => {
    setSelectedRole(newRole);
    open();
  };

  const handleChangeMemberRole = async () => {
    if (!selectedRole || selectedRole === member.role) {
      close();

      return;
    }

    await updateMemberRole({ organizationId, userId: member.user.id, role: selectedRole });

    notify('Success', 'Member role has been updated', 'brand');

    close();
  };

  const badge = <MemberRoleBadge role={member.role} />;

  if (!canUpdate) return badge;

  return (
    <>
      <Popover position="bottom-start" shadow="sm">
        <Popover.Target>{badge}</Popover.Target>

        <Popover.Dropdown p={4}>
          <Flex direction="column">
            <Flex align="center" justify="space-between" pl={12} pr={4} pt="xs" pb={4} gap="xs">
              <Text fz="0.75rem" color="gray.6" fw={500}>
                Select a role
              </Text>

              <Tooltip
                withArrow
                label={
                  <>
                    <ul style={{ paddingLeft: 16, margin: '4px 0' }}>
                      <li>Owner: Full management of your organization</li>
                      <li>Admin: Full management of your organization, except billing</li>
                      <li>Member: Use all features, cannot modify membership or manage billing</li>
                    </ul>
                  </>
                }
              >
                <ActionIcon size="xs">
                  <IconInfoCircle size={14} />
                </ActionIcon>
              </Tooltip>
            </Flex>

            <Flex direction="column">
              {ROLE_OPTIONS.map((roleOption) => {
                return (
                  <MenuItem
                    key={roleOption.role}
                    sx={{ fontSize: '0.875rem' }}
                    title={roleOption.title}
                    onClick={handleSelectMemberRole(roleOption.role)}
                  />
                );
              })}
            </Flex>
          </Flex>
        </Popover.Dropdown>
      </Popover>

      <Modal title="Change member role" opened={opened} onClose={close} zIndex={301} size={560}>
        <Flex direction="column" gap="lg">
          <Text fz="0.875rem">
            {(() => {
              if (selectedRole === OrganizationMemberRole.Owner)
                return 'Are you sure you want make this member an owner of your organization?';

              if (selectedRole === OrganizationMemberRole.Admin)
                return 'Are you sure you want make this member an admin of your organization?';

              if (selectedRole === OrganizationMemberRole.Member)
                return 'Are you sure you want change the role of this member?';
            })()}
          </Text>

          <Flex align="center" gap="xs" justify="end">
            <Button variant="subtle" color="gray.7" onClick={close} disabled={isLoading}>
              Cancel
            </Button>

            <Button loading={isLoading} onClick={handleChangeMemberRole}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const InteractableMemberRoleBadge = memo(InteractableMemberRoleBadgeBase);
