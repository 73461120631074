import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssignClientInput, AssignClientMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { assignClientMutation } from '@/pageAI/api';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { useUpdateClient } from '../useUpdateClient';

export const useAssignClient = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();
  const { updateClientLocally } = useUpdateClient();

  const { mutateAsync: assignClientBase, isLoading } = useMutation<AssignClientMutation, Error, AssignClientInput>({
    mutationFn: async (input) => {
      return request(assignClientMutation, { input });
    },
  });

  const assignClient = useCallback(
    async (input: AssignClientInput) => {
      try {
        const responseBody = await assignClientBase(input);

        updateClientLocally({ id: input.clientId, assignee: responseBody.assignClient.assignee });

        await queryClient.invalidateQueries(['clients']);
      } catch (error) {
        notify('Error', 'Failed to assign client');
      }
    },
    [assignClientBase, notify, queryClient, updateClientLocally],
  );

  return { assignClient, isLoading };
};
