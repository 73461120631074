export enum LeftSidebarType {
  CONVERSATIONS = 'CONVERSATIONS',
}

export enum RightSidebarType {
  ASSET = 'ASSET',
  WEB_PREVIEW = 'WEB_PREVIEW',
  MARKDOWN_PREVIEW = 'MARKDOWN_PREVIEW',
}

export interface LayoutState {
  isLeftSidebarOpened: boolean;
  isRightSidebarOpened: boolean;
  isPrinting: boolean;
  leftSidebarType: LeftSidebarType;
  rightSidebarType: RightSidebarType;
  headerRightSection?: React.ReactNode;
}
