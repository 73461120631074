import { useEffect, useState } from 'react';

import { useAccessToken } from '@/shared/hooks/auth/useAccessToken';

export const useAccessTokenOnce = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAccessToken();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      setAccessToken(token);
    })();
  }, [getAccessTokenSilently]);

  return { accessToken };
};
