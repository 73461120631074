import { memo } from 'react';
import { Loader, Select } from '@mantine/core';

import { ClientPermissionRole } from '@/pageAI/gql/graphql';
import { PermissionRoleSelectProps } from './PermissionRoleSelect.types';

const permissionRoleOptions: { value: ClientPermissionRole; label: string }[] = [
  {
    value: ClientPermissionRole.Viewer,
    label: 'Viewer',
  },
  {
    value: ClientPermissionRole.Editor,
    label: 'Editor',
  },
];

const removablePermissionRoleOptions = [
  ...permissionRoleOptions.map((option) => ({ ...option, group: 'Role' })),
  {
    value: 'Revoke',
    label: 'Revoke',
    group: '',
  },
];

const PermissionRoleSelectBase = ({
  value,
  size,
  sx,
  icon,
  clearable = false,
  label,
  disabled,
  loading,
  removable,
  onChange,
}: PermissionRoleSelectProps) => {
  return (
    <Select
      icon={icon}
      label={label}
      placeholder="Select a role"
      data={removable ? removablePermissionRoleOptions : permissionRoleOptions}
      value={value}
      onChange={onChange}
      size={size}
      sx={sx}
      clearable={clearable}
      withinPortal
      disabled={disabled || loading}
      rightSection={loading ? <Loader size={14} /> : null}
    />
  );
};

export const PermissionRoleSelect = memo(PermissionRoleSelectBase);
