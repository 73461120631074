import { memo, useCallback, useState } from 'react';

import { FeatureFlagSwitchProps } from './FeatureFlagSwitch.types';
import { SettingsSwitch } from '../SettingsSwitch';
import { isFeatureFlagEnabled, toggleFeatureFlag } from '@/shared/services/featureFlags';

const FeatureFlagSwitchBase = ({ flag, label, tooltip }: FeatureFlagSwitchProps) => {
  const [isEnabled, setIsEnabled] = useState(isFeatureFlagEnabled(flag));

  const handleSwitchChange = useCallback(
    (checked: boolean) => {
      setIsEnabled(checked);

      toggleFeatureFlag(flag)(checked);
    },
    [flag],
  );

  return <SettingsSwitch label={label} tooltip={tooltip} checked={isEnabled} onChange={handleSwitchChange} />;
};

export const FeatureFlagSwitch = memo(FeatureFlagSwitchBase);
