import { Container, Title, Text, Flex } from '@mantine/core';
import { IconUserOff } from '@tabler/icons-react';

import { DataEmptyPlaceholderProps } from './DataEmptyPlaceholder.types';

export const DataEmptyPlaceholder = ({
  icon = <IconUserOff size={80} />,
  title = 'No clients',
  description = 'You have no clients yet. Try creating at least a client to get started.',
}: DataEmptyPlaceholderProps) => {
  return (
    <Container
      sx={{
        paddingTop: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 400,
      }}
    >
      <Flex align="center" direction="column" gap="xs">
        <Flex sx={(theme) => ({ color: theme.colors.dark[5] })}>{icon}</Flex>

        <Title
          color="dark"
          sx={{
            textAlign: 'center',
            fontWeight: 900,
            fontSize: '1.625rem',
          }}
        >
          {title}
        </Title>

        <Text
          color="dimmed"
          size="md"
          align="center"
          sx={{
            maxWidth: 540,
          }}
        >
          {description}
        </Text>
      </Flex>
    </Container>
  );
};
