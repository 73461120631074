import { memo, useMemo } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconReload } from '@tabler/icons-react';

import { RetryOcrAllButtonProps } from './RetryOcrAllButton.types';
import { useOcrFiles } from '@/pageAI/hooks/files/useOcrFiles';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { isFileRetriable } from '@/shared/services/files';
import { RETRY_OCR_FLAG_STORAGE_KEY } from '@/shared/services/files';
import { isFeatureFlagEnabled } from '@/shared/services/featureFlags';

const RetryOcrAllButtonBase = ({ files, options, onRetry }: RetryOcrAllButtonProps) => {
  const { notify } = useNotifications();
  const { ocrFiles } = useOcrFiles();

  const isAdvancedModeEnabled = isFeatureFlagEnabled(RETRY_OCR_FLAG_STORAGE_KEY);

  const retriableFiles = useMemo(() => files?.filter((file) => isFileRetriable(file)) || [], [files]);

  const handleRetryOcrAll = async () => {
    try {
      await ocrFiles({ ids: retriableFiles.map((file) => file.id), options });

      onRetry?.();

      notify('Success!', 'The OCR process has been triggered. Please wait...', 'primary');
    } catch (error) {
      notify('Error', 'Could not trigger the OCR process');
    }
  };

  if (!isAdvancedModeEnabled) return null;

  return (
    <Tooltip label="Retry OCR">
      <ActionIcon onClick={handleRetryOcrAll} disabled={!retriableFiles.length} size="xs">
        <IconReload />
      </ActionIcon>
    </Tooltip>
  );
};

export const RetryOcrAllButton = memo(RetryOcrAllButtonBase);
