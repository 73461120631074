import { create } from 'zustand';

import { ChatState } from './chat.states.types';
import { MessageRetrieverType } from '@/pageAI/gql/graphql';

export const useChatStore = create<ChatState>(() => ({
  chatContent: '',
  attachmentIds: [],
  isWaitingForAnswer: false,
  retrieverType: MessageRetrieverType.Web,
}));

export const setChatContent = (chatContent: string) => {
  useChatStore.setState({ chatContent });
};

export const setAttachmentIds = (attachmentIds: string[]) => {
  useChatStore.setState({ attachmentIds });
};

export const setIsWaitingForAnswer = (isWaitingForAnswer: boolean) => {
  useChatStore.setState({ isWaitingForAnswer });
};

export const setMessageRetrieverType = (retrieverType: MessageRetrieverType) => {
  useChatStore.setState({ retrieverType });
};

export const toggleMessageRetrieverType = () => {
  useChatStore.setState((state) => ({
    retrieverType:
      state.retrieverType === MessageRetrieverType.Web ? MessageRetrieverType.Docs : MessageRetrieverType.Web,
  }));
};

export const resetChatContent = () => {
  setChatContent('');
  setAttachmentIds([]);
  setIsWaitingForAnswer(false);
};
