import { useCallback } from 'react';
import { IconMinus, IconPlus } from '@tabler/icons-react';

import {
  AddOrRemoveFileFromConditionController,
  RenderAddButtonProps,
  RenderRemoveButtonProps,
} from '../AddOrRemoveFileFromConditionController';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { useFilePreviewStore } from '@/shared/states/filePreview';

const AddOrRemoveFileFromConditionMenuItem = () => {
  const asset = useFilePreviewStore((state) => state.asset);

  const renderAddButton = useCallback(({ isAdding, onClick }: RenderAddButtonProps) => {
    return <MenuItem title="Add file to conditions" icon={<IconPlus size={16} />} onClick={onClick} />;
  }, []);

  const renderRemoveButton = useCallback(({ isRemoving, onClick }: RenderRemoveButtonProps) => {
    return <MenuItem title="Remove file from condition" icon={<IconMinus size={16} />} onClick={onClick} />;
  }, []);

  if (!asset) return null;

  return (
    <AddOrRemoveFileFromConditionController
      fileAsset={asset}
      renderAddButton={renderAddButton}
      renderRemoveButton={renderRemoveButton}
    />
  );
};

export default AddOrRemoveFileFromConditionMenuItem;
