import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Flex, Paper, Text } from '@mantine/core';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';

import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { saveItemToStorage } from '@/shared/lib/storage';
import { LogOutButton } from '@/pageAI/components/auth/LogOutButton';

const SignUpPageBase = () => {
  const { loginWithRedirect, logout, user } = useAuth0();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('code');

  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptInvitation = () => {
    if (!invitationCode) return;

    setIsLoading(true);

    saveItemToStorage('invitationCode', invitationCode);

    logout();
    loginWithRedirect({ screen_hint: 'signup' });
  };

  if (!invitationCode)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: '100%' }}>
        <ErrorPlaceholder title="No invitation code" description="You cannot sign up without an invitation code." />
      </Flex>
    );

  return (
    <Flex align="center" justify="center" sx={(theme) => ({ background: theme.colors.dark[5], height: '100%' })}>
      <Paper p={64} shadow="sm" sx={{ width: 564 }}>
        <Flex direction="column" gap="md">
          <Flex direction="column" align="center">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs">
              Welcome to Page
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center">
              You have been invited to join Page. Press the button below and you will be prompted to create a new Page
              account with your work email.
            </Text>
          </Flex>

          <Flex direction="column">
            <Button sx={{ width: '100%' }} color="red.6" size="md" onClick={handleAcceptInvitation} loading={isLoading}>
              Accept invitation
            </Button>
          </Flex>
        </Flex>
      </Paper>

      {user && (
        <Flex align="center" justify="center" pos="fixed" right={12} bottom={12}>
          <LogOutButton />
        </Flex>
      )}
    </Flex>
  );
};

export const SignUpPage = SignUpPageBase;
