import memoize from 'memoize';
import { URLSearchParams } from 'url';
import { EventBus } from 'native-pubsub';
import { omit, snakeCase } from 'lodash-es';

import { getClientFiles } from '../clients';
import {
  CaseTimelineEvent,
  EventConfidenceLevel,
  EventDetailsByType,
  RawCaseTimelineEvent,
} from '@/pageAI/@types/summaries';
import { FileAsset } from '@/shared/@types';
import { matchWords, snakeCaseToDisplayText, sortAlphabetically } from '@/shared/utils/string';
import { keys } from '@/shared/utils/object';
import { isFeatureFlagEnabled, toggleFeatureFlag } from '@/shared/services/featureFlags';
import { getItemFromStorage } from '@/shared/lib/storage';
import {
  HighlightType,
  extractHrefFromMarkdown,
  highlightKeywordsInMarkdown,
  highlightKeywordsInMarkdownHrefDisplayText,
} from '@/shared/utils/markdown';
import { truthy } from '@/shared/utils/boolean';
import { FilterCaseTimelineEventsProps } from './caseTimeline.services.types';
import { doesSummaryReferenceContainKeyword, extractFileInfoFromHref } from '../summaries';
import { isInViewport } from '@/shared/lib/dom';
import { DetailsType, SummariesType, TimelineEventType } from '@/pageAI/gql/graphql';
import { getEndOfDay, isDateObjectValid, parseDateOnly } from '@/shared/utils/date';

export const highlightTimelineEventPubsub = new EventBus<{
  event: CaseTimelineEvent;
  hideHighlightEffect?: boolean;
  shouldOpenReference?: boolean;
  prefix?: string;
}>();

export const DISPLAY_IRREGULAR_TIMELINE_EVENTS_FEATURE_FLAG = 'out_of_range_timeline_events';
export const USE_LATEST_MODEL_FEATURE_FLAG = 'use_the_latest_model';
export const TIMELINE_PAGINATION_FEATURE_FLAG = 'timeline_pagination';

export const TIMELINE_EVENT_TYPE_COLOR_MAPPING = {
  'Military service': 'yellow',
  'Claim history': 'teal',
  'Medical examination': 'violet',
  'Medical record': 'violet',
  'Rating decision': 'red',
  'Legal precedent': 'blue',
  'Legal reference': 'yellow',
  [TimelineEventType.ClaimOrCaseHistory]: 'teal',
  [TimelineEventType.MedicalEvidence]: 'violet',
  [TimelineEventType.MilitaryHistory]: 'yellow',
  [TimelineEventType.RatingOrCourtDecision]: 'red',
  [TimelineEventType.Other]: 'gray',
  [TimelineEventType.Exclude]: 'gray',
  'Rating / Court decision': 'red',
  'Military history': 'yellow',
  'Claim / Case history': 'teal',
  'Medical evidence': 'violet',
  'Decision history': 'red',
  'Procedural history': 'teal',
  Unclassified: 'red',
};

export const TIMELINE_EVENT_TYPE_DISPLAY_TEXT_MAPPING = {
  [TimelineEventType.ClaimOrCaseHistory]: 'Claim or Case History',
  [TimelineEventType.MedicalEvidence]: 'Medical Evidence',
  [TimelineEventType.MilitaryHistory]: 'Military History',
  [TimelineEventType.RatingOrCourtDecision]: 'Rating or Court Decision',
  [TimelineEventType.Other]: 'Exclude',
  [TimelineEventType.Exclude]: 'Exclude',
  Unclassified: 'Unclassified',
};

export const TIMELINE_EVENT_SUMMARIES_TYPE_DISPLAY_TEXT_MAPPING: Record<SummariesType, string> = {
  [SummariesType.AddendumRequest]: 'Addendum Request',
  [SummariesType.BvaIssueOrder]: 'BVA Issue Order',
  [SummariesType.CombinedFactor]: 'Combined Factor',
  [SummariesType.DbqSummary]: 'DBQ Summary',
  [SummariesType.DeferralInformation]: 'Deferral Information',
  [SummariesType.EventSummary]: 'Event Summary',
  [SummariesType.OpinionRestatement]: 'Opinion Restatement',
  [SummariesType.RatingDecisions]: 'Rating Decision(s)',
  [SummariesType.SocIssueDecision]: 'SOC Issue Decision',
  [SummariesType.IntentToFile]: 'Intent to File',
  [SummariesType.LayStatement]: 'Lay Statement',
  [SummariesType.SupportStatement]: 'Support Statement',
  [SummariesType.HearingSummary]: 'Hearing Summary',
  [SummariesType.Summary]: 'Summary',
  [SummariesType.AppealSummary]: 'Appeal Summary',
};

export const TIMELINE_EVENT_DETAILS_TYPE_DISPLAY_TEXT_MAPPING: Record<DetailsType, string> = {
  [DetailsType.AddendumResponse]: 'Addendum Response',
  [DetailsType.BvaRemand]: 'BVA Remand',
  [DetailsType.DetailedInformation]: 'Detailed Information',
  [DetailsType.FavorableFindings]: 'Favorable Findings',
  [DetailsType.MedicalOpinion]: 'Medical Opinion',
  [DetailsType.ClaimedConditions]: 'Claimed Conditions',
  [DetailsType.IssuesAppealed]: 'Issue(s) Appealed',
  [DetailsType.EvidenceRequests]: 'Evidence Request(s)',
  [DetailsType.AppealsCertified]: 'Appeal(s) Certified',
  [DetailsType.BvaIssuesAndOrders]: 'BVA Issue(s) and Order(s)',
  [DetailsType.SocIssuesAndDecisions]: 'SOC Issue(s) and Decision(s)',
  [DetailsType.ConditionsDiscussed]: 'Condition(s) Discussed',
  [DetailsType.ConditionsNotSubjectToCompensation]: 'Condition(s) Not Subject to Compensation',
  [DetailsType.ConditionsSubjectToCompensation]: 'Condition(s) Subject to Compensation',
  [DetailsType.IssuesMentioned]: 'Issue(s) Mentioned',
  [DetailsType.DeferredConditions]: 'Deferred Condition(s)',
  [DetailsType.EvaluatingIssues]: 'Evaluating Issue(s)',
};

export const turnLatestModelFeatureFlagOn = () => {
  if (typeof getItemFromStorage(USE_LATEST_MODEL_FEATURE_FLAG) === 'string') return;

  toggleFeatureFlag(USE_LATEST_MODEL_FEATURE_FLAG)(true);
};

turnLatestModelFeatureFlagOn();

export const getTimelineElementId = (clientId: string, prefix?: string) =>
  [prefix ? snakeCase(prefix) : '', `timeline-${clientId}`].filter(truthy).join('-');

export const getTimelineEventElementId = (eventId: string, prefix?: string) =>
  [prefix ? snakeCase(prefix) : '', `timeline-event-${eventId}`].filter(truthy).join('-');

export const getTimelineEventReferencceBadgeElement = (eventId: string, prefix?: string) =>
  document.querySelector(`#${getTimelineEventElementId(eventId, prefix)} a.reference-badge`) as
    | HTMLAnchorElement
    | undefined;

export const isTimelineEventFieldEmpty = (field?: string | null): field is undefined | null => {
  return !field || field.trim() === 'N/A';
};

export const sortCaseTimeline = (caseTimelineItems: CaseTimelineEvent[], sortOrder: 'asc' | 'desc' = 'desc') => {
  return [...caseTimelineItems].sort((itemA, itemB) => {
    return (sortOrder === 'desc' ? -1 : 1) * sortAlphabetically(itemA.date, itemB.date);
  });
};

export const groupCaseTimelineEventsByYear = (caseTimelineItems: CaseTimelineEvent[], sortOrder?: 'asc' | 'desc') => {
  const sortedCaseTimelineItems =
    typeof sortOrder === 'string' ? sortCaseTimeline(caseTimelineItems, sortOrder) : caseTimelineItems;

  const caseTimelineItemsByYear = sortedCaseTimelineItems.reduce((acc, item) => {
    const year = item.date.slice(0, 4);

    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    return acc;
  }, {} as Record<string, CaseTimelineEvent[]>);

  return caseTimelineItemsByYear;
};

export const groupTimelineEventsByDate = (caseTimelineItems: CaseTimelineEvent[], sortOrder?: 'asc' | 'desc') => {
  const sortedCaseTimelineItems =
    typeof sortOrder === 'string' ? sortCaseTimeline(caseTimelineItems, sortOrder) : caseTimelineItems;

  const caseTimelineItemsByDate = sortedCaseTimelineItems.reduce((acc, item) => {
    const year = item.date.slice(0, 4);

    if (!acc[year]) {
      acc[year] = {};
    }

    if (!acc[year][item.date]) {
      acc[year][item.date] = [];
    }

    acc[year][item.date].push(item);

    return acc;
  }, {} as Record<string, Record<string, CaseTimelineEvent[]>>);

  return caseTimelineItemsByDate;
};

export const getCaseTimelineItemUniqueIdentifier = (caseTimelineItem: CaseTimelineEvent) =>
  [caseTimelineItem.date, caseTimelineItem.eventType, caseTimelineItem.eventSummary].join('_');

export const updateTimelineEvent = (
  timelineEvents: CaseTimelineEvent[],
  newTimelineEvent: CaseTimelineEvent,
): CaseTimelineEvent[] => {
  const newTimelineEventWithoutReference = omit(newTimelineEvent, ['reference', 'referencedText']);

  const newTimelineEvents = timelineEvents.map((event) => {
    if (event.id === newTimelineEvent.id)
      return {
        ...event,
        ...newTimelineEventWithoutReference,
        formattedDate: formatTimelineEventDate(newTimelineEvent.date),
        eventSummary: formatTimelineEventSummary(newTimelineEvent),
      };

    return event;
  });

  return newTimelineEvents;
};

export const getBookmarkedTimelineEvents = (timelineEvents: CaseTimelineEvent[]) => {
  return timelineEvents.filter((timelineEvent) => timelineEvent.bookmarked);
};

export const getFileAssetsFromTimelineEvents = (fileAssets: FileAsset[], timelineEvents: CaseTimelineEvent[]) => {
  const fileIdMapping: Record<string, boolean> = {};

  timelineEvents.forEach((event) => {
    const { fileId } = extractFileInfoFromHref(fileAssets, extractHrefFromMarkdown(event.reference));

    if (fileId) fileIdMapping[fileId] = true;
  });

  return fileAssets.filter((file) => !!fileIdMapping[file.id]);
};

export const getUniqueFileTypesFromFileAssets = (fileAssets: FileAsset[]) => {
  return [
    ...new Set(fileAssets.map((fileAsset) => fileAsset.metadata?.pageAIMetadata?.cleanedFileName).filter(truthy)),
  ];
};

export const getCaseTimelineEventTypes = (caseTimelineItems: CaseTimelineEvent[]) => {
  return [...new Set(caseTimelineItems.map((item) => item.eventType).filter(truthy))];
};

export const isTimelineEventOfEventType = (event: CaseTimelineEvent, eventTypes: string[] = []) => {
  if (!eventTypes || !eventTypes.length) return true;

  return event.eventType && eventTypes.includes(event.eventType);
};

export const isTimelineEventOfFileType = (
  event: CaseTimelineEvent,
  fileTypes: string[] | null = [],
  clientFileAssets: FileAsset[] = [],
) => {
  if (fileTypes === null) return false;

  if (!fileTypes || !fileTypes.length) return true;

  const { fileAsset } = extractFileInfoFromHref(clientFileAssets, extractHrefFromMarkdown(event.reference));

  const eventFileType = fileAsset?.metadata?.pageAIMetadata?.cleanedFileName;

  if (!eventFileType) return false;

  return fileTypes.includes(eventFileType);
};

export const isTimelineEventOfImportantFiles = (event: CaseTimelineEvent, clientFileAssets: FileAsset[] = []) => {
  const { fileAsset } = extractFileInfoFromHref(clientFileAssets, extractHrefFromMarkdown(event.reference));

  return !!fileAsset?.isImportant;
};

export const isTimelineEventOfConfidenceLevels = (
  event: CaseTimelineEvent,
  confidenceLevels: EventConfidenceLevel[] = [],
) => {
  if (!confidenceLevels || !confidenceLevels.length) return true;

  return event.confidenceLevel && confidenceLevels.includes(event.confidenceLevel as EventConfidenceLevel);
};

export const doesTimelineEventDetailsContainKeyword = (
  event: CaseTimelineEvent,
  keyword: string,
  shouldMatchWholeWords = false,
) => {
  return (
    keys(event.detailsByType).some((detailsType) =>
      matchWords(getEventDetailsTypeDisplayText(detailsType), keyword, shouldMatchWholeWords),
    ) ||
    Object.values(event.detailsByType).some((details) =>
      details?.some((detail) => matchWords(detail, keyword, shouldMatchWholeWords)),
    )
  );
};

export const doesTimelineEventContainKeywords = (
  event: CaseTimelineEvent,
  keywords: string[],
  clientFileAssets: FileAsset[] = [],
  shouldMatchWholeWords = false,
  shouldMatchWholePhrase = false,
) => {
  if (!keywords || !keywords.length) return true;

  if (shouldMatchWholePhrase) {
    keywords = [keywords.join(' ')];
  }

  return keywords.some((keyword) => {
    const loweredCaseKeyword = keyword.toLowerCase().trim();

    return (
      (event.eventSummary && matchWords(event.eventSummary, loweredCaseKeyword, shouldMatchWholeWords)) ||
      doesTimelineEventDetailsContainKeyword(event, loweredCaseKeyword, shouldMatchWholeWords) ||
      matchWords(event.formattedDate, loweredCaseKeyword, shouldMatchWholeWords) ||
      doesSummaryReferenceContainKeyword(event.reference, keyword, clientFileAssets, shouldMatchWholeWords)
    );
  });
};

export const isMissingEvent = (event: CaseTimelineEvent) => {
  return event.metadata?.some((metadata) => metadata.key === 'affectedByJsonError' && metadata.value === 'true');
};

export const isTimelineEventWithinDates = (fromDate?: Date, toDate?: Date) => (event: CaseTimelineEvent) => {
  if (!fromDate && !toDate) return true;

  const eventDate = new Date(event.date);

  if (!isDateObjectValid(eventDate)) return false;

  if (fromDate && eventDate < fromDate) return false;

  if (toDate) {
    const actualToDate = getEndOfDay(toDate);

    if (eventDate > actualToDate) return false;
  }

  return true;
};

export const filterCaseTimelineEvents = ({
  client,
  events,
  eventTypes = [],
  fileTypes,
  fromDate,
  toDate,
  confidenceLevels = [],
  displayOnlyAdditionalEvents = false,
  displayOnlyImportantFiles = false,
  keywords = [],
  matchWholeWords = false,
  matchWholePhrase = false,
}: FilterCaseTimelineEventsProps) => {
  if (fileTypes === null) return [];

  const { fileAssets: clientFileAssets } = getClientFiles(client);

  return events.filter((event) => {
    return (
      isTimelineEventOfEventType(event, eventTypes) &&
      isTimelineEventOfFileType(event, fileTypes, clientFileAssets) &&
      isTimelineEventOfConfidenceLevels(event, confidenceLevels) &&
      isTimelineEventWithinDates(fromDate, toDate)(event) &&
      (!displayOnlyAdditionalEvents || isMissingEvent(event)) &&
      (!displayOnlyImportantFiles || isTimelineEventOfImportantFiles(event, clientFileAssets)) &&
      doesTimelineEventContainKeywords(event, keywords, clientFileAssets, matchWholeWords, matchWholePhrase)
    );
  });
};

export const getTimelineFilterValuesFromSearchParams = (searchParams: URLSearchParams) => {
  const year = searchParams.get('year') || undefined;
  const searchKeywords = searchParams.get('keywords') || '';
  const matchWholeWords = searchParams.get('wholeWords') === 'true';
  const matchWholePhrase = searchParams.get('wholePhrase') === 'true';
  const eventTypes = searchParams.getAll('eventType') as TimelineEventType[];
  const confidenceLevels = searchParams.getAll('confidenceLevel') as EventConfidenceLevel[];
  const fileGroups = searchParams.getAll('fileGroup');
  const fileTypes = searchParams.getAll('fileType');
  const displayOnlyAdditionalEvents = searchParams.get('displayOnlyAdditionalEvents') === 'true';
  const displayOnlyImportantFiles = searchParams.get('importantFiles') === 'true';
  const fromDateStr = searchParams.get('fromDate');
  const toDateStr = searchParams.get('toDate');

  const fromDate = fromDateStr ? parseDateOnly(fromDateStr) : undefined;
  const toDate = toDateStr ? parseDateOnly(toDateStr) : undefined;

  return {
    year,
    searchKeywords,
    matchWholeWords,
    matchWholePhrase,
    eventTypes,
    fileGroups,
    fileTypes,
    fromDate,
    toDate,
    confidenceLevels,
    displayOnlyAdditionalEvents,
    displayOnlyImportantFiles,
  };
};

export const getTimelineEventMapping = memoize((timelineEvents: CaseTimelineEvent[]) => {
  const timelineEventMapping = timelineEvents.reduce((acc, timelineEvent) => {
    acc[timelineEvent.id] = timelineEvent;

    return acc;
  }, {} as Record<string, CaseTimelineEvent>);

  return timelineEventMapping;
}) as (timelineEvents: CaseTimelineEvent[]) => Record<string, CaseTimelineEvent>;

export const highlightCaseTimelineItem = (
  event: CaseTimelineEvent,
  keywords: string[],
  highlightType?: HighlightType,
  shouldMatchWholeWords = false,
  shouldMatchWholePhrase = false,
): CaseTimelineEvent => {
  return {
    ...event,
    eventSummary: highlightKeywordsInMarkdown(
      event.eventSummary,
      keywords,
      highlightType,
      undefined,
      shouldMatchWholeWords,
      shouldMatchWholePhrase,
    ),
    summaries: (event.summaries || []).map((summary) =>
      highlightKeywordsInMarkdown(
        summary,
        keywords,
        highlightType,
        undefined,
        shouldMatchWholeWords,
        shouldMatchWholePhrase,
      ),
    ),
    detailsByType: keys(event.detailsByType).reduce((acc, detailsType) => {
      acc[detailsType] = (event.detailsByType[detailsType] || []).map((detail) =>
        highlightKeywordsInMarkdown(
          detail,
          keywords,
          highlightType,
          undefined,
          shouldMatchWholeWords,
          shouldMatchWholePhrase,
        ),
      );

      return acc;
    }, {} as EventDetailsByType),
    reference: highlightKeywordsInMarkdownHrefDisplayText(
      event.reference,
      keywords,
      highlightType,
      undefined,
      shouldMatchWholeWords,
      shouldMatchWholePhrase,
    ),
  };
};

export const scrollToTimelineEvent = (
  event: CaseTimelineEvent,
  shouldOpenReference = false,
  skipIfInViewport = false,
  prefix?: string,
) => {
  requestIdleCallback(() => {
    const timelineEventElement = document.getElementById(getTimelineEventElementId(event.id, prefix));
    const referenceBadgeElement = getTimelineEventReferencceBadgeElement(event.id, prefix);

    if (shouldOpenReference) {
      setTimeout(() => referenceBadgeElement?.click());
    }

    if (skipIfInViewport && referenceBadgeElement && isInViewport(referenceBadgeElement)) return;

    timelineEventElement?.scrollIntoView({ block: 'center' });
  });
};

export const formatTimelineEventDate = (date: string) => {
  const timelineEventDateObject = new Date(date);

  return isDateObjectValid(timelineEventDateObject)
    ? new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeZone: 'UTC' }).format(timelineEventDateObject)
    : date;
};

export const formatTimelineEventSummary = (timelineEvent: RawCaseTimelineEvent | CaseTimelineEvent) => {
  return timelineEvent.summaries?.join('\n\n') || '';
};

export const normalizeRawTimelineEvent = (timelineEvent?: RawCaseTimelineEvent | null): CaseTimelineEvent | null => {
  if (!timelineEvent) return null;

  const isEventContentEmpty = !timelineEvent.summaries?.length && !timelineEvent.details?.length;

  if (isEventContentEmpty) return null;

  const timelineEventDateObject = new Date(timelineEvent.date);

  const displayIrregularEvents = isFeatureFlagEnabled(DISPLAY_IRREGULAR_TIMELINE_EVENTS_FEATURE_FLAG);

  const isDateValid = isDateObjectValid(timelineEventDateObject);
  const isFutureDate = timelineEventDateObject > new Date();
  const isDateBefore1920 = timelineEventDateObject < new Date('1920-01-01');

  if (!displayIrregularEvents) {
    if (!isDateValid || isDateBefore1920 || isFutureDate || timelineEvent.duplicated) return null;
  }

  return {
    ...timelineEvent,
    formattedDate: formatTimelineEventDate(timelineEvent.date),
    eventSummary: formatTimelineEventSummary(timelineEvent),
    detailsByType: (timelineEvent.detailsTypes || []).reduce((acc, type, index) => {
      if (!acc[type]) acc[type] = [];

      if (timelineEvent.details?.[index]) acc[type]?.push(timelineEvent.details[index]);

      return acc;
    }, {} as EventDetailsByType),
    reference: timelineEvent.reference.replaceAll('_', '\\_'),
    comments: timelineEvent.comments?.nodes?.filter(truthy) || [],
  };
};

export const doesEventHaveMultipleSummaries = (event: CaseTimelineEvent) =>
  event.summaries && event.summaries.length > 1;

export const doesEventHaveRatingDecisions = (event: CaseTimelineEvent) =>
  event.summariesTypes?.[0] === SummariesType.RatingDecisions;

export const doesEventHaveFavoriteFindings = (event: CaseTimelineEvent) =>
  event.detailsTypes?.includes(DetailsType.FavorableFindings) && event.detailsTypes?.length > 0;

export const getEventNumberOfDecisionsWithFavorableFindings = (event: CaseTimelineEvent) =>
  event.detailsByType[DetailsType.FavorableFindings]?.length || 0;

export const canEditEventSummaries = (event: CaseTimelineEvent) => {
  return !event.summaries || event.summaries.length === 0 || (event.summaries && event.summaries.length === 1);
};

export const getEventSummariesTypeDisplayText = (summaryType?: SummariesType) => {
  if (!summaryType) return '';

  return TIMELINE_EVENT_SUMMARIES_TYPE_DISPLAY_TEXT_MAPPING[summaryType] || snakeCaseToDisplayText(summaryType);
};

export const getEventDetailsTypeDisplayText = (detailsType?: DetailsType) => {
  if (!detailsType) return '';

  return TIMELINE_EVENT_DETAILS_TYPE_DISPLAY_TEXT_MAPPING[detailsType] || snakeCaseToDisplayText(detailsType);
};

export const getTimelineTableOfContents = (timelineByYear: ReturnType<typeof groupTimelineEventsByDate>) => {
  const tableOfContents: Record<string, Record<string, CaseTimelineEvent[]>> = {};

  Object.entries(timelineByYear).forEach(([year, eventsByDate]) => {
    tableOfContents[year] = {};

    Object.entries(eventsByDate).forEach(([date, events]) => {
      const month = parseDateOnly(date).getMonth() + 1;

      if (!tableOfContents[year][month]) {
        tableOfContents[year][month] = [];
      }

      tableOfContents[year][month].push(...events);
    });
  });

  return tableOfContents;
};
