import React, { memo, useMemo, useRef } from 'react';
import { Accordion, Flex, Highlight, Text } from '@mantine/core';

import { openRightSidebar } from '@/shared/states/layout';
import {
  setFilePreviewAsset,
  setHighlightedTexts,
  setPagesToHighlight,
  setPdfPreviewInitalPageIndex,
} from '@/shared/states/filePreview';
import { FileSearchResultCardProps } from './FileSearchResultCard.types';
import { getFileSearchResultElementId, groupFileSearchResultByPage } from '@/pageAI/services/files';
import { keys } from '@/shared/utils/object';
import { singularOrPlural } from '@/shared/utils/string';
import { setActiveResult, useFileSearchStore } from '@/pageAI/states/fileSearch';
import { isInViewport } from '@/shared/lib/dom';
import { formatDate } from '@/shared/utils/date';

const FileSearchResultCardBase = ({ resultGroup, index, offset, query, onClick }: FileSearchResultCardProps) => {
  const accordionRef = useRef<HTMLDivElement>(null);

  const fileAsset = resultGroup.file;

  const resultsByPage = useMemo(() => groupFileSearchResultByPage(resultGroup.results), [resultGroup.results]);
  const activeResult = useFileSearchStore((state) => state.activeResult);

  const ensureVisible = (resultByPageIndex: number) => {
    const element = document.getElementById(getFileSearchResultElementId(offset + resultByPageIndex));

    if (!element) return;

    const isAccordionActive = accordionRef.current?.getAttribute('data-active') === 'true';

    if (!isAccordionActive)
      (accordionRef.current?.querySelector('.ghost-Accordion-control') as HTMLButtonElement | null)?.click();

    requestIdleCallback(() => {
      if (isInViewport(element)) return;

      element.scrollIntoView();
    });
  };

  const handleSelectResult = (pageNumber: number, resultByPageIndex: number) => (event: React.MouseEvent) => {
    if (!query) return;

    setActiveResult({ file: fileAsset, pageNumber, rowIndex: offset + resultByPageIndex });

    setHighlightedTexts(query.split(' '), true);
    setPdfPreviewInitalPageIndex(pageNumber - 1);
    setPagesToHighlight([pageNumber - 1]);
    openRightSidebar();
    setFilePreviewAsset({
      ...fileAsset,
      name: fileAsset.metadata?.pageAIMetadata?.cleanedFileName || '',
      index: fileAsset.metadata?.pageAIMetadata?.indexNumber,
    });

    onClick?.(event, index, fileAsset);

    requestIdleCallback(() => ensureVisible(resultByPageIndex));
  };

  const pageNumbers = useMemo(() => keys(resultsByPage), [resultsByPage]);

  return (
    <Accordion
      className={['file-search-result-card', index].join('-')}
      sx={(theme) => ({
        transition: 'all 0.1s',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderTop: 'none',
      })}
      defaultValue="item"
    >
      <Accordion.Item
        ref={accordionRef}
        value="item"
        data-file-id={fileAsset.id}
        sx={(theme) => ({
          borderBottom: 'none',
          '&[data-active=true]': {
            '.ghost-Accordion-control': {
              borderBottom: `1px solid ${theme.colors.gray[3]}`,
            },
          },
        })}
      >
        <Accordion.Control
          sx={(theme) => ({
            position: 'sticky',
            top: 58,
            zIndex: 1,
            padding: 0,
            background: theme.white,
            borderTop: `1px solid ${theme.colors.gray[3]}`,
            '.ghost-Accordion-label': { padding: 0 },
            '.ghost-Accordion-chevron': {
              marginLeft: 0,
              marginTop: 4,
              marginRight: 8,
            },
          })}
        >
          <Flex align="center" justify="space-between" gap="xs" pl="md" pr={4} pb="sm" pt="md">
            <Flex align="center" gap="xs">
              <Flex
                align="center"
                justify="center"
                sx={(theme) => ({
                  padding: 4,
                  background: theme.colors.dark[4],
                  width: 20,
                  height: 20,
                  borderRadius: 4,
                })}
              >
                <Text fz="0.75rem" fw={500} color="white">
                  {fileAsset.index || index + 1}
                </Text>
              </Flex>

              <Text
                fz="0.875rem"
                color="dark.5"
                fw={600}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {fileAsset.name}
              </Text>

              <Flex
                sx={(theme) => ({
                  width: 4,
                  height: 4,
                  borderRadius: '50%',
                  background: theme.colors.gray[5],
                })}
              />

              <Text fz="0.875rem" color="dark.3">
                {resultGroup.results.length} {singularOrPlural('result', 'results')(resultGroup.results.length)}
              </Text>
            </Flex>

            {fileAsset.createdAt && (
              <Flex align="center">
                <Text fz="0.875rem" color="dark.3">
                  Received {formatDate(fileAsset.createdAt)}
                </Text>
              </Flex>
            )}
          </Flex>
        </Accordion.Control>

        <Accordion.Panel p={0} sx={{ '.ghost-Accordion-content': { padding: 0 } }}>
          <Flex direction="column">
            {pageNumbers.map((pageNumber, resultByPageIndex) => {
              const results = resultsByPage[pageNumber];
              const isActive = activeResult?.file.id === fileAsset.id && activeResult?.pageNumber === pageNumber;

              return (
                <Flex
                  key={pageNumber}
                  id={getFileSearchResultElementId(offset + resultByPageIndex)}
                  align="center"
                  onClick={handleSelectResult(pageNumber, resultByPageIndex)}
                  gap="xs"
                  py="xs"
                  px="md"
                  sx={(theme) => ({
                    cursor: 'pointer',
                    ...(resultByPageIndex !== pageNumbers.length - 1 && {
                      borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    }),
                    transition: 'all 0.1s ease-in-out',
                    ...(isActive && {
                      background: theme.colors.gray[1],
                      '.file-search-result-snippet': {
                        border: `1px solid ${theme.colors.gray[4]}`,
                      },
                    }),
                    '&:hover': {
                      background: theme.colors.gray[1],
                      '.file-search-result-snippet': {
                        border: `1px solid ${theme.colors.gray[4]}`,
                      },
                    },
                  })}
                >
                  <Text
                    fz="0.875rem"
                    fw={500}
                    color="dark.4"
                    mt={4}
                    sx={{ width: 80, alignSelf: 'start', ...(isActive && { textDecoration: 'underline' }) }}
                  >
                    Page {pageNumber}
                  </Text>

                  <Flex direction="column" sx={{ flex: '1 1' }} gap="xs">
                    {results.map((result, resultIndex) => (
                      <Flex
                        key={result.snippet + result.score + resultIndex}
                        className="file-search-result-snippet"
                        align="center"
                        gap="xs"
                        pos="relative"
                        sx={(theme) => ({
                          padding: '4px 8px',
                          borderRadius: 4,
                          border: `1px solid ${theme.colors.gray[2]}`,
                          background: theme.colors.gray[2],
                          transition: 'all 0.1s ease-in-out',
                        })}
                      >
                        <Text fz="0.75rem">
                          <Highlight sx={{ wordBreak: 'break-word' }} highlight={query?.split(' ') || ''}>
                            {result.snippet}
                          </Highlight>
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export const FileSearchResultCard = memo(FileSearchResultCardBase);
