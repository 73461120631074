import { memo, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Flex } from '@mantine/core';

import { UnifiedLayout } from '@/pageAI/components/unified/UnifiedLayout';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { resetUnifiedState } from '@/pageAI/states/unified';
import { UnifiedTabs } from '@/pageAI/components/unified/UnifiedTabs/UnifiedTabs';

const UnifiedViewPageBase = () => {
  const { clientId } = useParams();
  const { client, isLoading, isError } = useClient({ clientId });

  const items = useMemo(() => {
    if (!client) return [];

    return [
      { title: 'Clients', href: '/clients' },
      { title: getClientDisplayName(client), href: `/clients/${client.id}` },
      { title: 'Summaries', href: `/clients/${client.id}/summaries` },
    ].map((item, index) => (
      <Anchor
        key={item.href}
        to={item.href}
        sx={{ ...(index === 2 ? { fontWeight: 600 } : { fontWeight: 500 }) }}
        component={Link}
      >
        {item.title}
      </Anchor>
    ));
  }, [client]);

  useEffect(() => {
    return () => {
      resetUnifiedState();
    };
  }, [clientId]);

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading client..."
        description="Please wait while Page retrieves the client."
        fullScreen
      />
    );

  if (isError) throw new Error('Could not load client');

  if (!client) return <ClientNotFoundPlaceholder />;

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={items}
        rightSection={
          <Flex ml="xs">
            <UnifiedTabs />
          </Flex>
        }
      />

      <UnifiedLayout />
    </Flex>
  );
};

export const UnifiedViewPage = memo(UnifiedViewPageBase);
