import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Anchor, Button, Divider, Flex, Popover, Text, Tooltip } from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import { IconFilter, IconFilterFilled } from '@tabler/icons-react';
import { EventBus } from 'native-pubsub';

import { TimelineFiltersProps } from './TimelineFilters.types';
import { TimelineFileFilter } from '../TimelineFileFilter';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';
import { isMissingEvent } from '@/pageAI/services/caseTimeline';
import { DisplayOnlyAdditionalEventsSwitch } from '../DisplayOnlyAdditionalEventsSwitch';
import { truthy } from '@/shared/utils/boolean';
import { toggleFileGroupManagementModalEventBus } from '../../files/FileGroupManagementModal';
import { TimelineFileGroupFilter, useFileGroupConfirmationModalStore } from '../TimelineFileGroupFilter';
import { FileGroup } from '@/pageAI/gql/graphql';
import { usePersistentFileTypeFilters } from '@/pageAI/hooks/summaries/usePersistentFileTypeFilters';
import { usePersistentFileGroupFilters } from '@/pageAI/hooks/summaries/usePersistentFileGroupFilters';
import { useSelectableFileTypes } from '@/pageAI/hooks/caseTimeline/useSelectableFileTypes';
import { getStartAndEndOfYear } from '@/shared/utils/date';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';

export const applyFileGroupFilterEventBus = new EventBus<{ fileGroups: FileGroup[] }>();

const TimelineFiltersBase = ({ caseTimeline }: TimelineFiltersProps) => {
  const client = useSelectedClient(true);
  const {
    fileGroups,
    setFileGroups,
    fileTypes,
    setFileTypes,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    confidenceLevels,
    displayOnlyAdditionalEvents,
    setDisplayOnlyAdditionalEvents,
    displayOnlyImportantFiles,
    setDisplayOnlyImportantFiles,
  } = useCaseTimelineContext();

  const { selectableFileTypes } = useSelectableFileTypes({ client, events: caseTimeline });

  const [persistentFileTypeFilters, setPersistentFileTypeFilters] = usePersistentFileTypeFilters(client.id);
  const [persistentFileGroupFilters, setPersistentFileGroupFilters] = usePersistentFileGroupFilters(client.id);

  const isFileGroupConfirmationModalOpened = useFileGroupConfirmationModalStore(
    (state) => state.isConfirmationModalOpened,
  );

  const [forceRemountCounter, setForceRemountCounter] = useState(0);

  const handleChangeFileTypes = useCallback(
    (value: string[] | null) => {
      setFileTypes(value);
      setPersistentFileTypeFilters(value);
    },
    [setFileTypes, setPersistentFileTypeFilters],
  );

  const handleChangeFileGroups = useCallback(
    (value: string[] | null) => {
      setFileGroups(value);
      setPersistentFileGroupFilters(value);
    },
    [setFileGroups, setPersistentFileGroupFilters],
  );

  const handleSelectYear = useCallback(
    (date: Date) => {
      const [startOfYear, endOfYear] = getStartAndEndOfYear(date);

      setFromDate?.(startOfYear);
      setToDate?.(endOfYear);
    },
    [setFromDate, setToDate],
  );

  useEffect(() => {
    const unsubscribe = applyFileGroupFilterEventBus.subscribe(({ fileGroups }) => {
      setFileGroups(fileGroups.map((fileGroup) => fileGroup.displayName));
      setForceRemountCounter((prev) => prev + 1);
    });

    return unsubscribe;
  }, [setFileGroups]);

  const areThereMissingEvents = useMemo(() => caseTimeline.some((event) => isMissingEvent(event)), [caseTimeline]);
  const hasFilters =
    fileTypes === null ||
    fileTypes.length > 0 ||
    (fileGroups && fileGroups.length > 0) ||
    confidenceLevels.length > 0 ||
    fromDate ||
    toDate ||
    displayOnlyAdditionalEvents ||
    displayOnlyImportantFiles;

  return (
    <Popover
      withinPortal
      position="bottom-end"
      shadow="sm"
      keepMounted
      closeOnClickOutside={!isFileGroupConfirmationModalOpened}
    >
      <Popover.Target>
        <Button
          color="gray.7"
          size="xs"
          variant="subtle"
          leftIcon={
            hasFilters ? (
              <Flex sx={{ position: 'relative' }} align="center" justify="center">
                <IconFilterFilled size={16} />

                <Flex
                  align="center"
                  justify="center"
                  sx={(theme) => ({
                    position: 'absolute',
                    right: -4,
                    top: -4,
                    width: 12,
                    height: 12,
                    background: theme.colors.red[6],
                    borderRadius: '50%',
                  })}
                >
                  <Text color="white" fz="0.5rem" fw={600}>
                    {
                      [
                        fileTypes === null || fileTypes.length > 0 || (fileGroups && fileGroups.length > 0),
                        confidenceLevels.length > 0,
                        displayOnlyAdditionalEvents,
                        displayOnlyImportantFiles,
                        fromDate || toDate,
                      ].filter(truthy).length
                    }
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <IconFilter size={16} />
            )
          }
        >
          Filter
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column" gap="sm">
          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Custom Filters
              </Text>

              <Anchor
                size="xs"
                sx={(theme) => ({
                  fontWeight: 600,
                  color: theme.colors.red[5],
                })}
                onClick={(event) => {
                  toggleFileGroupManagementModalEventBus.publish({});
                }}
              >
                Customize
              </Anchor>
            </Flex>

            <TimelineFileGroupFilter
              key={forceRemountCounter}
              fileTypes={fileTypes}
              initialValue={fileGroups}
              onChange={handleChangeFileGroups}
            />
          </Flex>

          <Flex direction="column">
            <Flex align="center" justify="space-between" mb={2}>
              <Text fz="0.75rem" color="dark.4" fw={500}>
                Filter by File Type
              </Text>

              {!fileGroups?.length && fileTypes?.length ? (
                <Tooltip label="Save as a new custom filter" withinPortal withArrow>
                  <Anchor
                    size="xs"
                    onClick={(event) => {
                      toggleFileGroupManagementModalEventBus.publish({ fileNames: fileTypes });
                    }}
                  >
                    Save
                  </Anchor>
                </Tooltip>
              ) : null}
            </Flex>

            <TimelineFileFilter
              key={forceRemountCounter}
              fileGroupValues={fileGroups}
              initialValue={fileTypes}
              selectableFileTypes={selectableFileTypes}
              onChange={handleChangeFileTypes}
            />
          </Flex>

          {areThereMissingEvents && (
            <DisplayOnlyAdditionalEventsSwitch
              initialValue={displayOnlyAdditionalEvents}
              onChange={setDisplayOnlyAdditionalEvents}
            />
          )}

          {/* <DisplayOnlyImportantFilesSwitch
            initialValue={displayOnlyImportantFiles}
            onChange={setDisplayOnlyImportantFiles}
          /> */}

          <Divider mb={-4} />

          <Flex align="center" gap="xs" pos="relative">
            <DateInput
              label="From"
              size="xs"
              placeholder="Start date"
              value={fromDate}
              onChange={setFromDate}
              clearable
              sx={{ flexGrow: 1, width: '100%' }}
            />

            <DateInput
              label="To"
              size="xs"
              placeholder="End date"
              value={toDate}
              onChange={setToDate}
              clearable
              sx={{ flexGrow: 1, width: '100%' }}
            />

            <Popover shadow="sm">
              <Popover.Target>
                <Anchor
                  size="xs"
                  pos="absolute"
                  sx={(theme) => ({
                    fontWeight: 600,
                    color: theme.colors.dark[5],
                    right: 0,
                    top: 2,
                  })}
                >
                  Select year
                </Anchor>
              </Popover.Target>

              <Popover.Dropdown p="xs">
                <DatePicker level="decade" size="xs" onYearSelect={handleSelectYear} />
              </Popover.Dropdown>
            </Popover>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const TimelineFilters = memo(TimelineFiltersBase);
