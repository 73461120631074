import { memo } from 'react';
import { Flex, Text, ThemeIcon } from '@mantine/core';
import { IconBook } from '@tabler/icons-react';

import { BotMessageSourcesProps } from './BotMessageSources.types';
import { MessageSource } from '../MessageSource';
import { truthy } from '@/shared/utils/boolean';
import { MessageSource as MessageSourceType } from '@/pageAI/gql/graphql';

const BotMessageSourcesBase: React.FC<BotMessageSourcesProps> = ({ message }) => {
  const sources = message?.sources;

  if (!sources || !sources.length) return null;

  return (
    <Flex direction="column" gap={4} mt="xs">
      <Flex align="center" gap={4}>
        <ThemeIcon variant="light" size="sm">
          <IconBook size={14} />
        </ThemeIcon>

        <Text fw={500} color="brand.5" fz="0.875rem">
          Sources
        </Text>
      </Flex>

      <Flex wrap="wrap" gap="xs">
        {sources.filter(truthy).map((source, sourceIndex) => (
          <MessageSource
            key={source?.website?.url || source?.file?.id || source?.snippet}
            source={source as unknown as MessageSourceType}
            sourceIndex={sourceIndex}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export const BotMessageSources = memo(BotMessageSourcesBase);
