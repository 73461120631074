import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionIcon, Breadcrumbs, Flex } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

export const PAGE_AI_HEADER_HEIGHT = 56;

export interface PageAIHeaderProps {
  headerItems: React.ReactNode | React.ReactNode[];
  headerSuffix?: React.ReactNode;
  rightSection?: React.ReactNode;
}

const PageAIHeaderBase = ({ headerItems, headerSuffix, rightSection }: PageAIHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isHomePage = location.pathname === '/' || location.pathname === '/clients';
  const canGoBack = !isHomePage || location.key !== 'default';

  const handleGoBack = () => {
    if (isHomePage) return navigate(-1);

    if (location.key === 'default') return navigate('/clients');

    navigate(-1);
  };

  return (
    <Flex align="center" justify="space-between" px={32} pt="md" pb={4} gap="xs" sx={{ height: PAGE_AI_HEADER_HEIGHT }}>
      <Flex align="center">
        {canGoBack && (
          <ActionIcon color="gray.7" mr={4} ml={-8} onClick={handleGoBack}>
            <IconArrowLeft size={16} />
          </ActionIcon>
        )}

        <Breadcrumbs>{headerItems}</Breadcrumbs>

        {headerSuffix}
      </Flex>

      <Flex align="center">
        <Flex align="center" id="page-ai-header-right-section" />

        {rightSection}
      </Flex>
    </Flex>
  );
};

export const PageAIHeader = memo(PageAIHeaderBase);
