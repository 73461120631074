import { memo, useEffect, useRef } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { TypedSubscriptionClient } from '@/shared/@types';
import { useAccessTokenOnce } from '@/shared/hooks/auth/useAccessTokenOnce';
import { getTimelineCommentOperationSubscriptionQuery } from '@/pageAI/api';
import { WEB_SOCKET_ENDPOINT } from '@/shared/config';
import { TimelineCommentOperationEventPayload } from '@/pageAI/@types';
import { useHandleTimelineCommentOperationEvent } from './TimelineCommentOperationWebSocket.utils';

const TimelineCommentOperationWebSocketBase = () => {
  const { accessToken } = useAccessTokenOnce();
  const { handleTimelineCommentCreatedEvent, handleTimelineCommentUpdatedEvent, handleTimelineCommentDeletedEvent } =
    useHandleTimelineCommentOperationEvent();

  const handleTimelineCommentCreatedEventRef = useRef(handleTimelineCommentCreatedEvent);
  const handleTimelineCommentUpdatedEventRef = useRef(handleTimelineCommentUpdatedEvent);
  const handleTimelineCommentDeletedEventRef = useRef(handleTimelineCommentDeletedEvent);

  handleTimelineCommentCreatedEventRef.current = handleTimelineCommentCreatedEvent;
  handleTimelineCommentUpdatedEventRef.current = handleTimelineCommentUpdatedEvent;
  handleTimelineCommentDeletedEventRef.current = handleTimelineCommentDeletedEvent;

  const subscriptionClient = useRef<TypedSubscriptionClient | null>(null);

  useEffect(() => {
    if (!accessToken) return;

    const query = getTimelineCommentOperationSubscriptionQuery();

    const observer = {
      next: (payload: TimelineCommentOperationEventPayload) => {
        switch (payload.data.event.__typename) {
          case 'ClientTimelineEventCommentCreated':
            handleTimelineCommentCreatedEventRef.current(payload.data.event);
            break;
          case 'ClientTimelineEventCommentUpdated':
            handleTimelineCommentUpdatedEventRef.current(payload.data.event);
            break;
          case 'ClientTimelineEventCommentDeleted':
            handleTimelineCommentDeletedEventRef.current(payload.data.event);
            break;
          default:
        }
      },
      error: (error: Error) => {
        console.error(error);
      },
    };

    if (!subscriptionClient.current) {
      subscriptionClient.current = new SubscriptionClient(WEB_SOCKET_ENDPOINT, {
        reconnect: true,
        connectionParams: {
          token: `${accessToken}`,
        },
      });
    }

    const subscription = subscriptionClient.current.request({ query }).subscribe(observer);

    return () => {
      subscription.unsubscribe();
    };
  }, [accessToken]);

  useEffect(() => {
    return () => {
      subscriptionClient.current?.close();
    };
  }, []);

  return null;
};

export const TimelineCommentOperationWebSocket = memo(TimelineCommentOperationWebSocketBase);
