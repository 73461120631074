import { memo, useMemo } from 'react';
import { Flex } from '@mantine/core';

import '@/shared/lib/time';

import { TimelineEventCommentSectionProps } from './TimelineEventCommentSection.types';
import { CommentRow } from '@/pageAI/components/comments/CommentRow';
import { AddCommentBox } from '@/pageAI/components/comments/AddCommentBox';

const TimelineEventCommentSectionBase = ({
  opened,
  client,
  event,
  compact = true,
  onSubmit,
  onReply,
}: TimelineEventCommentSectionProps) => {
  const comments = useMemo(() => event.comments.toReversed() || [], [event.comments]);

  return (
    <Flex direction="column" sx={{ width: '100%' }} className="comments">
      {comments.length > 0 && (
        <Flex direction="column" mt={6}>
          {comments.map((comment, commentIndex) => (
            <CommentRow
              key={comment.id}
              comment={comment}
              event={event}
              showReply={commentIndex === comments.length - 1}
              compact={compact}
              onReply={onReply}
            />
          ))}
        </Flex>
      )}

      <AddCommentBox opened={opened} client={client} event={event} onSubmit={onSubmit} />
    </Flex>
  );
};

export const TimelineEventCommentSection = memo(TimelineEventCommentSectionBase);
