import { memo } from 'react';
import { Avatar } from '@mantine/core';

import { UserAvatarProps } from './UserAvatar.types';

const UserAvatarBase = ({ user, size = 28 }: UserAvatarProps) => {
  return <Avatar size={size} src={user.avatarUrl} radius="50%" />;
};

export const UserAvatar = memo(UserAvatarBase);
