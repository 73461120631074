import React, { memo, useCallback } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';

import { AddOrRemoveFileFromConditionButtonProps } from './AddOrRemoveFileFromConditionButton.types';
import {
  AddOrRemoveFileFromConditionController,
  RenderAddButtonProps,
  RenderRemoveButtonProps,
} from '../AddOrRemoveFileFromConditionController';

const AddOrRemoveFileFromConditionButtonBase = ({ fileAsset }: AddOrRemoveFileFromConditionButtonProps) => {
  const renderAddButton = useCallback(({ onClick, isAdding }: RenderAddButtonProps) => {
    return (
      <Tooltip label="Add file to conditions" withArrow withinPortal>
        <ActionIcon size="xs" loading={isAdding} onClick={onClick}>
          <IconPlus size={14} />
        </ActionIcon>
      </Tooltip>
    );
  }, []);

  const renderRemoveButton = useCallback(
    ({ onClick, isRemoving }: RenderRemoveButtonProps) => (
      <Tooltip label="Remove file from condition" withArrow withinPortal>
        <ActionIcon size="xs" loading={isRemoving} onClick={onClick}>
          <IconMinus size={14} />
        </ActionIcon>
      </Tooltip>
    ),
    [],
  );

  return (
    <AddOrRemoveFileFromConditionController
      fileAsset={fileAsset}
      renderAddButton={renderAddButton}
      renderRemoveButton={renderRemoveButton}
    />
  );
};

export const AddOrRemoveFileFromConditionButton = memo(AddOrRemoveFileFromConditionButtonBase);
