import { useLayoutEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Box, Flex, ScrollArea, Text } from '@mantine/core';

import { ClientMainLayout } from '@/pageAI/components/clients/ClientMainLayout';
import { withPageWrapper } from '@/shared/hocs/withPageWrapper';
import { PAGE_AI_HEADER_HEIGHT, PageAIHeader } from '@/pageAI/components/layout/PageAIHeader';
import { ClientAssignee } from '@/pageAI/components/clients/ClientAssignee';
import { ClientHeaderActions } from '@/pageAI/components/clients/ClientHeaderActions';
import { ClientNotFoundPlaceholder } from '@/pageAI/components/clients/ClientNotFoundPlaceholder';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { setSelectedClient } from '@/pageAI/states/client';
import { useClient } from '@/pageAI/hooks/clients/useClient';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { useClientTimelineEvents } from '@/pageAI/hooks/clients/useClientTimelineEvents';

const ClientDetailsPageBase = () => {
  const { clientId } = useParams();
  const { client, isLoading: isLoadingClient, isError: isClientError } = useClient({ clientId });
  const { isLoading: isLoadingTimeline, isError: isTimelineError } = useClientTimelineEvents({ clientId });
  const { isLoading: isLoadingFileGroups, isError: isFileGroupsError } = useFileGroups();

  const isLoading = isLoadingClient || isLoadingFileGroups || isLoadingTimeline;
  const isError = isClientError || isFileGroupsError || isTimelineError;

  useLayoutEffect(() => {
    if (!isLoading && client) setSelectedClient(client);
  }, [client, isLoading]);

  const items = useMemo(
    () =>
      client
        ? [
            { title: 'Clients', href: '/clients' },
            { title: getClientDisplayName(client), href: '#' },
          ].map((item, index) => (
            <Anchor
              key={item.href}
              to={item.href}
              sx={{ ...(index === 1 ? { fontWeight: 600 } : { fontWeight: 500 }) }}
              component={Link}
            >
              {item.title}
            </Anchor>
          ))
        : [],
    [client],
  );

  if (isLoading)
    return (
      <LoadingPlaceholder
        title="Loading client..."
        description="Please wait while Page retrieves the client."
        fullScreen
      />
    );

  if (!clientId || isError)
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        <ErrorPlaceholder />
      </Flex>
    );

  if (!client) return <ClientNotFoundPlaceholder />;

  return (
    <Flex direction="column">
      <PageAIHeader
        headerItems={items}
        headerSuffix={
          client ? (
            <Flex align="center" ml={6} mt={2} sx={{ whiteSpace: 'nowrap' }}>
              <Text fz="0.875rem">(</Text>

              <Text fz="0.875rem" fw={500} color="dark.2" mx={2}>
                Assigned to
              </Text>

              <ClientAssignee client={client} compact />

              <Text fz="0.875rem">)</Text>
            </Flex>
          ) : null
        }
        rightSection={<ClientHeaderActions client={client} />}
      />

      <ScrollArea
        sx={{ height: `calc(100vh - ${PAGE_AI_HEADER_HEIGHT}px)` }}
        viewportProps={{ id: [clientId, 'scroll-area-viewport'].join('-'), className: 'scroll-area-viewport' }}
      >
        <Box p="md" pt="xs">
          <ClientMainLayout clientId={clientId} />
        </Box>
      </ScrollArea>
    </Flex>
  );
};

export const ClientDetailsPage = withPageWrapper(ClientDetailsPageBase);
