import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Text, Flex, Box } from '@mantine/core';

import { CaseTimelineContextProvider } from '@/pageAI/contexts/caseTimelineContext';
import { CaseTimeline } from '@/pageAI/components/timeline/CaseTimeline';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { PrintableCaseTimelineProps } from './PrintableCaseTimeline.types';
import {
  getBookmarkedTimelineEvents,
  getTimelineElementId,
  groupCaseTimelineEventsByYear,
} from '@/pageAI/services/caseTimeline';
import { waitUntilTruthy } from '@/shared/utils/boolean';
import { wait } from '@/shared/utils/time';

const PrintableCaseTimelineBase = ({
  client,
  caseTimeline,
  sortOrder = 'desc',
  tab = 'All',
  forceShowEventDetails,
  onFinish,
  onProgress,
}: PrintableCaseTimelineProps) => {
  const renderedContainer = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isFinished, setIsFinished] = useState(false);

  const timelineInTab = useMemo(
    () => (tab === 'All' ? caseTimeline : getBookmarkedTimelineEvents(caseTimeline)),
    [caseTimeline, tab],
  );

  const groupedCaseTimelineEventsByYear = useMemo(() => groupCaseTimelineEventsByYear(timelineInTab), [timelineInTab]);

  const caseTimelineEventsByYear = useMemo(() => {
    let caseTimelineItemsByYear = Object.entries(groupedCaseTimelineEventsByYear);

    if (sortOrder === 'desc') caseTimelineItemsByYear = caseTimelineItemsByYear.toReversed();

    return caseTimelineItemsByYear;
  }, [groupedCaseTimelineEventsByYear, sortOrder]);

  const timelineToRender = useMemo(
    () => caseTimelineEventsByYear[currentIndex]?.[1] || [],
    [caseTimelineEventsByYear, currentIndex],
  );

  const currentYear = caseTimelineEventsByYear[currentIndex]?.[0];

  const prefix = ['printable', '-', currentYear].join('');

  useEffect(() => {
    if (currentIndex === -1) {
      setTimeout(() => setCurrentIndex(0), 100);

      return;
    }

    if (!currentYear) {
      setIsFinished(true);
      onFinish?.();
      setCurrentIndex(-1);

      return;
    }

    if (currentIndex >= caseTimelineEventsByYear.length) return;

    requestIdleCallback(async () => {
      await waitUntilTruthy(() => !!document.getElementById(currentYear));

      const timelineElement = document.getElementById(getTimelineElementId(client.id, prefix));

      if (!timelineElement) return;

      const renderedElement = renderedContainer.current;

      if (!renderedElement) return;

      renderedElement.appendChild(timelineElement.cloneNode(true));

      onProgress?.((currentIndex + 1) / caseTimelineEventsByYear.length);

      requestIdleCallback(async () => {
        await wait(100);

        if (currentIndex < caseTimelineEventsByYear.length - 1) return setCurrentIndex(currentIndex + 1);

        setIsFinished(true);

        setTimeout(() => {
          requestIdleCallback(() => {
            onFinish?.();
            setCurrentIndex(-1);
          });
        });
      });
    });
  }, [currentIndex, client.id, prefix, currentYear, caseTimelineEventsByYear, onFinish, onProgress]);

  return (
    <CaseTimelineContextProvider
      tab={tab}
      keywords={[]}
      setSearchKeywords={() => {}}
      eventTypes={[]}
      setEventTypes={() => {}}
      fileGroups={[]}
      setFileGroups={() => {}}
      fileTypes={[]}
      setFileTypes={() => {}}
      confidenceLevels={[]}
      setConfidenceLevels={() => {}}
      displayOnlyAdditionalEvents={false}
      setDisplayOnlyAdditionalEvents={() => {}}
      displayOnlyImportantFiles={false}
      setDisplayOnlyImportantFiles={() => {}}
      forceShowEventDetails={forceShowEventDetails}
      isPrinting
      prefix={prefix}
    >
      <Flex
        direction="column"
        gap="sm"
        sx={{
          padding: '0 24px',
          minHeight: 'fit-content',
          overflow: 'visible',
          '.unprintable': {
            display: 'none',
          },
        }}
      >
        <Flex direction="column">
          <Text fw={600} color="dark.4" fz="1.125rem" align="center">
            Case Timeline
          </Text>

          <Text fw={500} color="dark.2" align="center" mt={-2}>
            {getClientDisplayName(client)}
          </Text>
        </Flex>

        <Box ref={renderedContainer} />

        {!isFinished && (
          <CaseTimeline timeline={timelineToRender} sortOrder={sortOrder} searchParamsDisabled isPrinting />
        )}
      </Flex>
    </CaseTimelineContextProvider>
  );
};

export const PrintableCaseTimeline = memo(PrintableCaseTimelineBase);
