import { useCallback } from 'react';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { fileQuery } from '@/pageAI/api';
import { UseFileUploadStatusProps } from './useFileUploadStatus.types';

export const useFileUploadStatus = () => {
  const { request } = useGraphQLRequest();

  const getFileById = useCallback(
    (id: string) => {
      return request(fileQuery, { id });
    },
    [request],
  );

  const startFileUploadStatusPolling = useCallback(
    ({ fileId, callback, seconds = 10 }: UseFileUploadStatusProps) => {
      const interval = setInterval(async () => {
        const { file } = await getFileById(fileId);

        return callback?.(file);
      }, seconds * 1000);

      return () => clearInterval(interval);
    },
    [getFileById],
  );

  return { startFileUploadStatusPolling };
};
