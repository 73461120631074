import { memo } from 'react';
import { Flex, ScrollArea, Text } from '@mantine/core';

import { OrganizationMemberMenuProps } from './OrganizationMemberMenu.types';
import { useOrganization } from '@/pageAI/hooks/organizations/useOrganization';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { User } from '@/pageAI/gql/graphql';
import { UserAvatar } from '@/pageAI/components/users/UserAvatar';

const OrganizationMemberMenuBase = ({ organizationId, onMemberSelect }: OrganizationMemberMenuProps) => {
  const { organization } = useOrganization(organizationId);

  if (!organization) return null;

  const handleMemberItemClick = (member: User) => (event: React.MouseEvent) => {
    event.stopPropagation();

    onMemberSelect?.(member);
  };

  return (
    <ScrollArea sx={{ height: 292, padding: 4 }}>
      <Flex direction="column" sx={{ minWidth: 200 }}>
        <Text fz="0.75rem" fw={500} color="dark.3" px={8} mt={8} mb={4}>
          Organization members
        </Text>

        {organization.members.map((member) => {
          if (!member) return null;

          return (
            <MenuItem
              key={member.user.id}
              title={
                <Flex align="center" gap={6}>
                  <UserAvatar user={member.user} size={24} />

                  {getUserDisplayName(member.user)}
                </Flex>
              }
              onClick={handleMemberItemClick(member.user)}
              sx={{ fontSize: '0.875rem' }}
            />
          );
        })}
      </Flex>
    </ScrollArea>
  );
};

export const OrganizationMemberMenu = memo(OrganizationMemberMenuBase);
