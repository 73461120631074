import { useMemo } from 'react';

import { ClientTab } from '@/pageAI/services/clients';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useInfiniteQuery } from '@tanstack/react-query';
import { clientsQuery, sharedClientsQuery } from '@/pageAI/api';
import { truthy } from '@/shared/utils/boolean';
import { ClientItem } from '@/pageAI/@types';
import { ClientOrderField, OrderDirection } from '@/pageAI/gql/graphql';

export interface UseClientsProps {
  tab?: ClientTab;
  first?: number;
  query?: string;
  assigneedIds?: string[];
  sortOrder?: OrderDirection;
  sortBy?: ClientOrderField;
}

export const DEFAULT_LIMIT = 20;

export const useClients = (props?: UseClientsProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError, fetchNextPage, isFetching, hasNextPage } = useInfiniteQuery({
    queryKey: ['clients', props?.tab, props?.first, props?.query, props?.assigneedIds, props?.sortOrder, props?.sortBy],
    queryFn: async ({ pageParam }) => {
      const options = {
        first: props?.first,
        after: pageParam,
        query: props?.query,
        assigneeIds: props?.assigneedIds,
        orderBy: {
          field: props?.sortBy || ClientOrderField.CreatedAt,
          direction: props?.sortOrder || OrderDirection.Desc,
        },
      };

      if (props?.tab === 'Shared with me') {
        const sharedClientResponse = await request(sharedClientsQuery, options);

        return sharedClientResponse.sharedClients;
      }

      const clientsResponse = await request(clientsQuery, options);

      return clientsResponse.viewer.organizations?.nodes?.[0]?.clients;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined;
    },
  });

  const { clients, pageInfo, totalCount } = useMemo(() => {
    if (!data) return { clients: [], pageInfo: null, totalCount: 0 };

    return {
      clients: data.pages.flatMap((page) => page?.nodes).filter(truthy) as ClientItem[],
      pageInfo: data.pages[data.pages.length - 1]?.pageInfo,
      totalCount: data.pages[0]?.totalCount || 0,
    };
  }, [data]);

  return {
    clients,
    pageInfo,
    totalCount,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};
