import { memo, useCallback, useMemo, useState } from 'react';
import { ActionIcon, Flex, Text, Tooltip, ScrollArea, Box, Divider, SegmentedControl } from '@mantine/core';
import { IconSortAscending2, IconSortDescending2 } from '@tabler/icons-react';

import { MedicalConditionsPanelProps } from './MedicalConditionsPanel.types';
import { Panel } from '@/pageAI/designSystem/Panel';
import { SortOrder } from '@/shared/@types';
import { categorizeConditions } from '@/pageAI/services/medicalConditions';
import { OldMedicalConditions } from '../OldMedicalConditions/MedicalConditions';

const MedicalConditionsPanelBase = ({ client, medicalConditions }: MedicalConditionsPanelProps) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [tab, setTab] = useState<'Summary' | 'Timeline'>('Summary');

  const handleChangeSortOrder = useCallback(() => {
    setSortOrder((currentSetOrder) => (currentSetOrder === 'desc' ? 'asc' : 'desc'));
  }, []);

  const { codesheetConditions, claimConditions, otherConditions } = useMemo(
    () => categorizeConditions(medicalConditions),
    [medicalConditions],
  );

  const renderMedicalConditionGroups = () => {
    return (
      <Flex direction="column">
        <OldMedicalConditions medicalConditions={codesheetConditions} tab={tab} />

        {claimConditions.length > 0 && (
          <>
            <Divider />

            <Flex align="center" px="md" pt="xs" pb={4}>
              <Text color="dark.4" fw={500}>
                Conditions from Claim Documents
              </Text>
            </Flex>

            <OldMedicalConditions medicalConditions={claimConditions} tab={tab} />
          </>
        )}

        {otherConditions.length > 0 && (
          <>
            <Divider />

            <Flex align="center" px="md" pt="xs" pb={4}>
              <Text color="dark.4" fw={500}>
                Potential Conditions
              </Text>
            </Flex>

            <OldMedicalConditions medicalConditions={otherConditions} tab={tab} />
          </>
        )}
      </Flex>
    );
  };

  return (
    <Box pb="md" pos="relative" sx={{ flex: '1 1' }}>
      <Panel sx={{ padding: '16px 0 0 0', gap: 0 }}>
        <Flex
          align="center"
          justify="space-between"
          sx={(theme) => ({ padding: '0 32px 12px', borderBottom: `1px solid ${theme.colors.gray[2]}` })}
          gap={6}
        >
          <Flex align="center" gap={6}>
            <Text fw={600} color="dark.4" fz="1.125rem">
              Conditions
            </Text>

            <SegmentedControl
              size="xs"
              data={['Summary', 'Timeline']}
              value={tab}
              onChange={(value) => setTab(value as 'Summary' | 'Timeline')}
              sx={{
                '.ghost-SegmentedControl-label': {
                  width: 96,
                },
              }}
            />

            <Tooltip
              label={sortOrder === 'desc' ? 'Sorting latest to oldest' : 'Sorting oldest to latest'}
              withinPortal
              withArrow
            >
              <ActionIcon onClick={handleChangeSortOrder}>
                {sortOrder === 'desc' ? <IconSortDescending2 size={20} /> : <IconSortAscending2 size={20} />}
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>

        <ScrollArea sx={{ height: 'calc(100vh - 112px)' }}>{renderMedicalConditionGroups()}</ScrollArea>
      </Panel>
    </Box>
  );
};

export const OldMedicalConditionsPanel = memo(MedicalConditionsPanelBase);
