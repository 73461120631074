import { forwardRef, memo, useCallback } from 'react';
import { Anchor, Badge } from '@mantine/core';

import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { useClientFileAnchorReference } from '@/pageAI/hooks/files/useClientFileAnchorReference';
import {
  extractFileInfoOfClientFromHref,
  extractPageNumbersFromReference,
  normalizeCaseTimelineItemReferencedTexts,
} from '@/pageAI/services/summaries';
import { setHighlightedTexts, setPagesToHighlight } from '@/shared/states/filePreview';
import { fillNumberArray } from '@/shared/utils/array';
import { SummaryFileReferenceBadgeProps } from './SummaryFileReferenceBadge.types';

const SummaryFileReferenceBadgeBase = (
  {
    client,
    reference,
    referencedTexts,
    isHighlighted = false,
    isPrinting = false,
    getPrintingHref,
    onClick,
  }: SummaryFileReferenceBadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const { handleOpenFileReferenceByHref } = useClientFileAnchorReference(client);

  const openFileWithReference = useCallback(
    (href: string) => {
      const normalizedReferencedTexts = normalizeCaseTimelineItemReferencedTexts(referencedTexts || []);

      const { startPage, endPage } = extractPageNumbersFromReference(reference);

      setHighlightedTexts(normalizedReferencedTexts);

      if (!Number.isNaN(startPage) || !Number.isNaN(endPage)) {
        let pageIndicesToHighlight: number[] = [];

        if (!Number.isNaN(startPage) && !Number.isNaN(endPage))
          pageIndicesToHighlight = fillNumberArray(startPage - 1, endPage - 1);
        else if (Number.isNaN(endPage)) pageIndicesToHighlight = [startPage - 1];

        setPagesToHighlight(pageIndicesToHighlight);
      }

      return handleOpenFileReferenceByHref(href);
    },
    [reference, referencedTexts, handleOpenFileReferenceByHref],
  );

  const ReferenceAnchorRenderer = ({ href, children }: { href?: string; children?: React.ReactNode }) => {
    if (!href) return null;

    const handleClick = (event: React.MouseEvent) => {
      if (!href) return;

      event.preventDefault();

      if (openFileWithReference(href)) onClick?.();
    };

    const { fileAsset, pageIndex } = isPrinting
      ? extractFileInfoOfClientFromHref(client, href)
      : { fileAsset: undefined, pageIndex: undefined };

    const actualHref = isPrinting ? getPrintingHref?.(href, fileAsset, pageIndex) || window.location.href : href;

    return (
      <Anchor
        href={actualHref}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        color="blue.8"
        className="reference-badge"
      >
        {children}
      </Anchor>
    );
  };

  return (
    <Badge
      ref={ref}
      component="span"
      sx={{
        '> p': { display: 'inline' },
        fontWeight: 500,
        textTransform: 'none',
        ...(isPrinting
          ? {
              whiteSpace: 'nowrap',
              width: 'fit-content',
            }
          : {}),
      }}
      {...(isHighlighted && !isPrinting
        ? {
            color: 'yellow.3',
            variant: 'filled',
          }
        : { color: 'gray' })}
    >
      <MarkdownRenderer content={reference} components={{ a: ReferenceAnchorRenderer }} />
    </Badge>
  );
};

export const SummaryFileReferenceBadge = memo(forwardRef(SummaryFileReferenceBadgeBase));
