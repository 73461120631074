import { memo } from 'react';
import { Box, Flex, Spoiler, Text } from '@mantine/core';

import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { getEventSummariesTypeDisplayText } from '@/pageAI/services/caseTimeline';
import { TimelineEventSummaryBoxProps } from './TimelineEventSummaryBox.types';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

const TimelineEventSummaryBoxBase = ({ style, event }: TimelineEventSummaryBoxProps) => {
  const summaryType = event.summariesTypes?.[0];

  if (!summaryType) return null;

  const renderContent = () => {
    return (
      <ol>
        {event.summaries?.map((summary) => (
          <li key={summary}>
            <MarkdownRenderer content={summary} />
          </li>
        ))}
      </ol>
    );
  };

  return (
    <Box
      style={style}
      sx={(theme) => ({
        padding: 12,
        background: theme.colors.gray[1],
        borderRadius: 8,
        ol: { paddingLeft: 24 },
        fontSize: '0.875rem',
        hr: {
          border: 'none',
          marginTop: 4,
        },
      })}
    >
      <Flex align="center" gap={6} mb={4}>
        <Text fz="0.75rem" fw={600} color="dark.4">
          {getEventSummariesTypeDisplayText(summaryType)}
        </Text>
      </Flex>

      <Spoiler
        maxHeight={326}
        showLabel={
          <Flex align="center" gap={2}>
            <Text fz="0.75rem" fw={600}>
              Show more
            </Text>

            <IconChevronDown size={14} />
          </Flex>
        }
        hideLabel={
          <Flex align="center" gap={2}>
            <Text fz="0.75rem" fw={600}>
              Show less
            </Text>

            <IconChevronUp size={14} />
          </Flex>
        }
      >
        {renderContent()}
      </Spoiler>
    </Box>
  );
};

export const TimelineEventSummaryBox = memo(TimelineEventSummaryBoxBase);
