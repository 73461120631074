import { create } from 'zustand';

import { ClientState } from './client.states.types';
import { Client } from '@/pageAI/@types';

export const useClientStore = create<ClientState>((set) => ({
  selectedClient: null,
}));

export const setSelectedClient = (client: Client | null) => {
  useClientStore.setState({ selectedClient: client });
};
