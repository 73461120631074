import { memo } from 'react';
import { Badge } from '@mantine/core';

import { EventTypeBadgeProps } from './EventTypeBadge.types';
import { TIMELINE_EVENT_TYPE_COLOR_MAPPING } from '@/pageAI/services/caseTimeline';
import { capitalize } from 'lodash-es';

const EventTypeBadgeBase = ({ eventType }: EventTypeBadgeProps) => {
  eventType = capitalize(eventType || '') as keyof typeof TIMELINE_EVENT_TYPE_COLOR_MAPPING;

  return (
    <Badge
      sx={{ textTransform: 'none' }}
      color={TIMELINE_EVENT_TYPE_COLOR_MAPPING[eventType as keyof typeof TIMELINE_EVENT_TYPE_COLOR_MAPPING]}
      variant="outline"
      size="xs"
    >
      {eventType}
    </Badge>
  );
};

export const EventTypeBadge = memo(EventTypeBadgeBase);
