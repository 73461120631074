export const keys = <T extends Record<string, unknown>>(object: T) => Object.keys(object) as (keyof T)[];

export const safelyParseJSON = (json: string) => {
  try {
    const parsedJSON = JSON.parse(json);

    if (typeof parsedJSON !== 'object') return {};

    return parsedJSON;
  } catch (error) {
    return {};
  }
};

export const isJSON = (text: string) => {
  try {
    JSON.parse(text);
  } catch (error) {
    return false;
  }

  return true;
};

export const isTabular = (json: Record<string, unknown>) => {
  if (!Array.isArray(json) || json.length === 0 || typeof json[0] !== 'object') {
    // Not an array of objects
    return false;
  }

  const firstObjectKeys = Object.keys(json[0]);

  for (let i = 1; i < json.length; i++) {
    const currentObjectKeys = Object.keys(json[i]);

    if (currentObjectKeys.length !== firstObjectKeys.length) {
      // Different number of properties
      return false;
    }

    for (let j = 0; j < firstObjectKeys.length; j++) {
      if (currentObjectKeys.indexOf(firstObjectKeys[j]) === -1) {
        // Property mismatch
        return false;
      }
    }
  }

  return true;
};

type NonNullishObject<T> = {
  [K in keyof T as Exclude<T[K], null | undefined> extends never ? never : K]: Exclude<T[K], null | undefined>;
};

export const filterNullishKeys = <T extends Record<string, unknown>>(object: T, keysToSkip?: (keyof T)[]) => {
  const newObject = { ...object };

  keys(newObject).forEach((key) => {
    if (keysToSkip?.includes(key)) return;

    if (newObject[key] === null || newObject[key] === undefined) {
      delete newObject[key];
    }
  });

  return newObject as NonNullishObject<T>;
};
