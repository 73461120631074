import { memo } from 'react';

import { LeftSidebarType, useLayoutStore } from '@/shared/states/layout';
import { ResearchConversationSidebar } from '../../research/ResearchConversationSidebar';

const LeftSubSidebarBase = () => {
  const { leftSidebarType } = useLayoutStore();

  if (leftSidebarType === LeftSidebarType.CONVERSATIONS) {
    return <ResearchConversationSidebar />;
  }

  return null;
};

export const LeftSubSidebar = memo(LeftSubSidebarBase);
