import { memo } from 'react';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconShare } from '@tabler/icons-react';

import { ClientHeaderActionsProps } from './ClientHeaderActions.types';
import { DeleteClientButton } from '../DeleteClientButton';
import { UpsertClientModal } from '../UpsertClientModal';
import { ShareClientModal } from '../ShareClientModal';

const ClientHeaderActionsBase = ({ client }: ClientHeaderActionsProps) => {
  const [isUpsertClientModalOpened, { open: openUpsertClientModal, close: closeUpsertClientModal }] = useDisclosure();
  const [isShareClientModalOpened, { open: openShareClientModal, close: closeShareClientModal }] = useDisclosure();

  if (!client) return null;

  return (
    <Flex direction="column" align="flex-end" sx={(theme) => ({ alignSelf: 'flex-start' })}>
      <Flex sx={{ width: '100%' }} justify="flex-end">
        {client.viewerCanShare && (
          <Tooltip label="Share this client" withinPortal withArrow>
            <ActionIcon onClick={openShareClientModal}>
              <IconShare size={16} />
            </ActionIcon>
          </Tooltip>
        )}

        {client.viewerCanUpdate && (
          <Tooltip label="Edit client's info" withinPortal withArrow>
            <ActionIcon onClick={openUpsertClientModal}>
              <IconEdit size={16} />
            </ActionIcon>
          </Tooltip>
        )}

        <DeleteClientButton client={client} color="red.4" />
      </Flex>

      <UpsertClientModal opened={isUpsertClientModalOpened} isEdit client={client} onClose={closeUpsertClientModal} />

      <ShareClientModal opened={isShareClientModalOpened} client={client} onClose={closeShareClientModal} />
    </Flex>
  );
};

export const ClientHeaderActions = memo(ClientHeaderActionsBase);
