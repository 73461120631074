import { memo, useEffect, useRef, useState } from 'react';
import { ActionIcon, Anchor, Flex, SegmentedControl, Tooltip } from '@mantine/core';
import { IconExternalLink, IconX } from '@tabler/icons-react';

import { closeWebPreviewer, useWebPreviewStore } from '@/shared/states/webPreview';
import { WebPreviewer } from '../WebPreviewer';

const buttonTabOptions = [
  {
    value: 'Web',
    label: 'Web',
  },
  {
    value: 'Text',
    label: 'Text',
  },
];

const WebPreviewerContainerBase = () => {
  const { url } = useWebPreviewStore();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerY, setContainerY] = useState(0);
  const [activeTab, setActiveTab] = useState<'Text' | 'Web'>('Web');

  useEffect(() => {
    if (!containerRef.current) return;

    const { y } = containerRef.current.getBoundingClientRect();

    setContainerY(y);
  }, [containerRef]);

  const handleChangeTab = (value: string) => {
    setActiveTab(value as 'Text' | 'Web');
  };

  return (
    <Flex ref={containerRef} direction="column" sx={{ height: `calc(100vh - ${containerY}px)` }}>
      <Flex align="center" justify="center" sx={{ width: '100%', position: 'relative' }}>
        <Flex align="center" gap={4} ml={32} mt="sm">
          <SegmentedControl
            value={activeTab}
            data={buttonTabOptions}
            onChange={handleChangeTab}
            size="xs"
            sx={(theme) => ({ backgroundColor: theme.colors.gray[3], width: 120 })}
          />

          <Tooltip label={url}>
            <Anchor href={url} target="_blank" rel="noopener noreferrer">
              <ActionIcon color="dark">
                <IconExternalLink size={16} />
              </ActionIcon>
            </Anchor>
          </Tooltip>
        </Flex>

        <ActionIcon onClick={closeWebPreviewer} sx={{ position: 'absolute', right: 12, top: 14 }} color="dark">
          <IconX size={16} />
        </ActionIcon>
      </Flex>

      <Flex sx={{ padding: '12px 12px 16px', height: '100%', minHeight: 0 }} direction="column">
        <WebPreviewer url={url} mode={activeTab} setActiveMode={setActiveTab} />
      </Flex>
    </Flex>
  );
};

export const WebPreviewerContainer = memo(WebPreviewerContainerBase);
