import { truthy } from '@/shared/utils/boolean';
import { keys } from '@/shared/utils/object';
import { markdownToTextParts, separateTextByLineBreaks } from '@/shared/utils/string';

export const parseTextFragmentDirective = (textFragment: string) => {
  const TEXT_FRAGMENT = /^(?:(.+?)-,)?(?:(.+?))(?:,([^-]+?))?(?:,-(.+?))?$/;

  const normalizeTextFragment = (replacement: string) => {
    const decoded = decodeURIComponent(textFragment.replace(TEXT_FRAGMENT, replacement));

    return separateTextByLineBreaks(decoded)[0] || decoded;
  };

  return {
    prefix: normalizeTextFragment('$1'),
    textStart: normalizeTextFragment('$2'),
    textEnd: normalizeTextFragment('$3'),
    suffix: normalizeTextFragment('$4'),
  };
};

export const parseTextFragmentToParts = (fragment: string) => {
  const textFragmentDirectives = parseTextFragmentDirective(fragment);

  return keys(textFragmentDirectives)
    .map((key) => markdownToTextParts(textFragmentDirectives[key]))
    .flat()
    .filter(truthy);
};
