import { memo } from 'react';

import { FileSearchContextProviderProps } from './FileSearchContext.types';
import { FileSearchContext } from './FileSearchContext';

const FileSearchContextProviderBase = ({
  fileGroups,
  setFileGroups,
  fileTypes,
  setFileTypes,
  children,
}: FileSearchContextProviderProps) => {
  return (
    <FileSearchContext.Provider
      value={{
        fileGroups,
        setFileGroups,
        fileTypes,
        setFileTypes,
      }}
    >
      {children}
    </FileSearchContext.Provider>
  );
};

export const FileSearchContextProvider = memo(FileSearchContextProviderBase);
