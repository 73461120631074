import { memo } from 'react';
import { Flex, Loader } from '@mantine/core';

import { LoadingPlaceholderProps } from './LoadingPlaceholder.types';
import { DataEmptyPlaceholder } from '@/pageAI/components/clients/DataEmptyPlaceholder';

const LoadingPlaceholderBase = ({
  title = 'Loading...',
  description = 'Please wait while data is being loaded.',
  fullScreen = false,
}: LoadingPlaceholderProps) => {
  const loadingPlaceholder = (
    <DataEmptyPlaceholder icon={<Loader size={40} />} title={title} description={description} />
  );

  if (fullScreen) {
    return (
      <Flex sx={{ width: '100%', height: '100%' }} align="center" justify="center">
        {loadingPlaceholder}
      </Flex>
    );
  }

  return loadingPlaceholder;
};

export const LoadingPlaceholder = memo(LoadingPlaceholderBase);
