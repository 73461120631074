import { useCallback, useRef } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { isEmpty } from 'lodash-es';

import { getItemFromStorage } from '@/shared/lib/storage';
import { safelyParseJSON } from '@/shared/utils/object';

export const PERSISTENT_FILE_TYPE_FILTERS_STORAGE_KEY = 'file_type_filters_by_client';

export const usePersistentFileTypeFilters = (clientId: string) => {
  const defaultValueRef = useRef(safelyParseJSON(getItemFromStorage(PERSISTENT_FILE_TYPE_FILTERS_STORAGE_KEY) || ''));

  const [fileTypeFiltersByClient, setFileTypeFiltersByClient] = useLocalStorage<Record<string, string[] | null>>({
    key: PERSISTENT_FILE_TYPE_FILTERS_STORAGE_KEY,
    defaultValue: isEmpty(defaultValueRef.current) ? {} : defaultValueRef.current,
  });

  const fileTypeFilters = fileTypeFiltersByClient[clientId] || [];

  const setFileTypeFilters = useCallback(
    (value: string[] | null) => {
      setFileTypeFiltersByClient((current) => ({
        ...current,
        [clientId]: value,
      }));
    },
    [clientId, setFileTypeFiltersByClient],
  );

  return [fileTypeFilters, setFileTypeFilters] as const;
};
