import { useMutation } from '@tanstack/react-query';

import { RequestToJoinOrganizationInput, RequestToJoinOrganizationMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { requestToJoinOrganizationMutation } from '@/pageAI/api';

export const useRequestToJoinOrganization = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const { mutateAsync: requestToJoinOrganization, isLoading } = useMutation<
    RequestToJoinOrganizationMutation,
    Error,
    RequestToJoinOrganizationInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(requestToJoinOrganizationMutation, { input });

      return responseBody;
    },
    onError: () => {
      notify('Error', 'Could not request to join the organization');
    },
  });

  return { requestToJoinOrganization, isLoading };
};
