import { useMemo } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import { ClientOrderField, OrderDirection } from '@/pageAI/gql/graphql';
import { getItemFromStorage } from '@/shared/lib/storage';

export const PERSISTENT_CLIENT_SORT_BY_STORAGE_KEY = 'clients_sort_by';
export const PERSISTENT_CLIENT_SORT_ORDER_STORAGE_KEY = 'clients_sort_order';

export const useClientSorting = () => {
  const currentSortBy = useMemo(
    () => getItemFromStorage(PERSISTENT_CLIENT_SORT_BY_STORAGE_KEY) as ClientOrderField | null,
    [],
  );
  const [sortBy, setSortBy] = useLocalStorage<ClientOrderField>({
    key: PERSISTENT_CLIENT_SORT_BY_STORAGE_KEY,
    defaultValue: currentSortBy || ClientOrderField.CreatedAt,
  });

  const currentSortOrder = useMemo(
    () => getItemFromStorage(PERSISTENT_CLIENT_SORT_ORDER_STORAGE_KEY) as OrderDirection | null,
    [],
  );
  const [sortOrder, setSortOrder] = useLocalStorage<OrderDirection>({
    key: PERSISTENT_CLIENT_SORT_ORDER_STORAGE_KEY,
    defaultValue: currentSortOrder || OrderDirection.Desc,
  });

  return { sortBy, setSortBy, sortOrder, setSortOrder };
};
