import { memo, useCallback } from 'react';
import { Flex, Switch, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { SettingsSwitchProps } from './SettingsSwitch.types';

const SettingsSwitchBase = ({ label, tooltip, checked, onChange }: SettingsSwitchProps) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked);
    },
    [onChange],
  );

  return (
    <Flex align="center">
      <Flex align="center" sx={{ width: 240 }} gap={6}>
        <Text fw={500} fz="0.875rem" color="dark.4">
          {label}
        </Text>

        <Tooltip label={tooltip} withinPortal withArrow>
          <Flex sx={(theme) => ({ color: theme.colors.gray[5] })}>
            <IconInfoCircle size={16} />
          </Flex>
        </Tooltip>
      </Flex>

      <Switch size="sm" checked={checked} onChange={handleChange} />
    </Flex>
  );
};

export const SettingsSwitch = memo(SettingsSwitchBase);
