import { useCallback } from 'react';
import { useLocalStorage } from '@mantine/hooks';

export const PERSISTENT_FILE_PREVIEW_SIZE_STORAGE_KEY = 'file_preview_size_by_client';

export const usePersistentFilePreviewSize = (clientId?: string) => {
  const [sizeByClient, setSizeByClient] = useLocalStorage<Record<string, number>>({
    key: PERSISTENT_FILE_PREVIEW_SIZE_STORAGE_KEY,
    defaultValue: {},
  });

  const size = sizeByClient[clientId || ''] || 40;

  const setSize = useCallback(
    (value: number) => {
      if (!clientId) return;

      setSizeByClient((current) => ({
        ...current,
        [clientId]: value,
      }));
    },
    [clientId, setSizeByClient],
  );

  return [size, setSize] as const;
};
