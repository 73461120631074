import { useCallback } from 'react';
import { useLocalStorage } from '@mantine/hooks';

import { NumberOfFilesByClient } from './useEnsureClientFilesUploaded.types';

const ENSURE_CLIENT_FILES_UPLOAD_STORAGE_KEY = 'num_of_files_by_client';

export const useEnsureClientFilesUploaded = (clientId: string) => {
  const [value, setValue] = useLocalStorage<NumberOfFilesByClient>({
    key: ENSURE_CLIENT_FILES_UPLOAD_STORAGE_KEY,
    defaultValue: {},
  });

  const saveNumberOfFiles = useCallback(
    (numberOfFiles: number) => {
      setValue({ ...value, [clientId]: numberOfFiles });
    },
    [value, setValue, clientId],
  );

  return {
    numberOfFiles: value[clientId] || 0,
    saveNumberOfFiles,
  };
};
