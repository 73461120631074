import { graphql } from '@/pageAI/gql';

export const generateSummariesMutation = graphql(/* GraphQL */ `
  mutation GenerateSummaries($input: GenerateCaseTimelineInput!) {
    generateCaseTimeline(input: $input) {
      id
      firstName
      lastName
      bio
      overview
      caseTimelineStatus
      summaryByConditionStatus
      createdAt
      updatedAt
    }
  }
`);

export const generateSummaryByConditionMutation = graphql(/* GraphQL */ `
  mutation GenerateSummaryByCondition($input: GenerateSummaryByConditionInput!) {
    generateSummaryByCondition(input: $input) {
      id
      firstName
      lastName
      bio
      overview
      caseTimelineStatus
      summaryByConditionStatus
      createdAt
      updatedAt
    }
  }
`);

export const bookmarkClientTimelineEventMutation = graphql(/* GraphQL */ `
  mutation BookmarkClientTimelineEvent($input: BookmarkClientTimelineEventInput!) {
    bookmarkClientTimelineEvent(input: $input) {
      id
    }
  }
`);

export const unbookmarkClientTimelineEventMutation = graphql(/* GraphQL */ `
  mutation UnbookmarkClientTimelineEvent($input: UnbookmarkClientTimelineEventInput!) {
    unbookmarkClientTimelineEvent(input: $input) {
      id
    }
  }
`);

export const updateClientTimelineEventMutation = graphql(/* GraphQL */ `
  mutation UpdateClientTimelineEvent($input: UpdateClientTimelineEventInput!) {
    updateClientTimelineEvent(input: $input) {
      id
      eventType
      summaries
      summariesTypes
      details
      detailsTypes
      date
      duplicated
      confidenceLevel
      reference
      referencedText
      verificationLevel
      bookmarked
      generation
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`);

export const getTimelineOperationSubscriptionQuery = () => {
  return `subscription TimelineOperationSubscription {
    event {
      ... on ClientTimelineEventUpdated {
        __typename
        clientTimelineEvent {
          id
          eventType
          summaries
          summariesTypes
          details
          detailsTypes
          date
          duplicated
          confidenceLevel
          reference
          referencedText
          verificationLevel
          bookmarked
          generation
          metadata {
            key
            value
          }
          createdAt
          updatedAt
          client {
            id
          }
        }
        issuedAt
        issuingPrincipal {
          ... on User {
            id
            firstName
            lastName
            email
            avatarUrl
            phone
            role
            createdAt
            updatedAt
          }
        }
      }
    }
  }`;
};

export const clientTimelineEventsQuery = graphql(/* GraphQL */ `
  query ClientTimelineEvents($id: ID!) {
    client(id: $id) {
      id
      timelineEvents(first: 9999) {
        nodes {
          id
          eventType
          summaries
          summariesTypes
          details
          detailsTypes
          date
          duplicated
          confidenceLevel
          reference
          referencedText
          verificationLevel
          bookmarked
          generation
          metadata {
            key
            value
          }
          viewerCanBookmark
          viewerCanComment
          viewerCanDelete
          viewerCanUnbookmark
          viewerCanUpdate
          viewerCanHide
          viewerCanUnhide
          createdAt
          updatedAt
          comments(first: 9999) {
            nodes {
              id
              content
              viewerCanDelete
              viewerCanUpdate
              viewerDidAuthor
              createdAt
              updatedAt
              author {
                id
                firstName
                lastName
                email
                avatarUrl
                phone
                role
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`);

export const clientMedicalConditionsQuery = graphql(/* GraphQL */ `
  query ClientMedicalConditions($id: ID!) {
    client(id: $id) {
      id
      medicalConditions(first: 9999) {
        nodes {
          id
          isMiscellaneous
          headerCondition
          subConditions
          conditionType
          conditionStatus
          conditionOrder
          isServiceConnected
          rating
          diagnosticCode

          mergedConditions

          latestCodesheet {
            date
            fileId
            reference
            summary
          }

          allCodesheets {
            date
            fileId
            reference
            summary
          }

          proceduralHistory {
            applicableItf {
              date
              fileId
              reference
              summary
            }
            filings {
              bvaAppeals {
                date
                fileId
                reference
                summary
              }
              disagreementNotices {
                date
                fileId
                reference
                summary
              }
              initialClaims {
                date
                fileId
                reference
                summary
              }
              requestsForHighLevelReview {
                date
                fileId
                reference
                summary
              }
              supplementalClaims {
                date
                fileId
                reference
                summary
              }
              bvaDisagreementNotices {
                date
                fileId
                reference
                summary
              }
            }
            firstDecision {
              date
              fileId
              reference
              summary
            }
            dateOfOriginalClaim {
              date
              fileId
              reference
              summary
            }
            otherDecisions {
              date
              fileId
              reference
              summary
            }
          }

          medicalEvidence {
            disabilityBenefitsQuestionnaires {
              date
              fileId
              reference
              summary
            }
            medicalOpinions {
              date
              fileId
              reference
              summary
            }
            addendumOpinions {
              date
              fileId
              reference
              summary
            }
          }

          otherEvidence {
            socStatements {
              date
              fileId
              reference
              summary
            }
            favorableFindings {
              date
              fileId
              reference
              summary
            }
            layStatements {
              date
              fileId
              reference
              summary
            }
            additionalEvidence {
              date
              fileId
              reference
              summary
            }
          }

          relatedTimelineEvents {
            id
            eventType
            summaries
            summariesTypes
            details
            detailsTypes
            date
            duplicated
            confidenceLevel
            reference
            referencedText
            verificationLevel
            bookmarked
            generation
            metadata {
              key
              value
            }
            viewerCanBookmark
            viewerCanComment
            viewerCanDelete
            viewerCanUnbookmark
            viewerCanUpdate
            viewerCanHide
            viewerCanUnhide
            createdAt
            updatedAt
            comments(first: 9999) {
              nodes {
                id
                content
                viewerCanDelete
                viewerCanUpdate
                viewerDidAuthor
                createdAt
                updatedAt
                author {
                  id
                  firstName
                  lastName
                  email
                  avatarUrl
                  phone
                  role
                  createdAt
                  updatedAt
                }
              }
            }
          }

          generation
          createdAt
          updatedAt
        }
      }
    }
  }
`);
