import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActionIcon, Flex, Skeleton, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconNews, IconNewsOff, IconSearch } from '@tabler/icons-react';

import { Panel } from '@/pageAI/designSystem/Panel';
import { setHighlightedTexts } from '@/shared/states/filePreview';
import { singularOrPlural } from '@/shared/utils/string';
import { ClientFileSearchPanelProps } from './ClientFileSearchPanel.types';
import { ClientFileSearchTable } from '../../files/ClientFileSearchTable';
import { useSearchClientFiles } from '@/pageAI/hooks/files/useSearchClientFiles/useSearchClientFiles';
import { closeRightSidebar } from '@/shared/states/layout';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { TableNavigationHint } from '../../files/TableNavigationHint';
import { DataEmptyPlaceholder } from '../DataEmptyPlaceholder';
import { FileFilters } from '../../files/FileFilters';
import { useFileSearchContext } from '@/pageAI/contexts/fileSearchContext';
import { filterFileSearchResults } from '@/pageAI/services/files';
import { useFileFilterSearchParams } from '@/pageAI/hooks/files/useFileFilterSearchParams';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';
import { getClientDisplayName } from '@/pageAI/services/clients';
import { FileSearchResultQuickNavigation } from '../../files/search/FileSearchResultQuickNavigation';

const ClientFileSearchPanelBase = ({ client }: ClientFileSearchPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { fileGroups, fileTypes } = useFileSearchContext();
  useFileFilterSearchParams({ fileGroups, fileTypes });

  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const form = useForm({
    initialValues: {
      searchValue: searchParams.get('query') || '',
    },
  });
  const [query, setQuery] = useState(form.values.searchValue);

  const { results = [], isLoading, isError } = useSearchClientFiles(client.id, query);

  const filteredResults = useMemo(() => filterFileSearchResults(results, fileTypes), [results, fileTypes]);

  const confirmSearch = () => {
    setQuery(form.values.searchValue);
    setSearchParams({ query: form.values.searchValue }, { replace: true });
  };

  const handleSubmit = () => {
    confirmSearch();
  };

  useEffect(() => {
    return () => {
      setHighlightedTexts([]);
      closeRightSidebar();
    };
  }, []);

  const renderBody = () => {
    if (isError) {
      return <ErrorPlaceholder />;
    }

    if (isLoading) {
      return (
        <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 164px)' }}>
          <LoadingPlaceholder
            title="Searching..."
            description={`Page is looking through ${
              client.fileCollections.nodes[0].files?.nodes.length || 0
            } files of ${getClientDisplayName(client)}.`}
          />
        </Flex>
      );
    }

    if (!filteredResults.length) {
      return (
        <Flex
          align="center"
          justify="center"
          sx={{ width: '100%', height: 'calc(100vh - 164px)', paddingBottom: '10vh' }}
        >
          {query ? (
            <DataEmptyPlaceholder
              icon={<IconNewsOff size={80} />}
              title="No results"
              description="Nothing was found. Please try with a different input."
            />
          ) : (
            <DataEmptyPlaceholder
              icon={<IconNews size={80} />}
              title="Enter keywords"
              description="Type your keywords in the search box and press Enter to start."
            />
          )}
        </Flex>
      );
    }

    return <ClientFileSearchTable results={filteredResults} query={query} />;
  };

  return (
    <Panel ref={tableContainerRef} sx={{ gap: 4, paddingTop: 0 }}>
      <Flex
        align="center"
        justify="space-between"
        gap="xs"
        pt={16}
        pb="sm"
        sx={(theme) => ({ position: 'sticky', top: 0, zIndex: 101, background: theme.white })}
      >
        <Flex align="center" gap="xs">
          <Flex align="center" gap={2}>
            <Flex align="center" justify="center" ml={-4}>
              <FileSearchResultQuickNavigation results={results} />
            </Flex>

            <Text fw={600} color="dark.4" sx={{ whiteSpace: 'nowrap' }}>
              Search Results
            </Text>
          </Flex>

          <Flex sx={(theme) => ({ width: 4, height: 4, background: theme.colors.dark[4], borderRadius: '50%' })} />

          {isLoading ? (
            <Skeleton width={64} height={22} />
          ) : (
            <Text fw={500} fz="0.875rem" color="dark.3">
              <>
                {filteredResults.length}&nbsp;
                {singularOrPlural('result', 'results')(filteredResults.length)}
              </>
            </Text>
          )}

          <TableNavigationHint />
        </Flex>

        <Flex align="center" gap="xs">
          <FileFilters client={client} />

          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              {...form.getInputProps('searchValue')}
              w={400}
              placeholder="Enter keywords..."
              rightSection={
                <ActionIcon onClick={confirmSearch} variant="filled" color="brand.4">
                  <IconSearch size={16} />
                </ActionIcon>
              }
            />
          </form>
        </Flex>
      </Flex>

      {renderBody()}
    </Panel>
  );
};

export const ClientFileSearchPanel = memo(ClientFileSearchPanelBase);
