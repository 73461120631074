import { memo } from 'react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand } from '@tabler/icons-react';

import { MedicalConditionTitleProps } from './MedicalConditionTitle.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { CombinedRatingBadge } from '../CombinedRatingBadge';

const MedicalConditionTitleBase = ({ isSidebarOpened = false, onClick }: MedicalConditionTitleProps) => {
  const client = useSelectedClient(true);

  return (
    <Flex className="header" align="center" gap={2} ml={-6} pos="relative">
      <Tooltip label="Toggle condition sidebar" withArrow withinPortal>
        <ActionIcon color="gray.8" onClick={onClick}>
          {isSidebarOpened ? <IconLayoutSidebarLeftCollapse size={20} /> : <IconLayoutSidebarLeftExpand size={20} />}
        </ActionIcon>
      </Tooltip>

      <Flex gap="xs" sx={{ overflow: 'hidden' }}>
        <Text
          color="dark"
          fw={600}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          Condition Summary
        </Text>

        <CombinedRatingBadge client={client} />
      </Flex>
    </Flex>
  );
};

export const MedicalConditionTitle = memo(MedicalConditionTitleBase);
