import isHtmlLib from 'is-html';
import sanitizeHtmlLib from 'sanitize-html';
import { marked } from 'marked';
import TurndownService from 'turndown';
import { Readability } from '@mozilla/readability';
import { escapeRegExp } from 'lodash-es';

import { CleanHtmlOptions } from './html.types';
import { replaceUrl } from '@/shared/utils/url';

export const replaceElementTag = (element: HTMLElement | null, newTag = 'div') => {
  if (!element) return;

  const parentElement = element.parentNode;

  if (!parentElement) return;

  const newElement = document.createElement(newTag);

  while (element.firstChild) {
    newElement.appendChild(element.firstChild);
  }

  parentElement.replaceChild(newElement, element);

  return newElement;
};

const INLINE_TAGS = [
  'A',
  'STRONG',
  'EM',
  'SPAN',
  'ABBR',
  'CITE',
  'CODE',
  'IMG',
  'INPUT',
  'LABEL',
  'Q',
  'CITE',
  'TIME',
  'ABBR',
  'DEL',
  'INS',
  'SUB',
  'SUP',
  'SMALL',
];

const isInlineTag = (htmlTag: string) => {
  return INLINE_TAGS.includes(htmlTag.toUpperCase());
};

const stripAttributesAndTags = (element?: HTMLElement | null, options?: CleanHtmlOptions) => {
  if (!element) return;

  if (element.nodeType === Node.ELEMENT_NODE) {
    for (let index = element.attributes.length - 1; index >= 0; index--) {
      if (options?.preservedAttributes && options.preservedAttributes.includes(element.attributes[index].name))
        continue;

      element.removeAttribute(element.attributes[index].name);
    }

    if (options?.allowedTags && !options.allowedTags.includes(element.tagName.toLowerCase()) && element.parentNode) {
      element = replaceElementTag(element, isInlineTag(element.tagName) ? 'span' : 'div');
    }
  }

  if (!element) return;

  for (let index = 0; index < element.childNodes.length; index++) {
    stripAttributesAndTags(element.childNodes[index] as HTMLElement | null, options);
  }
};

export const cleanHtmlContent = (htmlContent: string, options?: CleanHtmlOptions) => {
  const root = document.createElement('div');

  root.innerHTML = htmlContent;

  for (let index = 0; index < root.childNodes.length; index++) {
    stripAttributesAndTags(root.childNodes[index] as HTMLElement | null, options);
  }

  const cleanedHtmlContent = root.innerHTML;

  root.remove();

  return cleanedHtmlContent;
};

export const isHtml = (text: string) => {
  return isHtmlLib(text);
};

export const containsHtml = (text: string) => {
  const htmlRegex = /<[^>]*>/g;

  return htmlRegex.test(text);
};

export const stripHtml = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  return doc.body.textContent || '';
};

export const removeRedundantWrappingParagraphTag = (html: string) => {
  if (html.startsWith('<p>') && html.endsWith('</p>')) return html.slice(3, -4);

  return html;
};

export const sanitizeHtml = (html: string) => {
  return sanitizeHtmlLib(html);
};

export const markdownToHtml = (markdown: string) => {
  return marked.parse(markdown) as string;
};

const turndownService = new TurndownService({
  headingStyle: 'atx',
  bulletListMarker: '-',
  codeBlockStyle: 'fenced',
  emDelimiter: '*',
  fence: '```',
  strongDelimiter: '**',
  linkStyle: 'inlined',
});

export const htmlToMarkdown = (html: string) => {
  return turndownService.turndown(html);
};

export const getReadingContentFromHtml = (html: string) => {
  const clonedDocument = document.cloneNode(true) as Document;

  clonedDocument.body.innerHTML = html;

  const reader = new Readability(clonedDocument);

  const article = reader.parse();

  return article?.content;
};

export const wrapTextWithTag = (htmlContent: string, searchText: string, tag: string, className?: string) => {
  if (!searchText) return htmlContent;

  const regex = new RegExp(escapeRegExp(searchText), 'gi');

  return htmlContent.replace(regex, `<${tag} class='${className || ''}'>$&</${tag}>`);
};

export const replaceRelativePaths = (html: string, baseUrl: string, currentUrl: string) => {
  const document = new DOMParser().parseFromString(html, 'text/html');

  const replaceElementUrl = (element: Element, attribute: string) => {
    const url = element.getAttribute(attribute);

    if (!url) return;

    const replacedUrl = replaceUrl(url, baseUrl, currentUrl);

    if (replacedUrl) {
      element.setAttribute('target', '_blank');
      element.setAttribute('rel', 'noopener noreferrer');
      element.setAttribute(attribute, replacedUrl);
    }
  };

  const anchorElements = document.querySelectorAll('a[href]');
  anchorElements.forEach((element) => replaceElementUrl(element, 'href'));

  const imgElements = document.querySelectorAll('img[src]');
  imgElements.forEach((element) => replaceElementUrl(element, 'src'));

  const linkElements = document.querySelectorAll('link[href]');
  linkElements.forEach((element) => replaceElementUrl(element, 'href'));

  return document.documentElement.outerHTML;
};
