import { isEmpty, keys, omit } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  RawMedicalConditionField,
  MedicalConditionItem,
  MedicalConditionField,
  CaseTimelineEvent,
} from '@/pageAI/@types/summaries';
import { truthy } from '@/shared/utils/boolean';
import { extractPercentNumber, removeEnglishStopwords, sortAlphabetically } from '@/shared/utils/string';
import { normalizeRawTimelineEvent } from '../caseTimeline';
import {
  ConditionStatus,
  ConditionSummaryReferencedFileInfo,
  ConditionType,
  SummariesType,
} from '@/pageAI/gql/graphql';
import { isSummaryEmpty } from '../summaries';

export enum UnifiedTab {
  SUMMARY = 'Summary',
  TIMELINE = 'Timeline',
  CONTENT_SEARCH = 'Content Search',
  FILE_INDEX = 'File Index',
}

export const CONDITION_STATUS_COLOR_MAPPING = {
  [ConditionStatus.Ready]: 'teal',
  [ConditionStatus.Updating]: 'orange',
  [ConditionStatus.UpdateFailed]: 'red',
};

export const CONDITION_STATUS_TEXT_MAPPING = {
  [ConditionStatus.Ready]: 'Merged',
  [ConditionStatus.Updating]: 'Merging',
  [ConditionStatus.UpdateFailed]: 'Failed',
};

export const MEDICAL_CONDITION_TEXT_DISPLAY_MAPPING = {
  // Procedural History
  dateOfOriginalClaim: 'Original Claim',
  applicableItf: 'Applicable ITF',
  firstDecision: 'First Decision',
  otherDecisions: 'Other Decision(s)',

  // Filings
  initialClaims: 'Initial Claim(s)',
  supplementalClaims: 'Supplemental Claim(s)',
  requestsForHighLevelReview: 'Request(s) for High-Level Review',
  bvaAppeals: 'BVA Appeal(s)',
  bvaDisagreementNotices: 'BVA Disagreement Notice(s)',
  disagreementNotices: 'Disagreement Notice(s)',

  // Medical Evidence
  disabilityBenefitsQuestionnaires: 'Disability Benefits Questionnaires',
  medicalOpinions: 'Medical Opinion(s)',
  addendumOpinions: 'Addendum Opinion(s)',

  // Other Evidence
  socStatements: 'Statement(s) in Support of Claim',
  favorableFindings: 'Favorable Finding(s)',
  layStatements: 'Lay Statement(s)',
  additionalEvidence: 'Additional Evidence',

  buddyLetters: 'Buddy Letter(s)',
};

export const getKeywordsFromMedicalCondition = (medicalCondition: MedicalConditionItem) => {
  const keywords = new Set<string>();

  keywords.add(removeEnglishStopwords([medicalCondition.headerCondition])[0].toLowerCase());

  medicalCondition.subConditions.forEach((subCondition) => {
    keywords.add(removeEnglishStopwords([subCondition])[0].toLowerCase());
  });

  return Array.from(keywords);
};

export const sortMedicalConditions = (medicalConditions: MedicalConditionItem[]) => {
  return medicalConditions.toSorted((medicalConditionA, medicalConditionB) =>
    sortAlphabetically(medicalConditionA.headerCondition, medicalConditionB.headerCondition),
  );
};

export const categorizeConditions = (medicalConditions: MedicalConditionItem[]) => {
  const codesheetConditions = sortMedicalConditions(
    medicalConditions.filter((condition) => condition.conditionType === ConditionType.Codesheet),
  );
  const claimConditions = sortMedicalConditions(
    medicalConditions.filter((condition) => condition.conditionType === ConditionType.Va_21_526),
  );
  const otherConditions = sortMedicalConditions(
    medicalConditions.filter((condition) => condition.conditionType === ConditionType.Others),
  );

  return { codesheetConditions, claimConditions, otherConditions };
};

export const getFirstCodesheetCondition = (conditions: MedicalConditionItem[]) => {
  return conditions.find((condition) => condition.conditionType === ConditionType.Codesheet);
};

export const areConditionsMergeable = (conditions: MedicalConditionItem[]) => {
  return conditions.length > 1 && conditions.some((condition) => condition.conditionType === ConditionType.Codesheet);
};

export const getDefaultSelectedCondition = (medicalConditions: MedicalConditionItem[]) => {
  const { codesheetConditions } = categorizeConditions(medicalConditions);

  return codesheetConditions[0];
};

export const normalizeMedicalConditionTimelineEvents = (medicalCondition: MedicalConditionItem) => {
  const relatedTimelineEvents = medicalCondition.relatedTimelineEvents.map(normalizeRawTimelineEvent).filter(truthy);

  return relatedTimelineEvents;
};

export const doesMedicalConditionContainTimelineEvent = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.some((event) => event.id === eventId);
};

export const findTimelineEventInMedicalCondition = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.find((event) => event.id === eventId);
};

export const getMedicalConditionElementId = (medicalCondition: MedicalConditionItem) => {
  return medicalCondition.headerCondition;
};

const medicalConditionFieldKeySortOrderMapping = {
  // Procedural History
  dateOfOriginalClaim: 1,
  applicableItf: 2,
  firstDecision: 3,
  otherDecisions: 4,

  // Filings
  initialClaims: 1,
  supplementalClaims: 2,
  requestsForHighLevelReview: 3,
  bvaAppeals: 4,
  bvaDisagreementNotices: 5,
  disagreementNotices: 6,

  // Medical Evidence

  // Other Evidence
  socStatements: 1,
  favorableFindings: 2,
  layStatements: 3,
  additionalEvidence: 4,
};

export const getSortedMedicalConditionFieldKeys = (medicalConditionField: MedicalConditionField) => {
  return keys(medicalConditionField).sort((keyA, keyB) => {
    const sortOrderA =
      medicalConditionFieldKeySortOrderMapping[keyA as keyof typeof medicalConditionFieldKeySortOrderMapping] || 0;
    const sortOrderB =
      medicalConditionFieldKeySortOrderMapping[keyB as keyof typeof medicalConditionFieldKeySortOrderMapping] || 0;

    return sortOrderA - sortOrderB;
  });
};

export const normalizeMedicalConditionField = (
  medicalConditionField: RawMedicalConditionField | undefined,
  showNullFields: boolean,
) => {
  if (!medicalConditionField) return undefined;

  const normalizedMedicalConditionField: MedicalConditionField = {};

  const isFieldArray = (
    fieldItem: ConditionSummaryReferencedFileInfo | ConditionSummaryReferencedFileInfo[] | null,
  ): fieldItem is ConditionSummaryReferencedFileInfo[] => {
    return Array.isArray(fieldItem);
  };

  keys(medicalConditionField).forEach((key) => {
    if (key === '__typename') return;

    const medicalConditionFieldItem = medicalConditionField[key];

    if (isFieldArray(medicalConditionFieldItem)) {
      normalizedMedicalConditionField[key] = medicalConditionFieldItem.toSorted((itemA, itemB) =>
        sortAlphabetically(itemA.date, itemB.date),
      );
    } else {
      normalizedMedicalConditionField[key] = [medicalConditionFieldItem].filter(truthy);
    }

    if (!showNullFields) {
      normalizedMedicalConditionField[key] = normalizedMedicalConditionField[key].filter(
        (field) => field.summary !== 'N/A',
      );

      if (normalizedMedicalConditionField[key].length === 0) {
        delete normalizedMedicalConditionField[key];
      }
    }
  });

  if (isEmpty(normalizedMedicalConditionField)) return undefined;

  return normalizedMedicalConditionField;
};

export const convertConditionToTimelineEvents = (condition: ReturnType<typeof normalizeMedicalConditionFields>) => {
  const timelineEvents: (CaseTimelineEvent | null)[] = [];

  (['proceduralHistory', 'filings', 'medicalEvidence', 'otherEvidence'] as const).forEach((key) => {
    const conditionField = condition[key];

    if (!conditionField) return;

    keys(conditionField).forEach((fieldKey) => {
      const entries = conditionField[fieldKey];

      entries.forEach((entry) => {
        timelineEvents.push(
          normalizeRawTimelineEvent({
            id: uuidv4(),
            date: entry.date,
            reference: entry.reference,
            summaries: [entry.summary],
            summariesTypes: [SummariesType.EventSummary],
            details: [],
            detailsTypes: [],
            metadata: [{ key: 'fileId', value: entry.fileId || entry.reference }],
            comments: {
              nodes: [],
            },
            bookmarked: false,
            duplicated: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            viewerCanBookmark: false,
            viewerCanComment: false,
            viewerCanDelete: false,
            viewerCanHide: false,
            viewerCanUnbookmark: false,
            viewerCanUnhide: false,
            viewerCanUpdate: false,
          }),
        );
      });
    });
  });

  const nonNullTimelineEvents = timelineEvents.filter(truthy);

  const filteredTimelineEvents = nonNullTimelineEvents.filter((event, index) => {
    const { summaries, date } = event;
    const previousEvents = nonNullTimelineEvents.slice(0, index);

    return !previousEvents.some((prevEvent) => {
      return prevEvent.summaries?.join('') === summaries?.join('') && prevEvent.date === date;
    });
  });

  return filteredTimelineEvents;
};

export const normalizeMedicalCondition = (medicalConditionItem: MedicalConditionItem): MedicalConditionItem => {
  // This is a debug flag that is not used by our users but is useful for debugging by developers
  const searchParams = new URL(window.location.href).searchParams;
  const isDebug = searchParams.get('debug') === 'true';

  return {
    ...medicalConditionItem,
    headerCondition: isDebug
      ? medicalConditionItem.headerCondition
      : removeMedicalConditionDebugWords(medicalConditionItem.headerCondition),
    subConditions: isDebug
      ? medicalConditionItem.subConditions
      : medicalConditionItem.subConditions.map(removeMedicalConditionDebugWords),
    mergedConditions: isDebug
      ? medicalConditionItem.mergedConditions
      : medicalConditionItem.mergedConditions?.map(removeMedicalConditionDebugWords),
  };
};

export const normalizeMedicalConditionFields = (
  medicalConditionItem: MedicalConditionItem,
  showNullFields: boolean,
) => {
  return {
    ...medicalConditionItem,
    proceduralHistory: normalizeMedicalConditionField(
      omit(medicalConditionItem.proceduralHistory, 'filings') as RawMedicalConditionField | undefined,
      showNullFields,
    ),
    filings: normalizeMedicalConditionField(
      medicalConditionItem.proceduralHistory?.filings as RawMedicalConditionField | undefined,
      showNullFields,
    ),
    medicalEvidence: normalizeMedicalConditionField(
      medicalConditionItem.medicalEvidence as RawMedicalConditionField | undefined,
      showNullFields,
    ),
    otherEvidence: normalizeMedicalConditionField(
      medicalConditionItem.otherEvidence as RawMedicalConditionField | undefined,
      showNullFields,
    ),
  };
};

export const isCombinedRatingEmpty = (combinedRating?: string | null) => {
  return !combinedRating || combinedRating === 'N/A';
};

export const removeMedicalConditionDebugWords = (text: string) => {
  const debugWords = ['(seed-header)', '(seed-main)', '(seed-sub)', '(header)', '(main)', '(sub)'];

  debugWords.forEach((debugWord) => (text = text.replaceAll(debugWord, '')));

  return text.trim();
};

export const shouldAlwaysHideNullFields = (condition: MedicalConditionItem) => {
  return [ConditionType.Va_21_526, ConditionType.Others].includes(condition.conditionType);
};

export const getMedicalConditionHeader = (medicalCondition: MedicalConditionItem) => {
  return removeMedicalConditionDebugWords(medicalCondition.headerCondition);
};

export const getMedicalConditionParamsFromSearchParams = (searchParams: URLSearchParams) => {
  const medicalConditionId = searchParams.get('medicalConditionId');
  const tab = searchParams.get('tab') as UnifiedTab | null;
  const entryKey = searchParams.get('entryKey');

  return { medicalConditionId, tab, entryKey };
};

export const isConditionMerging = (medicalCondition: MedicalConditionItem) => {
  return medicalCondition.conditionStatus === ConditionStatus.Updating;
};

export const doesConditionHaveEvent = (medicalCondition: MedicalConditionItem, eventId: string) => {
  return medicalCondition.relatedTimelineEvents.some((event) => event.id === eventId);
};

export const doesConditionHaveFile = (medicalCondition: MedicalConditionItem, fileId: string) => {
  const normalizedCondition = normalizeMedicalConditionFields(medicalCondition, false);

  return (['proceduralHistory', 'filings', 'medicalEvidence', 'otherEvidence'] as const).some((sectionKey) => {
    const sectionValue = normalizedCondition[sectionKey];

    if (!sectionValue) return false;

    return keys(sectionValue).some((fieldKey) => {
      const field = sectionValue[fieldKey];

      return field.some((entry) => entry.fileId === fileId);
    });
  });
};

export const canDisplayCodesheetSection = (condition: MedicalConditionItem) => {
  return condition.allCodesheets?.length || !isSummaryEmpty(condition.latestCodesheet?.summary);
};

export const getConditionRatingNumber = (condition: MedicalConditionItem) => {
  return Number(extractPercentNumber(condition.rating || '0%'));
};

export const sortConditionsByRating = (conditions: MedicalConditionItem[]) => {
  return conditions.toSorted((conditionA, conditionB) => {
    if (conditionA.isServiceConnected && !conditionB.isServiceConnected) return -1;

    if (conditionA.rating && !conditionB.rating) return -1;

    return getConditionRatingNumber(conditionB) - getConditionRatingNumber(conditionA);
  });
};

// ==================== ELEMENTS ====================
export const getMedicalConditionEntryId = (medicalConditionId: string, entryKey: string) =>
  [medicalConditionId, entryKey].join('.');
