import { useQuery } from '@tanstack/react-query';

import { webHtmlContentQuery } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useWebHtmlContent = (url: string) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(['web-html-content', url], () => request(webHtmlContentQuery, { url }));

  return { webHtmlContent: data?.webHtmlContent, isLoading, isError };
};
