import { memo, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { Button, Flex, Loader, Modal, Popover, ScrollArea, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCircleCheckFilled, IconFolder, IconSelector } from '@tabler/icons-react';
import { EventBus } from 'native-pubsub';

import { MenuItem } from '@/shared/designSystem/MenuItem';
import { singularOrPlural } from '@/shared/utils/string';
import { TimelineFileGroupFilterProps } from './TimelineFileGroupFilter.types';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { FileGroupBadge } from '../../files/FileGroupBadge';
import { FileGroup } from '@/pageAI/gql/graphql';
import { create } from 'zustand';

export const resetFileGroupFiltersEventBus = new EventBus();

export const useFileGroupConfirmationModalStore = create(() => ({
  isConfirmationModalOpened: false,
  selectedFileGroupDisplayName: '',
}));

const openConfirmationModal = (selectedFileGroupDisplayName: string) => {
  useFileGroupConfirmationModalStore.setState({ isConfirmationModalOpened: true, selectedFileGroupDisplayName });
};

const closeConfirmationModal = () => {
  useFileGroupConfirmationModalStore.setState({ isConfirmationModalOpened: false, selectedFileGroupDisplayName: '' });
};

const TimelineFileGroupFilterBase = ({ initialValue = [], onChange, fileTypes }: TimelineFileGroupFilterProps) => {
  const { fileGroups = [] } = useFileGroups();

  const [isPending, startTransition] = useTransition();
  const [opened, { open, close }] = useDisclosure();
  const { isConfirmationModalOpened, selectedFileGroupDisplayName } = useFileGroupConfirmationModalStore();

  const [searchValue, setSearchValue] = useState('');
  const [value, setValue] = useState<string[] | null>(initialValue);

  const filteredFileGroups = useMemo(
    () =>
      fileGroups
        .filter((fileGroup) => fileGroup.displayName.toLowerCase().includes(searchValue.toLowerCase()))
        .sort((fileGroupA, fileGroupB) => fileGroupA.displayName.localeCompare(fileGroupB.displayName)),
    [fileGroups, searchValue],
  );

  const filteredDefaultFileGroups = useMemo(
    () => filteredFileGroups.filter((fileGroup) => fileGroup.isDefault),
    [filteredFileGroups],
  );

  const filteredCustomFileGroups = useMemo(
    () => filteredFileGroups.filter((fileGroup) => !fileGroup.isDefault),
    [filteredFileGroups],
  );

  const changeValue = useCallback(
    (newValue: string[] | null, skipTransition = false) => {
      setValue(newValue);

      if (skipTransition) return onChange?.(newValue);

      startTransition(() => onChange?.(newValue));
    },
    [onChange],
  );

  useEffect(() => {
    if (!value?.length) return;

    const equivalentFileGroup = fileGroups.find((fileGroup) => value.includes(fileGroup.displayName));

    if (equivalentFileGroup) return;

    changeValue([], true);
  }, [fileGroups, value, changeValue]);

  useEffect(() => {
    const unsubscribe = resetFileGroupFiltersEventBus.subscribe(() => {
      changeValue([], true);
    });

    return unsubscribe;
  }, [changeValue]);

  const handleSelectFileGroup = (selectedValue: string) => {
    if (value?.includes(selectedValue)) {
      const actualNewValue = value?.filter((fileGroup) => fileGroup !== selectedValue);

      changeValue(actualNewValue);

      return;
    }

    const actualNewValue = [...(value || []), selectedValue];

    changeValue(actualNewValue);
  };

  const handleClearAll = () => {
    changeValue([]);
  };

  const handleSelectAll = () => {
    changeValue(fileGroups.map((fileGroup) => fileGroup.displayName));
  };

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;

    setSearchValue(newSearchValue);
  };

  const renderActionButtons = () => {
    return (
      <Flex
        align="center"
        justify="space-between"
        sx={(theme) => ({
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 8,
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          '.ghost-Button-root': {
            padding: 0,
            fontWeight: 500,
            height: 'fit-content',
            '.ghost-Button-leftIcon': {
              marginRight: 4,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              borderBottom: `1px solid ${theme.colors.dark[3]}`,
            },
          },
        })}
      >
        <Flex ml={16}>
          <Text fz="0.75rem" color="dark.4">
            {getPlaceholder()}
          </Text>
        </Flex>

        <Flex align="center">
          <Button size="xs" variant="subtle" onClick={handleClearAll}>
            Unselect all
          </Button>

          <Text fz="0.875rem" color="dark.4" mx={2}>
            -
          </Text>

          <Button size="xs" variant="subtle" onClick={handleSelectAll}>
            Select all
          </Button>
        </Flex>
      </Flex>
    );
  };

  const getPlaceholder = () => {
    if (!value || value.length === 0) return '0  selected';

    return `${value.length} ${singularOrPlural('custom filter', 'custom filters')(value.length)} selected`;
  };

  const renderFileGroups = (fileGroupsToRender: FileGroup[], indexOffset = 0) => {
    if (!fileGroupsToRender.length) return null;

    return (
      <Flex direction="column">
        {fileGroupsToRender.map((fileGroup, index) => {
          const isSelected = !!value?.includes(fileGroup.displayName);
          const realIndex = index + indexOffset;

          return (
            <MenuItem
              key={fileGroup.id}
              title={<FileGroupBadge fileGroup={fileGroup} />}
              sx={{
                height: 32,
                fontSize: '0.75rem',
                '.ghost-Text-root': {
                  maxWidth: 280,
                },
                ...(realIndex === 0 && {
                  marginTop: 4,
                }),
                ...(realIndex === filteredFileGroups.length - 1 && {
                  marginBottom: 4,
                }),
              }}
              onClick={() => {
                if (fileTypes?.length && !value?.length) return openConfirmationModal(fileGroup.displayName);

                handleSelectFileGroup(fileGroup.displayName);
              }}
              rightSection={
                isSelected ? (
                  <Flex align="center" justify="center" sx={{ minWidth: 16, minHeight: 16 }}>
                    <IconCircleCheckFilled size={16} />
                  </Flex>
                ) : null
              }
            />
          );
        })}
      </Flex>
    );
  };

  const renderContent = () => {
    if (!filteredFileGroups.length)
      return (
        <Flex align="center" justify="center" sx={{ height: 32, paddingLeft: 12 }}>
          <Text fz="0.75rem" color="gray.7">
            Nothing found
          </Text>
        </Flex>
      );

    return (
      <>
        {renderFileGroups(filteredDefaultFileGroups)}

        {renderFileGroups(filteredCustomFileGroups, filteredDefaultFileGroups.length)}
      </>
    );
  };

  return (
    <>
      <Popover
        opened={opened}
        position="bottom"
        shadow="sm"
        width="target"
        closeOnClickOutside={!isConfirmationModalOpened}
        onClose={close}
      >
        <Popover.Target>
          <TextInput
            value={searchValue}
            onChange={handleSearchValueChange}
            placeholder={getPlaceholder()}
            onFocusCapture={open}
            size="xs"
            icon={<IconFolder size={16} />}
            rightSection={isPending ? <Loader size={12} /> : <IconSelector size={12} onClick={open} />}
            w={360}
          />
        </Popover.Target>

        <Popover.Dropdown p={0}>
          {renderActionButtons()}

          <ScrollArea
            sx={{
              height: Math.max(Math.min(400, filteredFileGroups.length * 32 + 8), 32),
              padding: '0 4px',
              position: 'relative',
            }}
          >
            {renderContent()}
          </ScrollArea>
        </Popover.Dropdown>
      </Popover>

      <Modal opened={isConfirmationModalOpened} title="Confirm selection" onClose={closeConfirmationModal} zIndex={301}>
        <Flex direction="column" gap="md">
          <Flex>
            <Text fz="0.875rem" color="dark.4">
              You have already selected some files. If you select a custom filter, the current file selection will be
              cleared. Do you confirm that you want to proceed?
            </Text>
          </Flex>

          <Flex align="center" justify="end" gap="xs">
            <Button variant="subtle" color="gray.7" onClick={closeConfirmationModal}>
              Cancel
            </Button>

            <Button
              onClick={() => {
                handleSelectFileGroup(selectedFileGroupDisplayName);
                closeConfirmationModal();
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const TimelineFileGroupFilter = memo(TimelineFileGroupFilterBase);
