import { memo } from 'react';

const UnusedSidebarBase = () => {
  return (
    <div id="sidebarContainer">
      <div id="toolbarSidebar">
        <div id="toolbarSidebarLeft">
          <div id="sidebarViewButtons" className="splitToolbarButton toggled" role="radiogroup">
            <button
              id="viewThumbnail"
              className="toolbarButton toggled"
              title="Show Thumbnails"
              tabIndex={2}
              data-l10n-id="pdfjs-thumbs-button"
              role="radio"
              aria-checked="true"
              aria-controls="thumbnailView"
            >
              <span data-l10n-id="pdfjs-thumbs-button-label">Thumbnails</span>
            </button>
            <button
              id="viewOutline"
              className="toolbarButton"
              title="Show Document Outline (double-click to expand/collapse all items)"
              tabIndex={3}
              data-l10n-id="pdfjs-document-outline-button"
              role="radio"
              aria-checked="false"
              aria-controls="outlineView"
            >
              <span data-l10n-id="pdfjs-document-outline-button-label">Document Outline</span>
            </button>
            <button
              id="viewAttachments"
              className="toolbarButton"
              title="Show Attachments"
              tabIndex={4}
              data-l10n-id="pdfjs-attachments-button"
              role="radio"
              aria-checked="false"
              aria-controls="attachmentsView"
            >
              <span data-l10n-id="pdfjs-attachments-button-label">Attachments</span>
            </button>
            <button
              id="viewLayers"
              className="toolbarButton"
              title="Show Layers (double-click to reset all layers to the default state)"
              tabIndex={5}
              data-l10n-id="pdfjs-layers-button"
              role="radio"
              aria-checked="false"
              aria-controls="layersView"
            >
              <span data-l10n-id="pdfjs-layers-button-label">Layers</span>
            </button>
          </div>
        </div>

        <div id="toolbarSidebarRight">
          <div id="outlineOptionsContainer">
            <div className="verticalToolbarSeparator"></div>

            <button
              id="currentOutlineItem"
              className="toolbarButton"
              disabled
              title="Find Current Outline Item"
              tabIndex={6}
              data-l10n-id="pdfjs-current-outline-item-button"
            >
              <span data-l10n-id="pdfjs-current-outline-item-button-label">Current Outline Item</span>
            </button>
          </div>
        </div>
      </div>
      <div id="sidebarContent">
        <div id="thumbnailView"></div>
        <div id="outlineView" className="hidden"></div>
        <div id="attachmentsView" className="hidden"></div>
        <div id="layersView" className="hidden"></div>
      </div>
      <div id="sidebarResizer"></div>
    </div>
  );
};

export const UnusedSidebar = memo(UnusedSidebarBase);
