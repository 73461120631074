import { memo } from 'react';
import { Checkbox } from '@mantine/core';

import { ShowNullFieldsFilterProps } from './ShowNullFieldsFilter.types';
import { setShowConditionSummaryNullFields, showNullFieldsSelector, useUnifiedStore } from '@/pageAI/states/unified';

const ShowNullFieldsFilterBase = (props: ShowNullFieldsFilterProps) => {
  const showNullFields = useUnifiedStore(showNullFieldsSelector);

  return (
    <Checkbox
      size="xs"
      label="Hide N/A fields"
      checked={!showNullFields}
      onChange={(event) => setShowConditionSummaryNullFields(!event.target.checked)}
    />
  );
};

export const ShowNullFieldsFilter = memo(ShowNullFieldsFilterBase);
