import { memo } from 'react';
import { Container, Flex, Title } from '@mantine/core';
import { IconMessage } from '@tabler/icons-react';

import { CommentSidebarEmptyPlaceholderProps } from './CommentSidebarEmptyPlaceholder.types';

const CommentSidebarEmptyPlaceholderBase = (props: CommentSidebarEmptyPlaceholderProps) => {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        marginBottom: 120,
      }}
    >
      <Flex align="center" direction="column" gap={4}>
        <Flex sx={(theme) => ({ color: theme.colors.dark[3] })}>
          <IconMessage size={32} />
        </Flex>

        <Title
          color="dark.3"
          sx={{
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '1rem',
          }}
        >
          No comments
        </Title>
      </Flex>
    </Container>
  );
};

export const CommentSidebarEmptyPlaceholder = memo(CommentSidebarEmptyPlaceholderBase);
