import { forwardRef, memo } from 'react';
import { Box, Text, Tooltip } from '@mantine/core';

import { ConfidenceLevelBadgeProps } from './ConfidenceLevelBadge.types';
import { CONFIDENCE_LEVEL_UI_MAPPING } from './ConfidenceLevelBadge.utils';
import { useCaseTimelineContext } from '@/pageAI/contexts/caseTimelineContext';

const ConfidenceLevelBadgeBase = (
  { confidenceLevel, onContextMenu, children }: ConfidenceLevelBadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const { isPrinting } = useCaseTimelineContext();
  const actualConfidenceLevel = confidenceLevel || 'low';
  const { tooltip, backgroundColor, text, fontSize } = CONFIDENCE_LEVEL_UI_MAPPING[actualConfidenceLevel];

  return (
    <Tooltip key={confidenceLevel} label={tooltip} withArrow withinPortal>
      <Box
        ref={ref}
        onContextMenu={onContextMenu}
        component="span"
        sx={(theme) => ({
          ...(isPrinting
            ? {
                marginLeft: -4,
              }
            : {}),
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 16,
          height: 16,
          borderRadius: '50%',
          position: 'relative',
        })}
        bg={isPrinting ? 'none' : backgroundColor}
      >
        <Text
          fz={isPrinting ? '0.875rem' : fontSize}
          fw={isPrinting ? 600 : 400}
          sx={{ position: 'absolute', fontStyle: 'normal' }}
          color={isPrinting ? backgroundColor : 'white'}
        >
          {text}
        </Text>

        {children}
      </Box>
    </Tooltip>
  );
};

export const ConfidenceLevelBadge = memo(forwardRef(ConfidenceLevelBadgeBase));
