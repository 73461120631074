import { memo, useCallback, useMemo } from 'react';
import { useContextMenu } from 'mantine-contextmenu';
import { Flex, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconShare, IconTrashX } from '@tabler/icons-react';

import { MedicalConditionSummaryEntryProps } from './MedicalConditionSummaryEntry.types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { HighlightType, highlightKeywordsInMarkdown } from '@/shared/utils/markdown';
import { SummaryFileReferenceBadge } from '../../summaries/SummaryFileReferenceBadge';
import { appendSearchParams } from '@/shared/utils/url';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { getMedicalConditionEntryId } from '@/pageAI/services/medicalConditions';
import { getClientFiles } from '@/pageAI/services/clients';
import { addFileIndexToEventReference } from '@/pageAI/services/summaries';
import { useManageConditionFiles } from '@/pageAI/hooks/conditions/useManageConditionFiles';

const MedicalConditionSummaryEntryBase = ({
  medicalCondition,
  entry,
  entryKey,
  keywords = [],
  isHighlighted = false,
  onReferenceClick,
}: MedicalConditionSummaryEntryProps) => {
  const { notify } = useNotifications();
  const showContextMenu = useContextMenu();

  const client = useSelectedClient(true);
  const { fileAssets } = useMemo(() => getClientFiles(client), [client]);
  const { removeFilesFromCondition, isRemoving } = useManageConditionFiles(client.id);

  const referenceWithIndex = useMemo(() => {
    if (!entry.reference) return null;

    return addFileIndexToEventReference(entry.reference, fileAssets);
  }, [entry.reference, fileAssets]);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      return showContextMenu(
        [
          {
            key: 'share',
            icon: <IconShare size={16} />,
            title: 'Share',
            onClick: () => {
              const searchParams = new URLSearchParams(window.location.search);

              searchParams.set('medicalConditionId', medicalCondition.id);
              searchParams.set('entryKey', entryKey);

              navigator.clipboard.writeText(appendSearchParams(window.location.href, searchParams));

              notify('Copied', 'Entry link copied', 'brand');
            },
          },
          {
            key: 'remove',
            color: 'red',
            icon: <IconTrashX size={16} />,
            title: 'Remove',
            onClick: () => {
              modals.openConfirmModal({
                title: 'Remove entry',
                children: (
                  <Text fz="0.875rem">
                    Are you sure you want to remove this entry from the condition? This action cannot be undone.
                  </Text>
                ),
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                confirmProps: {
                  color: 'red',
                },
                cancelProps: {
                  variant: 'subtle',
                  color: 'gray.7',
                },
                onConfirm: () => {
                  if (!entry.fileId) return;

                  removeFilesFromCondition(medicalCondition.id, [entry.fileId]);
                },
              });
            },
          },
        ],
        { sx: { minWidth: 164 } },
      )(event);
    },
    [showContextMenu, notify, medicalCondition, entryKey, entry.fileId, removeFilesFromCondition],
  );

  return (
    <>
      <Flex
        id={getMedicalConditionEntryId(medicalCondition.id, entryKey)}
        onContextMenu={handleContextMenu}
        align="center"
        gap={6}
        sx={{
          fontSize: '0.875rem',
          display: 'inline',
          p: {
            margin: 0,
            display: 'inline',
          },
          '.ghost-Badge-root': {
            marginLeft: 6,
          },
        }}
      >
        <MarkdownRenderer content={highlightKeywordsInMarkdown(entry.summary, keywords, HighlightType.LIGHTER)} />

        {referenceWithIndex && (
          <SummaryFileReferenceBadge
            client={client}
            isHighlighted={isHighlighted}
            onClick={() => onReferenceClick?.(entry, entryKey)}
            reference={referenceWithIndex}
          />
        )}
      </Flex>
    </>
  );
};

export const MedicalConditionSummaryEntry = memo(MedicalConditionSummaryEntryBase);
