import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { ClientConditionsQuery, queryMap } from '../../clients/useClientMedicalConditions';
import { updateCondition } from '@/pageAI/states/unified';
import { normalizeMedicalCondition } from '@/pageAI/services/medicalConditions';

export const useUpdateCondition = (clientId: string) => {
  const queryClient = useQueryClient();

  const updateConditionLocally = useCallback(
    (conditionId: string, fieldsToUpdate: Partial<MedicalConditionItem>) => {
      const queryKey = queryMap.client.medicalConditions.queryKey(clientId);

      const previousConditionsQuery = queryClient.getQueryData<ClientConditionsQuery>(queryKey);

      queryClient.setQueriesData<ClientConditionsQuery>(queryKey, (conditionsQuery) => {
        if (!conditionsQuery) return conditionsQuery;

        return conditionsQuery.map((condition) => {
          if (condition.id !== conditionId) return condition;

          return normalizeMedicalCondition({
            ...condition,
            ...fieldsToUpdate,
          });
        });
      });

      updateCondition(conditionId, fieldsToUpdate);

      return () => queryClient.setQueriesData<ClientConditionsQuery>(queryKey, previousConditionsQuery);
    },
    [queryClient, clientId],
  );

  return {
    updateConditionLocally,
  };
};
