import { forwardRef, memo } from 'react';
import { Badge, Box, Flex } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import { MemberRoleBadgeProps } from './MemberRoleBadge.types';
import {
  ORGANIZATION_MEMBER_ROLE_COLOR_MAPPING,
  ORGANIZATION_MEMBER_ROLE_DISPLAY_TEXT_MAPPING,
} from '@/pageAI/services/organizations';

const MemberRoleBadgeBase = ({ role, onClick }: MemberRoleBadgeProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <Flex align="center" gap={4} ref={ref}>
      <Badge
        onClick={onClick}
        variant="light"
        size="lg"
        sx={(theme) => ({
          backgroundColor: theme.colors[ORGANIZATION_MEMBER_ROLE_COLOR_MAPPING[role]][1],
          transition: 'all 0.2s',
          '.ghost-Badge-inner': {
            color: theme.colors.dark[5],
            fontWeight: 500,
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            gap: 2,
            userSelect: 'none',
          },
          ...(onClick && {
            paddingRight: 6,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors[ORGANIZATION_MEMBER_ROLE_COLOR_MAPPING[role]][2],
            },
          }),
        })}
      >
        {ORGANIZATION_MEMBER_ROLE_DISPLAY_TEXT_MAPPING[role]}

        <Box sx={{ flexGrow: 1 }} />

        {onClick && <IconChevronDown size={14} />}
      </Badge>
    </Flex>
  );
};

export const MemberRoleBadge = memo(forwardRef(MemberRoleBadgeBase));
