import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { truthy } from '@/shared/utils/boolean';
import { clientTimelineEventsQuery } from '@/pageAI/api';
import { normalizeRawTimelineEvent } from '@/pageAI/services/caseTimeline';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';

export interface UseClientTimelineEventsProps {
  clientId?: string;
}

const fetchTimelineEvents = async (request: GraphQLRequestFunction, clientId: string | undefined) => {
  if (typeof clientId !== 'string') return Promise.resolve(null);

  const responseBody = await request(clientTimelineEventsQuery, { id: clientId });

  const timelineEvents =
    responseBody.client.timelineEvents.nodes?.map((event) => normalizeRawTimelineEvent(event)).filter(truthy) || [];

  return timelineEvents;
};

export const queryMap = {
  client: {
    timelineEvents: {
      queryKey: (clientId?: string) => ['client', clientId, 'timelineEvents'],
      queryFn: fetchTimelineEvents,
    },
  },
};

export type ClientTimelineEventsQuery = Awaited<ReturnType<typeof queryMap.client.timelineEvents.queryFn>>;

export const useClientTimelineEvents = ({ clientId }: UseClientTimelineEventsProps) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(
    queryMap.client.timelineEvents.queryKey(clientId),
    () => queryMap.client.timelineEvents.queryFn(request, clientId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {
    timelineEvents: data || [],
    isLoading,
    isError,
  };
};
