import { useCallback } from 'react';

import { Client } from '@/pageAI/@types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { useUpdateTimelineEvent } from '@/pageAI/hooks/caseTimeline/useUpdateTimelineEvent';
import { TimelineUpdatedEvent } from '@/pageAI/@types/summaries';
import { timelineEventRecentlyUpdatedPubsub } from '@/pageAI/components/summaries/CaseTimelineItemView/CaseTimelineItemView.utils';
import { useClientTimelineEvents } from '@/pageAI/hooks/clients/useClientTimelineEvents';

export const useHandleTimelineOperationEvent = () => {
  const { currentUser } = useCurrentUser();
  const selectedClient = useSelectedClient();
  const { timelineEvents } = useClientTimelineEvents({ clientId: selectedClient?.id });
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();

  const canHandleTimelineOperationEvent = !!timelineEvents;
  const currentClient = selectedClient as Client;

  const handleTimelineUpdatedEvent = useCallback(
    (event: TimelineUpdatedEvent) => {
      if (!canHandleTimelineOperationEvent || currentClient.id !== event.clientTimelineEvent.client.id) return;

      const targetTimelineEvent = timelineEvents.find(
        (timelineEvent) => timelineEvent.id === event.clientTimelineEvent.id,
      );

      if (!targetTimelineEvent) return;

      updateTimelineEventLocally(currentClient, {
        ...event.clientTimelineEvent,
      });

      if (currentUser?.id !== event.issuingPrincipal.id)
        Promise.resolve().then(() => timelineEventRecentlyUpdatedPubsub.publish({ id: event.clientTimelineEvent.id }));
    },
    [updateTimelineEventLocally, currentUser, currentClient, canHandleTimelineOperationEvent, timelineEvents],
  );

  return {
    handleTimelineUpdatedEvent,
  };
};
