import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Client } from '@/pageAI/@types';
import { quickFindClientFile } from '@/pageAI/services/clients';
import { openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset, setPdfPreviewInitalPageIndex } from '@/shared/states/filePreview';
import { highlightTimelineEventPubsub } from '@/pageAI/services/caseTimeline';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';

export const useTimelineSearchParams = (timelineEvents?: CaseTimelineEvent[], client?: Client) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (hasEffectRun.current || !client || !timelineEvents) return;

    const eventId = searchParams.get('eventId');
    const fileId = searchParams.get('fileId');
    const rawPage = searchParams.get('page');

    if (eventId) {
      const eventToOpen = timelineEvents.find((timelineEvent) => timelineEvent.id === eventId);

      if (eventToOpen) {
        hasEffectRun.current = true;

        searchParams.delete('eventId');
        searchParams.delete('fileId');
        searchParams.delete('page');
        setSearchParams(searchParams, { replace: true });

        requestIdleCallback(() => {
          highlightTimelineEventPubsub.publish({ event: eventToOpen, shouldOpenReference: true });
        });

        return;
      }
    }

    if (fileId) {
      const fileAsset = quickFindClientFile(client, fileId);

      if (fileAsset?.metadata?.pageAIMetadata) {
        hasEffectRun.current = true;

        const { cleanedFileName, indexNumber } = fileAsset.metadata.pageAIMetadata;

        const pageIndex = rawPage ? Number(rawPage) - 1 : 0;

        openRightSidebar();
        setFilePreviewAsset(fileAsset, `[${indexNumber}] ${cleanedFileName}`);
        setPdfPreviewInitalPageIndex(Number.isNaN(pageIndex) ? 0 : pageIndex);
      }
    }
  }, [client, timelineEvents, searchParams, setSearchParams]);
};
