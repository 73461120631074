import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { omit } from 'lodash-es';

import { createCommentMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { CreateClientTimelineEventCommentInput, CreateCommentMutation, User } from '@/pageAI/gql/graphql';
import { useUpdateTimelineEvent } from '../useUpdateTimelineEvent';
import { Client, Comment } from '@/pageAI/@types';
import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';

export const useCreateComment = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateTimelineEventLocally } = useUpdateTimelineEvent();
  const { currentUser } = useCurrentUser();

  const { mutateAsync: createCommentBase, isLoading } = useMutation<
    CreateCommentMutation,
    Error,
    CreateClientTimelineEventCommentInput
  >({
    mutationFn: async (input) => {
      return request(createCommentMutation, { input });
    },
  });

  const createCommentLocally = useCallback(
    (
      client: Client,
      timelineEvent: CaseTimelineEvent,
      input: CreateClientTimelineEventCommentInput & { id?: string; shouldFlash?: boolean },
      user?: User,
    ) => {
      const createdAt = new Date();

      const newComment: Comment = {
        id: input.id || uuidv4(),
        content: input.content,
        createdAt,
        updatedAt: createdAt,
        shouldFlash: input.shouldFlash,
        author: user || omit(currentUser, ['organizations']),
        viewerCanDelete: true,
        viewerCanUpdate: true,
        viewerDidAuthor: true,
      };

      const reverse = updateTimelineEventLocally(client, {
        ...timelineEvent,
        comments: [newComment, ...(timelineEvent.comments || [])],
      });

      return reverse;
    },
    [currentUser, updateTimelineEventLocally],
  );

  const createComment = useCallback(
    async (client: Client, timelineEvent: CaseTimelineEvent, input: CreateClientTimelineEventCommentInput) => {
      const reverse = createCommentLocally(client, timelineEvent, input);

      try {
        const responseBody = await createCommentBase(input);

        updateTimelineEventLocally(client, {
          ...timelineEvent,
          comments: [
            omit(responseBody.createClientTimelineEventComment, ['__typename']),
            ...(timelineEvent.comments || []),
          ],
        });
      } catch (error) {
        reverse();

        notify('Error', 'Failed to add comment');
      }
    },
    [createCommentBase, notify, updateTimelineEventLocally, createCommentLocally],
  );

  return { createComment, createCommentLocally, isLoading };
};
