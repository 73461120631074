import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Box, Flex, LoadingOverlay, ScrollArea, Tooltip } from '@mantine/core';

import { WebPreviewerProps } from './WebPreviewer.types';
import { extractTextFragmentFromUrl, extractUrlOriginAndPathname } from '@/shared/utils/url';
import { useWebHtmlContent } from '@/shared/hooks/web/useWebHtmlContent/useWebHtmlContent';
import { ErrorPlaceholder } from '@/shared/components/common/placeholders/ErrorPlaceholder';
import { getReadingContentFromHtml, replaceRelativePaths, wrapTextWithTag } from '@/shared/lib/html';
import { applyTextFragmentHighlights } from '@/shared/lib/browser/textFragment';
import { injectCssIntoIframe, waitUntilIframeLoaded } from '@/shared/lib/browser';

const WebPreviewerBase: React.FC<WebPreviewerProps> = ({ url, setActiveMode, mode }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [didHighlightingFail, setDidHighlightingFail] = useState(false);

  const rootUrl = useMemo(() => extractUrlOriginAndPathname(url), [url]);
  const highlightTexts = useMemo(() => extractTextFragmentFromUrl(url), [url]);

  const { webHtmlContent: rawHtmlContent, isLoading, isError } = useWebHtmlContent(rootUrl);

  const htmlContentWithAbsolutePaths = useMemo(() => {
    let htmlContent = rawHtmlContent
      ? replaceRelativePaths(rawHtmlContent, new URL(url).origin, window.location.href)
      : '';

    highlightTexts.forEach((highlighText) => {
      htmlContent = wrapTextWithTag(htmlContent, highlighText, 'mark');
    });

    return htmlContent;
  }, [rawHtmlContent, url, highlightTexts]);

  const readingContent = useMemo(
    () => (htmlContentWithAbsolutePaths ? getReadingContentFromHtml(htmlContentWithAbsolutePaths) : ''),
    [htmlContentWithAbsolutePaths],
  );

  const readingContentWithHighlights = useMemo(() => {
    if (!readingContent || !highlightTexts) return readingContent;

    let newReadingContent = readingContent;

    highlightTexts.forEach((highlighText) => {
      newReadingContent = wrapTextWithTag(newReadingContent, highlighText, 'mark');
    });

    return newReadingContent;
  }, [readingContent, highlightTexts]);

  const moveToTheFirstMarkElement = useCallback(() => {
    const containerElement = mode === 'Web' ? iframeRef.current?.contentWindow?.document : ref.current;

    if (!containerElement) return;

    const firstMarkElement = containerElement.querySelector('mark');

    if (!firstMarkElement) return;

    firstMarkElement.scrollIntoView();
  }, [mode]);

  useEffect(() => {
    if (isLoading) return;

    setTimeout(async () => {
      setIsHighlighting(true);

      if (mode === 'Web' && iframeRef.current) {
        await waitUntilIframeLoaded(iframeRef.current);

        injectCssIntoIframe(iframeRef.current, 'mark { background-color: #ffe066; }');
      }

      const applied = applyTextFragmentHighlights(
        new URL(url).hash,
        mode === 'Web' ? iframeRef.current?.contentWindow?.document : undefined,
      );

      setIsHighlighting(false);
      setDidHighlightingFail(!applied);

      if (applied) return;

      moveToTheFirstMarkElement();
    }, 0);
  }, [isLoading, url, ref, mode, setActiveMode, moveToTheFirstMarkElement]);

  if (isLoading) {
    return (
      <Flex
        sx={{
          minWidth: 0,
          minHeight: 0,
          flexGrow: 1,
          position: 'relative',
          '.ghost-Overlay-root': {
            background: 'none',
          },
        }}
      >
        <LoadingOverlay visible />
      </Flex>
    );
  }

  if (isError) {
    return (
      <Flex sx={{ minWidth: 0, minHeight: 0, flexGrow: 1 }} align="center">
        <ErrorPlaceholder description="An error has occurred. Perhaps the text content of this website is not ready yet. Please try again later or with a different input." />
      </Flex>
    );
  }

  const renderWebPreviewer = () => {
    if (mode === 'Web') {
      return (
        <>
          <Box sx={{ position: 'relative', flexGrow: 1 }}>
            <iframe
              ref={iframeRef}
              title="HTML Previewer"
              width="100%"
              height="100%"
              srcDoc={htmlContentWithAbsolutePaths}
              style={{ boxShadow: 'none' }}
            />

            {isHighlighting && <LoadingOverlay visible />}
          </Box>
        </>
      );
    }

    return (
      <ScrollArea
        sx={(theme) => ({
          minWidth: 0,
          minHeight: 0,
          flexGrow: 1,
          background: 'white',
          borderRadius: 4,
          padding: '0 16px',
          border: `1px solid ${theme.colors.gray[4]}`,
          fontSize: '87.5%',
        })}
      >
        <Box
          ref={ref}
          dangerouslySetInnerHTML={{ __html: readingContentWithHighlights || '' }}
          sx={{ mark: { backgroundColor: '#ffe066' } }}
        />
      </ScrollArea>
    );
  };

  return (
    <>
      <Flex align="center" sx={{ position: 'absolute', top: 18, right: 44, zIndex: 11 }}>
        {didHighlightingFail ? (
          <Tooltip label="Failed to highlight texts" withinPortal>
            <Badge color="red" variant="outline">
              Failed
            </Badge>
          </Tooltip>
        ) : (
          <Badge
            variant="outline"
            sx={(theme) => ({ cursor: 'pointer', '&:hover': { backgroundColor: theme.colors.brand[0] } })}
            onClick={moveToTheFirstMarkElement}
          >
            Highlighted
          </Badge>
        )}
      </Flex>

      {renderWebPreviewer()}
    </>
  );
};

export const WebPreviewer = memo(WebPreviewerBase);
