export const moveCaretToEnd = (element?: HTMLElement) => {
  const selection = window.getSelection();

  if (!selection) return;

  if (element) selection.selectAllChildren(element);

  selection.collapseToEnd();
};

export const getCurrentCaretPosition = (
  containerElement?: HTMLElement,
  excludedElements?: (HTMLElement | undefined | null)[],
) => {
  const selection = window.getSelection();

  const range = selection?.getRangeAt(0);

  if (!range) return null;

  if (containerElement)
    if (
      (range.startContainer !== containerElement && !containerElement.contains(range.startContainer)) ||
      containerElement === range.startContainer
    ) {
      return null;
    }

  if (excludedElements) {
    for (const excludedElement of excludedElements) {
      if (excludedElement === range.startContainer) return null;
    }
  }

  const rect = range.getClientRects()[0];

  if (rect) return rect;

  const divElement = document.createElement('div');
  range.insertNode(divElement);

  const divElementRect = divElement.getBoundingClientRect();

  divElement.remove();

  return divElementRect;
};

export const isInViewport = (element: HTMLElement) => {
  if (!element) return false;
  if (1 !== element.nodeType) return false;

  const html = document.documentElement;
  const rect = element.getBoundingClientRect();

  return (
    !!rect && rect.bottom >= 0 && rect.right >= 0 && rect.left <= html.clientWidth && rect.top <= html.clientHeight
  );
};
