import { useQuery } from '@tanstack/react-query';

import { organizationByDomainQuery } from '@/pageAI/api/organization';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useOrganizationByDomain = (domain?: string | null) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(
    ['organizationByDomain', domain],
    () => (domain ? request(organizationByDomainQuery, { domain }) : Promise.resolve(null)),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return { organization: data?.organizationByDomain, isLoading, isError };
};
