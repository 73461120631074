import { memo, useMemo } from 'react';
import { Badge } from '@mantine/core';

import { FileStatusBadgeProps } from './FileStatusBadge.types';
import {
  FILE_DISPLAY_STATUS_COLOR_MAPPING,
  getFileDisplayStatus,
  getFileTextExtractionProgress,
} from '@/shared/services/files';
import { toPercent } from '@/shared/utils/number';

const FileStatusBadgeBase: React.FC<FileStatusBadgeProps> = ({ file }) => {
  const fileDisplayStatus = getFileDisplayStatus(file);

  const textExtractionProgress = useMemo(() => getFileTextExtractionProgress(file), [file]);

  const textExtractionProgressPercentContent = useMemo(
    () =>
      fileDisplayStatus === 'OCR In Progress' &&
      typeof textExtractionProgress === 'number' && <>&nbsp;({toPercent(textExtractionProgress)}%)</>,
    [textExtractionProgress, fileDisplayStatus],
  );

  return (
    <Badge variant="dot" color={FILE_DISPLAY_STATUS_COLOR_MAPPING[fileDisplayStatus]}>
      {fileDisplayStatus}
      {textExtractionProgressPercentContent}
    </Badge>
  );
};

export const FileStatusBadge = memo(FileStatusBadgeBase);
