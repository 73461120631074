import { useCallback, useEffect } from 'react';

import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import {
  saveContentSearchScrollPosition,
  saveFileIndexScrollPosition,
  saveSummaryScrollPosition,
  useUnifiedStore,
} from '@/pageAI/states/unified';
import { useScrollAreaScroll } from '@/shared/hooks/dom/useScrollAreaScroll';

export const useUnifiedScrollPosition = (tab: UnifiedTab) => {
  const handleScroll = useCallback(
    (viewportElement: HTMLDivElement) => {
      if (tab === UnifiedTab.SUMMARY) return saveSummaryScrollPosition(viewportElement.scrollTop);

      if (tab === UnifiedTab.CONTENT_SEARCH) return saveContentSearchScrollPosition(viewportElement.scrollTop);

      if (tab === UnifiedTab.FILE_INDEX) return saveFileIndexScrollPosition(viewportElement.scrollTop);
    },
    [tab],
  );

  const scrollAreaRef = useScrollAreaScroll(handleScroll);

  useEffect(() => {
    const viewportElement = scrollAreaRef.current?.querySelector('.ghost-ScrollArea-viewport') as
      | HTMLDivElement
      | undefined;

    const state = useUnifiedStore.getState();

    const scrollPositionObject = state.scrollPosition[tab];

    if (!viewportElement || !scrollPositionObject) return;

    if (tab === UnifiedTab.SUMMARY) {
      requestAnimationFrame(() => (viewportElement.scrollTop = scrollPositionObject as number));

      return;
    }

    if (tab === UnifiedTab.CONTENT_SEARCH) {
      requestAnimationFrame(() => (viewportElement.scrollTop = scrollPositionObject as number));

      return;
    }

    if (tab === UnifiedTab.FILE_INDEX) {
      requestAnimationFrame(
        () => (viewportElement.scrollTop = (scrollPositionObject as { scrollPosition: number }).scrollPosition),
      );

      return;
    }
  }, [tab, scrollAreaRef]);

  return scrollAreaRef;
};
