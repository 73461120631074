import React, { forwardRef, memo } from 'react';
import { Flex, Text } from '@mantine/core';

import { PanelProps } from './Panel.types';

const PanelBase = ({ className, sx, label, children }: PanelProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <Flex
      ref={ref}
      direction="column"
      className={className}
      sx={(theme) => ({
        backgroundColor: theme.white,
        borderRadius: 4,
        padding: 16,
        ...sx,
      })}
      gap="sm"
    >
      {label && (
        <Text fw={600} color="dark.4">
          {label}
        </Text>
      )}

      {children}
    </Flex>
  );
};

export const Panel = memo(forwardRef(PanelBase));
