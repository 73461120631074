import { createStyles, Title, Text, Group, Box, Flex } from '@mantine/core';

import { ErrorPlaceholderProps } from './ErrorPlaceholder.types';

import BugFixingIllustration from '@/shared/icons/bug-fixing.svg?react';

const useStyles = createStyles((theme) => ({
  inner: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },

  image: {
    ...theme.fn.cover(),
    opacity: 0.75,
  },

  content: {
    marginTop: 16,
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: '1.5rem',

    [theme.fn.smallerThan('sm')]: {
      fontSize: '1.25rem',
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: 4,
    marginBottom: 4,
  },
}));

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  title = 'Something went wrong',
  description = 'An error has occurred. Please try again later or with a different input.',
  action,
  illustration,
}) => {
  const { classes } = useStyles();

  return (
    <Flex className={classes.inner}>
      {illustration || <BugFixingIllustration height={320} />}

      <Box className={classes.content}>
        <Title className={classes.title} color="red.6">
          {title}
        </Title>

        <Text color="dimmed" fz="1rem" align="center" className={classes.description}>
          {description}
        </Text>

        {action && <Group position="center">{action}</Group>}
      </Box>
    </Flex>
  );
};
