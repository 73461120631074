import { useCallback } from 'react';

import {
  Client,
  TimelineCommentCreatedEvent,
  TimelineCommentDeletedEvent,
  TimelineCommentUpdatedEvent,
} from '@/pageAI/@types';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useCreateComment } from '@/pageAI/hooks/caseTimeline/useCreateComment';
import { useUpdateComment } from '@/pageAI/hooks/caseTimeline/useUpdateComment';
import { useDeleteComment } from '@/pageAI/hooks/caseTimeline/useDeleteComment';
import { useCurrentUser } from '@/pageAI/hooks/users/useCurrentUser';
import { useClientTimelineEvents } from '@/pageAI/hooks/clients/useClientTimelineEvents';

export const useHandleTimelineCommentOperationEvent = () => {
  const { currentUser } = useCurrentUser();
  const selectedClient = useSelectedClient();
  const { timelineEvents } = useClientTimelineEvents({ clientId: selectedClient?.id });
  const { createCommentLocally } = useCreateComment();
  const { updateCommentLocally } = useUpdateComment();
  const { deleteCommentLocally } = useDeleteComment();

  const canHandleTimelineOperationEvent = !!timelineEvents;
  const currentClient = selectedClient as Client;

  const handleTimelineCommentCreatedEvent = useCallback(
    (event: TimelineCommentCreatedEvent) => {
      if (!canHandleTimelineOperationEvent) return;

      const targetTimelineEvent = timelineEvents.find((timelineEvent) => timelineEvent.id === event.comment.event.id);

      if (!targetTimelineEvent) return;

      const doesCommentExist = targetTimelineEvent.comments.some((comment) => comment?.id === event.comment.id);

      if (doesCommentExist) return;

      createCommentLocally(
        currentClient,
        targetTimelineEvent,
        {
          eventId: targetTimelineEvent.id,
          id: event.comment.id,
          content: event.comment.content,
          shouldFlash: true,
        },
        event.issuingPrincipal,
      );
    },
    [createCommentLocally, currentClient, canHandleTimelineOperationEvent, timelineEvents],
  );

  const handleTimelineCommentUpdatedEvent = useCallback(
    (event: TimelineCommentUpdatedEvent) => {
      if (!canHandleTimelineOperationEvent) return;

      const targetTimelineEvent = timelineEvents.find((timelineEvent) => timelineEvent.id === event.comment.event.id);

      if (!targetTimelineEvent) return;

      updateCommentLocally(currentClient, targetTimelineEvent, {
        id: event.comment.id,
        content: event.comment.content,
        updatedAt: new Date(event.comment.updatedAt),
        ...(currentUser?.id !== event.issuingPrincipal.id ? { shouldFlash: true } : {}),
      });
    },
    [updateCommentLocally, currentUser, currentClient, canHandleTimelineOperationEvent, timelineEvents],
  );

  const handleTimelineCommentDeletedEvent = useCallback(
    (event: TimelineCommentDeletedEvent) => {
      if (!canHandleTimelineOperationEvent) return;

      const targetTimelineEvent = timelineEvents.find((timelineEvent) => timelineEvent.id === event.event.id);

      if (!targetTimelineEvent) return;

      deleteCommentLocally(currentClient, targetTimelineEvent, { id: event.deletedCommentId });
    },
    [deleteCommentLocally, currentClient, canHandleTimelineOperationEvent, timelineEvents],
  );

  return {
    handleTimelineCommentCreatedEvent,
    handleTimelineCommentUpdatedEvent,
    handleTimelineCommentDeletedEvent,
  };
};
