import { memo, useMemo } from 'react';
import { Flex } from '@mantine/core';

import { TimelineEventPersistentExtraBoxesProps } from './TimelineEventPersistentExtraBoxes.types';
import { DetailsType } from '@/pageAI/gql/graphql';
import { TimelineEventExtraBox } from '../TimelineEventExtraBox';
import {
  doesEventHaveMultipleSummaries,
  doesEventHaveRatingDecisions,
  isTimelineEventFieldEmpty,
} from '@/pageAI/services/caseTimeline';
import { TimelineEventSummaryBox } from '../TimelineEventSummaryBox';
import { keys } from '@/shared/utils/object';

const TimelineEventPersistentExtraBoxesBase = ({ event }: TimelineEventPersistentExtraBoxesProps) => {
  const hasDetails = useMemo(
    () =>
      keys(event.detailsByType).some(
        (detailsType) =>
          detailsType !== DetailsType.DetailedInformation &&
          event.detailsByType[detailsType]?.some((detail) => !isTimelineEventFieldEmpty(detail)),
      ),
    [event.detailsByType],
  );
  const hasMultipleSummaries = useMemo(() => doesEventHaveMultipleSummaries(event), [event]);
  const hasRatingDecisions = useMemo(() => doesEventHaveRatingDecisions(event), [event]);
  const uniqueDetailsTypes = useMemo(() => [...new Set(event.detailsTypes || [])], [event.detailsTypes]);

  if (!hasDetails && !hasMultipleSummaries && !hasRatingDecisions) return null;

  return (
    <Flex direction="column" gap={4} mt={8} mb={4}>
      {(hasMultipleSummaries || hasRatingDecisions) && <TimelineEventSummaryBox event={event} />}

      {uniqueDetailsTypes.map((detailsType) => {
        if (!event.detailsByType[detailsType]?.length) return null;

        return <TimelineEventExtraBox key={detailsType} event={event} detailsType={detailsType} />;
      })}
    </Flex>
  );
};

export const TimelineEventPersistentExtraBoxes = memo(TimelineEventPersistentExtraBoxesBase);
