import { useMutation } from '@tanstack/react-query';

import { CreateFeedbackInput, CreateFeedbackMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { createFeedbackMutation } from '@/pageAI/api';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useCreateFeedback = () => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();

  const { mutateAsync: createFeedback, isLoading } = useMutation<CreateFeedbackMutation, Error, CreateFeedbackInput>({
    mutationFn: async (input) => {
      return request(createFeedbackMutation, { input });
    },
    onSuccess: () => {
      notify('Success', 'Your feedback has been sent', 'brand');
    },
    onError: () => {
      notify('Error', 'Failed to send feedback');
    },
  });

  return { createFeedback, isLoading };
};
