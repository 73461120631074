import { memo, useState } from 'react';
import { Box, Button, Flex, Paper, Text } from '@mantine/core';

import PageLogoCircle from '@/pageAI/icons/page-logo-circle.svg?react';
import StandingMailboxIllustration from '@/shared/icons/standing-mailbox.svg?react';
import { useResendVerificationEmail } from '@/pageAI/hooks/onboarding/useResendVerificationEmail/useResendVerificationEmail';
import { LogOutButton } from '@/pageAI/components/auth/LogOutButton';

const VerifyEmailPageBase = () => {
  const { resendVerificationEmail, isLoading: isResending } = useResendVerificationEmail();

  const [hasResent, setHasResent] = useState(false);

  return (
    <Flex align="center" justify="center" sx={(theme) => ({ background: theme.colors.dark[5], height: '100%' })}>
      <Paper p={64} shadow="sm" sx={{ width: 564 }}>
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" mb="md">
            <PageLogoCircle width={64} height={64} />

            <Text fz="1.5rem" color="dark.5" fw={600} mt="xs" align="center">
              Verify your email
            </Text>

            <Text fz="1rem" color="dark.2" fw={500} align="center" mb={48}>
              Thank you for signing up for Page.
              <br />
              To complete the registration process, please verify your email address.
            </Text>

            <Box sx={(theme) => ({ color: theme.colors.red[5] })}>
              <StandingMailboxIllustration height={240} />
            </Box>

            <Flex mt="xl" gap="md" direction="column">
              <Button
                variant="outline"
                size="md"
                onClick={async () => {
                  await resendVerificationEmail({});
                  setHasResent(true);
                }}
                loading={isResending}
                disabled={hasResent}
              >
                Resend verification email
              </Button>

              <Button color="red.6" size="md" onClick={() => (window.location.href = window.location.origin)}>
                I have verified my email
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Paper>

      <Flex align="center" justify="center" pos="fixed" right={12} bottom={12}>
        <LogOutButton />
      </Flex>
    </Flex>
  );
};

export const VerifyEmailPage = memo(VerifyEmailPageBase);
