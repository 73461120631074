import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { OrganizationQuery, queryMap } from '../useOrganization';
import { Organization } from '@/pageAI/@types';
import { ViewerQuery } from '@/pageAI/gql/graphql';

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  const updateOrganizationLocally = useCallback(
    (organizationId: string) => (updateFunction: (currentOrganization: Organization) => Partial<Organization>) => {
      const queryKey = queryMap.organization.queryKey(organizationId);

      const previousQuery = queryClient.getQueryData<OrganizationQuery>(queryKey);

      const fieldsToUpdate = previousQuery ? updateFunction(previousQuery) : {};

      queryClient.setQueryData<OrganizationQuery>(queryKey, (organizationQuery) => {
        if (!organizationQuery) return organizationQuery;

        return {
          ...organizationQuery,
          ...fieldsToUpdate,
        };
      });

      if (fieldsToUpdate.joinRequests) {
        queryClient.setQueryData<ViewerQuery>(['currentUser'], (currentUserQuery) => {
          if (!currentUserQuery?.viewer.organizations) return currentUserQuery;

          return {
            ...currentUserQuery,
            viewer: {
              ...currentUserQuery.viewer,
              organizations: {
                ...currentUserQuery.viewer.organizations,
                nodes: currentUserQuery.viewer.organizations?.nodes?.map((organization) => {
                  if (organization?.id !== organizationId) return organization;

                  return {
                    ...organization,
                    joinRequests: {
                      nodes: fieldsToUpdate.joinRequests?.map((joinRequest) => ({ id: joinRequest.id })),
                    },
                  };
                }),
              },
            },
          };
        });
      }

      return () => queryClient.setQueryData<OrganizationQuery>(queryKey, previousQuery);
    },
    [queryClient],
  );

  return { updateOrganizationLocally };
};
