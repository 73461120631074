import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Flex, List, Modal, Text, TextInput } from '@mantine/core';

import { MergeConditionsModalProps } from './MergeConditionsModal.types';
import { useMergeConditions } from '@/pageAI/hooks/conditions/useMergeConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { truthy } from '@/shared/utils/boolean';
import { getFirstCodesheetCondition } from '@/pageAI/services/medicalConditions';
import { ConditionType } from '@/pageAI/gql/graphql';

const MergeConditionsModalBase = ({
  opened = false,
  onClose,
  onConfirm,
  onSuccess,
  medicalConditions,
  conditionIdsToMerge,
}: MergeConditionsModalProps) => {
  const { mergeConditions, isLoading } = useMergeConditions();
  const client = useSelectedClient(true);

  const conditionsToMerge = useMemo(() => {
    return conditionIdsToMerge
      .map((conditionId) => medicalConditions.find((condition) => condition.id === conditionId))
      .filter(truthy)
      .sort((conditionA, conditionB) => (conditionA.conditionType === ConditionType.Codesheet ? -1 : 1));
  }, [medicalConditions, conditionIdsToMerge]);

  const [newConditionName, setNewConditionName] = useState(
    getFirstCodesheetCondition(conditionsToMerge)?.headerCondition || '',
  );

  useEffect(() => {
    setNewConditionName(getFirstCodesheetCondition(conditionsToMerge)?.headerCondition || '');
  }, [opened, conditionsToMerge]);

  const handleMerge = async () => {
    onConfirm?.();

    const responseBody = await mergeConditions({
      clientId: client.id,
      mergedConditionHeader: newConditionName,
      sourceConditionIds: conditionIdsToMerge,
    });

    if (!responseBody) return;

    onSuccess?.(responseBody.mergeConditionsErrorCorrection.destCondition.id);
  };

  return (
    <Modal opened={opened} onClose={onClose} size={720} title="Merge Conditions">
      <Text fz="0.75rem" fw={500} color="gray.6" mb="xs">
        All the selected conditions will be merged into one. The merging process is irreversible and will take some
        time.
        <br />
        Please name the new condition and confirm merging.
      </Text>

      <TextInput
        label="New condition name"
        placeholder="Enter the name of the new condition..."
        value={newConditionName}
        onChange={(event) => setNewConditionName(event.target.value)}
      />

      <Flex direction="column">
        <Text fz="0.875rem" fw={600} color="dark.4" mt="xs">
          Selected conditions:
        </Text>

        <List type="ordered" fz="0.875rem" pl="xs">
          {conditionsToMerge.map((condition) => (
            <List.Item key={condition.id}>
              <Text fz="0.875rem" color="dark">
                {condition.headerCondition}
              </Text>
            </List.Item>
          ))}
        </List>
      </Flex>

      <Flex justify="flex-end" mt="lg" gap="xs">
        <Button variant="subtle" color="gray.7" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>

        <Button loading={isLoading} onClick={handleMerge}>
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};

export const MergeConditionsModal = memo(MergeConditionsModalBase);
