import { memo } from 'react';
import { Flex, Text } from '@mantine/core';
import { IconWorldWww } from '@tabler/icons-react';

import { MessageSourceProps } from './MessageSource.types';
import { MarkdownAnchorRenderer } from '@/shared/components/common/markdown/MarkdownAnchorRenderer';
import { extractUrlHostname } from '@/shared/utils/url';
import { getAssetIcon, normalizeFile } from '@/shared/services/files';
import { RightSidebarType, openRightSidebar } from '@/shared/states/layout';
import { setFilePreviewAsset } from '@/shared/states/filePreview';
import { AssetType } from '@/shared/@types';

const MessageSourceBase: React.FC<MessageSourceProps> = ({ source, sourceIndex }) => {
  if (!source.website) {
    if (!source.file) return null;

    const AssetIcon = getAssetIcon({ type: AssetType.FILE, mimeType: source.file.mimeType });

    // TODO: Refactor this into a new reusable component
    return (
      <Flex
        gap="xs"
        direction="column"
        onClick={() => {
          openRightSidebar(RightSidebarType.ASSET);
          setFilePreviewAsset(normalizeFile(source.file!));
        }}
        sx={(theme) => ({
          width: 280,
          backgroundColor: theme.colors.gray[2],
          padding: 12,
          borderRadius: 8,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.gray[3],
          },
        })}
      >
        <Flex align="center" gap={6}>
          <Flex sx={(theme) => ({ minWidth: 16, minHeight: 16, color: theme.colors.gray[6] })}>
            <AssetIcon width={16} height={16} />
          </Flex>

          <Text
            fz="0.75rem"
            fw={600}
            color="dark"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {source.file.name}
          </Text>
        </Flex>

        <Flex>
          <Text fz="0.75rem" color="gray.6">
            {sourceIndex + 1} - {source.file.mimeType}
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <MarkdownAnchorRenderer sx={{ textDecoration: 'none !important' }} href={source.website.url}>
      <Flex
        gap="xs"
        direction="column"
        sx={(theme) => ({
          width: 280,
          backgroundColor: theme.colors.gray[2],
          padding: 12,
          borderRadius: 8,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.gray[3],
          },
        })}
      >
        <Flex align="center" gap={6}>
          <Flex sx={(theme) => ({ minWidth: 16, minHeight: 16, color: theme.colors.gray[6] })}>
            <IconWorldWww size={16} />
          </Flex>

          <Text
            fz="0.75rem"
            fw={600}
            color="dark"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {source.website.name}
          </Text>
        </Flex>

        <Flex>
          <Text fz="0.75rem" color="gray.6">
            {sourceIndex + 1} - {extractUrlHostname(source.website.url)}
          </Text>
        </Flex>
      </Flex>
    </MarkdownAnchorRenderer>
  );
};

export const MessageSource = memo(MessageSourceBase);
