import { memo, useMemo, useState } from 'react';
import { ActionIcon, Checkbox, Flex, Popover, ScrollArea, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSelect } from '@tabler/icons-react';

import { TimelineTableOfContentsProps } from './TimelineTableOfContents.types';
import { MenuItem } from '@/shared/designSystem/MenuItem';
import { getTimelineTableOfContents, highlightTimelineEventPubsub } from '@/pageAI/services/caseTimeline';
import { getMonthText } from '@/shared/utils/date';

const TimelineTableOfContentsBase = ({ timelineByDate, sortOrder = 'desc' }: TimelineTableOfContentsProps) => {
  const [showMonths, setShowMonths] = useState(true);
  const [opened, { close, toggle }] = useDisclosure();

  const tableOfContents = useMemo(() => getTimelineTableOfContents(timelineByDate), [timelineByDate]);

  const sortedEntries = useMemo(() => {
    let entries = Object.entries(tableOfContents);

    if (sortOrder === 'desc') entries = entries.toReversed();

    return entries;
  }, [tableOfContents, sortOrder]);

  const handleSelect = (year: string) => {
    close();

    const event = Object.values(tableOfContents[year]).at(sortOrder === 'desc' ? -1 : 0)?.[0];

    if (!event) return;

    highlightTimelineEventPubsub.publish({ event, shouldOpenReference: false });
  };

  const handleSelectMonth = (year: string, month: string) => {
    close();

    const event = tableOfContents[year][month][0];

    if (!event) return;

    highlightTimelineEventPubsub.publish({ event, shouldOpenReference: false });
  };

  return (
    <Popover shadow="sm" width={280} position="bottom-start" opened={opened} onClose={close}>
      <Popover.Target>
        <Tooltip label="Quick Timeline Navigation" withArrow withinPortal>
          <ActionIcon color="gray.7" onClick={toggle}>
            <IconSelect size={20} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Flex direction="column">
          <Flex align="center" justify="space-between" sx={{ padding: '12px 12px 8px 8px' }}>
            <Text size="sm" fw={500} color="dark">
              Quick Navigation
            </Text>

            <Checkbox
              label="Show months"
              size="xs"
              checked={showMonths}
              onChange={(event) => setShowMonths(event.target.checked)}
            />
          </Flex>

          <ScrollArea h={440} sx={{ padding: '0 4px' }}>
            <Flex direction="column" pb={4}>
              {sortedEntries.map(([year, eventsByMonth]) => {
                let events = Object.entries(eventsByMonth);

                if (sortOrder === 'desc') events = events.toReversed();

                return (
                  <Flex key={year} direction="column">
                    <MenuItem onClick={() => handleSelect(year)}>
                      <Text fw={500}>{year}</Text>
                    </MenuItem>

                    {showMonths
                      ? events.map(([month, events]) => {
                          return (
                            <Flex key={month} direction="column">
                              <MenuItem onClick={() => handleSelectMonth(year, month)}>
                                <Flex align="center" gap={4} pl="xs">
                                  <Text>{getMonthText(month)}</Text>

                                  <Text color="dark.3" fz="0.75rem">
                                    ({events.length})
                                  </Text>
                                </Flex>
                              </MenuItem>
                            </Flex>
                          );
                        })
                      : null}
                  </Flex>
                );
              })}
            </Flex>
          </ScrollArea>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export const TimelineTableOfContents = memo(TimelineTableOfContentsBase);
