import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateUserProfileMutation } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { UpdateUserProfileInput, UpdateUserProfileMutation } from '@/pageAI/gql/graphql';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useUpdateUserProfile = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const { mutateAsync: updateUserProfile, isLoading } = useMutation<
    UpdateUserProfileMutation,
    Error,
    UpdateUserProfileInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(updateUserProfileMutation, { input });

      await queryClient.invalidateQueries(['currentUser']);

      return responseBody;
    },
    onError: () => {
      notify('Error', 'Could not update user profile');
    },
  });

  return { updateUserProfile, isLoading };
};
