import { memo, useMemo, useState } from 'react';
import { Badge, Flex, Highlight, Table, Text, Tooltip, useMantineTheme } from '@mantine/core';

import { formatDate, formatDateTime, sortDate } from '@/shared/utils/date';
import { OrganizationMembersTableProps } from './OrganizationMembersTable.types';
import { getUserDisplayName } from '@/pageAI/services/users/users.services';
import { UserAvatar } from '../../users/UserAvatar';
import { InteractableMemberRoleBadge } from '../InteractableMemberRoleBadge';
import { canUpdateMemberRole } from '@/pageAI/services/organizations';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import { sortAlphabetically } from '@/shared/utils/string';
import { RemoveMemberButton } from '../RemoveMemberButton';

const headers = ['Member', 'Joined Date', 'Email', 'Role', ''];

const OrganizationMembersTableBase = ({
  currentMember,
  members,
  organizationId,
  query,
}: OrganizationMembersTableProps) => {
  const theme = useMantineTheme();

  const [sortedColumnName, setSortedColumnName] = useState<string | null>('Member');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>('asc');

  const sortedMembers = useMemo(() => {
    let sorted = [...members].sort((memberA, memberB) => {
      if (!sortedColumnName || sortedColumnName === 'Member') {
        return sortAlphabetically(getUserDisplayName(memberB.user), getUserDisplayName(memberA.user));
      }

      if (sortedColumnName === 'Email') {
        return sortAlphabetically(memberB.user.email || '', memberA.user.email || '');
      }

      if (sortedColumnName === 'Role') {
        return sortAlphabetically(memberB.role, memberA.role);
      }

      if (sortedColumnName === 'Joined Date') {
        return sortDate(memberB.user.createdAt || new Date(), memberA.user.createdAt || new Date());
      }

      return 0;
    });

    if (sortOrder === 'asc') sorted = sorted.reverse();

    return sorted;
  }, [members, sortedColumnName, sortOrder]);

  const rows = useMemo(
    () =>
      sortedMembers.map((member) => {
        const isOtherMember = member.user.id !== currentMember.user.id;

        return (
          <tr key={member.user.id} style={{ height: 50 }}>
            <td>
              <Flex align="center" gap={6}>
                <UserAvatar user={member.user} size={24} />

                <Text fw={600} color="dark.4">
                  <Highlight highlight={query ? [query] : []}>{getUserDisplayName(member.user)}</Highlight>
                </Text>

                {!isOtherMember && (
                  <Badge color="teal.7" size="xs">
                    You
                  </Badge>
                )}
              </Flex>
            </td>

            <td>
              <Tooltip label={formatDateTime(member.user.createdAt)} withinPortal withArrow>
                <Text color="dark.5" sx={{ width: 'fit-content' }}>
                  {formatDate(member.user.createdAt)}
                </Text>
              </Tooltip>
            </td>

            <td>
              <Text color="dark.5" sx={{ width: 'fit-content' }}>
                {member.user.email}
              </Text>
            </td>

            <td>
              <InteractableMemberRoleBadge
                member={member}
                canUpdate={isOtherMember && canUpdateMemberRole(currentMember)}
              />
            </td>

            <td style={{ textAlign: 'right' }}>
              <Flex align="center" justify="end">
                <RemoveMemberButton member={member} currentMember={currentMember} organizationId={organizationId} />
              </Flex>
            </td>
          </tr>
        );
      }),
    [currentMember, sortedMembers, organizationId, query],
  );

  const handleSortColumn = (columnName: string) => () => {
    setSortedColumnName(columnName);

    if (sortOrder === null || sortedColumnName !== columnName) return setSortOrder('desc');

    if (sortOrder === 'desc') return setSortOrder('asc');

    if (sortOrder === 'asc') {
      setSortedColumnName(null);

      return setSortOrder(null);
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {headers.map((header, headerIndex) => {
            return (
              <th key={header} onClick={handleSortColumn(header)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    ...(headerIndex === headers.length - 1
                      ? {
                          justifyContent: 'flex-end',
                        }
                      : {}),
                  }}
                >
                  {header}

                  {headerIndex !== headers.length - 1 && (
                    <>
                      {sortedColumnName === header ? (
                        <>{sortOrder === 'asc' ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}</>
                      ) : (
                        <IconSelector size={14} />
                      )}
                    </>
                  )}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  return (
    <Table
      highlightOnHover
      sx={{
        th: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.gray[1],
          },
        },
      }}
    >
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>

      {renderTableHeader()}

      <tbody>{rows}</tbody>
    </Table>
  );
};

export const OrganizationMembersTable = memo(OrganizationMembersTableBase);
