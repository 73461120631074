import { useMemo } from 'react';
import { cloneDeep } from 'lodash-es';

import { Client } from '@/pageAI/@types';
import { getClientFiles } from '@/pageAI/services/clients';
import { useClientTimelineEvents } from '../useClientTimelineEvents';
import { extractFileIdAndPageIndexFromHref } from '@/pageAI/services/summaries';
import { extractHrefFromMarkdown } from '@/shared/utils/markdown';
import { FileAsset } from '@/shared/@types';

export const useClientFiles = (client: Client) => {
  const { fileAssetMapping, mainFileCollectionAsset } = useMemo(() => getClientFiles(client), [client]);
  const { timelineEvents, isLoading } = useClientTimelineEvents({ clientId: client.id });

  const updatedFileAssetMapping = useMemo(() => {
    if (!timelineEvents?.length) return fileAssetMapping;

    const newFileAssetMapping = cloneDeep(fileAssetMapping);

    timelineEvents.forEach((event) => {
      if (!event.bookmarked && !event.comments.length) return;

      const { fileId } = extractFileIdAndPageIndexFromHref(extractHrefFromMarkdown(event.reference));

      if (!fileId || !newFileAssetMapping[fileId]) return;

      newFileAssetMapping[fileId]!.isImportant = true;
    });

    return newFileAssetMapping;
  }, [fileAssetMapping, timelineEvents]);

  const updatedFileAssets = useMemo(
    () => Object.values(updatedFileAssetMapping) as FileAsset[],
    [updatedFileAssetMapping],
  );

  return { fileAssets: updatedFileAssets, fileCollection: mainFileCollectionAsset, isLoading };
};
