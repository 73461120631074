import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { updateSearchParams } from '@/shared/lib/browser';
import { getFileNamesFromFileGroups, getFileSearchFilterValuesFromSearchParams } from '@/pageAI/services/files';
import { useFileGroups } from '../useFileGroups';
import { truthy } from '@/shared/utils/boolean';

export const useFileSearchFilters = () => {
  const [searchParams] = useSearchParams();

  const { fileGroupsMapping } = useFileGroups();

  const initialValues = useMemo(() => getFileSearchFilterValuesFromSearchParams(searchParams), [searchParams]);

  const [fileGroups, setFileGroups] = useState<string[] | null>(initialValues.fileGroups);
  const [fileTypes, setFileTypes] = useState<string[] | null>(() => {
    if (initialValues.fileTypes?.length) return initialValues.fileTypes;

    return getFileNamesFromFileGroups((fileGroups || []).map((value) => fileGroupsMapping[value]).filter(truthy));
  });
  const [keywords, setKeywords] = useState<string | null>(initialValues.keywords);

  return { fileGroups, setFileGroups, fileTypes, setFileTypes, keywords, setKeywords };
};

interface UseFileFilterSearchParamsProps {
  fileGroups: string[] | null;
  fileTypes: string[] | null;
  keywords?: string | null;
  disabled?: boolean;
}

export const useFileFilterSearchParams = ({
  fileGroups,
  fileTypes,
  keywords,
  disabled,
}: UseFileFilterSearchParamsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (disabled) return;

    const updateSearchParamsFn = updateSearchParams(searchParams);

    updateSearchParamsFn('fileGroup', fileGroups);
    updateSearchParamsFn('fileType', fileGroups?.length ? [] : fileTypes);

    if (typeof keywords !== 'undefined') updateSearchParamsFn('fileKeywords', keywords);

    setSearchParams(searchParams, { replace: true });
  }, [disabled, searchParams, setSearchParams, fileGroups, fileTypes, keywords]);
};
