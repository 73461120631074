import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateFileGroupInput,
  CreateFileGroupMutation,
  DeleteFileGroupInput,
  DeleteFileGroupMutation,
  FileGroup,
  FileGroupsQuery,
  UpdateFileGroupInput,
  UpdateFileGroupMutation,
} from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { createFileGroupMutation, deleteFileGroupMutation, updateFileGroupMutation } from '@/pageAI/api';
import { useCallback } from 'react';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useManageFileGroups = (organizationId: string) => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const updateFileGroupsLocally = useCallback(
    (updateFn: (currentFileGroups: FileGroup[]) => FileGroup[]) => {
      const previousQueryData = queryClient.getQueryData(['file-groups', organizationId]) as
        | FileGroupsQuery
        | undefined;

      queryClient.setQueryData(['file-groups', organizationId], (data?: FileGroupsQuery) => {
        if (!data || !data.organization?.fileGroups) return data;

        return {
          ...data,
          organization: {
            ...data.organization,
            fileGroups: {
              ...data.organization.fileGroups,
              nodes: updateFn(data.organization.fileGroups.nodes),
            },
          },
        };
      });

      return () => queryClient.setQueryData(['file-groups', organizationId], previousQueryData);
    },
    [queryClient, organizationId],
  );

  const { mutateAsync: createFileGroup, isLoading: isCreating } = useMutation<
    CreateFileGroupMutation,
    Error,
    CreateFileGroupInput
  >({
    mutationFn: async (input) => {
      const response = await request(createFileGroupMutation, { input });

      updateFileGroupsLocally((fileGroups) => [...fileGroups, response.createFileGroup]);

      return response;
    },
    onError: () => {
      notify('Error', 'Failed to create custom filter');
    },
  });

  const { mutateAsync: updateFileGroup, isLoading: isUpdating } = useMutation<
    UpdateFileGroupMutation,
    Error,
    UpdateFileGroupInput
  >({
    mutationFn: async (input) => {
      const response = await request(updateFileGroupMutation, { input });

      updateFileGroupsLocally((fileGroups) =>
        fileGroups.map((fileGroup) => (fileGroup.id === input.id ? response.updateFileGroup : fileGroup)),
      );

      return response;
    },
    onError: () => {
      notify('Error', 'Failed to save custom filter');
    },
  });

  const { mutateAsync: deleteFileGroup, isLoading: isDeleting } = useMutation<
    DeleteFileGroupMutation,
    Error,
    DeleteFileGroupInput
  >({
    mutationFn: async (input) => {
      const revert = updateFileGroupsLocally((fileGroups) =>
        fileGroups.filter((fileGroup) => fileGroup.id !== input.id),
      );

      try {
        const response = await request(deleteFileGroupMutation, { input });

        notify('Success', 'Custom filter deleted', 'brand');

        return response;
      } catch (error) {
        revert();

        notify('Error', 'Failed to delete custom filter');

        throw error;
      }
    },
  });

  return { createFileGroup, updateFileGroup, deleteFileGroup, isCreating, isUpdating, isDeleting };
};
