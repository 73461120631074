import { useQuery } from '@tanstack/react-query';

import { conversationsQuery } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

const DEFAULT_LIMIT = 1000;

export const useConversations = () => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, refetch, isRefetching } = useQuery(['conversations'], () =>
    request(conversationsQuery, { first: DEFAULT_LIMIT }),
  );

  return { conversations: data?.conversations.nodes || [], isLoading, refetch, isRefetching };
};
