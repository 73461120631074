import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { currentUserQuery } from '@/pageAI/api';

export const useCurrentUser = () => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(['currentUser'], () => request(currentUserQuery));

  return { currentUser: data?.viewer, isLoading, isError };
};
