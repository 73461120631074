import { useMutation } from '@tanstack/react-query';

import { RemoveOrganizationMemberInput, RemoveOrganizationMemberMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { removeOrganizationMemberMutation } from '@/pageAI/api';
import { useUpdateOrganization } from '../useUpdateOrganization';

export const useRemoveOrganizationMember = (organizationId: string) => {
  const { request } = useGraphQLRequest();
  const { notify } = useNotifications();
  const { updateOrganizationLocally } = useUpdateOrganization();

  const { mutateAsync: removeMember, isLoading } = useMutation<
    RemoveOrganizationMemberMutation,
    Error,
    RemoveOrganizationMemberInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(removeOrganizationMemberMutation, { input });

      return responseBody;
    },
    onSuccess: (responseBody, input) => {
      notify('Success', 'The member has been removed from your organization.', 'brand');

      updateOrganizationLocally(organizationId)((currentOrganization) => ({
        members: currentOrganization.members.filter((member) => member.user.id !== input.userId),
      }));
    },
    onError: () => {
      notify('Error', 'Could not remove the member from your organization.');
    },
  });

  return { removeMember, isLoading };
};
