import { memo, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell, Flex, clsx } from '@mantine/core';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';

import { useLayoutStore } from '@/shared/states/layout';
import { RightSidebar } from '@/shared/components/layout/RightSidebar';
import { PageAILeftSidebar } from '../PageAILeftSidebar';
import { LeftSubSidebar } from '../LeftSubSidebar';
import { usePersistentFilePreviewSize } from '@/pageAI/hooks/filePreview/usePersistentFilePreviewSize';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { FileGroupManagementModal } from '../../files/FileGroupManagementModal';
import { OnboardingConfettiModal } from '../../onboarding/OnboardingConfettiModal';

const PageAILayoutBase = () => {
  const { isLeftSidebarOpened, isRightSidebarOpened, isPrinting } = useLayoutStore();
  const selectedClient = useSelectedClient();
  const [filePreviewSize, setFilePreviewSize] = usePersistentFilePreviewSize(selectedClient?.id);

  const handleResizeFinished = useCallback(
    (pairIdx: number, newSizes: number[]) => {
      setFilePreviewSize(newSizes[1]);
    },
    [setFilePreviewSize],
  );

  return (
    <AppShell
      className={clsx('page-ai-layout', { printing: isPrinting })}
      padding={0}
      navbar={<PageAILeftSidebar />}
      styles={(theme) => ({
        main: {
          backgroundColor: theme.white,
          'table.ghost-Table-root': {
            outline: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 4,
          },
          '.__dbk__gutter.Horizontal.custom-gutter-horizontal': {
            borderRight: `1px solid ${theme.colors.gray[3]}`,
            zIndex: 101,
          },
          '.__dbk__dragger.Horizontal.Dark': {
            background: theme.colors.gray[5],
            marginRight: -5,
            zIndex: 2,
          },
        },
      })}
    >
      <Flex direction="column" sx={{ height: '100%', width: '100%' }}>
        <Flex sx={{ height: '100%', position: 'relative' }}>
          {isLeftSidebarOpened && <LeftSubSidebar />}

          <ReactSplit
            gutterClassName="custom-gutter-horizontal"
            initialSizes={isRightSidebarOpened ? [100 - filePreviewSize, filePreviewSize] : [100]}
            direction={SplitDirection.Horizontal}
            onResizeFinished={handleResizeFinished}
          >
            <Outlet />

            {isRightSidebarOpened && <RightSidebar />}
          </ReactSplit>
        </Flex>
      </Flex>

      <FileGroupManagementModal />
      <OnboardingConfettiModal />
    </AppShell>
  );
};

export const PageAILayout = memo(PageAILayoutBase);
