import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateOrganizationMemberRoleInput, UpdateOrganizationMemberRoleMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { updateOrganizationMemberRoleMutation } from '@/pageAI/api/organization';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';

export const useUpdateOrganizationMemberRole = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const { mutateAsync: updateMemberRole, isLoading } = useMutation<
    UpdateOrganizationMemberRoleMutation,
    Error,
    UpdateOrganizationMemberRoleInput
  >({
    mutationFn: async (input) => {
      const responseBody = await request(updateOrganizationMemberRoleMutation, { input });

      await queryClient.invalidateQueries(['organization', input.organizationId]);

      return responseBody;
    },
    onError: () => {
      notify('Error', 'Failed to update member role');
    },
  });

  return { updateMemberRole, isLoading };
};
