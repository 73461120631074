import { ConvertUnitResult, FileSize, GeneralUnit, UnitConversionStrategy, Weight } from './unit.types';

const WEIGHT_UNITS = Object.values(Weight) as GeneralUnit[];
const FILE_SIZE_UNITS = Object.values(FileSize) as GeneralUnit[];

const UNIT_CONVERSION_STRATEGY: UnitConversionStrategy = {
  [Weight.Gram]: {
    unitOrder: WEIGHT_UNITS,
    threshold: 1_000,
  },
  [FileSize.Byte]: {
    unitOrder: FILE_SIZE_UNITS,
    threshold: 1_000,
  },
};

Object.values(UNIT_CONVERSION_STRATEGY).forEach((unitStrategy) => {
  unitStrategy.unitOrder.forEach((unit) => {
    UNIT_CONVERSION_STRATEGY[unit] = unitStrategy;
  });
});

export const convertUnit = (value: number, unit: GeneralUnit): ConvertUnitResult => {
  const unitConversionStrategy = UNIT_CONVERSION_STRATEGY[unit];

  if (!unitConversionStrategy) throw new Error('Unit not supported');

  const { unitOrder, threshold } = unitConversionStrategy;

  if (value < threshold || unit === unitOrder.at(-1)) {
    return {
      newValue: value,
      newUnit: unit,
    };
  }

  const nextUnit = unitOrder[Math.min(unitOrder.indexOf(unit) + 1, unitOrder.length - 1)];

  return convertUnit(value / threshold, nextUnit);
};
