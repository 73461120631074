import { memo } from 'react';
import { Box, Flex, ScrollArea } from '@mantine/core';

import { UnifiedContentSearchProps } from './UnifiedContentSearch.types';
import { ClientFileSearchLayout } from '../../clients/ClientFileSearchLayout';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { useUnifiedScrollPosition } from '@/pageAI/hooks/unified/useUnifiedScrollPosition/useUnifiedScrollPosition';
import { UnifiedTab } from '@/pageAI/services/medicalConditions';
import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { LoadingPlaceholder } from '@/shared/components/common/placeholders/LoadingPlaceholder';

const UnifiedContentSearchBase = (props: UnifiedContentSearchProps) => {
  const client = useSelectedClient(true);
  const { isLoading, isError } = useFileGroups();

  const scrollAreaRef = useUnifiedScrollPosition(UnifiedTab.CONTENT_SEARCH);

  if (isLoading)
    return (
      <Flex align="center" justify="center" sx={{ width: '100%', height: 'calc(100vh - 56px)' }}>
        <LoadingPlaceholder
          title="Loading content search..."
          description="Please wait while Page loads the page."
          fullScreen
        />
      </Flex>
    );

  if (isError) throw new Error('Could not load content search');

  return (
    <ScrollArea h="calc(100vh - 56px)" ref={scrollAreaRef}>
      <Box px={12}>
        <ClientFileSearchLayout client={client} />
      </Box>
    </ScrollArea>
  );
};

export const UnifiedContentSearch = memo(UnifiedContentSearchBase);
