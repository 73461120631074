import { create } from 'zustand';

interface ClientFileIndexState {
  selectedFileMapping: { [key: string]: boolean };
}

export const useClientFileIndexStore = create<ClientFileIndexState>((set) => ({
  selectedFileMapping: {},
}));

export const setSelectedClientFile = (fileId: string) => {
  useClientFileIndexStore.setState({ selectedFileMapping: { [fileId]: true } });
};

export const resetClientFileIndexStore = () => {
  useClientFileIndexStore.setState({ selectedFileMapping: {} });
};
