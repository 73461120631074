import { FileAsset } from '@/shared/@types';
import { getFileDisplayName } from '../files';

interface AppendDataToFeedbackProps {
  feedback: string;
  attachedLink: string;
  attachedFileAsset?: FileAsset;
}

export const appendDataToFeedback = ({ feedback, attachedLink, attachedFileAsset }: AppendDataToFeedbackProps) => {
  let updatedFeedback = `${feedback}\n
  ---
  \n\n**Attached link**: ${attachedLink}`;

  if (attachedFileAsset) {
    updatedFeedback = updatedFeedback.concat(
      `\n\n**Attached file**: ${getFileDisplayName(attachedFileAsset)} - ${attachedFileAsset.id}`,
    );
  }

  return updatedFeedback;
};
