import { CaseTimelineEvent } from '@/pageAI/@types/summaries';
import { create } from 'zustand';

export interface TimelineEventActionsState {
  isEditable: boolean;
  shareFunctionOverride?: (timelineEvent: CaseTimelineEvent) => string;
}

export const useTimelineEventActionsStore = create<TimelineEventActionsState>(() => ({
  isEditable: true,
}));

export const setTimelineEventsEditable = (isEditable: boolean) => {
  useTimelineEventActionsStore.setState({ isEditable });
};

export const setTimelineEventShareFunctionOverride = (
  shareFunctionOverride?: (timelineEvent: CaseTimelineEvent) => string,
) => {
  useTimelineEventActionsStore.setState({ shareFunctionOverride });
};
