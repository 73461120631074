import { useEffect, useRef } from 'react';

export const useScrollAreaScroll = (onScroll: (viewportElement: HTMLDivElement) => void) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const viewportElement = scrollAreaRef.current?.querySelector('.ghost-ScrollArea-viewport') as
      | HTMLDivElement
      | undefined;

    if (!viewportElement) return;

    const handleScroll = () => {
      onScroll(viewportElement);
    };

    viewportElement.addEventListener('scroll', handleScroll);

    return () => {
      viewportElement.removeEventListener('scroll', handleScroll);
    };
  }, [scrollAreaRef, onScroll]);

  return scrollAreaRef;
};
