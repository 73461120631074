import React, { memo, useCallback } from 'react';
import { Flex } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';

import { ConversationMessagesProps } from './ConversationMessages.types';
import { MessageRole } from '@/pageAI/gql/graphql';
import { UserMessage } from '../UserMessage';
import { BotMessage } from '../BotMessage';
import { MessagePlaceholder } from '../MessagePlaceholder';
import { useRegenerateAnswer } from '@/shared/hooks/chat/useRegenerateAnswer';
import { useNotifications } from '@/shared/hooks/notifications/useNotifications';
import { ConversationMessage } from '@/shared/@types';
import { useUpdateMessage } from '@/shared/hooks/chat/useUpdateMessage';
import { updateChatMessage } from '@/shared/services/chat/chat.services';

const ConversationMessagesBase: React.FC<ConversationMessagesProps> = ({ conversationId, messages }) => {
  const { notify } = useNotifications();
  const { regenerateAnswer, isLoading: isRegeneratingAnswer } = useRegenerateAnswer();
  const { updateMessage, isLoading: isUpdatingMessage } = useUpdateMessage();
  const queryClient = useQueryClient();

  const isLastMessageFromHuman = messages[messages.length - 1]?.role === MessageRole.Human;

  const regenerateBotMessage = useCallback(
    async (humanMessage: ConversationMessage, humanMessageIndex: number) => {
      const lastAIMessage = messages[humanMessageIndex + 1];

      if (!lastAIMessage) return;

      try {
        await regenerateAnswer({ id: humanMessage.id });

        updateChatMessage({
          queryClient,
          conversationId,
          messageId: lastAIMessage.id,
          updateInfo: { content: '', sources: [] },
        });
      } catch (error) {
        notify('Error', 'Could not regenerate answer');
      }
    },
    [messages, regenerateAnswer, queryClient, notify, conversationId],
  );

  const handleRegenerateAnswer = useCallback(async () => {
    const lastHumanMessage = messages.at(-2);

    if (!lastHumanMessage) return;

    regenerateBotMessage(lastHumanMessage, messages.length - 2);
  }, [messages, regenerateBotMessage]);

  const handleEditUserMessage = useCallback(
    async (newContent: string, message: ConversationMessage, messageIndex: number) => {
      try {
        await updateMessage({ id: message.id, content: newContent });

        updateChatMessage({ queryClient, conversationId, messageId: message.id, updateInfo: { content: newContent } });

        await regenerateBotMessage(message, messageIndex);
      } catch (error) {
        notify('Error', 'Could not update message');

        updateChatMessage({
          queryClient,
          conversationId,
          messageId: message.id,
          updateInfo: { content: message.content },
        });
      }
    },
    [updateMessage, notify, conversationId, queryClient, regenerateBotMessage],
  );

  return (
    <Flex direction="column" gap="md">
      {messages.map((message, messageIndex) => (
        <React.Fragment key={message.id}>
          {message.role === MessageRole.Human ? (
            <UserMessage message={message} messageIndex={messageIndex} onEdit={handleEditUserMessage} />
          ) : (
            <BotMessage
              message={message}
              canRegenerate={messageIndex === messages.length - 1 && !isRegeneratingAnswer}
              onRegenerate={handleRegenerateAnswer}
            />
          )}
        </React.Fragment>
      ))}

      {(isLastMessageFromHuman || messages.length === 0) && (
        <MessagePlaceholder role={messages.length === 0 ? MessageRole.Human : MessageRole.Ai} />
      )}
    </Flex>
  );
};

export const ConversationMessages = memo(ConversationMessagesBase);
