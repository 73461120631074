import { Client } from '@/pageAI/@types';
import { useClientStore } from '@/pageAI/states/client';

export function useSelectedClient(assertTrue: true): Client;
export function useSelectedClient(assertTrue?: false): Client | null;

export function useSelectedClient(assertTrue?: boolean): Client | null {
  const selectedClient = useClientStore((state) => state.selectedClient);

  if (assertTrue && !selectedClient) {
    throw new Error('Selected client is not found');
  }

  return selectedClient;
}
