import { memo, useEffect, useRef, useState } from 'react';
import { ActionIcon, Box, Flex, ScrollArea, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { MarkdownSidePreviewerProps } from './MarkdownSidePreviewer.types';
import { MarkdownRenderer } from '@/shared/components/common/markdown/MarkdownRenderer';
import { closeMarkdownPreviewer, useMarkdownPreviewStore } from '@/shared/states/markdownPreview';

const MarkdownSidePreviewerBase = (props: MarkdownSidePreviewerProps) => {
  const { markdown, title, titleSuffix } = useMarkdownPreviewStore();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerY, setContainerY] = useState(0);

  useEffect(() => {
    if (!containerRef.current) return;

    const { y } = containerRef.current.getBoundingClientRect();

    setContainerY(y);
  }, [containerRef]);

  return (
    <Box ref={containerRef} sx={{ padding: '16px 12px' }}>
      <Flex justify="center" align="center" mb="xs" sx={{ position: 'relative' }}>
        <Flex align="center" gap={4}>
          <Text fw={500} color="dark.5" fz="0.875rem">
            {title}
          </Text>

          {titleSuffix}
        </Flex>

        <ActionIcon color="dark" sx={{ position: 'absolute', right: 0 }} onClick={closeMarkdownPreviewer}>
          <IconX size={16} />
        </ActionIcon>
      </Flex>

      <ScrollArea
        sx={(theme) => ({
          background: theme.white,
          borderRadius: 4,
          border: `1px solid ${theme.colors.gray[2]}`,
          height: `calc(100vh - 60px - ${containerY}px)`,
        })}
      >
        <Box p="xs" sx={{ fontSize: '87.5%' }}>
          <MarkdownRenderer content={markdown} />
        </Box>
      </ScrollArea>
    </Box>
  );
};

export const MarkdownSidePreviewer = memo(MarkdownSidePreviewerBase);
