import { useMutation } from '@tanstack/react-query';

import { updateMessageMutation } from '@/pageAI/api';
import { UpdateMessageInput, UpdateMessageMutation } from '@/pageAI/gql/graphql';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useUpdateMessage = () => {
  const { request } = useGraphQLRequest();

  const { mutateAsync: updateMessage, isLoading } = useMutation<UpdateMessageMutation, Error, UpdateMessageInput>({
    mutationFn: async (input) => {
      return request(updateMessageMutation, { input });
    },
  });

  return { updateMessage, isLoading };
};
