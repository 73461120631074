import { memo } from 'react';
import { Button, Flex, Modal, Select, TextInput } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { IconMail } from '@tabler/icons-react';

import { OrganizationInvitationRole } from '@/pageAI/gql/graphql';
import { InviteMemberModalProps } from './InviteMemberModal.types';
import { useInviteOrganizationMember } from '@/pageAI/hooks/organizations/useInviteOrganizationMember';

const InviteMemberModalBase = ({ opened = false, organization, onClose }: InviteMemberModalProps) => {
  const { inviteMember, isLoading } = useInviteOrganizationMember();

  const form = useForm({
    initialValues: {
      email: '',
      role: OrganizationInvitationRole.Member,
    },

    validate: {
      email: isEmail('Email is not valid'),
    },
  });

  const handleSubmit = async () => {
    const { email, role } = form.values;

    await inviteMember({ email, role, organizationId: organization.id });

    form.reset();
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size={640}
      title="Send an invitation"
      sx={{
        '.ghost-Modal-title': {
          fontWeight: 500,
        },
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="column" gap="xl">
          <Flex direction="column" gap="md">
            <Flex align="center" gap="xs">
              <TextInput
                placeholder="Enter the email of the person you want to invite..."
                withAsterisk
                icon={<IconMail size={16} />}
                sx={{ flex: '1 1' }}
                disabled={isLoading}
                {...form.getInputProps('email')}
              />

              <Select
                w={120}
                withinPortal
                data={[
                  { label: 'Admin', value: OrganizationInvitationRole.Admin },
                  { label: 'Member', value: OrganizationInvitationRole.Member },
                ]}
                disabled={isLoading}
                {...form.getInputProps('role')}
              />
            </Flex>
          </Flex>

          <Flex justify="end" gap="xs">
            <Flex align="center" gap="xs">
              <Button color="gray.8" variant="subtle" onClick={onClose} disabled={isLoading}>
                Close
              </Button>

              <Button type="submit" color="red" disabled={!form.values.email} loading={isLoading}>
                Invite
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export const InviteMemberModal = memo(InviteMemberModalBase);
