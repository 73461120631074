import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, useMantineTheme } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

import { useFileGroups } from '@/pageAI/hooks/files/useFileGroups';
import { FileGroup } from '@/pageAI/gql/graphql';
import { FileGroupManagementProps } from './FileGroupManagement.types';
import { UpsertFileGroupLayout } from '../UpsertFileGroupLayout';
import { FileGroups } from '../FileGroups';
import { selectFileGroupEventBus } from '../FileGroupRow';
import { TIMELINE_EVENT_TYPE_COLOR_MAPPING } from '@/pageAI/services/caseTimeline';

const FileGroupManagementBase = ({
  organizationId,
  mode,
  fileNamesToSave,
  onModeChange,
  onClose,
}: FileGroupManagementProps) => {
  const theme = useMantineTheme();

  const [selectedFileGroup, setSelectedFileGroup] = useState<FileGroup | undefined>(() => {
    if (!fileNamesToSave?.length) return;

    return { id: uuidv4(), isDefault: false, displayName: '', color: null, fileNames: fileNamesToSave };
  });
  const { fileGroups, isLoading, isError } = useFileGroups();

  const handleBack = useCallback(() => {
    setSelectedFileGroup(undefined);
    onModeChange?.('View');
  }, [onModeChange]);

  useEffect(() => {
    if (mode !== 'View') return;

    setSelectedFileGroup(undefined);
  }, [mode]);

  const customFileGroupAction = useMemo(
    () => (
      <Button
        onClick={() => {
          onModeChange?.('Create');
        }}
        leftIcon={<IconPlus size={14} />}
        size="xs"
      >
        Create
      </Button>
    ),
    [onModeChange],
  );

  useEffect(() => {
    const unsubscribe = selectFileGroupEventBus.subscribe(({ fileGroup }) => {
      setSelectedFileGroup(fileGroup);
      onModeChange?.('Edit');
    });

    return unsubscribe;
  }, [onModeChange]);

  const handleDuplicate = useCallback(() => {
    if (!selectedFileGroup) return;

    onModeChange?.('Create');
    setSelectedFileGroup({
      ...selectedFileGroup,
      id: uuidv4(),
      isDefault: false,
      displayName: `Copy of ${selectedFileGroup.displayName}`,
      color:
        theme.colors[
          TIMELINE_EVENT_TYPE_COLOR_MAPPING[
            selectedFileGroup.displayName as keyof typeof TIMELINE_EVENT_TYPE_COLOR_MAPPING
          ]
        ]?.[1] || '',
    });
  }, [selectedFileGroup, theme, onModeChange]);

  return (
    <Flex direction="column">
      {mode === 'View' && (
        <Flex direction="column" gap="md">
          <FileGroups
            fileGroups={fileGroups || []}
            headerRightSection={customFileGroupAction}
            loading={isLoading}
            organizationId={organizationId}
          />
        </Flex>
      )}

      {mode !== 'View' && (
        <UpsertFileGroupLayout
          key={mode}
          organizationId={organizationId}
          selectedFileGroup={selectedFileGroup}
          isEdit={mode === 'Edit'}
          onBack={handleBack}
          onClose={onClose}
          onDuplicate={handleDuplicate}
        />
      )}
    </Flex>
  );
};

export const FileGroupManagement = memo(FileGroupManagementBase);
