import { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconPower } from '@tabler/icons-react';

import { LogOutButtonProps } from './LogOutButton.types';
import { useLogout } from '@/shared/hooks/auth/useLogout';

const LogOutButtonBase = (props: LogOutButtonProps) => {
  const { user } = useAuth0();
  const { logout } = useLogout();

  if (!user) return null;

  return (
    <Flex align="center" gap={4}>
      <Text color="white" fz="0.75rem">
        Logged in as{' '}
        <Text component="span" fw={500}>
          {user?.email}
        </Text>
      </Text>

      <Tooltip label="Log out" withArrow withinPortal>
        <ActionIcon
          onClick={logout}
          size="lg"
          sx={(theme) => ({
            color: theme.white,
            '&:hover': {
              backgroundColor: theme.colors.dark[3],
            },
          })}
        >
          <IconPower />
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};

export const LogOutButton = memo(LogOutButtonBase);
