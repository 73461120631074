import { memo } from 'react';

import { RightSidebarProps } from './RightSidebar.types';
import { AssetSidebar } from '@/shared/components/files/AssetSidebar';
import { RightSidebarType, useLayoutStore } from '@/shared/states/layout';
import { WebPreviewerContainer } from '@/shared/components/preview/WebPreviewerContainer';
import { MarkdownSidePreviewer } from '@/shared/components/text/MarkdownSidePreviewer';

const RightSidebarBase: React.FC<RightSidebarProps> = () => {
  const { rightSidebarType } = useLayoutStore();

  if (rightSidebarType === RightSidebarType.ASSET) {
    return <AssetSidebar />;
  }

  if (rightSidebarType === RightSidebarType.WEB_PREVIEW) {
    return <WebPreviewerContainer />;
  }

  if (rightSidebarType === RightSidebarType.MARKDOWN_PREVIEW) {
    return <MarkdownSidePreviewer />;
  }

  return null;
};

export const RightSidebar = memo(RightSidebarBase);
