import { memo } from 'react';
import { ActionIcon, Button, Flex, Modal, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconUserOff } from '@tabler/icons-react';

import { RemoveMemberButtonProps } from './RemoveMemberButton.types';
import { canRemoveMembers } from '@/pageAI/services/organizations';
import { useRemoveOrganizationMember } from '@/pageAI/hooks/organizations/useRemoveOrganizationMember';
import { getUserDisplayName } from '@/pageAI/services/users';

const RemoveMemberButtonBase = ({ member, currentMember, organizationId }: RemoveMemberButtonProps) => {
  const { removeMember, isLoading } = useRemoveOrganizationMember(organizationId);

  const [opened, { open, close }] = useDisclosure();

  const handleRemoveMember = async () => {
    await removeMember({ organizationId, userId: member.user.id });

    close();
  };

  if (member.user.id === currentMember.user.id || !canRemoveMembers(currentMember)) return null;

  return (
    <>
      <Tooltip label="Remove member" withArrow withinPortal>
        <ActionIcon onClick={open}>
          <IconUserOff size={16} />
        </ActionIcon>
      </Tooltip>

      <Modal title="Remove member" opened={opened} onClose={close} zIndex={301} size={560}>
        <Flex direction="column" gap="lg">
          <Text fz="0.875rem">
            Are you sure you want to remove <strong>{getUserDisplayName(member.user)}</strong> ({member.user.email})
            from the organization? This action is irreversible.
          </Text>

          <Flex align="center" gap="xs" justify="end">
            <Button variant="subtle" color="gray.7" onClick={close} disabled={isLoading}>
              Cancel
            </Button>

            <Button loading={isLoading} onClick={handleRemoveMember} color="red">
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const RemoveMemberButton = memo(RemoveMemberButtonBase);
