import { memo } from 'react';
import { Flex, Skeleton, Text } from '@mantine/core';

import { singularOrPlural } from '@/shared/utils/string';
import { FileGroupsProps } from './FileGroups.types';
import { FileGroupRow } from '../FileGroupRow';

const FileGroupsBase = ({ fileGroups, organizationId, headerRightSection, loading = false }: FileGroupsProps) => {
  const renderRows = () => {
    if (loading) {
      return (
        <>
          <Skeleton width="100%" height={28} my={4} />
          <Skeleton width="100%" height={28} my={4} />
          <Skeleton width="100%" height={28} my={4} />
          <Skeleton width="100%" height={28} my={4} />
        </>
      );
    }

    if (!fileGroups.length) {
      return (
        <Flex align="center" py={4}>
          <Text color="dark.3" fz="0.875rem">
            There are no custom filters yet.
          </Text>
        </Flex>
      );
    }

    return (
      <>
        {fileGroups.map((fileGroup) => {
          return <FileGroupRow key={fileGroup.id} fileGroup={fileGroup} organizationId={organizationId} />;
        })}
      </>
    );
  };

  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" justify="space-between" gap="xs">
        <Flex align="center" gap={6}>
          <Text fz="0.875rem" color="dark.4">
            {fileGroups.length} {singularOrPlural('custom filter', 'custom filters')(fileGroups.length)}
          </Text>
        </Flex>

        {headerRightSection}
      </Flex>

      <Flex direction="column">{renderRows()}</Flex>
    </Flex>
  );
};

export const FileGroups = memo(FileGroupsBase);
