import localforage from 'localforage';

export const getItemFromStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const saveItemToStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const removeItemFromStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const getItemFromDB = async (key: string) => {
  return localforage.getItem(key);
};

export const saveItemToDB = async (key: string, value: string) => {
  return localforage.setItem(key, value);
};

export const removeItemFromDB = async (key: string) => {
  return localforage.removeItem(key);
};
