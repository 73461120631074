import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { MarkdownRendererProps } from './MarkdownRenderer.types';
import { MarkdownAnchorRenderer } from '../MarkdownAnchorRenderer';

const MarkdownRendererBase: React.FC<MarkdownRendererProps> = ({ content, components }) => {
  return (
    // TODO: Fix this TS error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={{ a: MarkdownAnchorRenderer, ...components }}>
      {content}
    </ReactMarkdown>
  );
};

export const MarkdownRenderer = memo(MarkdownRendererBase);
