import { useQuery } from '@tanstack/react-query';

import { organizationQuery } from '@/pageAI/api/organization';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { truthy } from '@/shared/utils/boolean';
import { GraphQLRequestFunction } from '@/shared/lib/graphql';

export const fetchOrganization = async (request: GraphQLRequestFunction, id: string | undefined) => {
  if (!id) return Promise.resolve(null);

  const responseBody = await request(organizationQuery, { id });

  if (!responseBody.organization) return null;

  return {
    ...responseBody.organization,
    members: responseBody.organization.members.edges?.map((edge) => edge?.node).filter(truthy) || [],
    invitations: responseBody.organization.invitations?.nodes?.filter(truthy) || [],
    joinRequests: responseBody.organization.joinRequests?.nodes?.filter(truthy) || [],
  };
};

export const queryMap = {
  organization: {
    queryKey: (id?: string) => ['organization', id],
    queryFn: fetchOrganization,
  },
};

export type OrganizationQuery = Awaited<ReturnType<typeof queryMap.organization.queryFn>>;

export const useOrganization = (id?: string) => {
  const { request } = useGraphQLRequest();

  const { data, isLoading, isError } = useQuery(queryMap.organization.queryKey(id), () =>
    queryMap.organization.queryFn(request, id),
  );

  return { organization: data, isLoading, isError };
};
