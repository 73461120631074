import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { POST_LOG_OUT_REDIRECT_URL } from '@/shared/config';

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = useCallback(() => {
    auth0Logout({ returnTo: POST_LOG_OUT_REDIRECT_URL || window.location.origin });
  }, [auth0Logout]);

  return { logout };
};
