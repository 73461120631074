import { create } from 'zustand';

import { FileAsset } from '@/shared/@types';

export interface FileSearchState {
  activeResult: {
    file: FileAsset;
    pageNumber: number;
    rowIndex: number;
  } | null;
}

export const useFileSearchStore = create<FileSearchState>((set) => ({
  activeResult: null,
}));

export const setActiveResult = (activeResult: FileSearchState['activeResult']) =>
  useFileSearchStore.setState({ activeResult });

export const resetActiveResult = () => useFileSearchStore.setState({ activeResult: null });
