import { memo } from 'react';
import { Flex, Text, useMantineTheme } from '@mantine/core';

import { ClientSummaryCtaPlaceholderProps } from './ClientSummaryCtaPlaceholder.types';
import { Panel } from '@/pageAI/designSystem/Panel';
import { GenerateSummariesButton } from '@/pageAI/components/summaries/GenerateSummariesButton';

const ClientSummaryCtaPlaceholderBase = ({ client }: ClientSummaryCtaPlaceholderProps) => {
  const theme = useMantineTheme();

  return (
    <Panel sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <Flex
        direction="column"
        align="center"
        p="xl"
        sx={{
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: 4,
        }}
      >
        <Flex direction="column" align="center" gap={4}>
          <Text fz="1.25rem" fw={600} color="dark.5" align="center">
            Page Summaries
          </Text>

          <Text color="dark.2" align="center" mb="xs" sx={{ maxWidth: 800 }}>
            {client.viewerCanGenerateTimeline ? (
              <>
                After uploading all of your client&apos;s files, generate comprehensive summaries to quickly review and
                analyze conditions, rating decisions, medical evidence, military history, and claims/court records.
              </>
            ) : (
              <>
                You do not have permission to generate summaries for this client. Currently, only the owners of the
                client can perform this action.
              </>
            )}
          </Text>
        </Flex>

        <GenerateSummariesButton client={client} />
      </Flex>
    </Panel>
  );
};

export const ClientSummaryCtaPlaceholder = memo(ClientSummaryCtaPlaceholderBase);
