import { memo, useCallback, useMemo, useState } from 'react';
import { ActionIcon, Button, Flex, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { AddEventToUnknownConditionDropdownProps } from './AddEventToUnknownConditionDropdown.types';
import { MedicalConditionMenu } from '../../MedicalConditionMenu';
import { useClientMedicalConditions } from '@/pageAI/hooks/clients/useClientMedicalConditions';
import { useSelectedClient } from '@/pageAI/hooks/clients/useSelectedClient';
import { MedicalConditionItem } from '@/pageAI/@types/summaries';
import { selectedConditionSelector, useUnifiedStore } from '@/pageAI/states/unified';

const AddEventToUnknownConditionDropdownBase = ({ onConfirm, onClose }: AddEventToUnknownConditionDropdownProps) => {
  const client = useSelectedClient(true);

  const selectedCondition = useUnifiedStore(selectedConditionSelector);

  const [selectedConditionIds, setSelectedConditionIds] = useState<string[]>(() =>
    selectedCondition ? [selectedCondition.id] : [],
  );

  const { medicalConditions, isLoading, isError } = useClientMedicalConditions({
    clientId: client.id,
    autoSelect: false,
  });

  const selectedConditions = useMemo(
    () => medicalConditions.filter((condition) => selectedConditionIds.includes(condition.id)),
    [medicalConditions, selectedConditionIds],
  );

  const handleSelect = useCallback(
    (condition: MedicalConditionItem) => {
      if (selectedConditionIds.includes(condition.id)) {
        setSelectedConditionIds(
          selectedConditionIds.filter((selectedConditionId) => selectedConditionId !== condition.id),
        );

        return;
      }

      setSelectedConditionIds([...selectedConditionIds, condition.id]);
    },
    [selectedConditionIds],
  );

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();

    onClose?.();
  };

  const handleConfirm = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!selectedConditionIds.length) return;

    onConfirm?.(selectedConditions);
  };

  return (
    <Flex direction="column" onClick={(event) => event.stopPropagation()}>
      <Flex align="center" justify="space-between" py="xs" px={12} gap="xs">
        <Text fw={600} color="dark.4">
          Select conditions&nbsp;
          <Text component="span" fz="0.75rem" fw={400} color="gray.6">
            ({selectedConditionIds.length} selected)
          </Text>
        </Text>

        <ActionIcon size="xs" onClick={onClose}>
          <IconX size={14} />
        </ActionIcon>
      </Flex>

      <MedicalConditionMenu
        selected={selectedConditionIds}
        onSelect={handleSelect}
        medicalConditions={medicalConditions}
        loading={isLoading}
        maxHeight="240px"
        checkboxMode
      />

      <Flex
        align="center"
        justify="end"
        gap="xs"
        px={12}
        py="xs"
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Button variant="subtle" color="gray.7" size="xs" onClick={handleClose}>
          Close
        </Button>

        <Button size="xs" onClick={handleConfirm} disabled={!selectedConditionIds}>
          Confirm
        </Button>
      </Flex>
    </Flex>
  );
};

export const AddEventToUnknownConditionDropdown = memo(AddEventToUnknownConditionDropdownBase);
