import { memo, useCallback, useMemo, useState } from 'react';
import { Accordion, Avatar, Flex, Text } from '@mantine/core';

import { MedicalConditionsProps } from './MedicalConditions.types';
import { getMedicalConditionElementId } from '@/pageAI/services/medicalConditions';
import { useHighlightTimelineEventInMedicalConditions } from './MedicalConditions.utils';
import { OldMedicalSubConditionsTooltip } from '../OldMedicalSubConditionsTooltip';
import { MedicalConditionItemAsSummary } from '../../MedicalConditionItemAsSummary';
import { MedicalConditionItemAsTimeline } from '../../MedicalConditionItemAsTimeline';

const MedicalConditionsBase = ({ medicalConditions, tab = 'Summary' }: MedicalConditionsProps) => {
  const [value, setValue] = useState<string[]>([]);

  const valueMapping = useMemo(
    () => value.reduce((acc, val) => ({ ...acc, [val]: true }), {} as Record<string, boolean>),
    [value],
  );

  useHighlightTimelineEventInMedicalConditions(medicalConditions);

  const handleChange = useCallback((newValue: string[]) => {
    setValue(newValue);
  }, []);

  const items = useMemo(
    () =>
      medicalConditions.map((medicalCondition, index) => (
        <Accordion.Item
          key={medicalCondition.id}
          value={medicalCondition.id}
          id={getMedicalConditionElementId(medicalCondition)}
          sx={(theme) => ({
            '.ghost-Avatar-placeholder': {
              background: theme.colors.dark[3],
              transition: 'all 0.2s',
            },
            '.ghost-Accordion-label': {
              color: theme.colors.dark[4],
              transition: 'all 0.2s',
              padding: '12px 0',
            },
            '&[data-active=true]': {
              '.ghost-Avatar-placeholder': {
                background: theme.colors.red[6],
              },
              '.ghost-Accordion-label': {
                color: theme.colors.red[6],
              },
            },
          })}
        >
          <Accordion.Control>
            <Flex align="center" gap={6}>
              <Avatar radius="50%" variant="filled" size={22}>
                <Text fz="0.625rem">{index + 1}</Text>
              </Avatar>

              <Flex direction="column">
                <Flex align="center" gap={4}>
                  <Text fw={500} fz="0.875rem">
                    {medicalCondition.headerCondition}
                  </Text>

                  <OldMedicalSubConditionsTooltip medicalCondition={medicalCondition} />
                </Flex>
              </Flex>
            </Flex>
          </Accordion.Control>

          <Accordion.Panel
            sx={(theme) => ({
              paddingLeft: 4,
              '.ghost-Timeline-item': {
                '&:not(:last-of-type)::before': { left: -4 },
                paddingLeft: 14,
                '.ghost-Timeline-itemBullet': {
                  width: 20,
                  height: 20,
                },
                p: { margin: 0 },
                ul: { fontSize: '0.875rem', paddingLeft: 24, margin: 0 },
                '.ghost-Timeline-itemTitle': {
                  fontSize: '0.875rem',
                  paddingTop: 3,
                },
                code: {
                  fontFamily: 'unset',
                  textDecoration: 'underline',
                  fontWeight: 500,
                },
              },
              '.ghost-Accordion-content': {
                '> div': {
                  padding: '0 12px 0 0',
                },
              },
            })}
          >
            {valueMapping[medicalCondition.id as string] && (
              <>
                {tab === 'Summary' ? (
                  <MedicalConditionItemAsSummary medicalCondition={medicalCondition} />
                ) : (
                  <MedicalConditionItemAsTimeline medicalCondition={medicalCondition} />
                )}
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      )),
    [medicalConditions, tab, valueMapping],
  );

  return (
    <Accordion
      value={value}
      transitionDuration={200}
      onChange={handleChange}
      multiple
      chevronPosition="right"
      variant="contained"
      sx={{
        '.ghost-Accordion-item': {
          borderLeft: 'none',
          borderRight: 'none',
          paddingLeft: 12,
          '&:first-of-type': {
            borderTop: 'none',
          },
          '&:last-of-type': {
            borderBottom: 'none',
          },
        },
      }}
    >
      {items}
    </Accordion>
  );
};

export const OldMedicalConditions = memo(MedicalConditionsBase);
