import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';
import { getFileIndexConfigQuery } from '@/pageAI/api';
import { FileIndexConfig, TimelineEventType } from '@/pageAI/gql/graphql';
import { useSelectedClient } from '../../clients/useSelectedClient';
import { getClientFiles } from '@/pageAI/services/clients';

export const useFileIndexConfig = () => {
  const { request } = useGraphQLRequest();
  const selectedClient = useSelectedClient();

  const { data, isLoading, isError } = useQuery(['file-index-config'], () => request(getFileIndexConfigQuery));

  const fileNames = useMemo(() => {
    const fromData: FileIndexConfig[] = data?.getFileIndexConfig || [];
    const fromClient: FileIndexConfig[] = (selectedClient ? getClientFiles(selectedClient).fileAssets : []).map(
      (fileAsset) => ({
        cleanedFileName: fileAsset.metadata?.pageAIMetadata?.cleanedFileName || fileAsset.name,
        classification: fileAsset.metadata?.pageAIMetadata?.eventTypeClassifications?.[0] || TimelineEventType.Other,
      }),
    );
    const merged = [...fromData, ...fromClient];

    return merged
      .reduce((acc, curr) => {
        const found = acc.find((item) => item.cleanedFileName === curr.cleanedFileName);

        if (!found) acc.push(curr);

        return acc;
      }, [] as FileIndexConfig[])
      .map((fileConfig) => fileConfig.cleanedFileName);
  }, [data, selectedClient]);

  return {
    fileNames,
    isLoading,
    isError,
  };
};
