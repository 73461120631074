import { memo, useRef, useState } from 'react';
import { ActionIcon, Button, Flex, Text, Tooltip } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';

import { UserMessageProps } from './UserMessage.types';
import { UserAvatar } from '@/shared/components/users/UserAvatar';
import { moveCaretToEnd } from '@/shared/lib/dom';

const UserMessageBase: React.FC<UserMessageProps> = ({ message, messageIndex, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const messageContentElementRef = useRef<HTMLDivElement | null>(null);

  const handleStartEditing = () => {
    setIsEditing(true);

    setTimeout(() => {
      if (!messageContentElementRef.current) return;

      moveCaretToEnd(messageContentElementRef.current);
    });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
  };

  const handleFinishEditing = () => {
    if (!messageContentElementRef.current) return;

    onEdit?.(messageContentElementRef.current.innerText, message, messageIndex);

    setIsEditing(false);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFinishEditing();
    }
  };

  return (
    <Flex
      direction="column"
      gap={4}
      className="chat-user-message"
      sx={{
        '&:hover': {
          '.chat-user-message__actions': { opacity: 1, pointerEvents: 'all' },
        },
      }}
    >
      <Flex align="center" gap="xs">
        <UserAvatar />

        <Text
          ref={messageContentElementRef}
          color="dark"
          fz="0.875rem"
          contentEditable={isEditing}
          sx={{ outline: 'none !important', minWidth: '100%', minHeight: 22 }}
          onKeyDown={handleKeyDown}
        >
          {message.content}
        </Text>
      </Flex>

      {isEditing ? (
        <Flex align="center" sx={{ marginLeft: 40 }} gap={4}>
          <Button size="xs" onClick={handleFinishEditing}>
            Save & Submit
          </Button>

          <Button size="xs" onClick={handleCancelEditing} variant="subtle" color="dark">
            Cancel
          </Button>
        </Flex>
      ) : (
        <Flex
          className="chat-user-message__actions"
          align="center"
          sx={{
            marginLeft: 40,
            opacity: 0,
            transition: 'all 0.1s',
            pointerEvents: 'none',
          }}
          gap={4}
        >
          <Tooltip label="Edit">
            <ActionIcon onClick={handleStartEditing} size="xs">
              <IconEdit size={16} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
};

export const UserMessage = memo(UserMessageBase);
