import { create } from 'zustand';

import { RightSidebarType, closeRightSidebar, openRightSidebar } from '../layout';
import { MarkdownPreviewState } from './markdownPreview.states.types';

export const useMarkdownPreviewStore = create<MarkdownPreviewState>(() => ({
  title: '',
  markdown: '',
  titleSuffix: null,
}));

export const setMarkdownPreviewTitle = (title: string) => useMarkdownPreviewStore.setState({ title });

export const setMarkdownPreviewContent = (markdown: string) => useMarkdownPreviewStore.setState({ markdown });

export const setMarkdownPreviewTitleSuffix = (titleSuffix: React.ReactNode) =>
  useMarkdownPreviewStore.setState({ titleSuffix });

export const openMarkdownPreviewer = (markdown: string, title: string, titleSuffix: React.ReactNode = null) => {
  setMarkdownPreviewContent(markdown);
  setMarkdownPreviewTitle(title);
  setMarkdownPreviewTitleSuffix(titleSuffix);
  openRightSidebar(RightSidebarType.MARKDOWN_PREVIEW);
};

export const closeMarkdownPreviewer = () => {
  setMarkdownPreviewContent('');
  setMarkdownPreviewTitle('');
  setMarkdownPreviewTitleSuffix(null);
  closeRightSidebar();
};
