import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { getOcrTextQuery } from '@/pageAI/api';
import { useGraphQLRequest } from '@/shared/hooks/graphql/useGraphQLRequest';

export const useGetOcrText = () => {
  const { request } = useGraphQLRequest();
  const queryClient = useQueryClient();

  const getOcrText = useCallback(
    async (fileId: string) => {
      const response = await queryClient.fetchQuery(['ocr-text', fileId], () =>
        request(getOcrTextQuery, { id: fileId }),
      );

      return response.getOCRText.text;
    },
    [queryClient, request],
  );

  return { getOcrText };
};
